// @ts-nocheck
/* eslint-enable */
import styled from 'styled-components';
import { colors } from 'app/shared/styles/_colors';
import { font } from 'app/shared/styles/_fontsize';

export const MapOptionsButtonStyled = styled.button`
  -webkit-user-select: none;
  box-shadow: rgba(0, 0, 0, 0.298039) 0px 1px 4px -1px;
  border-radius: 4px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  background-color: ${colors['$hpx-white']};
  margin: 0px;
  margin-bottom: 10px;
  color: ${colors['$hpx-grey-600']};
  font-size: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MapOptionsResetButtonContainerStyled = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const MapOptionsResetButtonStyled = styled.button`
  ${font.sm};
  background: none;
  color: ${colors['$hpx-grey-600']};
  text-decoration: underline;
`;
