// @ts-nocheck
/* eslint-enable */
export const RENTAL_APPLICATION_STATUS = {
  // have not decided whether they will be accepting applications or not
  // show "request to apply" to rachel
  // HPWEB-6106: Always show "apply" if !accepted
  undecided: 'undecided',

  // are currently accepting applications
  // show "appy" button to rachel
  accepted: 'accepted',

  // landlord is not accepting applications
  // HPWEB-6106: Always show "apply" if !accepted
  unaccepted: 'unaccepted',
};

export const shouldShowApplyButton = (rentalApplicationStatus) => {
  if (rentalApplicationStatus?.toLowerCase() === RENTAL_APPLICATION_STATUS.accepted.toLowerCase()) {
    return true;
  } else {
    return false;
  }
};

export const shouldShowRequestToApplyButton = (rentalApplicationStatus) => {
  if (rentalApplicationStatus?.toLowerCase() !== RENTAL_APPLICATION_STATUS.accepted.toLowerCase()) {
    return true;
  } else {
    return false;
  }
};
