// @ts-nocheck
/* eslint-enable */
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { inline, padding, stacked } from 'app/shared/styles/_spacing';
import { colors } from 'app/shared/styles/_colors';
import { yieldCallback } from '@zillow/yield-callback';

// Lodash
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isNull from 'lodash/isNull';

import { analyticsEvent } from 'app/client/universal-analytics';
import { gaEvents } from 'app/shared/constants/AnalyticsConstants';
import Button from 'app/shared/core/Button';
import CommuteSelectionModal from 'app/shared/modules/hdp/CommuteSelectionModal';
import CommuteTimesV2 from 'app/shared/modules/hdp/CommuteTimesV2';
import commuteUtils from 'app/shared/utils/commuteUtils';
import constants from 'app/shared/constants/ConstantsBundle';
import controller from './controller';
import ErrorActions from 'app/shared/flux/actions/ErrorActions';
import { FilterActions_handleReduxOnlyFilterChange } from 'app/shared/flux/actions/FilterActions';
import gmapUtils from 'app/client/utils/map/gmapUtils';
import HdpContentWrapper from 'app/shared/modules/hdp/HdpContentWrapper';
import IconBike from 'images/icons/bike.svg';
import IconBus from 'images/icons/bus.svg';
import IconCar from 'images/icons/car.svg';
import IconFeet from 'images/icons/feet.svg';
import InrixLogo from 'images/inrix-logo.svg';
import IconInformation from 'images/icons/more-info-grey.svg';
import LinkController from 'app/shared/modules/LinkController';
import ListingEngineActions from 'app/shared/flux/actions/ListingEngineActions';
import { listingUtils_getDistanceToUserPoint } from 'app/shared/utils/listingUtils';
import LocationMarker from 'images/location-marker.svg';
import MiniMap from 'app/shared/modules/area/MiniMap';
import Popover from 'app/shared/modules/popups/Popover';
import PopupModal from 'app/shared/modules/popups/PopupModal';
import Text from 'app/shared/core/Text';
import UserPointActions from 'app/shared/flux/actions/UserPointActions';
import { COMMUTE_MODE_TYPES_JAVA_TO_REDUX } from 'app/shared/constants/FilterConstants';
import {
  disconnectIntersectionObserver,
  intersectionObserverWrapper,
} from 'app/shared/utils/intersectionObserverUtils';
import './style.scss';

// Static Asset URLs required by Google's StaticMap API.
const ICON_URL = {
  building: 'http://nodes3cdn.hotpads.com/hotpads-web/static_assets/building.png',
  condo: 'http://nodes3cdn.hotpads.com/hotpads-web/static_assets/building.png',
  house: 'http://nodes3cdn.hotpads.com/hotpads-web/static_assets/house.png',
  room: 'http://nodes3cdn.hotpads.com/hotpads-web/static_assets/room.png',
  userPoint: 'http://nodes3cdn.hotpads.com/hotpads-web/static_assets/userpoint.png',
};
const { MAX_COMMUTE_DISTANCE } = constants;

const StyledContainer = styled.div`
  ${stacked._2x};
  ${padding.top._2x};
`;
const StyledCustomLocationButton = styled.button`
  ${stacked._2x};
`;
const StyledLocationContainer = styled.div`
  ${stacked._2x};
`;
const StyledToolTipText = styled(Text)`
  ${inline._3x};
`;

const StyledToolTipIcon = styled.img`
  ${inline._xs};
`;
const StyledCommuteDestinationButton = styled(Button)`
  display: inline-block;
  width: 100%;
`;
const StyledDistanceText = styled.div`
  color: ${colors['$hpx-grey-600']};
`;
class Local extends React.Component {
  static propTypes = {
    listing: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    const localValue = typeof localStorage !== 'undefined' ? localStorage.getItem('commuteDepartureTime') : 'now';
    const departureTime = commuteUtils.mapLocalStorageTime(localValue);

    this.state = {
      activeRouteMode: typeof localStorage !== 'undefined' ? localStorage.getItem('commuteActiveRouteMode') : 'driving',
      containerWidth: null,
      distance: null,
      isInfoPopoverVisible: false,
      loading: true,
      mapData: {
        lat: null,
        lon: null,
        zoom: 14,
      },
      mapMarkers: null,
      travelTime: {
        cycling: '...',
        driving: '...',
        transit: '...',
        walking: '...',
      },
      routes: {
        cycling: null,
        driving: null,
        transit: null,
        walking: null,
      },
      departureTime,
      showDefaultLocation: false,
      userPointModalEditing: false,
      userPointModalVisible: false,
      userPoint: null,
      isComponentVisible: false,
    };

    this.mapElement = React.createRef();
    this.scrollY = false;

    this.targetRef = React.createRef();
    this.observer = null;
  }

  componentDidMount() {
    this.observer = intersectionObserverWrapper(this.targetRef.current, this.handleIntersection, {
      root: null,
      threshold: 0.5,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.isComponentVisible) {
      const currentLocation = this.state.userPoint;
      const nextLocation =
        !isEmpty(nextProps.userPoints) && !isNull(nextProps.activeUserPoint)
          ? nextProps.userPoints[nextProps.activeUserPoint]
          : null;

      const currentUserPoint =
        isEmpty(this.props.userPoints) && !isNull(this.props.activeUserPoint)
          ? this.props.userPoints[this.props.activeUserPoint]
          : null;

      const nextUserPoint =
        isEmpty(nextProps.userPoints) && !isNull(nextProps.activeUserPoint)
          ? nextProps.userPoints[nextProps.activeUserPoint]
          : null;

      // Handle case where user clears active user point.
      if (this.props.activeUserPoint !== null && nextProps.activeUserPoint === null) {
        this.setState(
          {
            distance: null,
            showDefaultLocation: false,
            userPoint: null,
          },
          () => {
            this.getMapMarkers(nextProps);
            this.getCenterPoint(nextProps);
          },
        );

        return;
      }

      if (isNull(this.state.userPoint) && !isEmpty(nextProps.userPoints) && !isNull(nextProps.activeUserPoint)) {
        this.setState(
          {
            showDefaultLocation: false,
            userPoint: nextLocation,
          },
          () => {
            this.calcTransitTimes(nextProps.userPoints[nextProps.activeUserPoint]);
            this.getMapMarkers(nextProps);
            this.getCenterPoint(nextProps);
          },
        );

        return;
      }

      // Handle user point location updated (either from default to new location or switching between existing locations.)
      if (nextLocation && !isEqual(currentLocation, nextLocation)) {
        this.setState(
          {
            showDefaultLocation: false,
            userPoint: nextLocation,
          },
          () => {
            this.calcTransitTimes(nextProps.userPoints[nextProps.activeUserPoint]);
            this.getMapMarkers(nextProps);
            this.getCenterPoint(nextProps);
          },
        );

        return;
      }

      if (!isEmpty(nextProps.userPoints) && !isNull(nextProps.activeUserPoint) && currentUserPoint !== nextUserPoint) {
        this.setState(
          {
            showDefaultLocation: false,
            userPoint: nextProps.userPoints[nextProps.activeUserPoint],
          },
          () => {
            this.calcTransitTimes(nextProps.userPoints[nextProps.activeUserPoint]);
            this.getMapMarkers(nextProps);
            this.getCenterPoint(nextProps);
          },
        );

        return;
      }
    }
  }

  componentWillUnmount() {
    disconnectIntersectionObserver(this.observer);
  }

  handleIntersection = () => {
    this.setState({
      isComponentVisible: true,
    });
    const { activeUserPoint, area, dispatch, userPoints } = this.props;
    const paddingSize = 32;

    this.setState({
      containerWidth: window.innerWidth - paddingSize,
    });

    if (!isEmpty(userPoints) && !isNull(activeUserPoint)) {
      this.setState(
        {
          activeRouteMode: localStorage.getItem('commuteActiveRouteMode') || 'driving',
          departureTime: localStorage.getItem('commuteDepartureTime') || 'now',
          loading: false,
          showDefaultLocation: false,
          userPoint: userPoints[activeUserPoint],
        },
        () => {
          this.calcTransitTimes(userPoints[activeUserPoint]);
          this.getMapMarkers(this.props);
          this.getCenterPoint(this.props);
        },
      );
    }

    if (isEmpty(userPoints)) {
      dispatch(UserPointActions.fetchDefaultLocation(area.resourceId)).then((defaultLocation) => {
        if (defaultLocation) {
          this.setState(
            {
              activeRouteMode: localStorage.getItem('commuteActiveRouteMode') || 'driving',
              departureTime: localStorage.getItem('commuteDepartureTime') || 'now',
              loading: false,
              showDefaultLocation: true,
              userPoint: defaultLocation,
            },
            () => {
              this.calcTransitTimes(defaultLocation);
              this.getMapMarkers(this.props);
              this.getCenterPoint(this.props);
            },
          );
        } else {
          this.setState(
            {
              activeRouteMode: localStorage.getItem('commuteActiveRouteMode') || 'driving',
              departureTime: localStorage.getItem('commuteDepartureTime') || 'now',
              loading: false,
              showDefaultLocation: false,
            },
            () => {
              this.getMapMarkers(this.props);
              this.getCenterPoint(this.props);
            },
          );
        }
      });
    }
  };

  calcTransitTimes = (userPoint) => {
    const { dispatch, listing } = this.props;
    const { departureTime } = this.state;

    const lat = userPoint.lat;
    const lon = userPoint.lon;

    const locationParams = {
      commuteTimeMode: departureTime,
      commuteType: 'DRIVING,CYCLING,WALKING,TRANSIT',
      lat0: listing.geo.lat,
      lon0: listing.geo.lon,
      originLat: lat,
      originLon: lon,
    };

    const distance = listingUtils_getDistanceToUserPoint(locationParams).toFixed(2);
    this.setState({ distance });

    dispatch(ListingEngineActions.getCommuteTime(locationParams))
      .then((result = []) => {
        if (!isEmpty(result)) {
          const travelTime = {
            cycling: result[0].cycling,
            driving: result[0].driving,
            transit: result[0].transit,
            walking: result[0].walking,
          };

          this.setState({ travelTime });
        }
      })
      .catch((error) => {
        this.props.dispatch(
          ErrorActions.errorHandler({
            error,
            errorLocation: 'component.commuteTimes.calcTransitTimes',
            errorClass: 'listingEngineActions',
          }),
        );
      });

    if (distance < MAX_COMMUTE_DISTANCE) {
      dispatch(ListingEngineActions.getCommuteRoute(locationParams)).then((result) => {
        const { cycling, driving, transit, walking } = result;
        const routes = {
          cycling: cycling ? cycling : null,
          driving: driving ? driving : null,
          transit: transit ? transit : null,
          walking: walking ? walking : null,
        };

        this.setState({ routes });
      });
    }
  };

  getCenterPoint = (props) => {
    const { listing } = props;
    const { containerWidth, userPoint } = this.state;

    if (isNull(userPoint) || !userPoint) {
      this.setState({
        mapData: {
          lat: listing.geo.lat,
          lon: listing.geo.lon,
          zoom: 14,
        },
      });

      return;
    }

    const minMaxLatLong = gmapUtils.getMaxLatLonExtent(listing.geo, userPoint);
    const latLon = gmapUtils.getCenterLatLonFromPoints(listing.geo, userPoint);
    const mapData = Object.assign({}, this.state.mapData);
    const zoom = gmapUtils.getZoomForBoundingBox({
      maxLon: minMaxLatLong.maxLon,
      minLon: minMaxLatLong.minLon,
      maxLat: minMaxLatLong.maxLat,
      minLat: minMaxLatLong.minLat,
      mapDimensions: {
        height: 300,
        width: containerWidth,
      },
    });

    mapData.lat = latLon.lat;
    mapData.lon = latLon.lon;
    mapData.zoom = zoom;

    this.setState({ mapData });
  };

  getMapMarkers = (props) => {
    const { listing } = props;
    const { userPoint } = this.state;

    const mapIcon = listing.listingType === 'room' ? ICON_URL.room : ICON_URL[listing.iconType];

    const mapMarkers = [
      {
        url: mapIcon,
        geo: {
          lat: listing.geo.lat,
          lon: listing.geo.lon,
        },
      },
    ];

    if (!isNull(userPoint) && userPoint) {
      mapMarkers.push({
        url: ICON_URL.userPoint,
        geo: {
          lat: userPoint.lat,
          lon: userPoint.lon,
        },
      });
    }

    this.setState({ mapMarkers });
  };

  handleFilterChange = (partialFilterObj) => {
    const { dispatch, filter } = this.props;

    dispatch(
      FilterActions_handleReduxOnlyFilterChange({
        commute: {
          ...filter.commute,
          ...partialFilterObj,
        },
      }),
    );
  };

  handleHideUserEditModal = (e) => {
    if (e) {
      e.preventDefault();
    }

    this.setState(
      {
        userPointModalEditing: false,
        userPointModalVisible: false,
      },
      () => {
        if (this.props.isMobile) {
          window.scrollTo(0, this.scrollY);
        }
      },
    );
  };

  handleSetActiveMode = ({ mode }) => {
    localStorage.setItem('commuteActiveRouteMode', mode);
    this.setState({ activeRouteMode: mode });

    this.handleFilterChange({
      commuteMode: COMMUTE_MODE_TYPES_JAVA_TO_REDUX[mode.toUpperCase()],
      commuteTimeMode: COMMUTE_MODE_TYPES_JAVA_TO_REDUX.now,
    });

    let trackEvent;
    switch (mode) {
      case 'driving':
        trackEvent = gaEvents.COMMUTEV2_DRIVE_CLICK_MODAL;
        break;
      case 'transit':
        trackEvent = gaEvents.COMMUTEV2_TRANSIT_CLICK_MODAL;
        break;
      case 'walking':
        trackEvent = gaEvents.COMMUTEV2_WALK_CLICK_MODAL;
        break;
      case 'cycling':
        trackEvent = gaEvents.COMMUTEV2_BIKE_CLICK_MODAL;
        break;
    }

    this.props.dispatch(analyticsEvent(trackEvent));
  };

  yieldHandleTracking = yieldCallback((event) => {
    const { dispatch } = this.props;
    dispatch(analyticsEvent(event));
  });

  yieldHandleCommuteState = yieldCallback((activeRouteMode, userPointModalEditing) => {
    this.setState({
      activeRouteMode,
      userPointModalEditing,
      userPointModalVisible: true,
    });
  });

  yieldHandleFilterChange = yieldCallback((mode) => {
    this.handleFilterChange({
      commuteMode: COMMUTE_MODE_TYPES_JAVA_TO_REDUX[mode.toUpperCase()],
      commuteTimeMode: COMMUTE_MODE_TYPES_JAVA_TO_REDUX.now,
    });
  });

  handleShowUserPointModal = ({ mode, focusInput, mapClick }) => {
    const { showDefaultLocation, userPoint } = this.state;

    const activeRouteMode = mode ? mode : this.state.activeRouteMode;
    const userPointModalEditing =
      focusInput === true || focusInput === false ? focusInput : this.state.userPointModalEditing;

    this.scrollY = window.scrollY;

    if (mode) {
      let trackEvent;

      this.yieldHandleFilterChange(mode);
      switch (mode) {
        case 'driving':
          trackEvent = gaEvents.COMMUTEV2_DRIVING_CLICK_HDP;
          break;
        case 'transit':
          trackEvent = gaEvents.COMMUTEV2_TRANSIT_CLICK_HDP;
          break;
        case 'walking':
          trackEvent = gaEvents.COMMUTEV2_FOOT_CLICK_HDP;
          break;
        case 'cycling':
          trackEvent = gaEvents.COMMUTEV2_BIKE_CLICK_HDP;
          break;
      }

      this.yieldHandleTracking(trackEvent);
    } else if (mapClick) {
      this.yieldHandleTracking(gaEvents.COMMUTEV2_MAP_CLICK_HDP);
    } else if (!showDefaultLocation && userPoint) {
      this.yieldHandleTracking(gaEvents.COMMUTEV2_EDIT_DEST_HDP);
    } else if (showDefaultLocation && userPoint) {
      this.yieldHandleTracking(gaEvents.COMMUTEV2_CHANGE_DEST_HDP);
    } else if (!showDefaultLocation && !userPoint) {
      this.yieldHandleTracking(gaEvents.COMMUTEV2_ADD_DEST_HDP);
    }

    this.yieldHandleCommuteState(activeRouteMode, userPointModalEditing);
  };

  yieldSetDepartureTime = yieldCallback((departureTime) => {
    localStorage.setItem('commuteDepartureTime', departureTime);
    this.setState({ departureTime }, () => {
      this.calcTransitTimes(this.state.userPoint);
    });

    let trackEvent;
    switch (departureTime) {
      case 'now':
        trackEvent = gaEvents.COMMUTEV2_DEPART_NOW;
        break;
      case 'rushHour':
        trackEvent = gaEvents.COMMUTEV2_DEPART_RUSH;
        break;
      case 'offPeak':
        trackEvent = gaEvents.COMMUTEV2_DEPART_OFFPEAK;
        break;
    }

    this.props.dispatch(analyticsEvent(trackEvent));
  });

  handleSetDepartureTime = (e) => {
    const departureTime = e.target.value;
    this.yieldSetDepartureTime(departureTime);
  };

  setPopoverVisibilitry = (bool) => {
    this.setState({ isInfoPopoverVisible: bool });
  };

  renderPreview = () => {
    let commuteTime;
    const { activeRouteMode, showDefaultLocation, travelTime, userPoint } = this.state;

    if (activeRouteMode) {
      const mins = travelTime[activeRouteMode];
      if (mins === 120 || mins === '>120') {
        commuteTime = '2+ h';
      } else if (mins > 60) {
        commuteTime = `1 h ${mins % 60} m`;
      } else if (mins === 60) {
        commuteTime = `1 h`;
      } else if (mins < 60) {
        commuteTime = `${mins} min`;
      }
    }

    const iconMap = {
      driving: IconCar,
      transit: IconBus,
      walking: IconFeet,
      cycling: IconBike,
    };

    return (
      <div className="HdpLocal-preview">
        {this.state.isComponentVisible ? (
          <>
            {(showDefaultLocation || !userPoint) && (
              <button
                className="HdpLocal-preview-link"
                onClick={() => this.handleShowUserPointModal({ focusInput: true })}
              >
                Add destination
              </button>
            )}
            {!showDefaultLocation && userPoint && commuteTime && (
              <>
                <img className="HdpLocal-preview-icon" src={iconMap[activeRouteMode]} alt="" />
                <span className="HdpLocal-preview-span">{commuteTime}</span>
              </>
            )}
          </>
        ) : (
          <p>Loading</p>
        )}
      </div>
    );
  };

  render() {
    let userPointName = '';
    const { activeUserPoint, listing, isMobile, userPoints } = this.props;
    const {
      activeRouteMode,
      containerWidth,
      distance,
      isInfoPopoverVisible,
      loading,
      mapData,
      mapMarkers,
      showDefaultLocation,
      travelTime,
      userPointModalEditing,
      userPointModalVisible,
      userPoint,
    } = this.state;

    if (!isEmpty(userPoints) && !isNull(activeUserPoint)) {
      userPointName = userPoints[activeUserPoint].name || userPoints[activeUserPoint].address;
    }

    const minMaxLatLong = gmapUtils.getMaxLatLonExtent(listing.geo, userPoint);
    const InfoIcon = (
      <StyledToolTipIcon
        className="HdpLocal-default-info-icon"
        src={IconInformation}
        alt="default commute information"
      />
    );

    return (
      <HdpContentWrapper
        collapsable
        headerAside={this.renderPreview()}
        header={`Commute`}
        headerHtmlTag="h2"
        parentRef={this.targetRef}
      >
        {userPointModalVisible && (
          <PopupModal className="HdpLocal-commute-modal" onHidePopup={this.handleHideUserEditModal}>
            <CommuteSelectionModal
              activeRouteMode={this.state.activeRouteMode}
              departureTime={this.state.departureTime}
              distance={this.state.distance}
              focusInput={userPointModalEditing}
              gmapLinks={controller.getGoogleDirectionsLinks(listing, userPoint)}
              listing={this.props.listing}
              loading={loading}
              minMaxLatLong={minMaxLatLong}
              onHandleSetActiveMode={this.handleSetActiveMode}
              onSetDepartureTime={this.handleSetDepartureTime}
              path={this.state.routes[activeRouteMode]}
              showDefaultLocation={showDefaultLocation}
              travelTime={travelTime}
              userPoint={userPoint}
              userPointName={userPointName}
              userPoints={userPoints}
            />
          </PopupModal>
        )}
        <div className="HdpLocal" ref={this.mapElement}>
          <StyledContainer className="HdpLocal-commute-details">
            <div className="HdpLocal-location-marker-container">
              <img className="HdpLocal-location-marker" src={LocationMarker} alt="" />
            </div>
            <div
              className={cx('HdpLocal-commute-details-distance', {
                'HdpLocal-details-padding': !showDefaultLocation && userPoint,
              })}
            >
              {loading && <Fragment>Loading commute times...</Fragment>}

              {!loading && !showDefaultLocation && !userPoint && (
                <Fragment>
                  <strong>Enter a commute destination</strong> to see commute options from this listing.
                </Fragment>
              )}

              {!loading && !showDefaultLocation && userPoint && (
                <Fragment>
                  Commute to <strong>{userPointName}</strong>.
                </Fragment>
              )}
              {isInfoPopoverVisible && (
                <Popover className="HpLocal-info-popover" onHidePopup={() => this.setPopoverVisibilitry(false)}>
                  <StyledToolTipText htmlTag="div" size="md">
                    For major cities, we select a default commute point based on where most people work. Don’t work
                    here? You can easily customize this location below.
                  </StyledToolTipText>
                </Popover>
              )}

              {!loading && showDefaultLocation && (
                <Fragment>
                  Commute to <strong>{userPoint.name}</strong> (city default{' '}
                  <button onClick={() => this.setPopoverVisibilitry(true)}>{InfoIcon}</button>)
                </Fragment>
              )}
            </div>
            {!loading && !showDefaultLocation && userPoint && (
              <div className="HdpLocal-edit">
                <LinkController onClick={() => this.handleShowUserPointModal({ focusInput: true })}>
                  Edit
                </LinkController>
              </div>
            )}
            <div className="HdpLocal-attribution">
              <StyledDistanceText className="HdpLocal-mileage">
                {distance && <> {distance} miles away.</>}
              </StyledDistanceText>
              <div className="HdpLocal-attribution-logo">
                <img src={InrixLogo} alt="Inrix logo" height="12" width="40" />
              </div>
            </div>
          </StyledContainer>
          {!loading && (showDefaultLocation || !userPoint) && (
            <Fragment>
              {showDefaultLocation && (
                <StyledCustomLocationButton
                  className="HdpLocal-custom-dest-link"
                  onClick={() => this.handleShowUserPointModal({ focusInput: true })}
                >
                  Enter custom destination
                </StyledCustomLocationButton>
              )}
              {!showDefaultLocation && (
                <StyledLocationContainer className="HdpLocal-commute-btn">
                  <StyledCommuteDestinationButton
                    btnType="text-color"
                    onClick={() => this.handleShowUserPointModal({ focusInput: true })}
                  >
                    {(!userPoint || showDefaultLocation) && <Fragment>+ Add commute destination</Fragment>}
                    {userPoint && !showDefaultLocation && <Fragment>Change commute destination</Fragment>}
                  </StyledCommuteDestinationButton>
                </StyledLocationContainer>
              )}
            </Fragment>
          )}
          {loading && isMobile && <div className="HdpLocal-map-container" />}
          {!loading && containerWidth && isMobile && (
            <div className="HdpLocal-map-container" onClick={() => this.handleShowUserPointModal({ mapClick: true })}>
              <MiniMap
                autoZoom
                className="HdpLocal-static-map"
                height={300}
                lat={mapData.lat}
                lon={mapData.lon}
                markers={mapMarkers}
                width={containerWidth}
              />
            </div>
          )}
          {!loading && (
            <CommuteTimesV2
              activeRouteMode={this.state.activeRouteMode}
              hideLinks
              gmapLinks={controller.getGoogleDirectionsLinks(listing, userPoint)}
              onClick={this.handleShowUserPointModal}
              travelTime={travelTime}
              userPoint={showDefaultLocation ? true : userPoint}
            />
          )}
        </div>
      </HdpContentWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    activeUserPoint: state.user.userPoints.activeUserPoint,
    area: state.area.area,
    clientLoaded: state.app.clientLoaded,
    filter: state.filter,
    isMobile: state.app.device.screenWidth === 'sm',
    screenWidth: state.app.device.screenWidth,
    userPoints: state.user.userPoints.destinations,
  };
};

export default connect(mapStateToProps)(Local);
