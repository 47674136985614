// @ts-nocheck
/* eslint-enable */
import React from 'react';
import map from 'lodash/map';
import PropTypes from 'prop-types';

import HdpContentWrapper from 'app/shared/modules/hdp/HdpContentWrapper';
import Text from 'app/shared/core/Text';

import './HdpTextSection.scss';

class HdpTextSection extends React.Component {
  static displayName = 'HdpTextSection';

  static propTypes = {
    content: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
  };

  render() {
    let { content, header } = this.props;

    if (!content) {
      return null;
    }

    content = content.split('\n');
    return (
      <HdpContentWrapper collapsable header={header} headerHtmlTag="h2">
        <Text size="md">
          <div className="HdpTextSection">
            {map(content, (v, k) => {
              return (
                <span key={k}>
                  {v}
                  <br />
                </span>
              );
            })}
          </div>
        </Text>
      </HdpContentWrapper>
    );
  }
}

export default HdpTextSection;
