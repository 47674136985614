// @ts-nocheck
/* eslint-enable */
import Head from 'react-helmet';
import React from 'react';
import { useSelector } from 'react-redux';

import { getItemPage, getListingFaqSchema } from './controller';

const ListingStructuredData = ({ breadcrumbs = [] }) => {
  const listing = useSelector((state) => state.currentListingDetails.currentListing);

  if (!listing) {
    return null;
  }

  const schemaCategories = [];
  const isTrusted = listing.trusted;

  schemaCategories.push(getItemPage(listing, breadcrumbs, isTrusted));

  if (isTrusted) {
    schemaCategories.push(getListingFaqSchema(listing));
  }

  return (
    <Head
      script={[
        {
          type: 'application/ld+json',
          innerHTML: JSON.stringify({
            '@context': 'http://schema.org',
            '@graph': schemaCategories,
          }),
        },
      ]}
    />
  );
};

export default ListingStructuredData;
