// @ts-nocheck
/* eslint-enable */
import isEmpty from 'lodash/isEmpty';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import AdActions from 'app/shared/flux/actions/AdActions';
import ErrorActions from 'app/shared/flux/actions/ErrorActions';
import trackingUtils from 'app/shared/utils/trackingUtils';
import queryUtils from 'app/shared/utils/queryUtils';
import { adUtils_getStateForAdvertisement } from 'app/shared/utils/adUtils';

class HdpWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      adTagsInitialized: false,
      gamAttributes: null,
    };
  }
  componentDidMount() {
    const { areaState, dispatch, listing, query } = this.props;
    const isMapBorderActive = queryUtils.isMapBorderActive(query);
    dispatch(trackingUtils.indexAdWrapper.appendScript());

    dispatch(AdActions.adTags({ aliasEncoded: listing.aliasEncoded }))
      .then((apiResponse) => {
        if (apiResponse.attributes && !this.isCancelled) {
          const formattedResponseAttributes = Object.assign({}, apiResponse.attributes, {
            ...apiResponse.attributes,
            state: adUtils_getStateForAdvertisement(isMapBorderActive, areaState, apiResponse.attributes.state),
          });

          this.setState({
            adTagsInitialized: true,
            gamAttributes: formattedResponseAttributes,
          });
        }
      })
      .catch((error) => {
        dispatch(
          ErrorActions.errorHandler({
            errorLocation: 'component.hdpWrapper.componentDidMount#adTags',
            errorClass: 'adActions',
            error,
          }),
        );
      });
  }
  componentWillUnmount() {
    const { dispatch } = this.props;

    dispatch(trackingUtils.indexAdWrapper.discardScript());

    this.isCancelled = true;
  }
  shouldShowAd = () => {
    if (!this.props.gadsInit) {
      return false;
    }
    if (!this.state.adTagsInitialized) {
      // make sure the hdp loads before rendering some children
      return false;
    }
    if (isEmpty(this.state.gamAttributes)) {
      return false;
    }
    if (this.props.isMobile) {
      return false;
    }
    return true;
  };
  render() {
    return React.cloneElement(this.props.children, {
      shouldShowAd: this.shouldShowAd(),
      gamAttributes: this.state.gamAttributes,
    });
  }
}

const mapStateToProps = (state) => {
  const areaState = state.area?.area?.state?.toLowerCase();
  return {
    areaState,
    gadsInit: state.ads.gadsInit,
    isInitialSsrPage: state.app.isInitialSsrPage,
    isMobile: state.app.device.screenWidth === 'sm',
    listing: state.currentListingDetails.currentListing,
    query: state.location.current.query,
    user: state.user,
  };
};

export default connect(mapStateToProps)(HdpWrapper);
