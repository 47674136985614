// @ts-nocheck
/* eslint-enable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { yieldCallback } from '@zillow/yield-callback';

import './style.scss';
import { gaEvents } from 'app/shared/constants/AnalyticsConstants';
import appUtils from 'app/shared/utils/appUtils';
import Button from 'app/shared/core/Button';
import Container from 'app/shared/core/Container';
import IconLeftCaret from 'images/icons/ic-caret-left.svg';
import IconMail from 'images/icons/mail-white.svg';
import PopupModal from 'app/shared/modules/popups/PopupModal';

class HdpFullDetailModal extends Component {
  static propTypes = {
    onClickContact: PropTypes.func,
    onHideModal: PropTypes.func,
  };

  static defaultProps = {
    onClickContact: () => {},
    onHideModal: () => {},
  };

  componentDidMount() {
    const { isMobile } = this.props;
    if (isMobile) {
      appUtils.lockVerticalScrolling();
    }
  }
  componentWillUnmount() {
    const { isMobile } = this.props;
    if (isMobile) {
      appUtils.unlockVerticalScrolling();
    }
  }

  handleContactCtaClick = yieldCallback(() => {
    const { onClickContact } = this.props;
    onClickContact({ customGaEvent: gaEvents.FDP_FLOOR_PLAN_CONTACT_CLICK });
  });

  render() {
    const { onHideModal, children, isMobile, onClickContact } = this.props;

    return (
      <PopupModal
        aria-labelledby="dialog-title"
        isFullScreen
        onHidePopup={this.handleGalleryBackClick}
        paddingTop="0"
        paddingBottom="0"
        useCloseButton={false}
      >
        <div className="HdpFullDetailModal-header">
          <button className="HdpFullDetailModal-header-left" onClick={onHideModal}>
            <img src={IconLeftCaret} className="HdpFullDetailModal-icon-caret" />
            <span className="HdpFullDetailModal-back">Back</span>
          </button>
        </div>
        {isMobile ? children : <Container>{children}</Container>}
        <div className="HdpFullDetailModal-contact-cont" onClick={onClickContact}>
          <Button onClick={this.handleContactCtaClick} full>
            <img src={IconMail} className="HdpFullDetailModal-contact-icon" />
            Contact now
          </Button>
        </div>
      </PopupModal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.app.device.screenWidth === 'xs' || state.app.device.screenWidth === 'sm',
    listing: state.currentListingDetails.currentListing,
  };
};

export default connect(mapStateToProps)(HdpFullDetailModal);
