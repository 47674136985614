// @ts-nocheck
/* eslint-enable */
// App
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useYieldCallback } from '@zillow/yield-callback';

// Commponents
import RadioButton from 'app/shared/modules/MobileSRP/__BaseComponents__/RadioButton';

// Misc / utils
import { GeneralFilterSection } from 'app/shared/modules/MobileSRP/Header/FilterBar/styles';
import AppActions from 'app/shared/flux/actions/AppActions';
import mapConstants from 'app/shared/constants/MapConstants';

const TransitOverlayOptions = () => {
  // Redux
  const dispatch = useDispatch();

  const mapOverlay = useSelector((state) => state.app.overlays.mapOverlay);

  // React state
  const [isLoaded, setIsLoaded] = useState(false);

  // run effect when mapOverlay changes
  useEffect(() => {
    if (!isLoaded) {
      setIsLoaded(true);
    } else {
      if (window.bicycleOverlay) {
        window.bicycleOverlay.setMap(null);
      }
      if (window.transitOverlay) {
        window.transitOverlay.setMap(null);
      }

      if (mapOverlay !== mapConstants.NO_OVERLAY) {
        if (window[mapOverlay]) {
          window[mapOverlay].setMap(window.map);
        }
      }
    }
  }, [mapOverlay]);

  const yieldChange = useYieldCallback((opt) => {
    dispatch(AppActions.changeMapOverlay(opt));
  });
  // dispatch changed overlay option
  const handleChange = (e) => {
    const overlayOption = e.target.value;
    yieldChange(overlayOption);
  };

  return (
    <GeneralFilterSection>
      <fieldset id="transit-options">
        <legend>Transit Options</legend>
        <RadioButton
          id="map-overlay-option-none"
          name="transit-options"
          ariaLabel="Turn off all Google map overlays"
          isChecked={mapOverlay === mapConstants.NO_OVERLAY}
          value={mapConstants.NO_OVERLAY}
          onChange={handleChange}
        >
          Off
        </RadioButton>
        <RadioButton
          id="map-overlay-option-bicycle"
          name="transit-options"
          ariaLabel="Turn on Google map bicycle overlay"
          isChecked={mapOverlay === mapConstants.BICYCLE_OVERLAY}
          value={mapConstants.BICYCLE_OVERLAY}
          onChange={handleChange}
        >
          Bicycle overlay
        </RadioButton>
        <RadioButton
          id="map-overlay-option-transit"
          name="transit-options"
          ariaLabel="Turn on Google map transit overlay"
          isChecked={mapOverlay === mapConstants.TRANSIT_OVERLAY}
          value={mapConstants.TRANSIT_OVERLAY}
          onChange={handleChange}
        >
          Transit overlay
        </RadioButton>
      </fieldset>
    </GeneralFilterSection>
  );
};

export default TransitOverlayOptions;
