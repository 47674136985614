// @ts-nocheck
/* eslint-enable */
const controller = {
  shouldShowMultiModels(currentListing) {
    const isBuildingPage = Boolean(currentListing?.isBuilding);
    const currentFloorplans = !isBuildingPage && currentListing?.floorplans;
    const hasMultipleFloorplans = (currentFloorplans && currentFloorplans.length > 1) || false;
    const hasMultipleUnits =
      currentFloorplans && currentFloorplans.length && currentFloorplans[0].units
        ? currentFloorplans[0].units.length > 1
        : false;
    const isMfApartmentBldg = Boolean(currentListing?.isApartmentBldg);

    return (!isBuildingPage && (hasMultipleFloorplans || hasMultipleUnits)) || isMfApartmentBldg;
  },
};

export default controller;
