// @ts-nocheck
/* eslint-enable */
/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import Button from 'app/shared/core/Button';
import { analyticsEvent } from 'app/client/universal-analytics';
import { gaEvents } from 'app/shared/constants/AnalyticsConstants';
import { ButtonFooter, ModalTitle, ScrollWrapper, StyledRenterProfileForm, StyledRenterProfilePopup } from './styles';

const PostContactRenterProfile = ({ onHidePopup = () => {} } = {}) => {
  const dispatch = useDispatch();
  const [shouldSaveData, setShouldSaveData] = useState(false);
  const [displaySavingMsg, setDisplaySavingMsg] = useState(false);

  /**
   * shouldSaveData is passed into the main renterProfileForm to trigger an
   * API call to save data, using the form's own handleSave method.
   * Once the API call is finished, hidePopupCallback is triggered
   * which automatically closes this form and continues the contact process.
   */
  const handleSaveClick = useCallback(() => {
    if (!shouldSaveData) {
      setDisplaySavingMsg(true);
      setShouldSaveData(true);
    }
  }, [shouldSaveData]);

  const hidePopupCallback = useCallback(() => {
    onHidePopup();
  }, [onHidePopup]);

  const handleCancelClick = useCallback(() => {
    dispatch(analyticsEvent(gaEvents.RENTER_PROFILE_CANCEL));
    hidePopupCallback();
  }, [dispatch, hidePopupCallback]);

  return (
    <StyledRenterProfilePopup>
      <ModalTitle htmlTag="div" id="dialog-title" className="PostContactSavedSearch-title" size="lg">
        Update your renter profile
      </ModalTitle>
      <ScrollWrapper>
        <StyledRenterProfileForm isPopup shouldSaveData={shouldSaveData} hidePopupCallback={hidePopupCallback} />
      </ScrollWrapper>

      <ButtonFooter>
        <Button full size="lg" btnType="text-color" onClick={handleSaveClick}>
          {displaySavingMsg ? 'Saving...' : 'Save'}
        </Button>
        <Button full size="lg" btnType="text-color-outline" onClick={handleCancelClick}>
          {'Cancel'}
        </Button>
      </ButtonFooter>
    </StyledRenterProfilePopup>
  );
};

export default PostContactRenterProfile;
