// @ts-nocheck
/* eslint-enable */
// App
import React from 'react';

// Misc / utils
import * as S from './styles';

const RadioButton = React.forwardRef(
  (
    {
      ariaLabel,
      icon,
      iconWidth = '12px',
      iconHeight = '12px',
      iconAriaAltText = '',
      isChecked = false,
      onChange,
      name,
      id,
      children,
      value,
      ...rest
    },
    ref,
  ) => (
    <S.Wrapper>
      <S.Input
        aria-label={ariaLabel}
        aria-checked={isChecked}
        type="radio"
        name={name}
        id={id}
        checked={isChecked}
        ref={ref}
        value={value}
        onChange={onChange}
        {...rest}
      />
      <S.Label htmlFor={id} value={value}>
        {icon && <S.Icon src={icon} width={iconWidth} height={iconHeight} alt={iconAriaAltText} />}
        {children}
      </S.Label>
    </S.Wrapper>
  ),
);

export default RadioButton;
