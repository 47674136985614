// @ts-nocheck
/* eslint-enable */
// App
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

// Components
import Button from 'app/shared/core/Button';

// Misc / utils
import IconX from 'images/icons/x-white.svg';
import * as S from 'app/shared/modules/hdp/ListingHiddenNotification/styles';

const ListingHiddenNotification = ({ onClose = () => {}, onUndo = () => {} }) => {
  // React
  const hiddenNoticeRef = useRef(null);

  const handleMouseDown = (e) => {
    if (hiddenNoticeRef.current && !hiddenNoticeRef.current.contains(e.target)) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleMouseDown);
    document.addEventListener('touchstart', handleMouseDown);
    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('touchstart', handleMouseDown);
    };
  }, []);

  return (
    <S.ListingHiddenNotice ref={hiddenNoticeRef}>
      <S.ListingHiddenText size="sm">Listing hidden from search results.</S.ListingHiddenText>
      <Button btnType="text-color-light" size="sm" onClick={onUndo}>
        Undo
      </Button>
      <S.ListingHiddenClose onClick={onClose}>
        <img src={IconX} height={20} width={20} alt="" />
      </S.ListingHiddenClose>
    </S.ListingHiddenNotice>
  );
};

ListingHiddenNotification.propTypes = {
  onClose: PropTypes.func,
  onUndo: PropTypes.func,
};

export default ListingHiddenNotification;
