import React from 'react';
import TourStatusModule from 'app/shared/modules/hdp/InstantTour/components/TourStatusModule';
import DateTimePicker from '../DateTimePicker';
import styled from 'styled-components';

export const SelectDateTimeStepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 20px 20px;
`;

const SelectDateTimeStep = ({
  isAvailTimesReqLoading,
  setIsPrimaryBtnDisabled,
}: {
  isAvailTimesReqLoading: boolean;
  setIsPrimaryBtnDisabled: () => void;
}) => {
  return (
    <SelectDateTimeStepContainer>
      <TourStatusModule />
      <DateTimePicker
        isAvailTimesReqLoading={isAvailTimesReqLoading}
        setIsPrimaryBtnDisabled={setIsPrimaryBtnDisabled}
      />
    </SelectDateTimeStepContainer>
  );
};

export default SelectDateTimeStep;
