import styled, { css } from 'styled-components';
import { colors } from 'app/shared/styles/_colors';
import { sidePadding16Px, transform } from 'app/shared/styles/_mixins';
import { viewports } from 'app/shared/styles/_breakpoints';
import Text from 'app/shared/core/Text';

const OVERFLOW_MARGIN = 8;

const overflowAdjustment = css`
  margin-top: ${OVERFLOW_MARGIN}px;
  @media ${viewports['md-and-up']} {
    margin-top: 0;
  }
`;

interface HdpContentWrapperProps {
  noBorder?: boolean;
  noPadding?: boolean;
}

export const HdpContentWrapper = styled.article<HdpContentWrapperProps>`
  border-top: 1px solid ${colors['$hpx-grey-100']};
  padding: 16px 0;
  position: relative;
  width: 100%;

  ${(props) =>
    props.noBorder &&
    css`
      border-top: none;
    `}

  ${(props) =>
    props.noPadding &&
    css`
      padding: 0;
    `}

    @media ${viewports['md-and-up']} {
    padding: 24px 0;

    ${(props) =>
      props.noBorder &&
      css`
        border-top: none;
      `}

    ${(props) =>
      props.noPadding &&
      css`
        padding: 0;
      `}
  }
`;

export const Header = styled.header`
  ${sidePadding16Px}
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  text-align: left;
  width: 100%;
  margin-top: ${-1 * OVERFLOW_MARGIN}px;

  &:hover {
    cursor: pointer;
  }

  @media ${viewports['md-and-up']} {
    margin-top: 0;
    padding-bottom: 16px;
    text-align: auto;

    &:hover {
      cursor: auto;
    }
  }
`;

export const HeaderLeftColumn = styled.div`
  ${overflowAdjustment}
  @media ${viewports['xl-and-up']} {
    width: 432px;
  }
`;

export const HeaderTitle = styled(Text)`
  display: inline-block;
  color: ${colors['$hpx-blue-500']};
  font-weight: 600;
  max-width: calc(100vw - 56px); // 56px = 32px (content margins) + 24px (dropdown arrow + margin)

  @media ${viewports['md-and-up']} {
    width: 100%;
  }
`;

export const HeaderRightColumn = styled.div`
  align-self: center;
  ${overflowAdjustment}
`;

interface ContentContainerProps {
  noHeader?: boolean;
  fullContent?: boolean;
  isActive?: boolean;
}

export const ContentContainer = styled.article<ContentContainerProps>`
  display: none;
  padding-top: ${(props: ContentContainerProps) => (props.noHeader ? '0' : '8px')};
  width: 100%;

  @media ${viewports['md-and-up']} {
    display: block;
    padding-top: 0;
  }

  ${(props) =>
    props.fullContent &&
    css`
      margin-bottom: 0px;
    `}

  ${(props) =>
    props.isActive &&
    css`
      display: block;
    `}
`;

export const DropdownWrapper = styled.div`
  display: inline-block;
  padding: 0 8px 0 0;
  vertical-align: top;

  @media ${viewports['md-and-up']} {
    display: none;
    padding: 10px 8px 0 0;
  }
`;

interface DropdownIconProps {
  isActive?: boolean;
}

export const DropdownIcon = styled.img<DropdownIconProps>`
  height: 16px;
  width: 16px;

  ${(props) =>
    props.isActive &&
    css`
      ${transform('rotate(90deg)')}
    `}

  ${(props) =>
    !props.isActive &&
    css`
      ${transform('rotate(-90deg)')}
    `}
`;
