// @ts-nocheck
/* eslint-enable */
/* eslint-disable react/jsx-no-bind */
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { DeferredRender, RenderMode } from '@zillow/react-deferred-hydration';

import UserItemActions from 'app/shared/flux/actions/UserItemActions';
import AppActions from 'app/shared/flux/actions/AppActions';
import ListingEngineActions from 'app/shared/flux/actions/ListingEngineActions';
import { analyticsEvent } from 'app/client/universal-analytics';
import { gaEvents } from 'app/shared/constants/AnalyticsConstants';

import FavoriteButton from 'app/shared/modules/FavoriteButton';
import LazyLoader from '../../LazyLoader';
import Carousel from './Carousel';
import StarRating from './StarRating';

import Icon3DGlasses from 'images/icons/3d-glasses.svg';
import IconVerified from 'images/verified.svg';
import SkeletonCard from './skeleton';
import noPhoto from 'images/noPhoto.png';

import { listingUtils_checkFavorite, listingUtils_formatPropertyType } from 'app/shared/utils/listingUtils';
import stringHelper from 'app/shared/utils/stringHelper';
import RouteActions from 'app/shared/flux/actions/RouteActions';
import { useYieldCallback } from '@zillow/yield-callback';

import perfUtils from 'app/shared/utils/perfUtils';
import {
  badgeContainerCX,
  badgeOutlineCX,
  detailedInfoContainerCX,
  detailedInfoTextCX,
  getHeroPhotoContainerCX,
  getInfoBadgeCX,
  getListingCardContainerCX,
  infoBadgesCX,
  listingCardAnchorCX,
  priceTextCX,
} from './styles';
import { useListing } from '../ListingCardProvider';
import { TrackSaveHomeClick, TrackUnSaveHomeClick } from 'app/shared/models/Clickstream/HdpClickstreamEvents';

const ListingCardV3 = ({
  listingViewed = false,
  keepMapLocation = false,
  onClick = () => {},
  shouldLazyLoadListingCardPhotos = false,
  shouldDeferRender = false,
}) => {
  const { listing, triggerLocation, triggerObject } = useListing();
  const dispatch = useDispatch();
  const {
    active,
    address,
    averageStarLevel,
    displayName,
    has3dTour,
    hasSpecialOffers,
    incomeRestricted,
    listingMinMaxPriceBeds,
    medPhotoUrls,
    previewPhotoMed,
    recencyTime,
    rentalApplicationStatus,
    modelTypeUnitCount,
    seniorHousing,
    studentHousing,
    trusted,
    propertyType,
  } = listing;
  const formattedPropertyType = listingUtils_formatPropertyType(propertyType);
  const isFavorited = listing ? listingUtils_checkFavorite(listing) : false;

  const yieldFavoriteClick = useYieldCallback(() => {
    if (!isFavorited) {
      dispatch(
        analyticsEvent(gaEvents.HDP_FAVORITE_ATTEMPT, {
          newLaneEvent: TrackSaveHomeClick({ listing, triggerLocation, triggerObject }),
        }),
      );
    } else {
      dispatch(analyticsEvent({ newLaneEvent: TrackUnSaveHomeClick({ listing, triggerLocation, triggerObject }) }));
    }

    dispatch(UserItemActions.toggleUserItem('favorite', listing));
  });

  const handleFavoriteClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    yieldFavoriteClick();
  };

  const yieldListingClickDispatches = useYieldCallback((isTransitioning = true) => {
    if (listing.has3dTour) {
      dispatch(analyticsEvent(gaEvents.SRP_LISTING_WITH_3D_TOUR_CLICK));
    }

    onClick(listing);
    perfUtils.setMarker('ListingCardV3Click');
    dispatch(AppActions.setAreaPageScrollPosition(listing.maloneLotIdEncoded));
    dispatch(ListingEngineActions.setPreviewListing(listing));
    dispatch(ListingEngineActions.setActiveMarkerMaloneLotId(listing.maloneLotIdEncoded));
    if (listing?.uriV2 && isTransitioning) {
      dispatch(RouteActions.transitionToListing({ listingUri: listing.uriV2, keepMapLocation }));
    }
  });

  const handleListingClick = useCallback(
    (e, isTransitioning = true) => {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
      }

      yieldListingClickDispatches(isTransitioning);
    },
    [yieldListingClickDispatches],
  );

  if (!listing) {
    return null;
  }

  if (previewPhotoMed?.url && medPhotoUrls.length === 0) {
    medPhotoUrls.push(previewPhotoMed.url);
  }

  const isBrokerListing = listing?.broker?.isBrokerExclusiveListing || false;
  const brokerName = listing?.broker?.companyName || '';
  const displayBrokerInfo = isBrokerListing && brokerName;

  const FavoriteButtonComponent = (
    <div style={{ position: 'absolute', top: '5px', right: '0px', zIndex: '1' }}>
      <FavoriteButton
        alreadyFavorited={isFavorited}
        isBiggerListingCard
        onClick={handleFavoriteClick}
        listing={listing}
        size="36px"
      />
    </div>
  );

  const PhotosComponent = (
    <figure className={getHeroPhotoContainerCX()} data-name="HeroPhotoContainer">
      {FavoriteButtonComponent}
      {medPhotoUrls?.length > 0 && (
        <Carousel displayName={displayName} handleListingClick={handleListingClick} photoUrls={medPhotoUrls} />
      )}
      {medPhotoUrls?.length === 0 && <img style={{ height: '100%' }} src={noPhoto} onClick={handleListingClick} />}
      <div className={badgeContainerCX} data-name="BadgeContainer" onClick={handleListingClick}>
        {active && recencyTime.daysDiff < 5 && (
          <div className={getInfoBadgeCX()} data-name="InfoBadge">
            New {recencyTime.displayDiff} ago
          </div>
        )}
        {hasSpecialOffers && (
          <div className={getInfoBadgeCX('upsell')} data-name="InfoBadge" aria-label="special offer">
            Special offer
          </div>
        )}
        {rentalApplicationStatus === 'accepted' && (
          <div className={getInfoBadgeCX('upsell')} data-name="InfoBadge" aria-label="accepts applications">
            Accepts applications
          </div>
        )}
        {incomeRestricted && (
          <div className={getInfoBadgeCX('housing')} data-name="InfoBadge" aria-label="income restricted">
            Income restricted
          </div>
        )}
        {studentHousing && (
          <div className={getInfoBadgeCX('housing')} data-name="InfoBadge" aria-label="student housing">
            Student housing
          </div>
        )}
        {seniorHousing && (
          <div className={getInfoBadgeCX('housing')} data-name="InfoBadge" aria-label="senior housing">
            Senior housing
          </div>
        )}
      </div>
    </figure>
  );

  return (
    <article className={getListingCardContainerCX(listingViewed)} data-name="ListingCardContainer">
      <DeferredRender
        mode={
          shouldDeferRender
            ? RenderMode.RENDER_CLIENT_SIDE_ON_VISIBLE
            : RenderMode.RENDER_SERVER_SIDE_HYDRATE_ON_IMMEDIATE
        }
        visibilityOptions={{ rootMargin: '150px' }}
      >
        {shouldLazyLoadListingCardPhotos ? (
          <LazyLoader
            height="auto"
            width="auto"
            customPlaceholder={<figure className={getHeroPhotoContainerCX()} data-name="HeroPhotoContainerLazyLoad" />}
          >
            {PhotosComponent}
          </LazyLoader>
        ) : (
          PhotosComponent
        )}
        <section className={detailedInfoContainerCX} data-name="DetailedInfoContainer" onClick={handleListingClick}>
          <div className={infoBadgesCX} data-name="InfoBadges">
            {trusted && (
              <div className={badgeOutlineCX} data-name="BadgeOutline">
                <img
                  src={IconVerified}
                  height="14px"
                  width="14px"
                  alt="This is a verified listing"
                  title="This is a verified listing"
                />
              </div>
            )}
            {has3dTour && (
              <div className={badgeOutlineCX} data-name="BadgeOutline">
                <img
                  src={Icon3DGlasses}
                  height="12px"
                  width="16px"
                  alt="This listing has a 3D tour available"
                  title="This listing has a 3D tour available"
                />
              </div>
            )}
          </div>
          <div className={detailedInfoTextCX} data-name="DetailedInfoText" onClick={handleListingClick}>
            <div className={priceTextCX} data-name="PriceText">
              {listingMinMaxPriceBeds.priceDisplayRange}
            </div>
            {!isBrokerListing && averageStarLevel && <StarRating totalStars={5} filledStars={averageStarLevel} />}
            <p style={{ fontWeight: 'bold' }}>
              {listingMinMaxPriceBeds.bedDisplaySemiCompact}
              {listingMinMaxPriceBeds.bathDisplaySemiCompact && (
                <span>, {listingMinMaxPriceBeds.bathDisplaySemiCompact}</span>
              )}
              {modelTypeUnitCount ? ` | ${stringHelper.pluralize(modelTypeUnitCount, 'unit', 'units')} available` : ''}
            </p>
            <a
              aria-label={`See more details about ${address?.hideAddress ? 'this listing' : displayName}`}
              className={listingCardAnchorCX}
              data-name="ListingCardAnchor"
              href={listing.uriV2}
              onClick={() => {
                handleListingClick(null, false);
              }}
            >
              <h3 style={{ display: 'inline' }}>{displayName}</h3>
            </a>
            <span>{displayBrokerInfo ? ` | ${brokerName}` : ''}</span>
            <p>{`${formattedPropertyType} in ${`${address.city}, ${address.state}`}`}</p>
          </div>
        </section>
      </DeferredRender>
    </article>
  );
};

export { SkeletonCard };
export default ListingCardV3;
