// @ts-nocheck
/* eslint-enable */
import React from 'react';
import PropTypes from 'prop-types';

import controller from 'app/shared/modules/map/MapMarker/controller';

class MapMarker extends React.Component {
  static propTypes = {
    active: PropTypes.bool,
    dataObj: PropTypes.object,
    icon: PropTypes.any,
    infoWindowContent: PropTypes.string,
    lat: PropTypes.any,
    lon: PropTypes.any,
    map: PropTypes.object,
    onClick: PropTypes.any,
    onMouseOut: PropTypes.func,
    onMouseOver: PropTypes.func,
    zIndex: PropTypes.number,
  };

  static defaultProps = {
    active: false,
    dataObj: {},
    icon: {},
    lat: null,
    lon: null,
    map: null,
    onClick: null,
    onMouseOut: null,
    onMouseOver: null,
    infoWindowContent: null,
    zIndex: 1,
  };

  constructor(props) {
    super(props);
    this.marker = null;
    this.createMarker = this.createMarker.bind(this);
    this.handleOnClick = this.handleOnClick.bind(this);
  }

  componentDidMount() {
    this.createMarker();
  }

  shouldComponentUpdate(nextProps) {
    const { active, icon, infoWindowContent, showInfoWindow } = this.props;

    if (nextProps.showInfoWindow !== showInfoWindow) {
      return true;
    } else if (active !== nextProps.active) {
      return true;
    } else if (infoWindowContent !== nextProps.infoWindowContent) {
      // create new marker when currentListing changes (HPWEB-5561)
      this.marker.infoWindow.close();
      this.marker.infoWindow.isOpen = false;
      this.marker = null;
      this.createMarker(nextProps);
      return true;
    } else if (icon && nextProps.icon && icon.url !== nextProps.icon.url) {
      // icons sometimes change type (condo to house, house to condo) that is not good.
      return true;
    } else {
      return false;
    }
  }

  componentDidUpdate(prevProps) {
    const { active, icon, lat, lon, onClick, showInfoWindow, zIndex } = this.props;

    if (this.marker) {
      if (showInfoWindow) {
        this.handleMouseOver();
      } else {
        this.handleMouseOut();
      }

      if (prevProps.onClick && !onClick) {
        this.marker.setOptions({ clickable: false });
        window.google.maps.event.clearListeners(this.marker, 'click');
      } else if (!prevProps.onClick && onClick) {
        this.marker.setOptions({ clickable: true });
        window.google.maps.event.addListener(this.marker, 'click', () => this.handleOnClick());
      }

      if (
        icon.scaledSize.width !== prevProps.icon.scaledSize.width ||
        icon.scaledSize.height !== prevProps.icon.scaledSize.height
      ) {
        this.marker.setIcon(icon);
        this.marker.setOptions({ zIndex });
      } else if (icon.url !== prevProps.icon.url) {
        this.marker.setIcon(icon);
        this.marker.setOptions({ zIndex });
      } else if (icon !== prevProps.icon) {
        this.marker.setIcon(icon);
        this.marker.setOptions({ zIndex });
      }

      if (prevProps.active && !active) {
        this.marker.setIcon(icon);
        this.marker.setOptions({ zIndex });
        controller.handleShowInfoWindow(this.marker, false);
      } else if (!prevProps.active && active) {
        this.marker.setIcon(icon);
        this.marker.setOptions({ zIndex });
        controller.handleShowInfoWindow(this.marker, true);
      }

      if (prevProps.lat !== lat || prevProps.lon !== lon) {
        this.marker.setOptions({ position: new window.google.maps.LatLng(lat, lon) });
      }
    }
  }

  componentWillUnmount() {
    window.google.maps.event.clearListeners(this.marker, 'click');
    window.google.maps.event.clearListeners(this.marker, 'mouseout');
    window.google.maps.event.clearListeners(this.marker, 'mouseover');

    if (this.marker) {
      controller.handleShowInfoWindow(this.marker, false);
      this.marker.setMap(null);
    }
  }

  handleOnClick() {
    const { dataObj, onClick } = this.props;

    controller.handleShowInfoWindow(this.marker, true);

    if (onClick) {
      onClick(dataObj, this.marker);
    }
  }

  createMarker(props) {
    const {
      active,
      dataObj,
      icon,
      infoWindowContent,
      lat,
      lon,
      map,
      onClick,
      onMouseOut,
      onMouseOver,
      storeMarkerRef,
      zIndex,
    } = props || this.props;
    const { createInfoWindow, createMarker, handleShowInfoWindow } = controller;
    const markerDetails = {
      icon,
      lat,
      lon,
      map,
      onClick,
      zIndex,
    };

    this.marker = createMarker(markerDetails);

    if (!this.marker) {
      return;
    }

    createInfoWindow(this.marker, infoWindowContent);

    if (active) {
      this.marker.setOptions({ zIndex: 999 });
      handleShowInfoWindow(this.marker, true);
    }

    if (onClick) {
      window.google.maps.event.addListener(this.marker, 'click', () => this.handleOnClick());
    }

    if (onMouseOut) {
      window.google.maps.event.addListener(this.marker, 'mouseout', () => this.handleMouseOut());
    }

    if (onMouseOver) {
      window.google.maps.event.addListener(this.marker, 'mouseover', () => this.handleMouseOver());
    }

    // Sometimes, we may want to store a reference to the marker for
    // quick lookup in order to change icon or toggle InfoWindow.
    if (storeMarkerRef) {
      storeMarkerRef(this.marker, dataObj);
    }
  }

  handleMouseOut() {
    const { active, dataObj, onMouseOut, zIndex } = this.props;

    // In the case of an active listing, this prevents
    // infoWindow from closing when user mouses away.
    if (active) {
      return;
    }

    controller.handleMarkerMouseOut({
      marker: this.marker,
      zIndex,
    });

    if (onMouseOut) {
      onMouseOut(dataObj, this.marker);
    }
  }

  handleMouseOver() {
    const { active, dataObj, onMouseOver } = this.props;

    // Google Maps fires a mouseOver event for mobile devices during onClick.
    if (active) {
      return;
    }

    controller.handleMarkerMouseOver(this.marker);

    if (onMouseOver) {
      onMouseOver(dataObj, this.marker);
    }
  }

  render() {
    return null;
  }
}

export default MapMarker;
