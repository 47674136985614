// @ts-nocheck
/* eslint-enable */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useYieldCallback } from '@zillow/yield-callback';
import constants from 'app/shared/constants/ConstantsBundle';
import controller from './controller';
import IconArrowLeftDark from 'images/icons/arrow-left-dark-grey.svg';
import IconArrowRightDark from 'images/icons/arrow-right-dark-grey.svg';
import './style.scss';

const sanitizeIndex = (index, indexToDefaultTo, children) => {
  if (index !== 0 && !index) {
    return indexToDefaultTo;
  }

  const childComponents = React.Children.toArray(children);
  if (index < 0 || index > childComponents.length) {
    return 0;
  }

  return index;
};

// TODO: deconstruct props & implement useCallback.
const PhotoCarouselV2 = (props) => {
  const containerRef = useRef();
  const startXRef = useRef();
  const startYRef = useRef();
  const endXRef = useRef();
  const prevXRef = useRef();
  const prevYRef = useRef();
  const [useTransition, setUseTransition] = useState(false);
  const [positionX, setPositionX] = useState(false);
  const [isMounting, setIsMounting] = useState(true);
  const [index, setIndex] = useState(sanitizeIndex(props.defaultIndex, 0, props.children));
  const { className, itemCountOffset, itemCountPosition, backgroundColor } = props;
  const children = React.Children.toArray(props.children);
  const maxIndex = props.maxIndex ? props.maxIndex : children.length - 1;
  const isFirst = index === 0;
  const isLast = index === maxIndex;

  // Force re-render on mount so that containerWidth is set correctly when containerRef is attached to element
  useEffect(() => {
    if (isMounting) {
      setIsMounting(false);
    }
  }, [isMounting]);

  const containerWidth = containerRef.current?.getBoundingClientRect().width;
  let realX = 0;
  if (containerWidth) {
    realX = positionX - index * containerWidth;
  }

  const maxDisplayItemCount = maxIndex + 1 + itemCountOffset;
  const currentDisplayIndex = index + 1;
  const displayItemCount = `${currentDisplayIndex}/${maxDisplayItemCount}`;
  const itemStyle = {
    width: containerWidth ? controller.getPx(containerWidth) : '100%',
  };
  const trackStyle = {
    transform: `translate3d(${realX}px, 0px, 0px)`,
    width: controller.getPx(containerWidth * children.length),
  };

  const goToIndex = useYieldCallback((newIndex, animateChange) => {
    const { onChange, loopable } = props;

    if (newIndex < 0) {
      newIndex = loopable ? maxIndex : 0;
    } else if (newIndex > maxIndex) {
      newIndex = loopable ? 0 : maxIndex;
    }

    setUseTransition(animateChange);
    setPositionX(0);
    setIndex(newIndex);
    onChange(newIndex);
  });

  useEffect(() => {
    const handleKeyboardNav = (e) => {
      if (props.ignoreKeypress) {
        return;
      }

      if (e.keyCode === constants.LEFT_ARROW_KEYCODE) {
        goToIndex(index - 1, props.animateChange);
      } else if (e.keyCode === constants.RIGHT_ARROW_KEYCODE) {
        goToIndex(index + 1, props.animateChange);
      }
    };

    document.addEventListener('keydown', handleKeyboardNav);
    return () => {
      document.removeEventListener('keydown', handleKeyboardNav);
    };
  }, [goToIndex, index, props.animateChange, props.ignoreKeypress]);

  const handleSwiping = useCallback(
    (e) => {
      const touchX = controller.touchX(e);
      const touchY = controller.touchY(e);
      const movedX = touchX - startXRef.current;
      const overscrollMultiplier = 0.2;
      let newPositionX;

      const deltaX = prevXRef.current - touchX;
      const deltaY = prevYRef.current - touchY;
      const isVerticalSwipe = Math.abs(deltaY) >= Math.abs(deltaX);
      prevXRef.current = touchX;
      prevYRef.current = touchY;
      if (isVerticalSwipe) {
        return false;
      }

      // no need for e.preventDefault() - https://www.chromestatus.com/features/5093566007214080
      e.stopPropagation();

      if (isFirst) {
        const maxOverscrollRightX = -1 * (containerWidth * (1 + overscrollMultiplier));
        const maxOverscrollLeftX = containerWidth * overscrollMultiplier;
        if (movedX > maxOverscrollLeftX) {
          newPositionX = maxOverscrollLeftX;
        } else if (movedX < maxOverscrollRightX) {
          newPositionX = maxOverscrollRightX;
        } else {
          newPositionX = movedX;
        }
      } else if (isLast) {
        const maxOverscrollRightX = -1 * (containerWidth * overscrollMultiplier);
        const maxOverscrollLeftX = containerWidth * (1 + overscrollMultiplier);
        if (movedX > maxOverscrollLeftX) {
          newPositionX = maxOverscrollLeftX;
        } else if (movedX < maxOverscrollRightX) {
          newPositionX = maxOverscrollRightX;
        } else {
          newPositionX = movedX;
        }
      } else {
        const maxOverscrollX = containerWidth * (1 + overscrollMultiplier);
        const isMaxedOut = Math.abs(movedX) > maxOverscrollX;
        newPositionX = isMaxedOut ? Math.sign(movedX) * maxOverscrollX : movedX;
      }

      setUseTransition(false);
      setPositionX(newPositionX);
    },
    [containerWidth, isFirst, isLast],
  );

  const handleEnd = useCallback(
    (e) => {
      endXRef.current = controller.touchX(e);
      const swipeLimitX = 50;
      const movedX = endXRef.current - startXRef.current;
      const prev = movedX > swipeLimitX;
      const next = movedX < -swipeLimitX;
      if (prev) {
        goToIndex(index - 1, true);
      } else if (next) {
        goToIndex(index + 1, true);
      } else {
        setUseTransition(true);
        setPositionX(0);
      }

      document.removeEventListener('mousemove', handleSwiping);
      document.removeEventListener('touchmove', handleSwiping);
      document.removeEventListener('mouseup', handleEnd);
      document.removeEventListener('touchend', handleEnd);
    },
    [goToIndex, handleSwiping, index],
  );

  useEffect(() => {
    if (props.defaultIndex) {
      goToIndex(props.defaultIndex);
    }

    document.removeEventListener('mousemove', handleSwiping);
    document.removeEventListener('touchmove', handleSwiping);
    document.removeEventListener('mouseup', handleEnd);
    document.removeEventListener('touchend', handleEnd);

    return () => {
      document.removeEventListener('mousemove', handleSwiping);
      document.removeEventListener('touchmove', handleSwiping);
      document.removeEventListener('mouseup', handleEnd);
      document.removeEventListener('touchend', handleEnd);
    };
  }, []);

  const handleStart = useCallback(
    (e) => {
      startXRef.current = prevXRef.current = controller.touchX(e);
      startYRef.current = prevYRef.current = controller.touchY(e);
      document.addEventListener('mousemove', handleSwiping);
      document.addEventListener('touchmove', handleSwiping);
      document.addEventListener('mouseup', handleEnd);
      document.addEventListener('touchend', handleEnd);
    },
    [handleEnd, handleSwiping],
  );

  const yieldHandleCallback = useYieldCallback((e, onClick) => {
    const movedX = endXRef.current - startXRef.current;
    if (Math.abs(movedX) < 2 && onClick) {
      onClick(e);
    }
  });

  const handleCenterClick = useCallback(
    (e, onClick) => {
      endXRef.current = controller.touchX(e);
      e.stopPropagation();
      e.preventDefault();
      yieldHandleCallback(e, onClick);
    },
    [yieldHandleCallback],
  );

  const handleClickNext = useCallback(
    (e) => {
      const { animateChange } = props;
      e.stopPropagation();
      goToIndex(index + 1, animateChange);
    },
    [goToIndex, index, props],
  );

  const handleClickPrev = useCallback(
    (e) => {
      const { animateChange } = props;
      e.stopPropagation();
      goToIndex(index - 1, animateChange);
    },
    [goToIndex, index, props],
  );

  return (
    <div className={cx('PhotoCarouselV2', className)}>
      <div
        className="PhotoCarouselV2-container"
        onMouseDown={handleStart}
        onTouchStart={handleStart}
        ref={containerRef}
        style={{ backgroundColor }}
      >
        <ul
          className={cx('PhotoCarouselV2-track', {
            'PhotoCarouselV2-track-transition': useTransition,
          })}
          style={trackStyle}
        >
          {children.map((child, i) => {
            return (
              <li
                className={cx('PhotoCarouselV2-item-wrapper', {
                  'PhotoCarouselV2-item-hidden': index !== i,
                })}
                style={itemStyle}
                key={child.key}
              >
                {React.cloneElement(child, {
                  className: cx('PhotoCarouselV2-item', child.props.className),
                  onClick: (e) => handleCenterClick(e, child.props.onClick),
                  style: { width: '100%' },
                })}
              </li>
            );
          })}
        </ul>
        {props.showItemCount && currentDisplayIndex <= maxDisplayItemCount && (
          <div
            className={cx('PhotoCarouselV2-item-count', {
              'PhotoCarouselV2-item-count-bottom-right': itemCountPosition === 'bottom-right',
              'PhotoCarouselV2-item-count-top-right': itemCountPosition === 'top-right',
              'PhotoCarouselV2-item-count-hide': itemCountPosition === 'none',
            })}
          >
            <div className="PhotoCarouselV2-item-count-badge">{displayItemCount}</div>
          </div>
        )}
        {children.length > 1 && (
          <div className="PhotoCarouselV2-arrow-controls">
            <div className="PhotoCarouselV2-prev-target" onClick={handleClickPrev}>
              <button
                aria-label="Previous image"
                className={cx('PhotoCarouselV2-arrow', {
                  'PhotoCarouselV2-arrow-disabled': isFirst,
                })}
                onClick={handleClickPrev}
              >
                <img src={IconArrowLeftDark} width="16px" height="16px" alt="" />
              </button>
            </div>
            <div className="PhotoCarouselV2-next-target" onClick={handleClickNext}>
              <button
                aria-label="Next image"
                className={cx('PhotoCarouselV2-arrow', 'PhotoCarouselV2-arrow-right', {
                  'PhotoCarouselV2-arrow-disabled': isLast,
                })}
                onClick={handleClickNext}
              >
                <img src={IconArrowRightDark} width="16px" height="16px" alt="" />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

PhotoCarouselV2.propTypes = {
  animateChange: PropTypes.bool,
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
  defaultIndex: PropTypes.number,
  ignoreKeypress: PropTypes.bool,
  itemCountOffset: PropTypes.number,
  itemCountPosition: PropTypes.string,
  loopable: PropTypes.bool,
  onChange: PropTypes.func,
  showItemCount: PropTypes.bool,
};

PhotoCarouselV2.defaultProps = {
  animateChange: true,
  backgroundColor: '#000',
  ignoreKeypress: false,
  itemCountOffset: 0,
  itemCountPosition: 'bottom-right',
  loopable: false,
  onChange: () => {},
  showItemCount: true,
};

export default PhotoCarouselV2;
