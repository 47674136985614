// @ts-nocheck
/* eslint-enable */
// GeoJson inverse boundary reverse engineered from: https://github.com/vasile/geomask
// Boundary mouseover tooltip: https://jsfiddle.net/asimmittal/jm24neys/

import forEach from 'lodash/forEach';
import assign from 'lodash/assign';
import flatten from 'lodash/flatten';
import gmapUtils from 'app/client/utils/map/gmapUtils';
import { getGlobalLogger } from '@zg-rentals/logger-base';
import { logError } from '@zg-rentals/log-error';

const logger = getGlobalLogger('modules/map');
let mapPolygon;

const controller = {
  pathIsCW(path) {
    let a = 0;
    for (let i = 0; i < path.length - 1; i++) {
      const psX = path[i].lng() - path[0].lng();
      const psY = path[i].lat() - path[0].lat();

      const peX = path[i + 1].lng() - path[0].lng();
      const peY = path[i + 1].lat() - path[0].lat();

      a += psX * (-1 * peY) - peX * (-1 * psY);
    }
    return a > 0;
  },

  parseGeoJson({ areaGeoJson, map, mapType }) {
    if (mapPolygon) {
      mapPolygon.setMap(null);
      mapPolygon = null;
    }

    mapPolygon = new window.google.maps.Polygon({
      map: null,
      clickable: true,
      strokeColor: mapType === 'default' ? '#2F3C53' : '#83E8DA',
      strokeOpacity: 0.8,
      strokeWeight: mapType === 'default' ? 2 : 3,
      fillColor: '#405474',
      fillOpacity: 0.4,
      paths: [],
    });

    const geojson = assign({}, areaGeoJson);

    let geometry = null;
    if (typeof geojson.features !== 'undefined') {
      geometry = geojson.features[0].geometry;
    } else if (typeof geojson.geometry !== 'undefined') {
      geometry = geojson.geometry;
    } else if (geometry === null) {
      geometry = geojson;
    }

    let coordinates = [];
    if (geometry.type === 'Polygon') {
      coordinates = geometry.coordinates;
    } else if (geometry.type === 'MultiPolygon') {
      const parsedCoordinates = [];
      coordinates = flatten(geometry.coordinates);

      forEach(coordinates, (polygonCoordinates) => {
        parsedCoordinates.push(gmapUtils.decodePolyline(polygonCoordinates));
        coordinates = [].concat(parsedCoordinates);
      });
    }

    const paths = [];
    forEach(coordinates, (pathCoordinates) => {
      const path = [];
      forEach(pathCoordinates, (point) => {
        const latPoint = parseFloat(point[0]);
        const lonPoint = parseFloat(point[1]);
        const newPoint = new window.google.maps.LatLng(latPoint, lonPoint);

        path.push(newPoint);
      });
      paths.push(path);
    });

    // Apply boundaries for entire world map:
    const worldCoords = [
      new window.google.maps.LatLng(-85.1054596961173, -180),
      new window.google.maps.LatLng(85.1054596961173, -180),
      new window.google.maps.LatLng(85.1054596961173, 180),
      new window.google.maps.LatLng(-85.1054596961173, 180),
      new window.google.maps.LatLng(-85.1054596961173, 0),
    ];

    paths.unshift(worldCoords);

    forEach(paths, (path, k) => {
      if (k > 0) {
        // Universe polygon is CW
        // The rest of the polygons are CCW
        if (controller.pathIsCW(path)) {
          path = path.slice().reverse();
        }
      }

      path = new window.google.maps.MVCArray(path);
      paths[k] = path;
    });

    mapPolygon.setPaths(new window.google.maps.MVCArray(paths));
    mapPolygon.setMap(map);
  },

  drawGeoJsonDataLayer({ map, mapType, areaGeoJson }) {
    if (!map) {
      return;
    }

    if (areaGeoJson.geometry) {
      try {
        controller.parseGeoJson({ areaGeoJson, map, mapType });
        return mapPolygon;
      } catch (err) {
        logError({ error: err, errorType: 'MapError', context: 'Error drawing boundary.' });
      }
    }
  },

  removeAllDataLayers() {
    if (mapPolygon) {
      window.google.maps.event.clearInstanceListeners(mapPolygon);
      mapPolygon.setMap(null);
      mapPolygon = null;
    }
  },
};

export default controller;
