// @ts-nocheck
/* eslint-enable */
import React from 'react';
import { connect } from 'react-redux';
import { yieldCallback } from '@zillow/yield-callback';
import styled from 'styled-components';
import { colors } from 'app/shared/styles/_colors';

import Button from 'app/shared/core/Button';
import 'app/shared/modules/photo-gallery/ContactCallToAction.scss';
import { analyticsEvent } from 'app/client/universal-analytics';
import { gaEvents } from 'app/shared/constants/AnalyticsConstants';
import Center from 'app/shared/modules/Center';
import Title from 'app/shared/modules/Title';

const StyledContactTodayText = styled(Title)`
  color: ${colors['$hpx-white']};
  fill: ${colors['$hpx-white']};
`;
class ContactCallToAction extends React.Component {
  yieldHandleClick = yieldCallback(() => {
    if (this.props.onCtaClick) {
      this.props.onCtaClick();
    }

    this.props.dispatch(analyticsEvent(gaEvents.PHOTO_GALLERY_CONTACT_CTA));
  });

  handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.yieldHandleClick();
  };

  render() {
    return (
      <div className="ContactCallToAction">
        <Center>
          <StyledContactTodayText size="md" htmlTag="div">
            Like what you see? Places go fast. <br />
            Contact today!
          </StyledContactTodayText>
          <div className="ContactCallToAction-btn">
            <Button onClick={this.handleClick}>Send an email</Button>
          </div>
        </Center>
      </div>
    );
  }
}

export { ContactCallToAction };
export default connect()(ContactCallToAction);
