// @ts-nocheck
/* eslint-enable */
// App
import React, { useCallback } from 'react';
import styled from 'styled-components';
import { margin } from 'app/shared/styles/_spacing';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useYieldCallback } from '@zillow/yield-callback';

// Components
import ButtonV2 from 'app/shared/core/ButtonV2';
import { hdpSharedCSSButtonContainers } from 'app/shared/modules/hdp/Hdp/styles';
import PoweredByZillowBadge from 'app/shared/modules/PoweredByZillowBadge';

// Misc / Utils
import IconApplicationsTeal from 'images/icons/applications-teal.svg';
import { shouldShowApplyButton } from 'app/shared/utils/cometUtils';
import { analyticsEvent } from 'app/client/universal-analytics';
import { gaEvents } from 'app/shared/constants/AnalyticsConstants';
import { TrackApplyNowClick, TrackRequestContactStart } from 'app/shared/models/Clickstream/HdpClickstreamEvents';

const { bool, func } = PropTypes;

export const StyledContainer = styled.div`
  ${hdpSharedCSSButtonContainers}
`;

const PoweredByZillowContainer = styled.div`
  ${margin.top._1x};
  text-align: center;
`;

const VERBIAGE_CONSTANTS = {
  true: {
    button: 'Apply now',
    footer: 'Powered by',
  },
  false: {
    button: 'Request to apply',
    footer: 'Powered by',
  },
};

const ZillowApplicationsButton = ({ showContactButton = '', onActionPopup = () => {} }) => {
  // Redux
  const dispatch = useDispatch();
  const listing = useSelector((state) => state.currentListingDetails.currentListing);

  // Other vars needed for rendering components
  const { rentalApplicationStatus } = listing;
  const acceptsApplications = shouldShowApplyButton(rentalApplicationStatus);

  const yieldTrackAndOpenModal = useYieldCallback(() => {
    onActionPopup('zillowApplications');
    if (acceptsApplications) {
      // Fire tracking for initial 'Apply now' button click
      dispatch(analyticsEvent(gaEvents.HDP_APPLICATION_CTA_APPLY_NOW, { newLaneEvent: TrackApplyNowClick() }));
    } else {
      // Fire request applications tracking (e.g., "Request to apply" button)
      dispatch(
        analyticsEvent(gaEvents.HDP_APPLICATION_CTA_APPLICATION_REQUEST, {
          newLaneEvent: TrackRequestContactStart(),
        }),
      );
    }
  });

  const handleButtonClick = useCallback(() => {
    yieldTrackAndOpenModal();
  }, [yieldTrackAndOpenModal]);

  return (
    <StyledContainer showContactButton={showContactButton}>
      <ButtonV2
        btnType={'primary-outline'}
        onClick={handleButtonClick}
        rounded={false}
        full
        bold
        size={'lg'}
        icon={<img src={IconApplicationsTeal} height="20px" width="22px" alt="" />}
      >
        {VERBIAGE_CONSTANTS[acceptsApplications]?.button}
      </ButtonV2>
      {acceptsApplications && (
        <PoweredByZillowContainer>
          <PoweredByZillowBadge />
        </PoweredByZillowContainer>
      )}
    </StyledContainer>
  );
};

ZillowApplicationsButton.propTypes = {
  showContactButton: bool,
  /**
   * onActionPopup: Engages the ZRentals (or any modal in ActionPopupWrapper for that matter)
   */
  onActionPopup: func,
};

export default ZillowApplicationsButton;
