import React from 'react';
import './style.scss';

interface ReviewsBreakdownBarProps {
  percentWidth: number;
}

const ReviewsBreakdownBar: React.FC<ReviewsBreakdownBarProps> = ({ percentWidth }) => {
  return (
    <div className="ReviewsBreakdownBar">
      <div className="ReviewsBreakdownBar-filled" style={{ width: `${percentWidth}%` }} />
    </div>
  );
};

export default ReviewsBreakdownBar;
