// @ts-nocheck
/* eslint-enable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import map from 'lodash/map';
import some from 'lodash/some';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';

import './ContactOverlay.scss';
import Button from 'app/shared/core/Button';
import { listingUtils_userRecentlyContactedListing } from 'app/shared/utils/listingUtils';
import Overlay from 'app/shared/modules/popups/Overlay';
import Row from 'app/shared/core/Row';

const propTypes = {
  contactSubmitted: PropTypes.bool.isRequired,
  listing: PropTypes.object.isRequired,
};

class ContactOverlay extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showOverlay: false,
    };
  }
  componentDidMount() {
    this.shouldShowOverLay();
  }
  componentWillReceiveProps(nextProps) {
    const { contactSubmitted, previousInquiryMessagePopup } = this.props;
    if (!contactSubmitted && nextProps.contactSubmitted) {
      this.shouldShowOverLay();
    }
    if (!previousInquiryMessagePopup && nextProps.previousInquiryMessagePopup) {
      this.shouldShowOverLay();
    }
  }
  componentDidUpdate(prevProps) {
    const { listing } = this.props;
    if (!isEqual(prevProps?.listing?.userItemTypes, listing?.userItemTypes)) {
      this.shouldShowOverLay();
    }
  }
  shouldShowOverLay = () => {
    const { listing } = this.props;
    const userOnWaitlist = listing.userOnWaitlist;
    const recentlyContacted = listingUtils_userRecentlyContactedListing(listing.userItemTypes, listing.userItemDates);
    const isStudentHousing = listing.details.studentHousing;
    const isSeniorHousing = listing.details.seniorHousing;
    const isLowIncome = listing.contact.restricted;

    const showOverlay = userOnWaitlist || recentlyContacted || isStudentHousing || isSeniorHousing || isLowIncome;

    if (showOverlay) {
      this.setState({ showOverlay: true });
    }
  };
  handleHideOverlay = (e) => {
    if (e) {
      e.stopPropagation();
    }

    this.setState({ showOverlay: false });
  };
  requiresIncomeRange = () => {
    const { listing } = this.props;

    if (listing.contact.restricted && some(listing.contact.restrictedIncomeByOccupants)) {
      return true;
    }

    return false;
  };
  getOverlayMessage = () => {
    const { listing } = this.props;
    const userOnWaitlist = listing.userOnWaitlist;
    const recentlyContacted = listingUtils_userRecentlyContactedListing(listing.userItemTypes, listing.userItemDates);
    const isStudentHousing = listing.details.studentHousing;
    const isSeniorHousing = listing.details.seniorHousing;
    const isLowIncome = listing.contact.restricted;
    const messageClassName = 'ContactOverlay-content';

    if (userOnWaitlist) {
      return (
        <div className={messageClassName}>
          <Row>Your name has already been added to the waitlist.</Row>
          <Row>
            <Button size="lg" btnType="text-color" onClick={this.handleHideOverlay}>
              Continue
            </Button>
          </Row>
        </div>
      );
    } else if (recentlyContacted) {
      return (
        <div className={messageClassName}>
          <Row>You have already contacted this property.</Row>
          <Row>
            <Button size="lg" btnType="text-color" onClick={this.handleHideOverlay}>
              Contact again?
            </Button>
          </Row>
        </div>
      );
    } else if (isStudentHousing) {
      return (
        <div className={messageClassName}>
          <Row>This property is student-only housing. You must qualify to live here.</Row>
          <Row>
            <Button size="lg" btnType="text-color" onClick={this.handleHideOverlay}>
              Continue
            </Button>
          </Row>
        </div>
      );
    } else if (isSeniorHousing) {
      return (
        <div className={messageClassName}>
          <Row>
            You are about to contact a senior housing community. Verification of age requirements is required to live
            here.
          </Row>
          <Row>
            <Button size="lg" btnType="text-color" onClick={this.handleHideOverlay}>
              Continue
            </Button>
          </Row>
        </div>
      );
    } else if (isLowIncome) {
      return (
        <div className={messageClassName}>
          <Row>{listing.contact.message}</Row>
          {this.requiresIncomeRange() && (
            <Row>
              {map(listing.contact.restrictedIncomeByOccupants, (restriction, persons) => {
                if (restriction === '') {
                  return null;
                }

                return (
                  <div className="ContactOverlay-income-req" key={`${restriction}${persons}`}>
                    {Number(persons) > 1 ? `${persons} residents` : `${persons} resident`}, income below {restriction}
                  </div>
                );
              })}
            </Row>
          )}
          <Row>
            <Button size="lg" btnType="text-color" onClick={this.handleHideOverlay}>
              Yes, I qualify!
            </Button>
          </Row>
        </div>
      );
    }
  };
  render() {
    const { hasConversation, overlayOffset, satelliteEnabled, shouldLockFocus } = this.props;
    const { showOverlay } = this.state;

    if (!showOverlay || (hasConversation && satelliteEnabled)) {
      return null;
    }

    return (
      <Overlay onHidePopup={this.handleHideOverlay} overlayOffset={overlayOffset} shouldLockFocus={shouldLockFocus}>
        <div className="ContactOverlay-container">{this.getOverlayMessage()}</div>
      </Overlay>
    );
  }
}

ContactOverlay.propTypes = propTypes;
export default connect()(ContactOverlay);
