import React from 'react';
import Container from 'app/shared/core/Container';
import Row from 'app/shared/core/Row';
import 'app/shared/modules/hdp/WaitlistNotice.scss';

const WaitlistNotice: React.FC = () => {
  return (
    <div className="WaitlistNotice">
      <Row>
        <Container>Listing in high demand - join the waitlist!</Container>
      </Row>
    </div>
  );
};

WaitlistNotice.displayName = 'WaitlistNotice';

export default WaitlistNotice;
