// @ts-nocheck
/* eslint-enable */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import AppActions from 'app/shared/flux/actions/AppActions';
import Button from 'app/shared/core/Button';
import ReviewResponseForm from 'app/shared/modules/reviews/ReviewResponseForm';
import Row from 'app/shared/core/Row';
import { getGlobalLogger } from '@zg-rentals/logger-base';

import './style.scss';

const logger = getGlobalLogger('modules/reviews');

class ReviewResponse extends React.Component {
  static displayName = 'ReviewResponse';
  static propTypes = {
    hasResponse: PropTypes.bool,
    reviewResponseStatus: PropTypes.string,
    reviewId: PropTypes.string,
  };

  constructor(props) {
    super(props);
    const { reviewResponseStatus } = props;

    this.state = {
      showResponseForm: false,
      hasPendingResponse: reviewResponseStatus === 'PENDING',
      loading: false,
    };
  }

  handleResponseClick = () => {
    this.setState(() => {
      return {
        showResponseForm: true,
      };
    });
  };

  handleResponseSubmit = (comment) => {
    const { dispatch, reviewId } = this.props;

    this.setState(() => {
      return {
        loading: true,
      };
    });

    dispatch(AppActions.saveReviewResponse(reviewId, comment))
      .then((data) => {
        if (!data.success) {
          this.setState(() => {
            return {
              loading: false,
            };
          });

          dispatch(
            AppActions.toggleNotificationMessage({
              content: 'Sorry! We encountered an error.',
              notificationStyle: 'alert',
            }),
          );

          return;
        }

        dispatch(
          AppActions.toggleNotificationMessage({
            content: 'Thank you for your feedback.',
          }),
        );

        this.setState(() => {
          return {
            loading: false,
            hasPendingResponse: true,
            showResponseForm: false,
          };
        });
      })
      .catch((err) => {
        this.setState(() => {
          return {
            loading: false,
          };
        });

        dispatch(
          AppActions.toggleNotificationMessage({
            content: 'Sorry! We encountered an error.',
            notificationStyle: 'alert',
          }),
        );

        logger?.warn(err, 'Error submitting user response to review.');
      });
  };

  handleResponseCancel = () => {
    this.setState(() => {
      return {
        showResponseForm: false,
      };
    });
  };

  render() {
    const { hasResponse, userCanReplyToReviews } = this.props;
    const { showResponseForm, hasPendingResponse, loading } = this.state;
    const showResponseBtn = userCanReplyToReviews && !showResponseForm && (!hasResponse || hasPendingResponse);
    if (!showResponseBtn && !showResponseForm) {
      return null;
    }

    return (
      <div className="ReviewResponse">
        <Row>
          {showResponseBtn && (
            <Button size="sm" onClick={this.handleResponseClick} disabled={hasPendingResponse}>
              {hasPendingResponse ? 'Response pending review' : 'Respond to review'}
            </Button>
          )}
          {showResponseForm && (
            <div className="ReviewResponse-response-form">
              <ReviewResponseForm
                onResponseSubmit={this.handleResponseSubmit}
                onResponseCancel={this.handleResponseCancel}
                isLoading={loading}
              />
            </div>
          )}
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userCanReplyToReviews: state.currentListingDetails.userCanReplyToReviews,
    userEmail: state.user.info.email,
    userToken: state.user.userToken,
  };
};

export { ReviewResponse };
export default connect(mapStateToProps)(ReviewResponse);
