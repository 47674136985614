// @ts-nocheck
/* eslint-enable */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import AreaActions from 'app/shared/flux/actions/AreaActions';
import AreaBoundaryCache from 'app/shared/cache/areaBoundaryCache';
import MapBorderTooltip from 'app/shared/modules/map/mapborder-tooltip';
import controller from 'app/shared/modules/map/MapBorder/controller';

class MapBorder extends React.Component {
  static propTypes = {
    area: PropTypes.object,
    isMobile: PropTypes.bool,
    map: PropTypes.object,
    mapType: PropTypes.string,
  };

  static defaultProps = {
    area: {},
    isMobile: false,
    map: null,
    mapType: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      showTooltip: false,
    };

    this.boundaryLayer = null;
    this.attachClickListeners = this.attachClickListeners.bind(this);
  }

  componentDidMount() {
    if (!isEmpty(this.props.area)) {
      this.fetchBoundaryData();
    }
  }

  componentDidUpdate(prevProps) {
    const { area, mapType } = this.props;
    const areaChanged = prevProps.area.id !== area.id;
    const mapSatelliteViewToggled = prevProps.mapType !== mapType;

    if ((area.id && areaChanged) || mapSatelliteViewToggled) {
      this.fetchBoundaryData();
    }
  }

  componentWillUnmount() {
    this.boundaryLayer = null;
    controller.removeAllDataLayers();
  }

  fetchBoundaryData = () => {
    const { area, map, mapType } = this.props;
    const areaGeoJson = AreaBoundaryCache.get(area.id);
    controller.removeAllDataLayers();

    if (areaGeoJson) {
      this.boundaryLayer = controller.drawGeoJsonDataLayer({ map, mapType, areaGeoJson });
      this.attachClickListeners();
    } else {
      this.props.dispatch(AreaActions.getAreaGeoJson(area)).then(() => {
        const newAreaGeoJson = AreaBoundaryCache.get(area.id);
        this.boundaryLayer = controller.drawGeoJsonDataLayer({ map, mapType, areaGeoJson: newAreaGeoJson });
        this.attachClickListeners();
      });
    }
  };

  attachClickListeners() {
    const { map, onBoundaryClick, onBoundaryDblClick } = this.props;

    if (this.boundaryLayer) {
      window.google.maps.event.addListener(this.boundaryLayer, 'click', () => {
        onBoundaryClick(map);
      });

      window.google.maps.event.addListener(this.boundaryLayer, 'dblclick', () => {
        onBoundaryDblClick();
      });

      window.google.maps.event.addListener(this.boundaryLayer, 'mouseover', () => {
        this.setState({ showTooltip: true });
      });

      window.google.maps.event.addListener(this.boundaryLayer, 'mouseout', () => {
        this.setState({ showTooltip: false });
      });
    }
  }

  render() {
    const { isMobile } = this.props;
    const { showTooltip } = this.state;

    if (showTooltip && !isMobile) {
      return <MapBorderTooltip />;
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.app.device.screenWidth === 'sm',
  };
};

export default connect(mapStateToProps)(MapBorder);
