import type { ReactNode } from 'react';
import React, { Fragment } from 'react';
import { getGlobalLogger } from '@zg-rentals/logger-base';
import { logError } from '@zg-rentals/log-error';

const logger = getGlobalLogger('modules/tab');

interface TabItemProps {
  tabTitle?: string; // tabTitle is an optional string prop
  children?: ReactNode; // children can be any valid React nodes
}

const TabItem: React.FC<TabItemProps> = ({ tabTitle, children }) => {
  if (!tabTitle) {
    logError({ error: 'TabItem is missing tabTitle prop', errorType: 'TabItemError' });
  }
  return <Fragment>{children}</Fragment>;
};

export default TabItem;
