// @ts-nocheck
/* eslint-enable */
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { colors } from 'app/shared/styles/_colors';
import { yieldCallback } from '@zillow/yield-callback';

import cx from 'classnames';
import HdpContentWrapper from 'app/shared/modules/hdp/HdpContentWrapper';
import LinkToggle from 'app/shared/modules/LinkToggle';
import ListItem from 'app/shared/modules/ListItem';
import Row from 'app/shared/core/Row';
import Text from 'app/shared/core/Text';
import './style.scss';
import { analyticsEvent } from 'app/client/universal-analytics';
import { gaEvents } from 'app/shared/constants/AnalyticsConstants';

const StyledDarkText = styled(Text)`
  color: ${colors['$hpx-grey-600']};
  font-weight: bold;
`;
class HdpAmenitySection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAllPropAmenities: false,
      showAllUnitAmenities: false,
      INITIAL_VISIBLE_COUNT: 8,
    };
  }
  componentDidMount = () => {
    if (this.props.screenWidth === 'sm') {
      this.setState({ INITIAL_VISIBLE_COUNT: Infinity });
    }
  };
  yieldToggleShowMore = yieldCallback((stateParam) => {
    this.setState({
      [stateParam]: !this.state[stateParam],
    });
    this.props.dispatch(
      analyticsEvent(gaEvents.SHOW_MORE_LINK_TOGGLE, {
        category: 'HDP',
        label: 'HdpAmenitySection',
      }),
    );
  });
  toggleShowMore = (stateParam) => {
    this.yieldToggleShowMore(stateParam);
  };
  renderAmenties = (amenities, amentiesTitle, toggleState) => {
    const totalAmenities = amenities.length;
    let toggleMoreLess;

    if (totalAmenities === 0) {
      return null;
    }

    if (totalAmenities > this.state.INITIAL_VISIBLE_COUNT && !this.state[toggleState]) {
      toggleMoreLess = 'Show more';
    } else if (totalAmenities > this.state.INITIAL_VISIBLE_COUNT) {
      toggleMoreLess = 'Show less';
    } else {
      toggleMoreLess = null;
    }

    const amentiesLinks = amenities.map((amenity) => {
      return <ListItem key={amenity}>{amenity}</ListItem>;
    });

    return (
      <Fragment>
        <Row size="sm">
          <StyledDarkText size="sm">{amentiesTitle}</StyledDarkText>
        </Row>
        <div>
          <ul>{amentiesLinks.slice(0, this.state.INITIAL_VISIBLE_COUNT)}</ul>
          <ul className={cx({ 'HdpAmenitySection-hidden': !this.state[toggleState] })}>
            {amentiesLinks.slice(this.state.INITIAL_VISIBLE_COUNT)}
          </ul>
        </div>
        {toggleMoreLess && (
          <Row>
            <LinkToggle onClick={() => this.toggleShowMore(toggleState)}>{toggleMoreLess}</LinkToggle>
          </Row>
        )}
      </Fragment>
    );
  };
  render() {
    const { listing } = this.props;
    const isExpired = !listing.active;
    const propAmenities = listing.amenities.amenities;
    const unitAmenities = listing.amenities.modelAmenities;

    if (propAmenities.length === 0 && unitAmenities.length === 0) {
      return null;
    }

    return (
      <div className="HdpAmenitySection">
        <HdpContentWrapper active={isExpired} collapsable header="Apartment amenities" headerHtmlTag="h2">
          <div className={cx({ 'HdpAmenitySection-half-width': propAmenities.length && unitAmenities.length })}>
            {this.renderAmenties(propAmenities, 'Property amenities', 'showAllPropAmenities')}
          </div>
          <div className={cx({ 'HdpAmenitySection-half-width': propAmenities.length && unitAmenities.length })}>
            {this.renderAmenties(unitAmenities, 'Unit amenities', 'showAllUnitAmenities')}
          </div>
        </HdpContentWrapper>
      </div>
    );
  }
}

export default connect()(HdpAmenitySection);
