// @ts-nocheck
/* eslint-enable */
import React, { Component } from 'react';
import { yieldCallback } from '@zillow/yield-callback';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import forEach from 'lodash/forEach';
import styled from 'styled-components';
import { padding } from 'app/shared/styles/_spacing';

// Components
import ArrowLeft from 'images/icons/arrow-left-dark-grey.svg';
import Button from 'app/shared/core/Button';
import PhotoGallery3DTour from 'app/shared/modules/photo-gallery/PhotoGallery3DTour';
import PhotoGalleryCarousel from 'app/shared/modules/photo-gallery/PhotoGalleryCarousel';
import PhotoGalleryFull from 'app/shared/modules/photo-gallery/PhotoGalleryFull';
import PhotoGalleryImage from 'app/shared/modules/photo-gallery/PhotoGalleryImage';
import PhotoGalleryHeader from 'app/shared/modules/photo-gallery/PhotoGalleryWrapper/Header';
import PopupModal from 'app/shared/modules/popups/PopupModal';

// Misc / Utils
import './style.scss';
import { analyticsEvent } from 'app/client/universal-analytics';
import { gaEvents } from 'app/shared/constants/AnalyticsConstants';
import controller from './controller';
import IconXBlack from 'images/icons/x.svg';
import IconXWhite from 'images/icons/x-white.svg';
import Icon3DGlasses from 'images/icons/3d-glasses.svg';
import { listingHasTourUrls } from 'app/shared/utils/listingUtils';
import {
  GridWallCSS,
  LargeTileCSS,
  SmallTileCSS,
} from 'app/shared/modules/photo-gallery/PhotoGalleryPopupModal/styles';

const { bool, func, array, object } = PropTypes;

const StyledTitleDiv = styled.div`
  ${padding.top._4x};
`;

/* eslint-disable no-undefined */
class PhotoGalleryPopupModal extends Component {
  static propTypes = {
    isMobile: bool,
    listing: object.isRequired,
    onClose: func,
    onClickContact: func,
    onClickOpenFdp: func,
    photos: array.isRequired,
    show3dToursIframe: bool,
  };

  static defaultProps = {
    onClickContact: () => {},
    onClickOpenFdp: () => {},
    onClose: () => {},
    show3dToursIframe: false,
  };

  constructor(props) {
    super(props);
    this.loggedIntersections = {};
    this.rootRef = React.createRef();

    this.state = {
      currentPhotoIndex: null,
      photoGridArray: [],
      floorplanGridArray: [],
      scrollYPosition: 0,
      show3dToursIframe: props.show3dToursIframe,
    };

    if (window) {
      require('intersection-observer');
      this.observer = new IntersectionObserver(this.intersectionCallback, {
        root: this.rootRef.current,
      });
    }
  }

  componentDidMount() {
    const { listing = {} } = this.props;
    // this must be in cdm because this.observer must be initialized
    const photoAndFloorplanGridPhotos = controller.getPhotoGridPhotos({
      listing,
      observer: this.observer,
    });

    this.setState(photoAndFloorplanGridPhotos);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentPhotoIndex !== null && this.state.currentPhotoIndex === null) {
      setTimeout(() => {
        const element = document.getElementsByClassName('PhotoGalleryPopupModal-content')[0];

        if (this.props.isMobile) {
          document.documentElement.scrollTop = element.offsetTop;
        } else {
          element.scrollTop = this.state.scrollYPosition;
        }
      }, 25);
    }
  }

  componentWillUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  intersectionCallback = (entries) => {
    forEach(entries, (entry = {}) => {
      const { target } = entry;
      const { src } = target;

      if (entry.isIntersecting && typeof this.loggedIntersections[src] === 'undefined') {
        this.loggedIntersections[src] = true;
        this.props.dispatch(analyticsEvent(gaEvents.HDP_ADDITIONAL_PHOTO_VIEW));
      }
    });
  };

  yieldContactClick = yieldCallback(() => {
    const { onClickContact, dispatch } = this.props;
    onClickContact();
    dispatch(analyticsEvent(gaEvents.PHOTO_GALLERY_FULL_CONTACT_CTA));
  });

  handleContactCtaClick = (e) => {
    e.stopPropagation();
    this.yieldContactClick();
  };

  handleBackClick = yieldCallback(() => {
    if (this.state.currentPhotoIndex) {
      this.setState({ currentPhotoIndex: null });
    } else {
      this.props.onClose();
    }
  });

  handleGalleryBackClick = yieldCallback(() => {
    this.setState({
      currentPhotoIndex: null,
      show3dToursIframe: false,
    });
  });

  handlePhotoClick = yieldCallback((e, photoObj = {}) => {
    this.saveScrollPosition();
    this.setState({ currentPhotoIndex: photoObj.photo.galleryidx || photoObj.index });
  });

  handleShow3DTour = yieldCallback(() => {
    this.setState({ show3dToursIframe: true });
  });

  saveScrollPosition = () => {
    const element = document.getElementsByClassName('PhotoGalleryPopupModal-content')[0];
    this.setState({ scrollYPosition: element.scrollTop });
  };

  renderGallery() {
    const { photoGridArray, floorplanGridArray } = this.state;

    return (
      <>
        <div className="PhotoGalleryPopupModal-title" id="dialog-title">
          Photos
        </div>
        <ul className={GridWallCSS}>
          {photoGridArray.map((photo, idx) => {
            if (idx % 3 === 0) {
              return (
                <li key={`photo-${idx}`} className={LargeTileCSS}>
                  <PhotoGalleryImage photo={photo} onClick={this.handlePhotoClick} />
                </li>
              );
            }
            return (
              <li key={`photo-${idx}`} className={SmallTileCSS}>
                <PhotoGalleryImage photo={photo} onClick={this.handlePhotoClick} />
              </li>
            );
          })}
        </ul>
        {!isEmpty(floorplanGridArray) && (
          <>
            <StyledTitleDiv className="PhotoGalleryPopupModal-title">Floor plans</StyledTitleDiv>
            <ul className={GridWallCSS}>
              {floorplanGridArray.map((photo, idx) => {
                if (idx % 3 === 0) {
                  return (
                    <li key={`floorplan-photo-${idx}`} className={LargeTileCSS}>
                      <PhotoGalleryImage photo={photo} onClick={this.handlePhotoClick} />
                    </li>
                  );
                }
                return (
                  <li key={`floorplan-photo-${idx}`} className={SmallTileCSS}>
                    <PhotoGalleryImage photo={photo} onClick={this.handlePhotoClick} />
                  </li>
                );
              })}
            </ul>
          </>
        )}
      </>
    );
  }

  render() {
    const { onClose, isMobile, listing, onClickContact, onClickOpenFdp, photos } = this.props;
    const { currentPhotoIndex, photoGridArray, floorplanGridArray, show3dToursIframe } = this.state;
    const { active, details, displayName, photoCount } = listing;
    const { tourUrls } = details;
    const showContactUpsell = active;
    const show3dToursButton = listingHasTourUrls(listing);
    const showFullScreenPhoto = currentPhotoIndex !== null;

    if (show3dToursIframe) {
      return (
        <PopupModal
          aria-label="3D Tour"
          customCloseIcon={<img src={IconXBlack} height={20} width={20} alt="" />}
          onHidePopup={onClose}
          paddingTop="0"
          paddingBottom="0"
        >
          <PhotoGallery3DTour urls={tourUrls} onGalleryBackClick={this.handleGalleryBackClick} isMobile={isMobile} />
        </PopupModal>
      );
    }

    return (
      <>
        <PopupModal
          aria-labelledby="dialog-title"
          paddingTop="0"
          paddingBottom="0"
          onHidePopup={onClose}
          useCloseButton={!isMobile}
          scrollContainerOverride={true}
        >
          <div className="PhotoGalleryPopupModal-container">
            {isMobile && (
              <PhotoGalleryHeader className="PhotoGalleryPopupModal-header" showBottomBorder>
                <div className="PhotoGalleryPopupModal-header-left">
                  <button className="PhotoGalleryPopupModal-back-btn" onClick={this.handleBackClick}>
                    <img className="PhotoGalleryPopupModal-arrow" src={ArrowLeft} alt="" />
                    <div className="PhotoGalleryPopupModal-back">Back</div>
                  </button>
                </div>
                <div className="PhotoGalleryPopupModal-header-right">
                  {show3dToursButton && (
                    <div className="PhotoGalleryPopupModal-3d-mweb-btn">
                      <Button btnType="default" size="sm" onClick={this.handleShow3DTour}>
                        <img src={Icon3DGlasses} className="PhotoGalleryPopupModal-3d-icon" alt="" />
                        3D Tour
                      </Button>
                    </div>
                  )}
                </div>
              </PhotoGalleryHeader>
            )}
            <div className="PhotoGalleryPopupModal-content" ref={this.rootRef}>
              {isEmpty(photoGridArray) ? <div>This listing has no photos.</div> : this.renderGallery()}
            </div>
            <div className="PhotoGalleryPopupModal-footer">
              <div>
                <strong>{displayName}</strong>
                <br />
                {Number(photoGridArray.length) + Number(floorplanGridArray.length)} photos
              </div>
              <div className="PhotoGalleryPopupModal-buttons">
                {show3dToursButton && (
                  <div className="PhotoGalleryPopupModal-3d-btn">
                    <Button btnType="secondary" onClick={this.handleShow3DTour}>
                      <img src={Icon3DGlasses} className="PhotoGalleryPopupModal-3d-icon" alt="" />
                      Switch to 3D tour
                    </Button>
                  </div>
                )}
                {showContactUpsell && <Button onClick={onClickContact}>Contact now</Button>}
              </div>
            </div>
          </div>
          {showFullScreenPhoto && isMobile && (
            <PopupModal
              aria-labelledby="dialog-title"
              isFullScreen
              onHidePopup={this.handleGalleryBackClick}
              paddingTop="0"
              paddingBottom="0"
              useCloseButton={false}
            >
              <PhotoGalleryCarousel
                currentPhotoIndex={currentPhotoIndex}
                listing={listing}
                onClickContact={this.handleContactCtaClick}
                onClickOpenFdp={onClickOpenFdp}
                onClose={onClose}
                onGalleryBackClick={this.handleGalleryBackClick}
                photos={photos}
              />
            </PopupModal>
          )}
          {showFullScreenPhoto && !isMobile && (
            <PopupModal
              aria-labelledby="dialog-title"
              customCloseIcon={<img src={IconXWhite} height={20} width={20} alt="" />}
              isFullScreen
              onHidePopup={this.handleGalleryBackClick}
              paddingTop="0"
              paddingBottom="0"
            >
              <PhotoGalleryFull
                defaultCurrentIndex={currentPhotoIndex}
                onClickContact={this.handleContactCtaClick}
                onClickOpenFdp={onClickOpenFdp}
                onPhotoGalleryTo3dTours={this.handleShow3DTour}
                photoCount={photoCount}
                photos={photos}
                showContactUpsell={active}
                thumbPhotos={photos}
                has3dTour={details ? details.tourUrls.length > 0 : false}
              />
            </PopupModal>
          )}
        </PopupModal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  isMobile: state.app.device.screenWidth === 'sm' || state.app.device.screenWidth === 'xs',
  screenWidth: state.app.device.screenWidth,
});

export default connect(mapStateToProps)(PhotoGalleryPopupModal);
