// @ts-nocheck
/* eslint-enable */
import React from 'react';
import styled from 'styled-components';
import Text from 'app/shared/core/Text';
import Container from 'app/shared/core/Container';
import HdpContentWrapper from 'app/shared/modules/hdp/HdpContentWrapper';
import { colors } from 'app/shared/styles/_colors';
import { fontSize } from 'app/shared/styles/_fontsize';

const ListingRestrictionTag = styled(Text)`
  display: inline-block;
  background: ${colors['$hpx-teal-100']};
  color: ${colors['$hpx-blue-500 ']};
  font-size: ${fontSize['$font-size-sm']};
  padding: 4px 16px;
  border-radius: 16px;
  vertical-align: bottom;
  margin-right: 8px;
`;

const ListingTagWrapper = styled(HdpContentWrapper)`
  border-bottom: 1px solid ${colors['$hpx-grey-100']};
`;

const HdpSummaryListingTags = ({ listing }) => {
  const { building, contact, details, listingType } = listing;
  const restrictionsArray = [];
  const isIncomeRestricted = !building && contact.restricted;
  const isStudentHousing = details.studentHousing;
  const isSeniorHousing = details.seniorHousing;
  const isRoom = listingType === 'room';
  const isSublet = listingType === 'sublet';
  const isFurnished = details.furnished;

  const hasRestriction = isIncomeRestricted || isSeniorHousing || isStudentHousing || isRoom || isSublet || isFurnished;

  if (!hasRestriction) {
    return null;
  }

  if (isRoom) {
    restrictionsArray.push('Room for rent');
  }
  if (isSublet) {
    restrictionsArray.push('Sublet');
  }

  if (isFurnished) {
    restrictionsArray.push('Furnished');
  }

  if (isIncomeRestricted) {
    restrictionsArray.push('Income restricted');
  }

  if (isSeniorHousing) {
    restrictionsArray.push('Senior housing');
  }

  if (isStudentHousing) {
    restrictionsArray.push('Student housing');
  }
  return (
    <div className="HdpSummaryListingTags">
      <ListingTagWrapper fullContent active>
        <Container>
          <div>
            {restrictionsArray.map((item) => (
              <ListingRestrictionTag size="sm" key={item}>
                {item}
              </ListingRestrictionTag>
            ))}
          </div>
        </Container>
      </ListingTagWrapper>
    </div>
  );
};

export default HdpSummaryListingTags;
