// @ts-nocheck
/* eslint-enable */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import dateUtils from 'app/shared/utils/dateUtils';
import DayPicker from 'app/shared/core/DayPicker';
import Input from 'app/shared/core/Input';
import './style.scss';

class MoveInCalendar extends Component {
  static propTypes = {
    value: PropTypes.string,
    toggleSubmitDisabled: PropTypes.func,
    onCalendarInput: PropTypes.func.isRequired,
  };

  static defaultProps = {
    value: '',
    toggleSubmitDisabled: () => {},
  };

  constructor(props) {
    super(props);
    const { value: initialValue } = this.props;

    this.state = {
      openCalendar: false,
      month: initialValue ? new Date(initialValue) : '',
      value: initialValue ? initialValue : '',
      alertMessage: null,
    };
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  toggleCalendar = () => {
    const { toggleSubmitDisabled } = this.props;
    const { openCalendar } = this.state;
    this.setState(
      {
        openCalendar: !openCalendar,
        alertMessage: null,
      },
      () => {
        toggleSubmitDisabled();
      },
    );
  };

  setAlert = (alertMessage) => {
    this.setState({
      alertMessage,
    });
  };

  handleDayTouchTap = (day, modifiers) => {
    if (modifiers.disabled) {
      return;
    }

    const { onCalendarInput, toggleSubmitDisabled } = this.props;
    const dayValue = dateUtils.formatDateMmddyyyy(day);
    this.setState(
      {
        value: dayValue,
        month: day,
        openCalendar: false,
        alertMessage: null,
      },
      () => {
        setTimeout(() => {
          if (this.mounted) {
            toggleSubmitDisabled();
          }
        }, 500);
      },
    );

    onCalendarInput(dayValue);
  };

  renderCalendar = () => {
    const { state } = this;

    const modifiers = {
      disabled: function disabled(d1) {
        const d2 = new Date();

        if (d1.getFullYear() < d2.getFullYear()) {
          return true;
        } else {
          if (d1.getMonth() < d2.getMonth()) {
            return true;
          } else if (d1.getMonth() === d2.getMonth()) {
            return d1.getDate() < d2.getDate();
          } else {
            return false;
          }
        }
      },
      selected: function selected(d1) {
        const d2 = state.month;

        return (
          d1.getDate() === d2.getDate() && d1.getMonth() === d2.getMonth() && d1.getFullYear() === d2.getFullYear()
        );
      },
    };

    return (
      <DayPicker
        enableOutsideDays
        initialMonth={state.month}
        modifiers={modifiers}
        numberOfMonths={1}
        onDayClick={this.handleDayTouchTap}
      />
    );
  };

  render() {
    const { onCalendarInput } = this.props;
    const { value, openCalendar, alertMessage } = this.state;
    return (
      <div className="MoveInCalendar">
        <Input
          label="Move-in date"
          onClick={this.toggleCalendar}
          required
          value={value}
          onChange={() => onCalendarInput(value)}
        />
        {openCalendar ? this.renderCalendar() : ''}
        {alertMessage && <div className="alert-box warning">{alertMessage}</div>}
      </div>
    );
  }
}

export default MoveInCalendar;
