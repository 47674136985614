// @ts-nocheck
/* eslint-enable */
import queryUtils from 'app/shared/utils/queryUtils';

const controller = {
  getShareUrl() {
    // use canonical url if available. no need to add filters, map params, etc
    const canonical =
      (window.querySelector && window.querySelector('link[rel=canonical]')) ||
      (document.querySelector && document.querySelector('link[rel=canonical]'));
    let url = canonical.href || window.location.href;
    url = queryUtils.addOrUpdateUrlParam({ url, name: 'utm_medium', value: 'referral' });
    url = queryUtils.addOrUpdateUrlParam({ url, name: 'utm_source', value: 'tellafriend' });
    url = queryUtils.addOrUpdateUrlParam({ url, name: 'utm_campaign', value: 'tellafriend' });
    return url;
  },
  getShareTexts({ address, displayName, mobileOS }) {
    const oneLineShareText = controller.getShareTextOneLine();
    const smsShareHref = controller.getShareHref({ method: 'sms', mobileOS });
    const emailShareHref = controller.getShareHref({ method: 'email', mobileOS, address, displayName });

    return { oneLineShareText, smsShareHref, emailShareHref };
  },
  getShareText({ address, displayName }) {
    return `Check out this listing I found on HotPads! \n
        ${displayName} located at ${address.city}, ${address.state} ${address.zip}. \n
        ${controller.getShareUrl()}`;
  },
  getShareTextOneLine() {
    return `Check out this listing I found on HotPads! ${controller.getShareUrl()}`;
  },
  getShareHref({ method, mobileOS, address, displayName }) {
    let result = method === 'sms' ? 'sms:' : 'mailto:';
    result += mobileOS === 'iOS' ? ';' : '?';
    result += 'body=';
    result += method === 'sms' ? controller.getShareTextOneLine() : controller.getShareText({ address, displayName });

    return result;
  },
};

export default controller;
