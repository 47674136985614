// @ts-nocheck
/* eslint-enable */
// App
import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { yieldCallback } from '@zillow/yield-callback';

// Components
import Text from 'app/shared/core/Text';
import TabItem from 'app/shared/modules/TabItem';

// Misc / Utils
import './style.scss';
import ValueConstants from 'app/shared/constants/ValueConstants';
/**
 * TabItem is the child that gets passed into TabGroup. Casting types and
 * setting defaultProps for a child must be done outside of the parent class.
 *
 * Source: https://stackoverflow.com/questions/50046115/react-set-default-props-on-this-props-children
 */
TabItem.propTypes = {
  onClick: PropTypes.func,
};

TabItem.defaultProps = {
  onClick: () => {},
};

class TabGroup extends React.Component {
  static propTypes = {
    customClass: PropTypes.string,
    evenSpiltWidth: PropTypes.bool,
    maxWidth: PropTypes.string,
    loadAllContent: PropTypes.bool,
  };

  static defaultProps = {
    customClass: null,
    evenSpiltWidth: true,
    maxWidth: null,
    loadAllContent: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      index: this.props.defaultIndex || 0,
    };

    this.tablistRef = React.createRef();
  }

  calculateWidth = (numOfTabs) => {
    if (numOfTabs === 1) {
      return '50%';
    } else {
      return 100 / numOfTabs + '%';
    }
  };

  handleKeyDown = (e) => {
    if (
      e.keyCode === ValueConstants.DOWN_ARROW_KEYCODE ||
      e.keyCode === ValueConstants.UP_ARROW_KEYCODE ||
      e.keyCode === ValueConstants.SPACE_KEYCODE
    ) {
      e.preventDefault();
    }
  };

  handleKeyUp = (e) => {
    let direction;
    const tabs = this.tablistRef.current.querySelectorAll('[role="tab"]');
    const currIdx =
      e.target.attributes.getNamedItem('data-index') && Number(e.target.attributes.getNamedItem('data-index').value);

    if (e.keyCode === ValueConstants.LEFT_ARROW_KEYCODE || e.keyCode === ValueConstants.UP_ARROW_KEYCODE) {
      direction = -1;
    } else if (e.keyCode === ValueConstants.RIGHT_ARROW_KEYCODE || e.keyCode === ValueConstants.DOWN_ARROW_KEYCODE) {
      direction = 1;
    } else if (e.keyCode === ValueConstants.ENTER_KEYCODE || e.keyCode === ValueConstants.SPACE_KEYCODE) {
      this.setState({ index: currIdx });
      if (this.props.children[currIdx] && this.props.children[currIdx].props.onClick) {
        this.props.children[currIdx].props.onClick();
      }
      return;
    } else {
      return;
    }

    if (tabs.length && typeof currIdx !== 'undefined') {
      if (direction < 0) {
        tabs[Number(currIdx) + direction >= 0 ? Number(currIdx) + direction : tabs.length - 1].focus();
      } else {
        tabs[Number(currIdx) + direction < tabs.length ? Number(currIdx) + direction : 0].focus();
      }
    }
  };

  render() {
    const { appearance, children, customClass, className, evenSpiltWidth, maxWidth, loadAllContent } = this.props;
    const { index } = this.state;
    const isButtonAppearance = appearance === 'button';

    const styles = { marginLeft: 'auto', marginRight: 'auto' };
    if (maxWidth) {
      styles.maxWidth = maxWidth;
    }

    return (
      <div className="TabGroup">
        <div
          className={cx('TabGroup-tabs', customClass, className)}
          role="tablist"
          ref={this.tablistRef}
          style={styles}
        >
          {React.Children.map(this.props.children, (child, i) => {
            const tabTitle = child.props.tabTitle || `Tab ${i}`;
            const childOnClick = child.props.onClick;
            const isActive = index === i;

            return (
              <Text
                aria-selected={isActive}
                className={cx(child.props.className, {
                  'TabGroup-tab': !isButtonAppearance,
                  'TabGroup-tab-active': isActive && !isButtonAppearance,
                  'TabGroup-tab-button': isButtonAppearance,
                  'TabGroup-tab-button-active': isActive && isButtonAppearance,
                })}
                id={`${tabTitle}-tab`}
                key={i}
                data-index={i}
                /* eslint-disable react/jsx-no-bind */
                onClick={() => {
                  const yieldSetState = yieldCallback(() => {
                    this.setState({ index: i });
                    childOnClick();
                  });
                  yieldSetState();
                }}
                onKeyDown={this.handleKeyDown}
                onKeyUp={this.handleKeyUp}
                role="tab"
                size="sm"
                style={evenSpiltWidth ? { width: this.calculateWidth(this.props.children.length) } : null}
                tabIndex={isActive ? '0' : '-1'}
              >
                {tabTitle}
              </Text>
            );
          })}
        </div>
        {loadAllContent ? (
          children.map((child, i) => (
            <div
              key={child.props.tabTitle + 'key'}
              className={cx('TabGroup-content', i !== index && 'TabGroup-content-hidden')}
              role="tabpanel"
              aria-labelledby={child.props.tabTitle + '-tab'}
            >
              {child}
            </div>
          ))
        ) : (
          <div className="TabGroup-content" role="tabpanel" aria-labelledby={children[index].props.tabTitle + '-tab'}>
            {children[index]}
          </div>
        )}
      </div>
    );
  }
}

export default TabGroup;
