// @ts-nocheck
/* eslint-enable */
/* eslint-disable no-nested-ternary */
import assign from 'lodash/assign';
import each from 'lodash/each';
import forIn from 'lodash/forIn';
import isEmpty from 'lodash/isEmpty';
import isNaN from 'lodash/isNaN';
import map from 'lodash/map';
import { listingUtils_getListingMetaDescription } from 'app/shared/utils/listingUtils';
import schemaUtils from 'app/shared/utils/schemaUtils';
import { isNull } from 'util';

// Misc / Utils
import { getFaqSchemaTemplate } from 'app/shared/modules/FaqSection/controller';

const schemaHelpers = {
  _getBreadcrumbs(breadcrumbs, listing) {
    if (breadcrumbs.length === 0) {
      return null;
    }

    const { uriV2, displayName } = listing;
    let position = 0;
    const mainBreadcrumbElements = map(breadcrumbs, (crumb) => {
      const { uri, name } = crumb;
      return {
        '@type': 'ListItem',
        position: ++position,
        item: {
          '@type': 'Thing',
          '@id': uri,
          name,
        },
      };
    });

    const currentListingBreadcrumbElement = {
      '@type': 'ListItem',
      position: ++position,
      item: {
        '@type': 'Thing',
        '@id': uriV2,
        name: displayName,
      },
    };

    mainBreadcrumbElements.push(currentListingBreadcrumbElement);

    return {
      '@type': 'BreadcrumbList',
      itemListElement: mainBreadcrumbElements,
    };
  },
  _getBusinessHours(businessHours) {
    /**
     * Currently, API returns a long string seperated by newline chars. Ideally,
     * we would like to avoid the FE from parsing hours, but this will have to do for now.
     *
     * @deprecate once API returns parsed business hours.
     *
     * Supports HPWEB-3590
     */

    const splitBusinessHours = businessHours.split(/\n/);

    if (splitBusinessHours.length < 2) {
      return null;
    }

    const businessHoursMap = {};
    const businessHoursSchema = [];
    const removeAmPmSuffix = (timeString) => {
      const cleanTimeString = timeString.replace(/am|pm|a.m.|p.m.|a|p/i, '');
      const [hour, minutes = '00'] = cleanTimeString.split(':');

      // For PM times, remove the "PM" and add 12 hours to convert it
      // to 24-hour time.
      if (/pm|p.m.|p/i.test(timeString)) {
        return (parseInt(hour) + 12).toString().concat(':').concat(minutes);
      }

      return parseInt(hour).toString().concat(':').concat(minutes);
    };

    each(splitBusinessHours, (currentHour) => {
      const splitTime = currentHour.split(': ');

      if (splitTime.length < 2) {
        return null;
      }
      const [day, timeSlot] = splitTime;
      const splitTimeSlot = timeSlot.split(/:/gi);

      // Check if timeSlot is actually a time
      if (splitTimeSlot.length < 2 && isNaN(parseInt(splitTimeSlot[0]))) {
        return null;
      }

      if (!businessHoursMap[timeSlot]) {
        businessHoursMap[timeSlot] = [day];
      } else {
        businessHoursMap[timeSlot].push(day);
      }
    });

    forIn(businessHoursMap, (daysOfWeek, timeSlot) => {
      const timeSlotNoWhitespace = timeSlot.replace(/\s/gi, '');
      const [opens, closes] = timeSlotNoWhitespace.split(/to|-/i);

      // Check if open and closing times are actually numbers
      if (isNaN(parseInt(opens)) || isNaN(parseInt(closes))) {
        return null;
      }

      const sanitizedOpeningTime = removeAmPmSuffix(opens);
      const sanitizedClosingTime = removeAmPmSuffix(closes);

      const schemaEntry = {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: daysOfWeek,
        opens: sanitizedOpeningTime,
        closes: sanitizedClosingTime,
      };
      businessHoursSchema.push(schemaEntry);
    });

    return !isEmpty(businessHoursSchema) ? businessHoursSchema : null;
  },
  _getFaqSchemaMainEntity(listing) {
    const listingFaqData = getFaqSchemaTemplate({ data: { listing } });
    const listingFaqDataArray = listingFaqData && listingFaqData.data;

    if (isNull(listingFaqDataArray)) {
      return null;
    }

    return map(listingFaqDataArray, (questionAnswerCluster) => {
      const question = questionAnswerCluster.question;
      const answer = questionAnswerCluster.answer;
      const answerType = questionAnswerCluster.answerType;

      return {
        '@type': 'Question',
        name: question,
        acceptedAnswer: schemaUtils.getFormattedFaqAnswer(answer, answerType),
      };
    });
  },
  _getMainEntity(listing, propertyType = '') {
    const { uriV2, details, displayName, listedBy, previewPhoto } = listing;
    const { street, city, state, zip } = listing.address;
    const { lat, lon } = listing.geo;
    const { minPrice, maxPrice } = listing.listingMinMaxPriceBeds;
    const isHouse = propertyType && propertyType === 'house';
    const houseOrApartment = isHouse ? 'SingleFamilyResidence' : 'ApartmentComplex';
    const address = {
      '@type': 'PostalAddress',
      streetAddress: street,
      addressLocality: city,
      addressRegion: state,
      postalCode: zip,
      addressCountry: {
        '@type': 'Country',
        name: 'US',
      },
    };

    const geo = {
      '@type': 'GeoCoordinates',
      latitude: lat,
      longitude: lon,
    };

    const containedInPlace = {
      '@type': 'LocalBusiness',
      '@id': uriV2,
      name: displayName,
      priceRange: `$${minPrice}-$${maxPrice}`,
      image: previewPhoto.url,
      telephone: !listing.isBuilding && listedBy ? listedBy.contactPhone : null,
      address,
    };

    const openingHoursSpecification =
      listing.details && listing.details.contactTimes
        ? schemaHelpers._getBusinessHours(listing.details.contactTimes)
        : null;

    const amenityFeature =
      listing.amenities && listing.amenities.amenities
        ? map(listing.amenities.amenities, (amenity) => {
            return {
              '@type': 'LocationFeatureSpecification',
              name: amenity,
            };
          })
        : null;

    const foo = {
      '@type': houseOrApartment,
      '@id': uriV2,
      name: displayName,
      url: uriV2,
      telephone: !listing.isBuilding && listedBy ? listedBy.contactPhone : null,
      description: details.fullDescription,
      address,
      geo,
      image: previewPhoto.url,
      openingHoursSpecification,
      amenityFeature,
      containedInPlace,
    };
    return foo;
  },
  _getProduct(listing) {
    const { minPrice, maxPrice } = listing.listingMinMaxPriceBeds;
    const metaDescription = listingUtils_getListingMetaDescription(listing);
    const availability = schemaUtils.getAvailability(listing);
    const { details, displayName, previewPhoto, ratingsAndReviews } = listing;

    let product = {
      '@type': 'Product',
      name: displayName,
      description: details && details.fullDescription,
      disambiguatingDescription: metaDescription,
      image: previewPhoto && previewPhoto.url,
    };

    if (listing.active) {
      product.offers = {
        priceCurrency: 'USD',
        '@type': 'AggregateOffer',
        lowPrice: minPrice,
        highPrice: maxPrice,
      };
    }

    if (availability && product.offers) {
      product.offers.availability = availability;
    }

    if (
      ratingsAndReviews &&
      ratingsAndReviews.reviewsSummary.totalNumberOfReviews > 0 &&
      ratingsAndReviews.reviews.length > 0
    ) {
      const { reviewsSummary, reviews } = ratingsAndReviews;
      const formattedReviews = map(reviews, (review) => {
        return schemaUtils.getReviewSnippet(review);
      });

      const ratingMarkup = {
        aggregateRating: {
          '@type': 'AggregateRating',
          worstRating: 1,
          bestRating: 5,
          ratingValue: reviewsSummary.averageStarLevel,
          ratingCount: reviews.length,
        },
        review: formattedReviews,
      };

      product = assign(product, ratingMarkup);
    }

    return product;
  },
  _getSpeakable() {
    return {
      '@type': 'SpeakableSpecification',
      cssSelector: ['#HdpDescriptionContent'],
    };
  },
};

export const getItemPage = (listing, breadcrumbs, isTrusted) => {
  const about = schemaHelpers._getProduct(listing);
  const speakable = schemaHelpers._getSpeakable();
  const breadcrumb = schemaHelpers._getBreadcrumbs(breadcrumbs, listing);
  const mainEntity = schemaHelpers._getMainEntity(listing, listing.propertyType);

  return {
    '@type': 'ItemPage',
    about: isTrusted ? null : about, // Hide about schema for MF buildings
    speakable,
    breadcrumb,
    mainEntity,
  };
};

export const getListingFaqSchema = (listing) => {
  const mainEntity = schemaHelpers._getFaqSchemaMainEntity(listing);

  if (isNull(mainEntity)) {
    return null;
  }

  return {
    '@context': 'http://schema.org',
    '@type': 'FAQPage',
    mainEntity,
  };
};

export default schemaHelpers;
