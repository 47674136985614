// @ts-nocheck
/* eslint-enable */
const commuteUtils = {
  buildCommuteTimesRequest(filter = {}, listings = [], userPoint = {}) {
    const locationParams = {
      originLat: filter.commuteLats || userPoint.lat,
      originLon: filter.commuteLons || userPoint.lon,
      commuteTimeMode: filter.commuteTimeMode?.toLowerCase() || 'now',
      commuteType: filter.commuteMode?.toUpperCase() || 'DRIVING',
    };

    listings.forEach((listing, i) => {
      const { geo } = listing;
      const { lat, lon } = geo;

      locationParams[`lat${i}`] = lat;
      locationParams[`lon${i}`] = lon;
    });

    return locationParams;
  },

  isCommuteFilterSet(filter = {}) {
    const { commuteTimeMode, commuteMode, commuteLats, commuteLons } = filter;

    return commuteTimeMode && commuteMode && commuteLats && commuteLons;
  },

  // In earlier versions of the commute filter module, we stored
  // values for preferred departure time of transit inside a localStorage variable.
  // This translates those values into something our API uses
  // and overwrites / deletes incorrect values.
  mapLocalStorageTime(value = '') {
    if (!value) {
      return 'now';
    }

    let localValue;

    if (typeof localStorage !== 'undefined') {
      localValue = localStorage.getItem('commuteDepartureTime');
    }

    const string = value.toLowerCase();
    let newValue;

    switch (string) {
      case 'rush hour':
      case 'rushhour':
        newValue = 'rushHour';
        break;
      case 'now':
        newValue = 'now';
        break;
      case 'off peak':
      case 'offpeak':
        newValue = 'offPeak';
        break;
      default:
        newValue = 'now';
    }

    if (localValue && localValue !== newValue) {
      localStorage.setItem('commuteDepartureTime', newValue);
    }

    return newValue;
  },

  prettyPrintDepartureTime(option = '') {
    if (!option) {
      throw new Error(`Missing option for getDepartureTime`);
    }

    if (option.toLowerCase() === 'now') {
      return 'Now';
    } else if (option.toLowerCase() === 'rushhour') {
      return 'Rush hour';
    } else if (option.toLowerCase() === 'offpeak') {
      return 'Off-peak';
    } else {
      throw new Error(`Invalid option provided: ${option}`);
    }
  },
};

export default commuteUtils;
