// @ts-nocheck
/* eslint-enable */
import constants from 'app/shared/constants/ConstantsBundle';
import dotIcon from 'images/hp-icons/map-dot.png';
import usertiming from 'app/shared/utils/performanceUtils';

const attachedListeners = {};
const visibleMarkers = {};
let hasMarked = false;

const dotManager = {
  createIcon({ isMobile, zoom }) {
    return {
      anchor: { x: 4, y: 2 },
      url: dotIcon,
      scaledSize: dotManager.getIconSize({ isMobile, zoom }),
    };
  },

  getIconSize({ isMobile, zoom }) {
    if (isMobile) {
      if (zoom >= 15) {
        return { width: 10, height: 10 };
      } else if (zoom >= 14) {
        return { width: 6, height: 6 };
      } else {
        return { width: 3, height: 3 };
      }
    } else {
      if (zoom > 14) {
        return { width: 12, height: 12 };
      } else if (zoom === 14) {
        return { width: 10, height: 10 };
      } else if (zoom >= 12) {
        return { width: 6, height: 6 };
      } else {
        return { width: 3, height: 3 };
      }
    }
  },

  createMarker: ({ dot, isMobile, zoom }) => {
    const { lat, lon: lng } = dot;

    let clickable = false;
    if (isMobile && zoom >= 15) {
      clickable = true;
    } else if (!isMobile && zoom >= 14) {
      clickable = true;
    }

    const marker = new window.google.maps.Marker({
      position: { lat, lng },
      anchorPoint: { x: 0, y: -30 },
      clickable,
      map: window.map,
      icon: dotManager.createIcon({ isMobile, zoom }),
      zIndex: 1,
    });

    // Store current zoom level on marker object so we know if we
    // need to update the icon when map zoom changes.
    marker.mapZoom = zoom;

    return marker;
  },

  hideNonVisibleDots(dotMapArray) {
    const updatedVisibleMarkers = {};

    for (let i = 0, numDots = dotMapArray.length; i < numDots; i++) {
      const dot = dotMapArray[i] || {};
      updatedVisibleMarkers[dot.lotId] = true;
    }

    // eslint-disable-next-line no-unused-vars
    for (const dotId in visibleMarkers) {
      if (!updatedVisibleMarkers[dotId]) {
        window.google.maps.event.clearListeners(visibleMarkers[dotId], 'click');

        visibleMarkers[dotId].setMap(null);
      }
    }
  },

  processAndSliceMarkers({ dotMapArray, handleDotClick, isMobile, startIndex = 0 } = {}) {
    if (startIndex > dotMapArray.length) {
      dotManager.hideNonVisibleDots(dotMapArray);
      return;
    }

    const slicedDotMapArray = dotMapArray.slice(startIndex, startIndex + constants.DOT_MAP_DISPLAY_LIMIT);
    dotManager.update({
      dotArray: slicedDotMapArray,
      handleDotClick,
      isMobile,
      callback: () =>
        dotManager.processAndSliceMarkers({
          dotMapArray,
          handleDotClick,
          isMobile,
          startIndex: startIndex + constants.DOT_MAP_DISPLAY_LIMIT,
        }),
    });
  },

  update: ({ dotArray = [], handleDotClick, isMobile, callback }) => {
    if (!window.map) {
      return false;
    }
    const zoom = window.map && window.map.getZoom();

    // Store keys for quick lookup of visible markers
    // and compare to visibleMarkers object.
    const updateVisibleMarkers = {};

    if (dotArray.length && !hasMarked) {
      hasMarked = true;
      usertiming.mark('GoogleMap beginDrawingDots');
    }

    for (let i = 0, total = dotArray.length; i < total; i++) {
      const dot = dotArray[i];
      const { lotId: dotId } = dot;

      if (visibleMarkers[dotId]) {
        if (zoom !== visibleMarkers[dotId].mapZoom) {
          window.google.maps.event.clearListeners(visibleMarkers[dotId], 'click');
          visibleMarkers[dotId].setMap(null);
          delete attachedListeners[dotId];

          visibleMarkers[dotId] = dotManager.createMarker({ dot, isMobile, zoom });
        } else {
          if (visibleMarkers[dotId].getMap() === null) {
            visibleMarkers[dotId].setMap(window.map);
          }
        }
      } else {
        visibleMarkers[dotId] = dotManager.createMarker({ dot, isMobile, zoom });
      }

      if (zoom >= 14) {
        // api resposnes for dot array can return multiple objects
        // with same dotId. this happens when multiple units
        // associated with same malonelotId. Check object for
        // attached listeners associated with this dotId
        // prevents attaching multiple listeners.
        if (!attachedListeners[dotId]) {
          attachedListeners[dotId] = true;
          window.google.maps.event.addListener(visibleMarkers[dotId], 'click', () => {
            handleDotClick(dotId);
          });
        }
      }

      updateVisibleMarkers[dotId] = true;
    }

    // Setting timeout to 0 will wait for next tick to carry out action.
    // This gives the Google Maps library a chance to process and draw updated icons
    // and keeps the map slightly more performant, allowing the user to pan.
    // Otherwise, immediately executing `callback()` here will cause the map to freeze
    // until all icons are processed.
    setTimeout(() => {
      callback();
    }, 0);
  },
};

export default dotManager;
