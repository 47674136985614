// @ts-nocheck
/* eslint-enable */
// App
import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Actions
import FetchListingActions from 'app/shared/flux/actions/FetchListingActions';
import ListingEngineActions from 'app/shared/flux/actions/ListingEngineActions';
import RouteActions from 'app/shared/flux/actions/RouteActions';
import UserItemActions from 'app/shared/flux/actions/UserItemActions';
import UserSearchActions from 'app/shared/flux/actions/UserSearchActions';

// Components
import controller from 'app/shared/modules/map/MarkersContainer/controller';
import DotMapCache from 'app/shared/cache/dotMapCache';
import dotManager from 'app/shared/modules/map/MarkersContainer/dotManager';
import ListingMarkers from './ListingMarkers';
import MapMarker from 'app/shared/modules/map/MapMarker';
import { gaEvents } from 'app/shared/constants/AnalyticsConstants';
import { analyticsEvent } from 'app/client/universal-analytics';

// Lodash
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isUndefined from 'lodash/isUndefined';

// Misc / Utils
import gmapUtils from 'app/client/utils/map/gmapUtils';
import perfUtils from 'app/shared/utils/perfUtils';
import queryUtils from 'app/shared/utils/queryUtils';
import { iconForListing } from 'app/client/utils/map/gmapIcons';
import userPointMarker from 'images/user-point-marker.png';
import { yieldCallback } from '@zillow/yield-callback';

class MarkersContainer extends React.Component {
  static propTypes = {
    activeMarkerMaloneLotId: PropTypes.string,
    currentListing: PropTypes.object,
    isMobile: PropTypes.bool,
    listings: PropTypes.array,
    map: PropTypes.object,
  };

  static defaultProps = {
    activeMarkerMaloneLotId: null,
    currentListing: null,
    isMobile: false,
    listings: null,
    map: {},
  };

  constructor(props) {
    super(props);

    this.state = {
      dotMapArray: null,
    };
  }

  componentDidMount() {
    const { gmapLoaded } = this.props;

    if (gmapLoaded) {
      this.fetchDotMap();
    }
  }

  shouldComponentUpdate(nextProps) {
    const { activeMarkerMaloneLotId, gmapLoaded, listings, currentListing, query } = this.props;
    const { listings: nextListings } = nextProps;
    const currentListingCount = listings && listings.length;
    const firstListingId = listings[0] && listings[0].aliasEncoded;
    const lastListingId = listings[listings.length - 1] && listings[listings.length - 1].aliasEncoded;
    const nextListingCount = nextListings && nextListings.length;
    const nextFirstListingId = nextListings[0] && nextListings[0].aliasEncoded;
    const nextLastListingId =
      nextListings[nextListings.length - 1] && nextListings[nextListings.length - 1].aliasEncoded;

    if (this.props.userPoint && nextProps.userPoint && this.props.userPoint.name !== nextProps.userPoint.name) {
      return true;
    }

    if (!currentListing && nextProps.currentListing) {
      return true;
    }

    if (
      gmapLoaded &&
      isEqual(nextProps.query, query) &&
      currentListingCount === nextListingCount &&
      firstListingId === nextFirstListingId &&
      lastListingId === nextLastListingId &&
      activeMarkerMaloneLotId === nextProps.activeMarkerMaloneLotId
    ) {
      return false;
    }

    return true;
  }

  componentDidUpdate(prevProps) {
    const { border, gmapLoaded, isClientSideLoadedPage, listings, query } = this.props;
    const { listings: prevListings } = prevProps;
    const firstListingId = listings[0] && listings[0].aliasEncoded;
    const lastListingId = listings[listings.length - 1] && listings[listings.length - 1].aliasEncoded;
    const prevFirstListingId = prevListings[0] && prevListings[0].aliasEncoded;
    const prevLastListingId =
      prevListings[prevListings.length - 1] && prevListings[prevListings.length - 1].aliasEncoded;
    const latChanged = query.lat !== prevProps.query.lat;
    const lonChanged = query.lon !== prevProps.query.lon;

    const isSsrAndGmapsLoaded = !isClientSideLoadedPage && !prevProps.gmapLoaded && gmapLoaded;
    const hasAreaChanged =
      isClientSideLoadedPage && firstListingId !== prevFirstListingId && lastListingId !== prevLastListingId;
    const hasLatLonChanged =
      isClientSideLoadedPage && (border === true || isUndefined(border)) && (latChanged || lonChanged);

    // get dotMap on SSR once map loads
    if (isSsrAndGmapsLoaded) {
      this.fetchDotMap();
    }

    // get dotMap when area's have changed
    if (hasAreaChanged) {
      this.fetchDotMap();
    }

    // get dotMap on CSR if border exists and lat/lon has changed
    if (hasLatLonChanged) {
      this.fetchDotMap();
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;

    dispatch(ListingEngineActions.resetDotMap());
  }

  fetchDotMap() {
    const { dispatch, currentQuery, gmapLoaded } = this.props;
    const mapData = gmapLoaded ? gmapUtils.getMapData(window.map) : {};
    const { lat = '', lon = '', zoom = '' } = mapData;
    const currQuery = currentQuery;
    const stringifiedQuery = queryUtils.stringify(currQuery) || queryUtils.stringify({ lat, lon, z: zoom });
    const isCached = this.cacheStatus(stringifiedQuery);

    if (isCached) {
      const dotMapArray = DotMapCache.get(stringifiedQuery);
      this.setState({ dotMapArray }, () => this.renderDotMap());
    } else {
      dispatch(ListingEngineActions.fetchDotMap(stringifiedQuery)).then(() => {
        const dotMapArray = DotMapCache.get(stringifiedQuery);
        this.setState({ dotMapArray }, () => this.renderDotMap());
      });
    }
  }

  filterBonusListings() {
    const { gmapLoaded, listings } = this.props;
    const mapData = gmapLoaded ? gmapUtils.getMapData(window.map) : {};
    const { zoom = '' } = mapData;

    /**
     * When map is zoomed out, we only want to show normal listings on the map.
     * So, we explicitly filter out any tier 3 bonus listings or tier 2 claimed free listings
     * here. However, they will still be visible within the listings wrapper sidebar.
     * We filter listings here in the MarkersContainer since both the map and
     * listings side bar utilize the same listings store from Redux.
     *
     * Otherwise, show normal and tier 3 listings on the map if
     * zoom >= 18. Show normal, tier 3, tier 2 listings on map if
     * zoom >=19.
     *
     * 19 matches max zoom level on Zillow.com
     */

    /**
     * If zoom >= 19, show EVERYTHING:
     * - Normal listings
     * - Tier 3 Bonus listings
     * - Tier 2 Claimed free listings
     */
    if (zoom >= 19) {
      return listings;
    }

    /**
     * If zoom >= 18, show:
     * - Normal listings
     * - Tier 3 Bonus listings
     */
    if (zoom >= 18) {
      const isNormalOrBonusListingsArray = listings.filter((listing) => {
        const isBonusListing = listing.isBonusListing === true;
        const isNormalListing = listing.isBonusListing === false && listing.isClaimedFreeListing === false;
        return isBonusListing || isNormalListing;
      });
      return isNormalOrBonusListingsArray;
    }

    /**
     * Else, show:
     * - Normal listings
     */
    return listings.filter((listing) => {
      const isNormalListing = listing.isBonusListing === false && listing.isClaimedFreeListing === false;
      return isNormalListing;
    });
  }

  cacheStatus(query) {
    return Boolean(DotMapCache.get(query));
  }

  handleListingMarkerMouseOver = (listing, marker) => {
    const icon = iconForListing(listing, listing.maloneLotIdEncoded);
    marker.setIcon(icon);
  };

  handleListingMarkerMouseOut = (listing, marker) => {
    const { activeMarkerMaloneLotId } = this.props;
    if (listing.maloneLotIdEncoded === activeMarkerMaloneLotId) {
      return;
    }
    const icon = iconForListing(listing, false);
    marker.setIcon(icon);
  };

  handleDotClick = (maloneLotIdEncoded) => {
    const { dispatch, isMobile } = this.props;

    dispatch(
      FetchListingActions.fetchListingByMaloneLotIdNoRedirect({
        maloneLotIdEncoded,
        isBuilding: true,
      }),
    ).then((listing) => {
      if (!listing) {
        console.warn(`ERROR: No listing for MaloneLotId: ${maloneLotIdEncoded}`);
        return;
      }

      dispatch(UserItemActions.addUserItem('viewed', listing));
      dispatch(ListingEngineActions.setActiveMarkerMaloneLotId(listing.maloneLotIdEncoded));

      if (isMobile) {
        dispatch(ListingEngineActions.setPreviewListing(listing));
      } else {
        dispatch(RouteActions.transitionToListing({ listingUri: listing.uriV2, keepMapLocation: true }));
      }
    });
  };

  // TODO Move to a FetchListingActions (since we're selecting a listing)
  handleMarkerClick = yieldCallback((listing) => {
    const { dispatch, isMobile, currentListing } = this.props;

    // don't need to transition to a listing we are already on
    const alreadyOnListing = !isEmpty(currentListing) && currentListing.aliasEncoded === listing.aliasEncoded;

    dispatch(ListingEngineActions.setPreviewListing(listing));
    if (isMobile) {
      dispatch(
        analyticsEvent(gaEvents.MOBILE_MAP_ICON_CLICK, {
          label: listing.aliasEncoded,
        }),
      );

      if (listing.building) {
        dispatch(
          FetchListingActions.fetchBuilding({
            maloneLotIdEncoded: listing.maloneLotIdEncoded,
            isOnlyCache: true,
          }),
        );
      } else {
        dispatch(
          FetchListingActions.fetchListingByMaloneLotId({
            listingTypes: listing.listingTypes,
            maloneLotIdEncoded: listing.maloneLotIdEncoded,
            urlMaloneUnit: listing.urlMaloneUnit,
            isOnlyCache: true,
          }),
        );
      }
    } else if (!isMobile && !alreadyOnListing) {
      perfUtils.setMarker('MapMarkerClick');
      dispatch(RouteActions.transitionToListing({ listingUri: listing.uriV2, keepMapLocation: true }));
    }

    dispatch(ListingEngineActions.setActiveMarkerMaloneLotId(listing.maloneLotIdEncoded));
    dispatch(UserSearchActions.listingOnCurrentSearchClicked());
  });

  renderDotMap = () => {
    const { isMobile } = this.props;
    const { dotMapArray } = this.state;

    if (dotMapArray) {
      dotManager.processAndSliceMarkers({
        dotMapArray,
        handleDotClick: this.handleDotClick,
        isMobile,
      });
    }
  };

  render() {
    const { activeMarkerMaloneLotId, currentListing, isMobile, map, previewListing, userPoint } = this.props;
    const activeMarker = currentListing || previewListing;

    let userIconDetails;
    let userPointInfoWindow = null;

    if (userPoint) {
      userPointInfoWindow = `<strong>${userPoint.name}</strong>`;

      userIconDetails = {
        size: { width: 32, height: 32 },
        scaledSize: { width: 32, height: 32 },
        anchor: { x: 31 / 2, y: 25 / 2 },
        url: userPointMarker,
      };
    }

    const filteredListings = this.filterBonusListings();

    return (
      <Fragment>
        <ListingMarkers
          activeMarkerMaloneLotId={activeMarkerMaloneLotId}
          currentListing={currentListing}
          onListingMarkerMouseOut={this.handleListingMarkerMouseOut}
          onListingMarkerMouseOver={this.handleListingMarkerMouseOver}
          onMarkerClick={this.handleMarkerClick}
          map={map}
          isMobile={isMobile}
          listings={filteredListings}
        />

        {activeMarker && (
          <MapMarker
            active
            dataObj={activeMarker}
            icon={iconForListing(activeMarker, activeMarker.maloneLotIdEncoded === activeMarkerMaloneLotId)}
            infoWindowContent={!isMobile ? controller.listingInfoWindowContent(activeMarker) : null}
            lat={activeMarker.geo.lat}
            lon={activeMarker.geo.lon}
            map={map}
            zIndex={999}
            showInfoWindow
          />
        )}

        {userPoint && (
          <MapMarker
            icon={userIconDetails}
            infoWindowContent={!isMobile ? userPointInfoWindow : null}
            lat={userPoint.lat}
            lon={userPoint.lon}
            map={map}
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const activeUserPoint = state.user.userPoints.activeUserPoint;
  const userPoints = state.user.userPoints.destinations;

  return {
    activeMarkerMaloneLotId: state.currentListingDetails.activeMarkerMaloneLotId,
    border: queryUtils.parse(ownProps.location.search).border,
    currentListing: state.currentListingDetails.currentListing,
    currentQuery: state.location.current.query,
    gmapLoaded: state.app.gmapLoaded,
    isClientSideLoadedPage: state.app.isClientSideLoadedPage,
    isMobile: state.app.device.screenWidth === 'sm',
    listings: state.listings.listingGroups.byCoords,
    previewListing: state.listings.listingGroups.previewListing,
    query: queryUtils.parse(ownProps.location.search),
    userPoint: userPoints[activeUserPoint],
  };
};

export default withRouter(connect(mapStateToProps)(MarkersContainer));
