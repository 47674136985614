// @ts-nocheck
/* eslint-enable */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import includes from 'lodash/includes';

import './style.scss';
import { analyticsEvent } from 'app/client/universal-analytics';
import { gaEvents } from 'app/shared/constants/AnalyticsConstants';
import ArrowLeft from 'images/icons/arrow-left-dark-grey.svg';
import Button from 'app/shared/core/Button';
import IconArrowRight from 'images/icons/arrow-right-dark-grey.svg';
import ImageLoader from 'app/shared/modules/photo-gallery/ImageLoader';
import PhotoCarousel from 'app/shared/modules/PhotoCarousel';
import PhotoGalleryHeader from 'app/shared/modules/photo-gallery/PhotoGalleryWrapper/Header';

const { func, array, number, object } = PropTypes;

// This component displays listing photos (and floorplans) in a scrollable carousel for MWEB ONLY

class PhotoGalleryCarousel extends React.Component {
  static propTypes = {
    currentPhotoIndex: number,
    listing: object.isRequired,
    onClickContact: func,
    onClickOpenFdp: func,
    onClose: func,
    onGalleryBackClick: func,
    photos: array.isRequired,
  };

  static defaultProps = {
    onClickContact: () => {},
    onClickOpenFdp: () => {},
    onClose: () => {},
    onGalleryBackClick: () => {},
  };

  constructor(props) {
    super(props);
    const { currentPhotoIndex } = this.props;

    this.state = {
      currentPhotoIndex,
    };
  }

  handleBackClick = () => {
    if (this.state.currentPhotoIndex) {
      this.setState({ currentPhotoIndex: null });
    } else {
      this.props.onClose();
    }
  };

  handleOpenFdpClick = () => {
    const { listing, onClickOpenFdp, photos } = this.props;
    const { currentPhotoIndex } = this.state;
    const currentPhoto = currentPhotoIndex < photos.length ? photos[currentPhotoIndex] : false;
    const isFloorplanPhoto = currentPhotoIndex >= listing.photoCount;
    const floorplanModelId = isFloorplanPhoto && currentPhoto ? currentPhoto.modelId : '';

    if (!floorplanModelId) {
      return;
    }

    onClickOpenFdp({
      fdpName: 'FloorPlans', // PascalCase because it is used for analytics
      source: 'PhotoGallery',
      params: { modelId: floorplanModelId },
    });
  };

  handlePhotoCarouselChange = (i) => {
    this.setState({ currentPhotoIndex: i });
    this.props.dispatch(analyticsEvent(gaEvents.HDP_ADDITIONAL_PHOTO_VIEW));
  };

  render() {
    const { listing, onClickContact, onGalleryBackClick, isMobile, screenWidth, photos } = this.props;
    const { currentPhotoIndex } = this.state;

    const currentPhoto = currentPhotoIndex < photos.length ? photos[currentPhotoIndex] : false;
    const caption = currentPhoto ? currentPhoto.caption : '';
    const isMediumOrSmallerScreenSize = !includes(['lg', 'xl'], screenWidth);
    const showContactUpsell = listing.active;
    const isFloorplanPhoto = currentPhotoIndex >= listing.photoCount;

    return (
      <>
        <PhotoGalleryHeader className="PhotoGalleryCarousel-header" showBottomBorder>
          <div className="PhotoGalleryCarousel-header-left">
            <button className="PhotoGalleryCarousel-back-btn" onClick={onGalleryBackClick}>
              <img className="PhotoGalleryCarousel-arrow" src={ArrowLeft} />
              <div className="PhotoGalleryCarousel-back">{isMobile ? 'Back' : 'Back to gallery'}</div>
            </button>
          </div>
          <div className="PhotoGalleryCarousel-header-center">
            <strong>
              {currentPhotoIndex + 1} of {photos.length}
            </strong>
          </div>
          <div className="PhotoGalleryCarousel-header-right" />
        </PhotoGalleryHeader>
        <div className="PhotoGalleryCarousel-caption">{caption}</div>
        <div className="PhotoGalleryCarousel-content">
          <div className="PhotoGalleryCarousel-wrapper">
            <PhotoCarousel
              animateChange={isMediumOrSmallerScreenSize}
              backgroundColor="#fff"
              className="PhotoGalleryCarousel-height"
              defaultIndex={currentPhotoIndex}
              itemCountOffset={-1}
              itemCountPosition="none"
              loopable
              maxIndex={photos.length - 1}
              onChange={this.handlePhotoCarouselChange}
              trackScroll
            >
              {photos.map((photo) => {
                const displayName = listing.displayName;
                const photoCaption = photo.caption;
                const imgAltText = photoCaption ? `${photoCaption} - ${displayName}` : '';
                return (
                  <ImageLoader
                    className="PhotoGalleryCarousel-image"
                    image={photo.url}
                    key={photo.url}
                    altText={imgAltText ? imgAltText : ''}
                  />
                );
              })}
            </PhotoCarousel>
          </div>
        </div>
        {isFloorplanPhoto && (
          <section className="PhotoGalleryCarousel-floorplan-btn-container">
            <Button
              btnType="secondary"
              size="sm"
              className="PhotoGalleryCarousel-floorplan-btn"
              onClick={this.handleOpenFdpClick}
            >
              View details about this floor plan
              <img className="PhotoGalleryCarousel-floorplan-btn-caret" src={IconArrowRight} alt="" />
            </Button>
          </section>
        )}
        <div className="PhotoGalleryCarousel-footer">
          <div className="PhotoGalleryCarousel-buttons">
            {showContactUpsell && (
              <Button className="PhotoGalleryCarousel-contact-btn" onClick={onClickContact}>
                Contact now
              </Button>
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  isMobile: state.app.device.screenWidth === 'sm' || state.app.device.screenWidth === 'xs',
  screenWidth: state.app.device.screenWidth,
});

export default connect(mapStateToProps)(PhotoGalleryCarousel);
