// @ts-nocheck
/* eslint-enable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { yieldCallback } from '@zillow/yield-callback';

import Container from 'app/shared/core/Container';
import HdpContentWrapper from 'app/shared/modules/hdp/HdpContentWrapper';
import Linker from 'app/shared/modules/Linker';
import RouteActions from 'app/shared/flux/actions/RouteActions';
import Row from 'app/shared/core/Row';
import Text from 'app/shared/core/Text';

class LinkToBuilding extends Component {
  yieldTransitionTo = yieldCallback(() => {
    const { listing } = this.props;
    this.props.dispatch(RouteActions.transitionToListing({ listingUri: listing.uriBuilding, keepMapLocation: true }));
  });

  handleTransitionToBuildingPage = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.yieldTransitionTo();
  };

  getBuildingInfo = () => {
    const { listing } = this.props;
    if (listing?.forRentUnitsInBuilding?.length > 1) {
      return {
        renderLink: 'rental',
        count: listing.forRentUnitsInBuilding.length - 1,
      };
    } else {
      return {
        renderLink: false,
      };
    }
  };

  getLinkString = (buildingInfo) => {
    const unitString = buildingInfo.count === 1 ? 'unit' : 'units';
    return `${buildingInfo.count} other rental ${unitString} in this building`;
  };

  render = () => {
    const listing = this.props.listing;
    const buildingInfo = this.getBuildingInfo();

    if (!buildingInfo.renderLink === false) {
      return (
        <div className="LinkToBuilding">
          <HdpContentWrapper fullContent active>
            <Container>
              <Row>
                <Text responsive={{ smAndUp: 'sm', xlAndUp: 'md' }} className="LinkToBuilding-link">
                  <Linker to={listing.uriBuilding} onClick={this.handleTransitionToBuildingPage}>
                    {this.getLinkString(buildingInfo)}
                  </Linker>
                </Text>
              </Row>
            </Container>
          </HdpContentWrapper>
        </div>
      );
    } else {
      return null;
    }
  };
}

export default connect()(LinkToBuilding);
