// @ts-nocheck
/* eslint-enable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { yieldCallback } from '@zillow/yield-callback';
import { inline, stacked } from 'app/shared/styles/_spacing';
import './style.scss';
import { analyticsEvent } from 'app/client/universal-analytics';
import { gaEvents } from 'app/shared/constants/AnalyticsConstants';
import clientUtils from 'app/client/utils/clientUtils';
import controller from './controller';
import Linker from 'app/shared/modules/Linker';
import Text from 'app/shared/core/Text';
import IconShareLink from 'images/icons/share-link.svg';
import IconShareFacebook from 'images/icons/share-facebook.svg';
import IconShareTwitter from 'images/icons/share-twitter.svg';
import IconShareSms from 'images/icons/share-sms.svg';
import IconShareEmail from 'images/icons/share-email.svg';
import {
  TrackHdpCopyShareUrl,
  TrackHdpEmailShare,
  TrackHdpTextShare,
} from 'app/shared/models/Clickstream/HdpClickstreamEvents';

const StyledIconImg = styled.img`
  ${inline._2x};
`;
const StyledText = styled(Text)`
  ${stacked._1x};
  font-weight: 700;
`;
class ShareModal extends Component {
  state = { copyText: 'Copy link' };

  handleCopyLinkClick = yieldCallback(() => {
    const urlReadOnlyInput = document.getElementById('ShareModal-readonly-url-input');
    urlReadOnlyInput.focus();
    urlReadOnlyInput.select();
    document.execCommand('Copy');
    this.setState({ copyText: 'Copied!' });
    this.props.dispatch(analyticsEvent(gaEvents.HDP_COPY_LINK, { newLaneEvent: TrackHdpCopyShareUrl() }));
  });

  handleSMSClick = yieldCallback(() => {
    this.props.dispatch(analyticsEvent(gaEvents.HDP_SMS_FRIEND, { newLaneEvent: TrackHdpTextShare() }));
  });

  handleEmailClick = yieldCallback(() => {
    this.props.dispatch(analyticsEvent(gaEvents.HDP_EMAIL_FRIEND, { newLaneEvent: TrackHdpEmailShare() }));
  });

  handleFbClick = yieldCallback(() => {
    this.props.dispatch(analyticsEvent(gaEvents.HDP_FACEBOOK_SHARE_FRIEND));
  });

  handleTwitterClick = yieldCallback(() => {
    this.props.dispatch(analyticsEvent(gaEvents.HDP_TWITTER_SHARE_FRIEND));
  });

  render() {
    const { address, displayName, mobileOS } = this.props;
    const { copyText } = this.state;
    const { oneLineShareText, smsShareHref, emailShareHref } = controller.getShareTexts({
      address,
      displayName,
      mobileOS,
    });
    const shareUrl = controller.getShareUrl();

    return (
      <div className="ShareModal">
        <StyledText id="dialog-title" className="ShareModal-header" size="sm">
          Share this home with friends
        </StyledText>
        <Linker className="ShareModal-item" linkType="text-color" onClick={this.handleCopyLinkClick} tabIndex="0">
          <StyledIconImg className="ShareModal-item-icon" height="20px" width="20px" src={IconShareLink} />
          <Text className="ShareModal-item-text" size="sm">
            {copyText}
            <input id="ShareModal-readonly-url-input" readOnly value={shareUrl} />
          </Text>
        </Linker>
        <Linker className="ShareModal-item" linkType="text-color" onClick={this.handleSMSClick} to={smsShareHref}>
          <StyledIconImg className="ShareModal-item-icon" height="20px" width="20px" src={IconShareSms} />
          <Text className="ShareModal-item-text" size="sm">
            Text
          </Text>
        </Linker>
        <Linker className="ShareModal-item" linkType="text-color" onClick={this.handleEmailClick} to={emailShareHref}>
          <StyledIconImg className="ShareModal-item-icon" height="20px" width="20px" src={IconShareEmail} />
          <Text className="ShareModal-item-text" size="sm">
            Email
          </Text>
        </Linker>
        <Linker
          className="ShareModal-item"
          linkType="text-color"
          onClick={this.handleFbClick}
          to={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`}
          target="_blank"
        >
          <StyledIconImg className="ShareModal-item-icon" height="20px" width="20px" src={IconShareFacebook} />
          <Text className="ShareModal-item-text" size="sm">
            Facebook
          </Text>
        </Linker>
        <Linker
          className="ShareModal-item"
          linkType="text-color"
          onClick={this.handleTwitterClick}
          to={`https://twitter.com/intent/tweet?text=${oneLineShareText}`}
          target="_blank"
        >
          <StyledIconImg className="ShareModal-item-icon" height="20px" width="20px" src={IconShareTwitter} />
          <Text className="ShareModal-item-text" size="sm">
            Twitter
          </Text>
        </Linker>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    address: state.currentListingDetails.currentListing.address,
    displayName: state.currentListingDetails.currentListing.displayName,
    mobileOS: clientUtils.getMobileOperatingSystem(state.app.userAgent),
  };
};

export default connect(mapStateToProps)(ShareModal);
