const devFbId = '26396991252';
const productionFbId = '60854893843';

const isoConfig = {
  googlePublisherId: '7449',
  facebookAppId: '6d73be7cb8eb044e691415c3abffd495',
  fbid: __DEV__ ? devFbId : productionFbId,
};

export default isoConfig;
