// @ts-nocheck
/* eslint-enable */
import constants from 'app/shared/constants/ConstantsBundle';
import gmapUtils from 'app/client/utils/map/gmapUtils';
import isEmpty from 'lodash/isEmpty';
import routeUtils from 'app/shared/utils/routeUtils';

const controller = {
  /**
   * Generates lat / lon / zoom for map view.
   * @param  {Object} options.area
   * @param  {Object} options.currentListing
   * @param  {Object} options.location
   * @param  {Boolan} initLoad
   * @return {Object}
   */
  getLatLonZoom({ area, currentListing, pathname, query }) {
    const hasLatAndLon = routeUtils.hasLatAndLon(query);
    const hasValidMinMaxLatLon = routeUtils.hasValidMinMaxLatLon(query);
    const isAreaUrl = routeUtils.isAreaUrl(pathname);
    const isPadOrBuildingUrl = routeUtils.isPadOrBuildingUrl(pathname);
    const isWithinMapBounds = controller.isWithinMapBounds({ currentListing });

    if (isPadOrBuildingUrl && isWithinMapBounds) {
      // Prevent updating / moving map when clicking on listing card currently in
      // map view. (e.g., clicking on ListingCards on right sidebar.)
      return false;
    } else if (hasValidMinMaxLatLon) {
      return {
        lat: (Number(query.minLat) + Number(query.maxLat)) / 2,
        lon: (Number(query.minLon) + Number(query.maxLon)) / 2,
        zoom: Number(
          gmapUtils.getZoomForBoundingBox({
            minLat: query.minLat,
            maxLat: query.maxLat,
            minLon: query.minLon,
            maxLon: query.maxLon,
          }),
        ),
      };
    } else if (hasLatAndLon) {
      return {
        lat: Number(query.lat),
        lon: Number(query.lon),
        zoom: Number(query.z) || 12,
      };
    } else if ((isAreaUrl || !currentListing) && !isEmpty(area)) {
      return {
        lat: Number(area.coordinates.lat),
        lon: Number(area.coordinates.lon),
        zoom: Number(
          gmapUtils.getZoomForBoundingBox({
            minLat: Number(area.minLat),
            maxLat: Number(area.maxLat),
            minLon: Number(area.minLon),
            maxLon: Number(area.maxLon),
          }),
        ),
      };
    } else if (currentListing) {
      return {
        lat: Number(currentListing.geo.lat),
        lon: Number(currentListing.geo.lon),
        zoom: Number(constants.ZOOM_CURRENT_LISTING),
      };
    } else {
      return false;
    }
  },

  isWithinAreaBounds({ area, currentListing }) {
    if (!window || !window.map) {
      return;
    }

    if (window.store.getState().app.isInitialSsrPage) {
      return;
    }

    if (area && currentListing) {
      const lat = currentListing.geo.lat;
      const lon = currentListing.geo.lon;
      const bounds = new window.google.maps.LatLngBounds(
        new window.google.maps.LatLng(area.maxLat, area.minLon),
        new window.google.maps.LatLng(area.minLat, area.maxLon),
      );
      const listingCoords = new window.google.maps.LatLng(lat, lon);

      if (bounds) {
        return bounds.contains(listingCoords);
      }
    }
  },

  isWithinMapBounds({ currentListing }) {
    if (!window || !window.map) {
      return false;
    } else if (window.store.getState().app.isInitialSsrPage) {
      return false;
    } else if (currentListing) {
      const lat = currentListing.geo.lat;
      const lon = currentListing.geo.lon;
      const listingCoords = new window.google.maps.LatLng(lat, lon);

      // Web app will sometimes hit a state where window.map technically exists,
      // but it has no properties inside. Not exactly sure why this happens, but it
      // causes the site to explode. Wrapping in try / catch handles that and will
      // cause map to try and reload things when map is available a few moments later.
      try {
        return window.map.getBounds().contains(listingCoords);
      } catch (err) {
        return false;
      }
    } else {
      return true;
    }
  },

  latLonOrZoomChanged(mapData1, mapData2) {
    const latChanged = mapData1.lat !== mapData2.lat;
    const lonChanged = mapData1.lon !== mapData2.lon;
    const zoomChanged = mapData1.zoom !== mapData2.zoom;

    return latChanged || lonChanged || zoomChanged;
  },
};

export default controller;
