// @ts-nocheck
/* eslint-enable */
/* eslint-disable react/no-did-update-set-state */

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import debounce from 'lodash/debounce';
import usertiming from 'app/shared/utils/performanceUtils';

// Actions
import AppActions from 'app/shared/flux/actions/AppActions';
import AreaActions from 'app/shared/flux/actions/AreaActions';
import ListingEngineActions from 'app/shared/flux/actions/ListingEngineActions';
import RouteActions from 'app/shared/flux/actions/RouteActions';
import UserSearchActions from 'app/shared/flux/actions/UserSearchActions';

// Components
import ListingsCount from 'app/shared/modules/map/ListingsCount';
import Spinner from 'app/shared/modules/Spinner';

// Utils / Misc
import { analyticsEvent } from 'app/client/universal-analytics';
import { gaEvents } from 'app/shared/constants/AnalyticsConstants';
import constants from 'app/shared/constants/ConstantsBundle';
import controller from 'app/shared/wrappers/MapWrapper/controller';
import queryUtils from 'app/shared/utils/queryUtils';
import routeUtils from 'app/shared/utils/routeUtils';
import { MapWrapper } from 'app/shared/wrappers/MapWrapper/styles';

// Map Components
import gmapUtils from 'app/client/utils/map/gmapUtils';
import GoogleMap from 'app/shared/modules/map/GoogleMap';
import MapBorder from 'app/shared/modules/map/MapBorder';
import MarkersContainer from 'app/shared/modules/map/MarkersContainer';
import MapLayerButtons from 'app/shared/modules/map/MapLayerButtons';
import MapRemoveBorderButton from 'app/shared/modules/map/MapRemoveBorderButton';
import NoResultsMapBanner from 'app/shared/modules/search-page/NoResultsMapBanner';
import ErrorActions from 'app/shared/flux/actions/ErrorActions';
import isEmpty from 'lodash/isEmpty';

const BOUNDS_DEBOUNCE_MS = 250;
const RESIZE_DEBOUNCE_MS = 800;

class MapController extends React.Component {
  static propTypes = {
    area: PropTypes.object,
    currentListing: PropTypes.object,
    isAreaUrl: PropTypes.bool,
    isMapView: PropTypes.bool,
    isMobile: PropTypes.bool,
    isPadOrBuildingUrl: PropTypes.bool,
    previewListing: PropTypes.object,
    useStaticMap: PropTypes.bool,
    setIsMwebMapLoaded: PropTypes.func,
  };

  static defaultProps = {
    area: {},
    currentListing: null,
    isAreaUrl: true,
    isMapView: true,
    isMobile: false,
    isPadOrBuildingUrl: false,
    previewListing: null,
    useStaticMap: false,
    setIsMwebMapLoaded: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      border: null,
      googleMap: null,
      mapType: 'default',
      lon: null,
      lat: null,
      zoom: null,
      tilesLoaded: false,
    };

    this.handlingDebounce = false;

    this.hasMapZoomed = false;
    this.mounted = true;
    this.mapLoaded = false;
    this.handleBoundsChanged = this.handleBoundsChanged.bind(this);
    this.handleMapResize = debounce(this.handleMapResize.bind(this), RESIZE_DEBOUNCE_MS, {
      leading: false,
      trailing: true,
    });

    this.handleBoundsChangedDebounced = debounce(this.handleBoundsChangedDebounced.bind(this), BOUNDS_DEBOUNCE_MS, {
      leading: false,
      trailing: true,
    });

    this.handleBoundaryClick = this.handleBoundaryClick.bind(this);
    this.handleBoundaryDblClick = this.handleBoundaryDblClick.bind(this);
    this.handleBoundarySingleClick = this.handleBoundarySingleClick.bind(this);
    this.handleMapClick = this.handleMapClick.bind(this);
    this.handleMapDblClick = this.handleMapDblClick.bind(this);
    this.handleMapDrag = this.handleMapDrag.bind(this);
    this.handleMapFirstIdle = this.handleMapFirstIdle.bind(this);
    this.handleMapTilesLoaded = this.handleMapTilesLoaded.bind(this);
    this.handleMapSingleClick = this.handleMapSingleClick.bind(this);
    this.handleMapResize = this.handleMapResize.bind(this);
    this.handleMapZoom = this.handleMapZoom.bind(this);
    this.updateUrlToNewLocation = this.updateUrlToNewLocation.bind(this);

    this.handleNewMapData = this.handleNewMapData.bind(this);

    this.shouldPan = false;
    this.mapRef = React.createRef();
    this.setMap = this.setMap.bind(this);
    this.shouldResetPageQuery = false;

    this.removeBorder = this.removeBorder.bind(this);
    this.resetPageQuery = this.resetPageQuery.bind(this);

    this.handleIdle = this.handleIdle.bind(this);
    this.handleIdleTimeout = null;
  }

  componentDidMount() {
    const { area, currentListing, query, pathname } = this.props;
    window.addEventListener('click', this.shouldPanToListing);
    window.addEventListener('touchstart', this.shouldPanToListing);

    this.setState({ border: query.border });

    const newMapData = controller.getLatLonZoom({ area, currentListing, pathname, query });

    const { lat, lon, zoom } = newMapData;

    if (newMapData) {
      this.handleNewMapData(lat, lon, zoom);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { area, currentListing, query, pathname, isAreaUrl, isNearMeUrl, isPadOrBuildingUrl } = nextProps;
    const { lat, lon, zoom } = this.state;
    // Prevent map from moving when leaving area / listing page
    // (e.g, url updates to homepage but map hasn't unmounted yet)
    if (!isAreaUrl && !isPadOrBuildingUrl && !isNearMeUrl) {
      return;
    }
    const thisArea = this.props.area || {};
    const nextArea = area || {};
    const thisListing = this.props.currentListing || {};
    const nextListing = currentListing || {};

    if (
      thisArea.resourceId !== nextArea.resourceId ||
      (thisListing.aliasEncoded !== nextListing.aliasEncoded && nextListing.aliasEncoded)
    ) {
      const newMapData = controller.getLatLonZoom({ area, currentListing, pathname, query });
      if (newMapData) {
        const mapData = { lat, lon, zoom };
        if (controller.latLonOrZoomChanged(mapData, newMapData)) {
          this.handleNewMapData(newMapData.lat, newMapData.lon, newMapData.zoom);
        }
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { isMobile, previewListing, query, mapType, isMapPanning } = this.props;
    const { googleMap, border } = this.state;

    if (this.didMapTypeChange(prevProps.mapType, mapType)) {
      this.handleMapTypeChange();
    }

    if (isMapPanning) {
      this.handleIdle();
    }

    // Handles showing border when clicking the back button
    // e.g., Going from area page -> Remove border -> Clicking back.
    if (query.border === false && border !== false) {
      this.setState({ border: false });
    } else if (query.border !== false && border === false) {
      this.setState({ border: true });
    }

    // Use setTimeout to force panTo logic to wait for next tick in event loop,
    // otherwise this logic misses detecting if prevProps and current props
    // have changed.
    setTimeout(() => {
      if (
        this.shouldPan &&
        isMobile &&
        previewListing &&
        (!prevProps.previewListing ||
          (prevProps.previewListing &&
            prevProps.previewListing.maloneLotIdEncoded !== previewListing.maloneLotIdEncoded))
      ) {
        const previewCoords = {
          lat: previewListing.geo.lat,
          lng: previewListing.geo.lon,
        };
        googleMap.panTo(previewCoords); // Center listing in map view.
        this.shouldPan = false;
      }
    }, 0);
  }

  componentWillUnmount() {
    this.mounted = false;
    this.handleBoundsChangedDebounced.cancel();
    window.removeEventListener('click', this.shouldPanToListing);
    window.removeEventListener('touchstart', this.shouldPanToListing);

    if (this.handleIdleTimeout) {
      clearTimeout(this.handleIdleTimeout);
    }
  }

  setMap(googleMap) {
    this.setState({ googleMap });
  }

  handleBoundsChanged(googleMap) {
    const { dispatch, isMapView, isMapPanning, isInitialSsrPage } = this.props;

    // Prevents mWeb list from updating map coords in background when clicking on listing.
    if (!isMapView) {
      return;
    }

    if (this.mapLoaded) {
      this.handleBoundsChangedDebounced(googleMap);
    }

    if (!isMapPanning && !isInitialSsrPage) {
      dispatch(AppActions.setAppStoreBool('isMapPanning', true));
    }
  }

  handleIdle() {
    const { dispatch, isMapPanning } = this.props;

    // need to wait for at least the debounce time
    if (isMapPanning) {
      this.handleIdleTimeout = window.setTimeout(() => {
        dispatch(AppActions.setAppStoreBool('isMapPanning', false));
        // 500 corresponds to debounceTimeMs in SplitMapTemplate
        // aka minimum time before fetchListings will be called
      }, BOUNDS_DEBOUNCE_MS + 500);
    }
  }

  handleBoundsChangedDebounced(googleMap) {
    const { dispatch } = this.props;

    // if we're currently doing a debounce
    // do not trigger another one
    if (this.handlingDebounce) {
      return false;
    }

    this.handlingDebounce = true;

    const mapData = gmapUtils.getMapData(googleMap);

    dispatch(UserSearchActions.setCurrentSearch({ mapData }));
    dispatch(ListingEngineActions.fetchNumDefaultFilterListings()).catch((error) => {
      dispatch(
        ErrorActions.errorHandler({
          error,
          errorLocation: 'component.mapWrapper.handleBoundsChangedDebounced#fetchNumDefaultFilterListings',
          errorClass: 'listingEngineActions',
        }),
      );
    });

    this.updateUrlToNewLocation(googleMap);
  }

  handleMapResize(googleMap) {
    window.google.maps.event.trigger(googleMap, 'resize');
  }

  handleMapFirstIdle(googleMap) {
    const { dispatch } = this.props;

    this.mapLoaded = true;
    usertiming.mark('GoogleMap firstIdle');
    const mapData = gmapUtils.getMapData(googleMap);
    dispatch(UserSearchActions.setCurrentSearch({ mapData, isMapFirstIdle: true }));
    dispatch(AppActions.setAppStoreBool('gmapLoaded', true));
  }

  handleMapTilesLoaded() {
    this.setState({
      tilesLoaded: true,
    });
    if (this.props.setIsMwebMapLoaded) {
      this.props.setIsMwebMapLoaded(true);
    }
  }

  shouldPanToListing = (e) => {
    const HEADER_OFFSET = 42 + 48; // 42px NavBar, 48px FilterNav
    const PREVIEW_OFFSET = 200 + 30; // 200px high ListingPreview, 30px padding above.

    // e.clientY handles mouse clicks.
    // e.touches handles mWeb touch events.
    const clientY = (e.clientY || (e.touches && e.touches[0].clientY)) - HEADER_OFFSET;

    const mapHeight = this.mapRef.current.getBoundingClientRect().height;
    const panThreshold = mapHeight - PREVIEW_OFFSET;

    if (clientY >= panThreshold) {
      this.shouldPan = true;
    } else {
      this.shouldPan = false;
    }
  };

  updateUrlToNewLocation(googleMap) {
    const { dispatch, isAreaUrl, isNearMeUrl } = this.props;
    const { border, zoom, lat, lon } = this.state;
    const currentMapData = { lat, lon, zoom };
    const shouldFetchNewArea = (isAreaUrl || isNearMeUrl) && border === false;

    if (!this.mounted) {
      return;
    }

    const mapData = gmapUtils.getMapData(googleMap);

    mapData.border = border;

    if (shouldFetchNewArea) {
      dispatch(
        AreaActions.getBestFitArea({
          minLat: Number(mapData.minLat).toFixed(4),
          maxLat: Number(mapData.maxLat).toFixed(4),
          minLon: Number(mapData.minLon).toFixed(4),
          maxLon: Number(mapData.maxLon).toFixed(4),
        }),
      )
        .then((bestFitArea) => {
          if (bestFitArea && bestFitArea.resourceId) {
            return dispatch(AreaActions.setCurrentArea({ area: bestFitArea }));
          } else {
            return bestFitArea;
          }
        })
        .then((areaResult = {}) => {
          const { state } = this;
          const hideBorderAfterApi = state.border === false;
          const mapDataAfterApi = gmapUtils.getMapData(googleMap);

          mapDataAfterApi.border = hideBorderAfterApi;

          const { lat: newLat, lon: newLon, zoom: newZoom } = mapDataAfterApi;

          this.handleNewMapData(newLat, newLon, newZoom);

          dispatch(
            RouteActions.updateUrlWithMapDataAndAreaResourceId({
              areaResourceId: areaResult.resourceId,
              mapData: mapDataAfterApi,
            }),
          );

          this.handlingDebounce = false;
        })
        .catch(() => {
          this.handlingDebounce = false;
        });
    } else if (controller.latLonOrZoomChanged(gmapUtils.getMapData(googleMap), currentMapData)) {
      const { lat: newLat, lon: newLon, zoom: newZoom } = mapData;

      this.handleNewMapData(newLat, newLon, newZoom);

      dispatch(RouteActions.updateUrlWithMapData(mapData));

      this.handlingDebounce = false;
    } else {
      this.handlingDebounce = false;
    }
  }

  removeBorder() {
    const { dispatch } = this.props;
    const mapData = gmapUtils.getMapData(window.map);

    mapData.border = false; // TO DO: cosolidate mapData data models, and maybe incorporate border?
    this.setState({ border: false });

    dispatch(RouteActions.updateUrlWithMapData(mapData));
    dispatch(UserSearchActions.setCurrentSearch({ mapData }));
  }

  didMapTypeChange(oldMapType, newMapType) {
    return oldMapType !== newMapType;
  }

  handleMapTypeChange() {
    const { googleMap: map } = this.state;
    const { dispatch } = this.props;

    if (map.getMapTypeId() === 'hybrid') {
      map.setMapTypeId(window.google.maps.MapTypeId.ROADMAP);
    } else if (map.getMapTypeId() === 'roadmap') {
      map.setOptions({ styles: [] });
      map.setMapTypeId(window.google.maps.MapTypeId.HYBRID);
    }
    dispatch(
      analyticsEvent(gaEvents.GOOGLE_MAPS_TYPE, {
        action: 'ToggleMapType-' + map.getMapTypeId(),
      }),
    );
  }

  handleMapDrag() {
    const { dispatch } = this.props;

    dispatch(analyticsEvent(gaEvents.GOOGLE_MAPS_DRAG));

    this.resetPageQuery();
  }

  handleBoundaryClick(googleMap) {
    this.resetPageQuery();
    this.doubleClickedBoundary = false;
    window.setTimeout(() => this.handleBoundarySingleClick(googleMap), 250);
  }

  handleBoundaryDblClick() {
    this.doubleClickedBoundary = true;
  }

  handleBoundarySingleClick(googleMap) {
    const { dispatch, isPadOrBuildingUrl } = this.props;

    if (!this.doubleClickedBoundary) {
      this.removeBorder(googleMap);
      dispatch(ListingEngineActions.clearActiveMarkerAndPreviewListing());

      if (isPadOrBuildingUrl) {
        dispatch(RouteActions.transitionToAreaWithMapData());
      }
    }
  }

  handleMapClick() {
    this.resetPageQuery();
    this.doubleClicked = false;
    window.setTimeout(this.handleMapSingleClick, 250);
  }

  handleMapSingleClick() {
    const { dispatch, isPadOrBuildingUrl } = this.props;

    if (!this.doubleClicked) {
      dispatch(ListingEngineActions.clearActiveMarkerAndPreviewListing());

      if (isPadOrBuildingUrl) {
        dispatch(RouteActions.transitionToAreaWithMapData());
      }
    }
  }

  handleMapDblClick() {
    this.doubleClicked = true;
  }

  handleMapZoom(googleMap) {
    const { zoom } = this.state;
    if (googleMap.getZoom() < constants.MIN_MAP_ZOOM) {
      googleMap.setZoom(constants.MIN_MAP_ZOOM);
    }
    if (googleMap.zoom === zoom) {
      return;
    }
    const { dispatch } = this.props;
    dispatch(analyticsEvent(gaEvents.GOOGLE_MAPS_ZOOM));
    this.resetPageQuery();
    this.hasMapZoomed = true;
  }

  handleZoomOut() {
    const { zoom } = this.state;
    this.setState({
      zoom: zoom - 1,
    });
  }

  resetPageQuery() {
    this.shouldResetPageQuery = true;
  }

  handleNewMapData(lat, lon, zoom) {
    const latFormatted = Number(lat.toFixed(4));
    const lonFormatted = Number(lon.toFixed(4));
    const zoomFormatted = Number(zoom);

    this.setState({
      lat: latFormatted,
      lon: lonFormatted,
      zoom: zoomFormatted,
    });
  }

  render() {
    const {
      area,
      currentListing,
      isAreaUrl,
      isMapPanning,
      isMapView,
      isMobile,
      isNearMeUrl,
      isPadOrBuildingUrl,
      listingsLoading,
      numDefaultFilterListings,
      previewListing,
      totalListings,
    } = this.props;

    if (isEmpty(area)) {
      return (
        <MapWrapper id="MapWrapper">
          <Spinner className="Spinner-wrapper" />
        </MapWrapper>
      );
    }

    const { border, lat, lon, mapType, zoom } = this.state;
    const showBorder = border !== false && area.resourceId !== 'united-states';
    const showBanner =
      ((totalListings === 0 && numDefaultFilterListings > 0) || (totalListings === 0 && !showBorder)) &&
      isMobile &&
      !listingsLoading;
    const isLoading = !area.resourceId || listingsLoading || isMapPanning;
    const ariaLabel = gmapUtils.getAccessibleLabel({
      isAreaUrl,
      isNearMeUrl,
      isPadOrBuildingUrl,
      area,
      listing: currentListing,
    });
    const accessibleInstructions = `Details about each listing, including address and price, can be found in the search results
        under the h2 heading 'Apartments for Rent'`;

    // no need to ask for a google map component (and subsequent map loading) for views that don't have a map
    if (!isMapView) {
      return null;
    }

    // hide map when we're using static map and the map tiles aren't finished loading
    const hideDynamicMap = this.props.useStaticMap && !this.state.tilesLoaded;

    return (
      <MapWrapper ref={this.mapRef} id="MapWrapper">
        <GoogleMap
          onBoundsChanged={this.handleBoundsChanged}
          onMapClick={this.handleMapClick}
          onMapDblClick={this.handleMapDblClick}
          onMapDrag={this.handleMapDrag}
          onMapFirstIdle={this.handleMapFirstIdle}
          onMapResize={this.handleMapResize}
          onMapZoom={this.handleMapZoom}
          onMapIdle={this.handleIdle}
          onTilesLoaded={this.handleMapTilesLoaded}
          lat={Number(lat)}
          lon={Number(lon)}
          setMap={this.setMap}
          zoom={Number(zoom)}
          hidden={hideDynamicMap}
          ariaLabel={ariaLabel}
          accessibleInstructions={accessibleInstructions}
        >
          {showBanner ? (
            <NoResultsMapBanner
              numDefaultFilterListings={numDefaultFilterListings}
              showBorder={showBorder}
              onZoomOut={this.handleZoomOut}
            />
          ) : (
            <Fragment>
              <ListingsCount isLoading={isLoading} />
              {showBorder && <MapRemoveBorderButton mapType={mapType} removeBorder={this.removeBorder} />}
            </Fragment>
          )}
          <MapLayerButtons isMobile={isMobile} previewListing={previewListing} />
          {showBorder && (
            <MapBorder
              area={area}
              mapType={mapType}
              onBoundaryClick={this.handleBoundaryClick}
              onBoundaryDblClick={this.handleBoundaryDblClick}
            />
          )}
          <MarkersContainer zoom={Number(zoom)} />
        </GoogleMap>
      </MapWrapper>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    area: state.area.area,
    currentListing: state.currentListingDetails.currentListing,
    isAreaUrl: routeUtils.isAreaUrl(ownProps.location.pathname),
    isInitialSsrPage: state.app.isInitialSsrPage,
    isMapPanning: state.app.isMapPanning,
    isMobile: state.app.device.screenWidth === 'sm',
    isNearMeUrl: routeUtils.isNearMeUrl(ownProps.location.pathname),
    isPadOrBuildingUrl: routeUtils.isPadOrBuildingUrl(ownProps.location.pathname),
    listingsLoading: !state.app.fetchListingsByCoordsComplete,
    mapType: state.app.mapType,
    numDefaultFilterListings: state.listings.numDefaultFilterListings,
    pathname: ownProps.location.pathname,
    previewListing: state.listings.listingGroups.previewListing,
    query: queryUtils.parse(ownProps.location.search),
    totalListings: state.listings.totalListings,
  };
};

export default withRouter(connect(mapStateToProps)(MapController));
