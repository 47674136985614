// @ts-nocheck
/* eslint-enable */
import React from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import HpBreadcrumb from 'app/shared/modules/HpBreadcrumb';
import controller from './controller';

class AreaBreadcrumbs extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { area, className = null, currentListing, filter } = this.props;
    const LIMIT = 3;

    const breadcrumbs = controller.getBreadcrumbs({
      area,
      filter,
      currentListing,
    });

    return (
      <div className={cx('AreaBreadcrumbs', className)}>
        {Boolean(breadcrumbs.length) && <HpBreadcrumb breadcrumbs={breadcrumbs.slice(0, LIMIT)} />}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    area: state.area.area,
    currentListing: state.currentListingDetails.currentListing,
    filter: state.filter,
  };
};

export default withRouter(connect(mapStateToProps)(AreaBreadcrumbs));
