// @ts-nocheck
/* eslint-enable */
import { useEffect, useState } from 'react';

/**
 * - extendedItems
 * To simulate infinite scrolling in both directions, the items passed into Carousel
 * are "extended" on both sides.
 *   items:               |  1  |  2  |  3  |  4  |  5  |
 *   extendedItems: |  5* |  1  |  2  |  3  |  4  |  5  |  1* |
 *
 * - canScroll
 * canScroll is used to throttle scrolling. setTimeout inside the fn means we can't
 * use _.throttle or something similar
 *
 * - useTransition
 * When scrolling to the ends of the list, the carousel must eventually scroll back
 * to the "real" item location (5* -> 5 or 1* -> 1). Do NOT animate this.
 * Controlling useTransition with useState is too slow and results in the css
 * transition applying to the 5* -> 5 and 1* -> 1 scroll.
 *
 * - autoScrollTimeout
 * Pause auto scroll for some duration after any user interaction to allow users
 * read and engage with the current carousel item.
 */

let canScroll = true;
let useTransition = false;
let autoScrollTimeout = setTimeout(() => {});

const SCROLL_START_THRESHOLD_DISTANCE = 10; // min distance dragged before horiz scrolling begins (pixels)
const SCROLL_NEXT_THRESHOLD_FRACTION = 0.3; // min distance dragged before resulting in index update (%)

export const useCarousel = ({ autoScrollPauseDuration, autoScrollSpeed, count, transitionDuration, width }) => {
  const [index, updateIndex] = useState(0);
  const [translateX, updateTranslateX] = useState(Number(width));
  const [canAutoScroll, updateCanAutoScroll] = useState(true);

  // after manually navigating between cards, do not auto scroll for autoScrollPauseDuration
  const debounceAutoScroll = () => {
    if (!autoScrollSpeed) {
      return;
    }
    clearTimeout(autoScrollTimeout);
    updateCanAutoScroll(false);

    autoScrollTimeout = setTimeout(() => {
      updateCanAutoScroll(true);
    }, autoScrollPauseDuration);
  };

  useEffect(() => {
    // when width is first available, scroll to extendedItems[1]
    updateTranslateX(width);

    setTimeout(() => {
      useTransition = true;
    }, autoScrollSpeed);
  }, [autoScrollSpeed, width]);

  useEffect(() => {
    return () => {
      clearTimeout(autoScrollTimeout);
    };
  }, []);
  const handleAutoScroll = ({ index: _index, width: _width }) => {
    if (_index < count - 1) {
      updateIndex(_index + 1);
      updateTranslateX((_index + 2) * _width);
    } else {
      updateIndex(0);
      updateTranslateX((count + 1) * _width);

      setTimeout(() => {
        useTransition = false;
        updateTranslateX(_width);

        setTimeout(() => {
          useTransition = true;
        }, 0);
      }, transitionDuration);
    }
  };
  const handleGoToItem = (_index) => {
    if (useTransition === false) {
      useTransition = true;
    }
    updateIndex(_index);
    updateTranslateX((_index + 1) * width);
    debounceAutoScroll();
  };
  const handlePrev = (e) => {
    if (e) {
      e.stopPropagation();
    }
    if (!canScroll) {
      return;
    }
    canScroll = false;
    setTimeout(() => {
      canScroll = true;
    }, transitionDuration);

    if (index === 0) {
      debounceAutoScroll();
      updateIndex(count - 1);
      updateTranslateX(0);

      setTimeout(() => {
        // after scroll completion, block transition and scroll to the "real" position
        useTransition = false;
        updateTranslateX(count * width);

        setTimeout(() => {
          useTransition = true;
        }, 0);
      }, transitionDuration);
    } else {
      handleGoToItem(index - 1);
    }
  };
  const handleNext = (e) => {
    if (e) {
      e.stopPropagation();
    }
    if (!canScroll) {
      return;
    }
    canScroll = false;
    setTimeout(() => {
      canScroll = true;
    }, transitionDuration);

    if (index === count - 1) {
      debounceAutoScroll();
      updateIndex(0);
      updateTranslateX((count + 1) * width);

      setTimeout(() => {
        useTransition = false;
        updateTranslateX(width);

        setTimeout(() => {
          useTransition = true;
        }, 0);
      }, transitionDuration);
    } else {
      handleGoToItem(index + 1);
    }
  };

  // the states and functions below are for touch scrolling (mweb only)
  const [touchPosStart, updateTouchPosStart] = useState({ x: -1, y: -1 });
  const [touchPos, updateTouchPos] = useState({ x: -1, y: -1 });
  const [carouselTranslateXStart, updateCarouselTranslateXStart] = useState(0);
  const [isVerticallyScrolling, updateIsVerticallyScrolling] = useState(false);

  const handleTouchStart = (e) => {
    if (!width || !canScroll) {
      /**
       * To reduce jank, block scrolling by dragging...
       * - until width ref is attached
       * - when already in the process of scrolling
       */
      return;
    }
    const touch = e.touches[0];
    if (touch) {
      useTransition = false;
      updateCarouselTranslateXStart(translateX);
      updateTouchPosStart({ x: touch.clientX, y: touch.clientY });
    }
  };

  const handleTouchMove = (e) => {
    const touch = e.touches[0];
    if (touch && !isVerticallyScrolling) {
      if (
        Math.abs(touchPosStart.y - touch.clientY) > SCROLL_START_THRESHOLD_DISTANCE &&
        touchPos.x === -1 &&
        touchPos.y === -1
      ) {
        // if user has started vertically scrolling, do not scroll through carousel
        updateIsVerticallyScrolling(true);
      } else if (
        Math.abs(touchPosStart.x - touch.clientX) > SCROLL_START_THRESHOLD_DISTANCE &&
        !isVerticallyScrolling
      ) {
        updateTouchPos({ x: touch.clientX, y: touch.clientY });
        updateTranslateX(carouselTranslateXStart + (touchPosStart.x - touch.clientX));
      }
    }
  };

  const handleTouchEnd = () => {
    if (isVerticallyScrolling) {
      updateIsVerticallyScrolling(false);
    } else if (touchPos.x === -1 || touchPos.y === -1) {
      // do nothing - user did not drag
    } else {
      if (touchPos.x - touchPosStart.x > width * SCROLL_NEXT_THRESHOLD_FRACTION) {
        handlePrev();
      } else if (touchPosStart.x - touchPos.x > width * SCROLL_NEXT_THRESHOLD_FRACTION) {
        handleNext();
      } else {
        handleGoToItem(index);
      }
    }
    updateTouchPos({ x: -1, y: -1 });
    useTransition = true;
  };

  return {
    canAutoScroll,
    handleAutoScroll,
    handleGoToItem,
    handleNext,
    handlePrev,
    handleTouchEnd,
    handleTouchMove,
    handleTouchStart,
    index,
    useTransition,
    translateX,
  };
};
