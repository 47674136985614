// @ts-nocheck
/* eslint-enable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import controller from './controller';
import FdpFloorplans from 'app/shared/modules/hdp/HdpFdp/FdpFloorplans';
import HdpFullDetailModal from 'app/shared/modules/hdp/HdpFdp/HdpFullDetailModal';

const { string, func, object } = PropTypes;

// This component handles selection of fdp component and the gathering of necessary props
class HdpFullDetailModalWrapper extends Component {
  static propTypes = {
    componentName: string,
    componentParams: object,
    onClickContact: func,
    onHideModal: func,
  };

  static defaultProps = {
    componentName: '',
    componentParams: {},
    onClickContact: () => {},
    onHideModal: () => {},
  };

  renderHdpFullDetailComponent = () => {
    const { componentName, componentParams, currentListing, onHideModal } = this.props;

    // in the near future, there will be many more hdp sections with FDPs
    if (componentName === 'FloorPlans') {
      // PascalCase because componentName is also used for analytics (Hdp/component)
      const { floorplans, active, waitlisted } = currentListing;
      const { modelId } = componentParams;
      const floorplan = controller.getFloorplan(floorplans, modelId);

      return <FdpFloorplans active={active} floorplan={floorplan} waitlisted={waitlisted} onHideModal={onHideModal} />;
    }
  };

  render() {
    const { onClickContact, onHideModal } = this.props;
    return (
      <HdpFullDetailModal onHideModal={onHideModal} onClickContact={onClickContact}>
        {this.renderHdpFullDetailComponent()}
      </HdpFullDetailModal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentListing: state.currentListingDetails.currentListing,
  };
};

export default connect(mapStateToProps)(HdpFullDetailModalWrapper);
