// @ts-nocheck
/* eslint-enable */
import styled from 'styled-components';
import Radio from 'app/shared/core/Radio';
import { colors } from 'app/shared/styles/_colors';
import { font } from 'app/shared/styles/_fontsize';

export const SelectTourTypeStepContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 25px 20px 20px;
`;

export const LocationMarkerIcon = styled.img`
  height: 30px;
  width: 30px;
`;

export const DisplayNameAndAddress = styled.p`
  ${font.md}

  margin-top: 3px;
  margin-bottom: 30px;
`;

export const ContainerItem = styled.div`
  margin: 15px 0px;
`;

export const TourTypeRadio = styled(Radio)`
  margin: 15px 0px;
`;

export const RadiosContainer = styled.div`
  padding-bottom: 15px;
  align-self: flex-start;
`;

export const Header = styled.h3`
  ${font.xl}
  font-weight: bold;
  align-self: flex-start;
`;

export const TourIconAndLabel = styled.div`
  &:hover {
    cursor: ${(props) => (props?.isClickable ? 'pointer' : 'default')};
  }
`;

export const IconAndTitle = styled.div`
  display: flex;
  align-items: center;
`;

export const LabelIcon = styled.img`
  height: 20px;
  width: 20px;
  margin-right: 5px;
`;

export const LearnMoreTextBtn = styled.button`
  display: flex;
  align-items: center;
  font-weight: bold;
  color: ${colors['$hpx-teal-500']};

  &:hover {
    cursor: pointer;
  }
`;

export const LearnMoreContainer = styled.div`
  ${(props) =>
    `
        margin-left: ${props?.isBelowRadio ? '28px' : '0px'};
        margin-top: ${!props?.isBelowRadio ? '10px' : '0px'};
    `};
`;

export const LearnMoreTopContent = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const LearnMoreText = styled.p`
  display: block;
  margin: 5px 0px;
`;

export const UnavailableToursButton = styled.button`
  width: 100%;
  display: flex;
  padding: 16px;

  text-align: left;
  border: 1px solid ${colors['$hpx-grey-500']};
  border-radius: 4px 4px 0px 0px;
  background-color: ${colors['$hpx-grey-200']};

  &:hover {
    cursor: pointer;
  }

  ${(props) =>
    props?.showUnavailableTours &&
    `
        background-color: ${colors['$hpx-grey-400']};
    `};
`;

export const UnavailableToursContainer = styled.div`
  width: 100%;
  padding: 20px;

  border: 1px solid ${colors['$hpx-grey-500']};
  border-radius: 4px 4px;
`;
