// @ts-nocheck
/* eslint-enable */
import { listingUtils_truncateListingUpdated } from 'app/shared/utils/listingUtils';

// Hardcodes the display order for highlights in order of importance based on HPWEB-5992
export const AMENITY_DISPLAY_ORDER = [
  'pets',
  'laundry',
  'hvac',
  'parking',
  'outdoorAreas',
  'security',
  'pool',
  'gym',
  'appliances',
];

// Same as AMENITY_DISPLAY_ORDER, but guarantee that parking will always be included first for r4r listings.
export const AMENITY_DISPLAY_ORDER_R4R = [
  'parking',
  'pets',
  'laundry',
  'hvac',
  'outdoorAreas',
  'security',
  'pool',
  'gym',
  'appliances',
];

// Some amenities require further parsing to cleanup data.
// e.g., 'Pool' usually has a type that is named "On-site",
// which is redunant for our purposes. We want to cleanup
// and modify how this is displayed.
export const AMENITIES_TO_FORMAT = ['gym', 'laundry', 'pets', 'pool'];

// Some categories of amenities need to be expanded for further details.
// e.g., 'hvac' can include both "Heating" and "Central A/C" types, and
// we'd like to list both of those within the highlights module.
export const SHOULD_EXPAND_AMENITY = ['hvac', 'pets'];

export const parseActivatedTime = (history = {}, isMultiFamily) => {
  const { activated, lastUpdated } = history;
  if (activated?.date > lastUpdated?.date || isMultiFamily) {
    return false;
  }

  return `Listed ${listingUtils_truncateListingUpdated(activated?.ago)}`;
};

export const parseUpdatedTime = (history = {}) => {
  const { activated, lastUpdated } = history;

  if (!activated?.date && !lastUpdated?.date) {
    return '';
  }

  if ((activated?.date && !lastUpdated?.date) || activated?.date > lastUpdated?.date) {
    return `Posted ${listingUtils_truncateListingUpdated(activated.ago)}`;
  }

  return `Updated ${listingUtils_truncateListingUpdated(lastUpdated.ago)}`;
};

// Builds a dictionary for quick lookup by object's key for later use
// when determining exact display order of amenities.
export const buildHighlightsDictionary = (highlights = []) => {
  const highlightsDictionary = {};
  highlights.forEach((amenity = {}) => {
    if (amenity.id) {
      highlightsDictionary[amenity.id] = amenity;
    }
  });

  return highlightsDictionary;
};

export const prettyPrintHighlight = {
  gym: (types = []) => {
    if (types.includes('On-site')) {
      return 'Gym';
    }

    // Fallback in case we're missing an option;
    return types.join(', ');
  },
  laundry: (types = []) => {
    if (types.includes('In Unit')) {
      return 'In-unit washer / dryer';
    }
    if (types.includes('Shared')) {
      return 'On-site laundry';
    }
    if (types.includes('No laundry')) {
      return 'No laundry';
    }

    // Fallback in case we're missing an option;
    return types.join(', ');
  },
  pets: (types = []) => {
    if (types.includes('Cats and Dogs Allowed')) {
      return 'Cats and dogs allowed';
    }
    if (types.includes('Cats and Small Dogs Allowed')) {
      return 'Cats and small dogs allowed';
    }
    if (types.includes('No Pets Allowed')) {
      return 'No pets allowed';
    }

    // Fallback in case we're missing an option;
    return types.join(', ');
  },
  pool: (types = []) => {
    if (types.includes('On-site')) {
      return 'Pool';
    }

    // Fallback in case we're missing an option;
    return types.join(', ');
  },
};

export const getExpandedAmenityDetails = (amenityId = '', amenityType = '') => {
  if (amenityId === 'hvac') {
    if (amenityType.toLowerCase().includes('a/c')) {
      return {
        icon: 'cooling',
        description: amenityType,
      };
    }

    if (amenityType.toLowerCase().includes('heating')) {
      return {
        icon: 'heating',
        description: amenityType,
      };
    }
  }

  if (amenityId === 'pets') {
    if (amenityType.toLowerCase().includes('cats allowed')) {
      return {
        icon: 'cats',
        description: 'Cats allowed',
      };
    }

    return {
      icon: 'dogs',
      description: prettyPrintHighlight.pets([amenityType]),
    };
  }
};
