import type { ClickstreamEvent } from 'app/types';

// @ts-expect-error TODO: Need to properly type store
import { store } from 'app/shared/flux/store/configureStore';
import { Envelope } from './Envelope';
import { setPropertyInfoBlock } from './contextual-blocks/PropertyInfoBlock';

export const TrackTourRequestSubmit = ({ rentalSubmitId }: { rentalSubmitId: string }) => {
  // @ts-expect-error TODO: Need to properly type store
  const state = store.getState();
  const listing = state.currentListingDetails.currentListing;

  const csPayload: ClickstreamEvent = {
    envelope: new Envelope({
      eventId: '5986',
      eventVersion: '2',
      templateId: '68',
      templateVersion: '1',
    }),
    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: 'home_details',
      trigger_source_nm: 'button_to_submit_instant_tour',
      trigger_object_nm: 'home_details_component|instant_tour_modal',
    },
    semantic: {
      semantic_event_nm: 'instant_tour_submit',
    },
    contact_request_form: { rental_submit_id: rentalSubmitId },
  };

  if (listing) {
    csPayload.property_info = setPropertyInfoBlock(listing);
  }

  return csPayload;
};

export const TrackTourRequestFailed = () => {
  // @ts-expect-error TODO: Need to properly type store
  const state = store.getState();
  const listing = state.currentListingDetails.currentListing;

  const csPayload: ClickstreamEvent = {
    envelope: new Envelope({
      eventId: '5988',
      eventVersion: '1',
      templateId: '4',
      templateVersion: '1',
    }),
    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: 'home_details',
      trigger_source_nm: 'button_to_submit_instant_tour',
      trigger_object_nm: 'home_details_component|instant_tour_modal',
    },
    semantic: {
      semantic_event_nm: 'instant_tour_failure',
    },
  };

  if (listing) {
    csPayload.property_info = setPropertyInfoBlock(listing);
  }

  return csPayload;
};

export const TrackTourRequestSuccess = () => {
  // @ts-expect-error TODO: Need to properly type store
  const state = store.getState();
  const listing = state.currentListingDetails.currentListing;

  const csPayload: ClickstreamEvent = {
    envelope: new Envelope({
      eventId: '5987',
      eventVersion: '1',
      templateId: '4',
      templateVersion: '1',
    }),
    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: 'home_details',
      trigger_source_nm: 'button_to_submit_instant_tour',
      trigger_object_nm: 'home_details_component|instant_tour_modal',
    },
    semantic: {
      semantic_event_nm: 'instant_tour_success',
    },
  };

  if (listing) {
    csPayload.property_info = setPropertyInfoBlock(listing);
  }

  return csPayload;
};
