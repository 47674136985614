import React from 'react';

import Container from 'app/shared/core/Container';
import HdpContentWrapper from 'app/shared/modules/hdp/HdpContentWrapper';
import SatelliteMsgStatus from 'app/shared/modules/satellite/SatelliteMsgStatus';
import './style.scss';

const HdpSatelliteWrapper = () => {
  return (
    <HdpContentWrapper fullContent active>
      <Container className="HdpSatelliteWrapper">
        <SatelliteMsgStatus />
      </Container>
    </HdpContentWrapper>
  );
};

export default HdpSatelliteWrapper;
