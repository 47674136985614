// @ts-nocheck
/* eslint-enable */
/**
 * Component for parsing and rendering an encoded Polyline on a Google Map.
 * https://developers.google.com/maps/documentation/utilities/polylinealgorithm
 */

import React from 'react';
import PropTypes from 'prop-types';

import { wrappedPromise } from 'app/client/google/utils';

export class Polyline extends React.Component {
  static displayName = 'Polyline';

  static propTypes = {
    map: PropTypes.object,
    path: PropTypes.string,
    strokeColor: PropTypes.string,
    strokeOpacity: PropTypes.number,
    strokeWeight: PropTypes.number,
  };

  static defaultProps = {
    map: null,
    path: null,
    strokeColor: '#189c88',
    strokeOpacity: 1.0,
    strokeWeight: 4,
  };

  componentDidMount() {
    this.markerPromise = wrappedPromise();
    this.renderPolyline();
  }

  componentDidUpdate(prevProps) {
    const { map, path } = this.props;
    if (map !== prevProps.map || path !== prevProps.path) {
      if (this.polyline) {
        this.polyline.setMap(null);
      }
      this.renderPolyline();
    }
  }

  componentWillUnmount() {
    if (this.polyline) {
      this.polyline.setMap(null);
    }
  }

  renderPolyline() {
    const { map, path, strokeColor, strokeOpacity, strokeWeight } = this.props;

    if (!path) {
      return this.markerPromise.resolve();
    }

    this.polyline = new window.google.maps.Polyline({
      path: window.google.maps.geometry.encoding.decodePath(path),
      geodesic: true,
      map,
      strokeColor,
      strokeOpacity,
      strokeWeight,
    });

    this.markerPromise.resolve(this.polyline);
  }

  render() {
    return null;
  }
}

export default Polyline;
