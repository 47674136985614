// @ts-nocheck
/* eslint-enable */
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import HdpContentWrapper from '../../HdpContentWrapper';
import { font } from 'app/shared/styles/_fontsize';

const BrokerText = styled.p`
  ${font.md};

  ${(props) =>
    props.paddingBottom &&
    `
        padding-bottom: 8px;
    `}
`;

const BrokerListedBy = () => {
  const listing = useSelector((state) => state.currentListingDetails.currentListing);
  const { details = {}, broker = {}, active: isListingActive } = listing;
  const { isBrokerExclusiveListing } = broker;
  const { isMls, mlsName, mlsLogo, disclaimer } = details;

  const renderBrokerDetails = () => {
    return (
      <HdpContentWrapper header="Listing provided by" headerHtmlTag="h2" active>
        <address>
          <BrokerText>
            {broker.contactName} {broker.agentLicenseType && <span> | {broker.agentLicenseType} </span>}
          </BrokerText>
          <BrokerText>{broker.companyName}</BrokerText>
          {isListingActive && <BrokerText>{broker.companyPhoneNumber}</BrokerText>}
        </address>
      </HdpContentWrapper>
    );
  };

  const renderMlsDetails = (header = 'Source') => {
    return (
      <HdpContentWrapper header={header} headerHtmlTag="h2" active>
        <address>
          <BrokerText>{mlsName}</BrokerText>
          {mlsLogo && <img src={mlsLogo} width="100px" />}
          <BrokerText>{disclaimer}</BrokerText>
        </address>
      </HdpContentWrapper>
    );
  };

  if (isBrokerExclusiveListing && isMls) {
    return (
      <>
        {renderBrokerDetails()}
        {renderMlsDetails()}
      </>
    );
  }

  if (!isBrokerExclusiveListing && isMls) {
    return <>{renderMlsDetails('Listing provided by')}</>;
  }

  if (isBrokerExclusiveListing && !isMls) {
    return <>{renderBrokerDetails()}</>;
  }

  if (!isBrokerExclusiveListing && !isMls) {
    return null;
  }
};

export default BrokerListedBy;
