// @ts-nocheck
/* eslint-enable */
const controller = {
  getDotMarkerIcon({ marker, isMobile, zoom }) {
    const markerIcon = {
      anchor: { x: 4, y: 2 },
      url: marker,
    };

    if (isMobile) {
      if (zoom >= 15) {
        markerIcon.scaledSize = { width: 10, height: 10 };
      } else if (zoom >= 14) {
        markerIcon.scaledSize = { width: 6, height: 6 };
      } else {
        markerIcon.scaledSize = { width: 3, height: 3 };
      }
    } else {
      if (zoom > 14) {
        markerIcon.scaledSize = { width: 12, height: 12 };
      } else if (zoom === 14) {
        markerIcon.scaledSize = { width: 10, height: 10 };
      } else if (zoom >= 12) {
        markerIcon.scaledSize = { width: 6, height: 6 };
      } else {
        markerIcon.scaledSize = { width: 3, height: 3 };
      }
    }

    return markerIcon;
  },

  listingInfoWindowContent(listing = {}) {
    let bedAdjustForRoom;
    const hideStreet = listing.address && listing.address.hideStreet;

    if (listing.listingType === 'room') {
      bedAdjustForRoom = 'Room for rent';
    } else if (listing.listingType === 'land') {
      bedAdjustForRoom = 'Land';
    } else {
      bedAdjustForRoom = listing.listingMinMaxPriceBeds.bedDisplay;
    }
    const content = `<b>${hideStreet ? '(Undisclosed Address)' : listing.displayName}<br/>
            ${bedAdjustForRoom}:  ${listing.listingMinMaxPriceBeds.priceDisplay}</b>`;

    return content;
  },
};

export default controller;
