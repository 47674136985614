import React from 'react';
import Dialog from '../../Dialog';
import { Dialog as StyledDialog } from './styles';

interface MapOptionsModalProps {
  ariaLabel: string;
  children: React.ReactNode;
  dialogId: string;
  handleClickAway: () => void;
  onClose: () => void;
}

const MapOptionsModal = (props: MapOptionsModalProps) => {
  return (
    <Dialog DialogComponent={StyledDialog} {...props}>
      {props.children}
    </Dialog>
  );
};

export default MapOptionsModal;
