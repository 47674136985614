// @ts-nocheck
/* eslint-enable */
// App
import React from 'react';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

// Components
import StreetViewError from 'app/shared/modules/photo-gallery/StreetViewError';

// Misc / Utils

import gmapInit from 'app/client/utils/map/gmapInit';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import { getGlobalLogger } from '@zg-rentals/logger-browser';
import { logError } from '@zg-rentals/log-error';

const logger = getGlobalLogger('google/streetview');

class StreetView extends React.Component {
  static propTypes = {
    gmapApiReady: PropTypes.bool,
    apiKey: PropTypes.string.isRequired,
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
    height: PropTypes.string,
  };

  static defaultProps = {
    gmapApiReady: false,
    height: '100%',
  };

  constructor() {
    super();
    this.directionsService = null;
    this.geocoderService = null;
    this.geocoderLatLng = null;
    this.streetView = null;
    this.streetViewService = null;
    this.state = {
      hasStreetViewError: false,
    };
  }

  componentDidMount() {
    const { apiKey } = this.props;
    gmapInit({ key: apiKey });
    this.initialize();
  }

  componentDidUpdate(prevProps) {
    const { gmapApiReady } = this.props;
    if (gmapApiReady !== prevProps.gmapApiReady) {
      this.initialize();
    }
  }

  componentWillUnmount() {
    if (this.streetView) {
      window.google.maps.event.clearInstanceListeners(this.streetView);
    }
  }

  initialize() {
    const { address = {}, lat, lng, gmapApiReady } = this.props;
    const { street = '', city = '', state = '', zip = '' } = address;
    const container = ReactDOM.findDOMNode(this);
    const fullAddress = `${street}, ${city}, ${state}, ${zip}`;

    if (!container || !gmapApiReady || !window.google || this.streetView !== null) {
      return;
    }

    this.geocoderService = new window.google.maps.Geocoder();
    this.directionsService = new window.google.maps.DirectionsService();
    this.streetViewService = new window.google.maps.StreetViewService();

    /**
     * To avoid "Back-alley" street view situations, it hasn't been enough to simply feed
     * our listing's lat/lng info into Google's `google.maps.LatLng(lat, lng)` function.
     *
     * Additionally, using Google's Geocoding service hasn't yielded the streetview
     * results that we expect (we still get back-alley situations... problematic for paid listings).
     *
     * The code below has been modified to follow this roadmap:
     *
     * Use the directions service to get directions from the desired address to itself.
     * Use that location instead of the geocoder result for the street view location.
     * Use the geocoder result (hopefully a ROOFTOP accuracy result) for the place to look "at".
     *
     * "This is a clever solution, which works on the principle that Directions API will route
     * you to the entrance of the place you want to look at."
     *
     * Adapted from:
     * @see https://stackoverflow.com/questions/31176327/request-main-road-curbside-streetview-panoramas-instead-of-back-alleys-from-ap
     */

    if (isEmpty(street) && isEmpty(city) && isEmpty(state)) {
      // We should never reach this point! All listings SHOULD have an address.
      const desiredLatLng = new window.google.maps.LatLng(lat, lng);
      return this.getPanorama(desiredLatLng);
    }

    // STEP #1: Get geocode latlng and store it to this.geocoderLatLng
    this.geocoderService.geocode(
      {
        address: fullAddress,
      },
      (results, geocoderStatus) => {
        if (geocoderStatus !== window.google.maps.GeocoderStatus.OK) {
          logError({ error: 'geocoderService.geocode', errorType: 'GoogleMapsError', context: { geocoderStatus } });
          this.setState({
            hasStreetViewError: true,
          });
          return;
        }

        this.geocoderLatLng = results[0].geometry.location;

        const request = {
          origin: fullAddress,
          destination: fullAddress,
          travelMode: window.google.maps.DirectionsTravelMode.DRIVING,
        };

        // STEP #2: Get driving directions to desired address; this will provide the proper streetView in STEP #3
        this.directionsService.route(request, (response, directionsStatus) => {
          if (directionsStatus !== window.google.maps.DirectionsStatus.OK) {
            logError({ error: 'directionsService.route', errorType: 'GoogleMapsError', context: { directionsStatus } });
            this.setState({
              hasStreetViewError: true,
            });
            return;
          }

          const directionsLatLng = response.routes[0].legs[0].start_location;

          // Call STEP #3 with directionsLatLng
          this.getPanorama(directionsLatLng);
        });
      },
    );
  }

  // STEP #3: Use directionsLatLng to set proper streetView (front-door of property and not back-alley) and use headingLatLng to orient where to "look at"
  getPanorama = (directionsLatLng) => {
    const container = ReactDOM.findDOMNode(this);
    const headingLatLng = isNil(this.geocoderLatLng) ? directionsLatLng : this.geocoderLatLng;

    this.streetViewService.getPanorama(
      {
        location: directionsLatLng,
        radius: 200,
        source: 'outdoor',
      },
      (panoramaData, status) => {
        if (status !== window.google.maps.StreetViewStatus.OK) {
          logError({ error: 'streetViewService.getPanorama', errorType: 'GoogleMapsError', context: { status } });
          this.setState({
            hasStreetViewError: true,
          });
          return;
        }

        // panoId is a session-stable reference to the queried street view
        const panoId = panoramaData.location.pano;
        const heading = window.google.maps.geometry.spherical.computeHeading(
          panoramaData.location.latLng,
          headingLatLng,
        );
        this.streetView = new window.google.maps.StreetViewPanorama(container, {
          pano: panoId,
          position: directionsLatLng,
          pov: {
            heading,
            pitch: 0,
          },
          addressControl: false,
          linksControl: false,
          visible: true,
          zoom: 0,
        });
      },
    );
  };

  render() {
    const { className, height, width } = this.props;
    const { hasStreetViewError } = this.state;
    if (hasStreetViewError) {
      return <StreetViewError />;
    }
    const styleProps = {
      height,
      ...(width && { width }),
    };

    return <div className={className} style={{ ...styleProps }} />;
  }
}

const mapStateToProps = (state) => ({
  address: state.currentListingDetails.currentListing.address,
  apiKey: state.app.googleMaps.key,
  gmapApiReady: state.app.gmapApiReady,
});

export default connect(mapStateToProps)(StreetView);
