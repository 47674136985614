// @ts-nocheck
/* eslint-enable */
import React from 'react';
import omit from 'lodash/omit';
import PropTypes from 'prop-types';
import * as S from './styles';

const { bool, func, element, string, oneOf, any } = PropTypes;

const ButtonV2 = React.forwardRef((props = {}, ref) => {
  const rest = omit(props, [
    'bold',
    'btnType',
    'children',
    'className',
    'dispatch',
    'full',
    'hideInputBorder',
    'inputButtonGroupPosition',
    'rounded',
    'size',
    'htmlTag',
    'icon',
    'iconGap',
    'onClick',
  ]);

  const {
    bold,
    btnType,
    children,
    className,
    disabled,
    full,
    hideInputBorder,
    htmlTag: CustomTag,
    inputButtonGroupPosition,
    rounded,
    size,
    icon,
    iconGap,
    onClick,
  } = props;

  return (
    <S.Button
      {...rest}
      as={CustomTag}
      size={size}
      full={full}
      inputButtonGroupPosition={inputButtonGroupPosition}
      hideInputBorder={hideInputBorder}
      btnType={btnType}
      bold={bold}
      disabled={disabled}
      rounded={rounded}
      className={className}
      ref={ref}
      onClick={onClick}
    >
      <S.ButtonContentContainer iconGap={iconGap}>
        {icon}
        {children}
      </S.ButtonContentContainer>
    </S.Button>
  );
});

ButtonV2.propTypes = {
  btnType: oneOf([
    'primary',
    'secondary',
    'tertiary',
    'text-color',
    'text-color-outline',
    'text-color-light',
    'default',
    'warning',
    'alert-secondary',
    'primary-outline',
    'primary-inactive',
    'dark',
  ]),
  children: any,
  className: string,
  disabled: bool,
  full: bool,
  htmlTag: string,
  rounded: bool,
  hideInputBorder: bool,
  inputButtonGroupPosition: string,
  size: oneOf(['sm', 'md', 'lg']),
  bold: bool,
  icon: element,
  iconGap: oneOf(['', 'none', 'xs', 'sm']),
  onClick: func,
};

ButtonV2.defaultProps = {
  btnType: 'primary',
  children: null,
  className: '',
  disabled: false,
  htmlTag: 'button',
  full: false,
  rounded: false,
  hideInputBorder: false,
  inputButtonGroupPosition: null,
  size: 'md',
  bold: false,
  icon: null,
  iconGap: 'sm',
  onClick: () => {},
};

export default ButtonV2;
