import React from 'react';
import TourStatusModule from 'app/shared/modules/hdp/InstantTour/components/TourStatusModule';
import * as S from './styles';

const SchedulingErrorStep = () => {
  return (
    <S.SchedulingErrorStepContainer aria-live="assertive">
      <S.Header size="xl">Something went wrong</S.Header>
      <TourStatusModule />
    </S.SchedulingErrorStepContainer>
  );
};

export default SchedulingErrorStep;
