// @ts-nocheck
/* eslint-enable */
import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { yieldCallback } from '@zillow/yield-callback';

import Animation from 'app/shared/modules/Animation';
import animationData from 'images/animations/alertAnimationMap.json';
import { analyticsEvent } from 'app/client/universal-analytics';
import AppActions from 'app/shared/flux/actions/AppActions';
import { gaEvents } from 'app/shared/constants/AnalyticsConstants';
import GeolocationButton from 'app/shared/modules/location/GeolocationButton';
import SearchActions from 'app/shared/flux/actions/SearchActions';
import './style.scss';
import MapOptionsButton from '../MapOptionsButton';
import { TrackOpenSearchAlertModal } from 'app/shared/models/Clickstream/SearchAlertEvents';

class GoogleMapButtonContainerV2 extends React.Component {
  static propTypes = {
    device: PropTypes.object,
    map: PropTypes.object,
  };

  static defaultProps = {
    device: null,
    map: null,
  };

  constructor(props) {
    super(props);
  }

  handleGeolocationSuccess = (position = {}) => {
    const { dispatch } = this.props;
    dispatch(analyticsEvent(gaEvents.SEARCH_CURRENT_GEOLOCATION));
    dispatch(
      SearchActions.goToGeolocation({
        lat: position.coords.latitude,
        lon: position.coords.longitude,
        zoom: 15,
      }),
    );
  };

  handleAlertButtonClick = yieldCallback(() => {
    const { dispatch } = this.props;
    dispatch(analyticsEvent(gaEvents.OPEN_SEARCH_ALERT_MODAL_MAP, { newLaneEvent: TrackOpenSearchAlertModal() }));
    dispatch(AppActions.toggleOverlay('createSearchAlert', true));
  });

  render() {
    const { device, isMobile, previewListing } = this.props;

    return (
      <div
        className={cx('GoogleMapButtonContainer-layer-options', {
          'GoogleMapButtonContainer-layer-adjust': isMobile && previewListing,
        })}
      >
        {device.screenWidth === 'sm' && (
          <button className="GoogleMap-layer-btn GoogleMap-layer-icon" onClick={this.handleAlertButtonClick}>
            <Animation
              className="GoogleMap-search-alert-icon"
              shouldLoop
              shouldAutoplay
              animationData={animationData}
            />
          </button>
        )}
        <MapOptionsButton map={this.props.map} />
        <GeolocationButton size="sm" onSuccess={this.handleGeolocationSuccess} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    device: state.app.device,
    isMobile: state.app.device.screenWidth === 'sm',
  };
};

export default connect(mapStateToProps)(GoogleMapButtonContainerV2);
