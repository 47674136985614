// @ts-nocheck
/* eslint-enable */
import React from 'react';
import Container from 'app/shared/core/Container';
import Row from 'app/shared/core/Row';
import UserRoleRequired from 'app/shared/modules/UserRoleRequired';
import constants from 'app/shared/constants/ConstantsBundle';
import IconArrowRight from 'images/icons/arrow-right-teal.svg';
import Linker from 'app/shared/modules/Linker';
import Text from 'app/shared/core/Text';

const RepHdpLink = ({ listing }) => {
  return (
    <UserRoleRequired rep>
      <div className="RepHdpLink">
        <Container>
          <Text htmlTag="h2" size="lg">
            Reps only
          </Text>
          <Row size="md">
            <Linker
              to={constants.REP_HDP_URI + listing.aliasEncoded}
              target="_blank"
              style={{ display: 'inlineBlock' }}
            >
              Go to listing tools <img src={IconArrowRight} style={{ height: '16px', width: '16px' }} />
            </Linker>
          </Row>
        </Container>
      </div>
    </UserRoleRequired>
  );
};

export default RepHdpLink;
