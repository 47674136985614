// @ts-nocheck
/* eslint-enable */
// App
import React from 'react';

// Icons
import IconStarYellow from 'images/icons/star-filled.svg';
import IconStarOutlineYellow from 'images/icons/star-empty.svg';

// Misc / utils
import { getStarImgCX, starContainerCX } from './styles';

const StarRating = ({ totalStars, filledStars }) => {
  const starArr = new Array(totalStars).fill('*');

  return (
    <span
      className={starContainerCX}
      data-name="StarContainer"
      role="img"
      aria-label={`Rating: ${filledStars} out of 5 stars`}
    >
      {starArr.map((_, idx) => {
        const isLastStar = idx === starArr.length - 1;

        if (idx < Math.round(filledStars)) {
          return (
            <img
              alt=""
              aria-hidden="true"
              className={getStarImgCX(isLastStar)}
              data-name="Filled Star"
              key={`filled-star-number-${idx}`}
              src={IconStarYellow}
              height={12}
              width={12}
            />
          );
        }

        return (
          <img
            alt=""
            aria-hidden="true"
            className={getStarImgCX(isLastStar)}
            data-name="Outline Star"
            key={`outline-star-number-${idx}`}
            src={IconStarOutlineYellow}
            height={12}
            width={12}
          />
        );
      })}
    </span>
  );
};

export { StarRating };
export default StarRating;
