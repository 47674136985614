// @ts-nocheck
/* eslint-enable */
import includes from 'lodash/includes';
import numberUtils from 'app/shared/utils/numberUtils';
import sortBy from 'lodash/sortBy';
import { adapt_reduxToJava } from 'app/shared/flux/actions/FilterActions/adapters';

const controller = {
  unitMatchesFilter({ unit, filter }) {
    const adaptedFilter = adapt_reduxToJava({ filter });
    const lowPrice = Number(adaptedFilter.lowPrice);
    const highPrice = Number(adaptedFilter.highPrice);
    const minSqft = Number(adaptedFilter.minSqft);
    const maxSqft = Number(adaptedFilter.maxSqft);

    const unitBeds = unit.listingMinMaxPriceBeds.minBeds;
    const unitBaths = unit.listingMinMaxPriceBeds.minBaths;

    const unitMinPrice = unit.listingMinMaxPriceBeds.minPrice;
    const unitMaxPrice = unit.listingMinMaxPriceBeds.maxPrice;

    const unitMinSqft = unit.listingMinMaxPriceBeds.minSqft;
    const unitMaxSqft = unit.listingMinMaxPriceBeds.maxSqft;

    // Adapt some filter types into an array for easier comparison
    const arrayAdaptedListingTypes = adaptedFilter.listingTypes.split(',');
    const arrayAdaptedBeds = adaptedFilter.bedrooms.split(',');
    const arrayAdaptedBaths = adaptedFilter.bathrooms.split(',');

    // Listing type
    const shouldUnitBeFilteredBasedOnListingTypes = !includes(arrayAdaptedListingTypes, unit.listingType);

    // Beds
    const bedsFromFilter = arrayAdaptedBeds.map((bed) => Number(bed));
    const currentUnitBeds = Number(unitBeds);
    const shouldUnitBeFilteredBasedOnBeds = !includes(bedsFromFilter, currentUnitBeds);
    const firstBedFilterSelection = Number(arrayAdaptedBeds[0]);
    const doesNotInclude8PlusBeds = !includes(arrayAdaptedBeds, '8plus');

    // Baths
    const bathsFromFilter = arrayAdaptedBaths.map((bath) => Number(bath));
    const currentUnitBaths = Number(unitBaths);
    const shouldFloorplanBeFilteredBasedOnBaths = !includes(bathsFromFilter, currentUnitBaths);
    const firstBathFilterSelection = Number(arrayAdaptedBaths[0]);
    const doesNotInclude8PlusBaths = !includes(arrayAdaptedBaths, '8plus');

    if (shouldUnitBeFilteredBasedOnListingTypes) {
      return false;
    }

    if (shouldUnitBeFilteredBasedOnBeds && (doesNotInclude8PlusBeds || currentUnitBeds < firstBedFilterSelection)) {
      return false;
    }

    if (
      shouldFloorplanBeFilteredBasedOnBaths &&
      (doesNotInclude8PlusBaths || currentUnitBaths < firstBathFilterSelection)
    ) {
      return false;
    }

    if (lowPrice && highPrice) {
      if (unitMinPrice < lowPrice && unitMaxPrice < lowPrice) {
        return false;
      } else if (unitMinPrice > highPrice) {
        return false;
      }
    }

    if (highPrice && unitMinPrice > highPrice) {
      return false;
    }

    if (lowPrice && unitMinPrice < lowPrice) {
      return false;
    }

    if (minSqft && Number(unitMinSqft) < minSqft) {
      return false;
    }

    if (maxSqft && Number(unitMaxSqft) > maxSqft) {
      return false;
    }

    return true;
  },
  getMinMaxPriceOfUnits(units) {
    let lowPrice = Infinity;
    let highPrice = 0;

    units.forEach((unit) => {
      const unitMinPrice = unit.listingMinMaxPriceBeds.minPrice;
      const unitMaxPrice = unit.listingMinMaxPriceBeds.maxPrice;
      if (unitMinPrice <= lowPrice) {
        lowPrice = unitMinPrice;
      }
      if (unitMaxPrice >= highPrice) {
        highPrice = unitMaxPrice;
      }
    });

    if (lowPrice === highPrice) {
      return '$' + numberUtils.compact(lowPrice);
    } else {
      return `$${numberUtils.compact(lowPrice)}—$${numberUtils.compact(highPrice)}`;
    }
  },
  getUnitsByType({ building, filter }) {
    let rentUnits = [];
    let matchingUnitsRoom = [];
    let matchingUnits0bed = [];
    let matchingUnits1bed = [];
    let matchingUnits2bed = [];
    let matchingUnits3bed = [];
    let matchingUnits4bedPlus = [];
    let matchingUnits = [];

    building.units.forEach((unit) => {
      rentUnits.push(unit);

      if (controller.unitMatchesFilter({ unit, filter })) {
        matchingUnits.push(unit);
        const unitBeds = unit.listingMinMaxPriceBeds.minBeds;
        if (unit.listingType === 'room') {
          matchingUnitsRoom.push(unit);
        } else if (unitBeds === 0) {
          matchingUnits0bed.push(unit);
        } else if (unitBeds === 1) {
          matchingUnits1bed.push(unit);
        } else if (unitBeds === 2) {
          matchingUnits2bed.push(unit);
        } else if (unitBeds === 3) {
          matchingUnits3bed.push(unit);
        } else {
          matchingUnits4bedPlus.push(unit);
        }
      }
    });

    rentUnits = sortBy(rentUnits, (unit) => unit.listingMinMaxPriceBeds.priceDisplay);
    matchingUnitsRoom = sortBy(matchingUnitsRoom, (unit) => unit.listingMinMaxPriceBeds.priceDisplay);
    matchingUnits0bed = sortBy(matchingUnits0bed, (unit) => unit.listingMinMaxPriceBeds.priceDisplay);
    matchingUnits1bed = sortBy(matchingUnits1bed, (unit) => unit.listingMinMaxPriceBeds.priceDisplay);
    matchingUnits2bed = sortBy(matchingUnits2bed, (unit) => unit.listingMinMaxPriceBeds.priceDisplay);
    matchingUnits3bed = sortBy(matchingUnits3bed, (unit) => unit.listingMinMaxPriceBeds.priceDisplay);
    matchingUnits4bedPlus = sortBy(matchingUnits4bedPlus, (unit) => unit.listingMinMaxPriceBeds.priceDisplay);
    matchingUnits = sortBy(matchingUnits, (unit) => unit.listingMinMaxPriceBeds.priceDisplay);

    return {
      matchingUnits,
      rentUnits,
      matchingUnitsRoom,
      matchingUnits0bed,
      matchingUnits1bed,
      matchingUnits2bed,
      matchingUnits3bed,
      matchingUnits4bedPlus,
    };
  },
};

export default controller;
