// @ts-nocheck
/* eslint-enable */
import styled from 'styled-components';
import { colors } from 'app/shared/styles/_colors';
import { font } from 'app/shared/styles/_fontsize';

export const EditTourContainer = styled.div`
  padding: 16px;
`;

export const GreyDivider = styled.div`
  height: 16px;
  background: ${colors['$hpx-grey-100']};
`;

export const Header = styled.h3`
  ${font.xl}
  font-weight: bold;
`;

export const RadiosContainer = styled.div`
  padding-bottom: 15px;
  align-self: flex-start;
`;

export const ContainerItem = styled.div`
  margin: 15px 0px;
`;

export const TourIconAndLabel = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

export const IconAndTitle = styled.div`
  display: flex;
  align-items: center;
`;

export const LabelIcon = styled.img`
  height: 20px;
  width: 20px;
  margin-right: 5px;
`;
