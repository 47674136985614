// @ts-nocheck
/* eslint-enable */
import React, { Component } from 'react';

import IconGallery from 'images/icons/gallery-white.svg';
import IconMail from 'images/icons/mail-white.svg';
import './style.scss';

class Iframe3dTours extends Component {
  render() {
    const { url, onClickContact, onGalleryOpen } = this.props;
    return (
      <div className="Iframe3dTours">
        <div className="Iframe3dTours-nav-btns">
          <div className="Iframe3dTours-btn" onClick={onClickContact}>
            <img className="Iframe3dTours-contact-icon" src={IconMail} />
            <span className="Iframe3dTours-btn-text">Contact now</span>
          </div>
          <div className="Iframe3dTours-btn" onClick={onGalleryOpen}>
            <img className="Iframe3dTours-gallery-icon" src={IconGallery} />
            <span className="Iframe3dTours-btn-text">Photo gallery</span>
          </div>
        </div>
        <div className="Iframe3dTours-iframe-container">
          <iframe src={url} title="3D Tour" width="100%" height="100%" referrerPolicy="no-referrer-when-downgrade" />
        </div>
      </div>
    );
  }
}

export default Iframe3dTours;
