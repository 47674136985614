// @ts-nocheck
/* eslint-enable */
// Misc / Utils
import genericUtils from 'app/shared/utils/genericUtils';
import { listingUtils_getDistanceToUserPoint } from 'app/shared/utils/listingUtils';
import numberUtils from 'app/shared/utils/numberUtils';

// Lodash
import assign from 'lodash/assign';
import forEach from 'lodash/forEach';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import reduce from 'lodash/reduce';

const BEDROOM_MAP = {
  0: 'Studio',
  1: '1 bed',
  2: '2 beds',
  3: '3 beds',
  4: '4+ beds',
};

const controller = {
  /**
   * Creates the comparison table categories
   */
  getComparisonCategories(listings) {
    const staticCategories = ['Distance', 'Pets', 'Laundry', 'Security', 'A/C', 'Dishwasher', 'Parking', 'Gym', 'Pool'];
    const dynamicPricingCategories = map(controller.getMinMaxBedsOfAllListings(listings), (val) => {
      return val;
    });

    /**
     * All pricing categories should be lumped into one broad category, which
     * is why we're inserting it as an array.
     */
    const allCategories = [dynamicPricingCategories, ...staticCategories];

    return allCategories;
  },
  /**
   * Determines the straight line distance between the current listing and the similar
   * listings.
   */
  getDistance(currentListing, similarListing) {
    const locationParams = {
      originLat: currentListing.geo.lat,
      originLon: currentListing.geo.lon,
      lat0: similarListing.lat,
      lon0: similarListing.lon,
    };
    if (locationParams.originLat === locationParams.lat0 || locationParams.originLon === locationParams.lon0) {
      return null;
    }

    const distanceToListingNum = listingUtils_getDistanceToUserPoint(locationParams);

    // If distance is 0 (point of origin)
    if (distanceToListingNum < 0.1) {
      return null;
      // If distance is 1 mile away
    } else if (distanceToListingNum.toFixed(1) === '1.0') {
      const distanceToListingStr = distanceToListingNum.toFixed(0);
      return `${distanceToListingStr} mile away`;
    } else {
      // If distance is > 1 mile away
      const distanceToListingStr = distanceToListingNum.toFixed(1);
      return `${distanceToListingStr} miles away`;
    }
  },
  /**
   * Will need to adapt `newListingsArray` data to fit required data shape for
   * the table component `ComparisonTable`
   */
  getFormattedTableData(currentListing, newListingsArray) {
    const minMaxBedsOfAllListings = controller.getMinMaxBedsOfAllListings(newListingsArray);

    return map(newListingsArray, (listing) => {
      const { displayName, uriV2, address } = listing;
      const hideStreet = address && address.hideStreet;
      const previewPhotoUrl = listing.previewPhoto.url || '';
      const {
        pets = null,
        laundry = null,
        security = null,
        airConditioning = null,
        dishwasher = null,
        parking = null,
        gym = null,
        pool = null,
      } = listing.amenities.condensedAmenities;
      const modelPriceByNumBeds = controller.getModelPriceByNumBeds(listing, minMaxBedsOfAllListings);
      const distanceToListing = controller.getDistance(currentListing, listing.geo) || null;
      const listingData = {
        // Required fields below
        title: hideStreet ? '(Undisclosed Address)' : displayName,
        image: previewPhotoUrl,
        link: uriV2,
        rawListingDetails: listing,
        // Categories for comparison below
        Distance: distanceToListing,
        Pets: pets,
        Laundry: laundry,
        Security: security,
        'A/C': airConditioning,
        Dishwasher: dishwasher,
        Parking: parking,
        Gym: gym,
        Pool: pool,
      };

      /**
       * Add the dynamic comparison categories here, such as
       * "Studio", "1-bed", "3-beds", etc. These all vary between
       * each cluster of recommended listings!
       */
      return assign(listingData, modelPriceByNumBeds);
    });
  },
  /**
   * Determines how many "dynamic" bedroom categories we should show.
   * This is based on all the listings passed in.
   *
   * i.e.
   * if all listings range from studio to 2-beds, we want to show
   * "studio, 1-bed, 2-beds".
   *
   * if all listings range from 2-beds to 4+beds, we want to show
   * "2-beds, 3-beds, 4+beds"
   */
  getMinMaxBedsOfAllListings(listings) {
    let min = Number.POSITIVE_INFINITY;
    let max = Number.NEGATIVE_INFINITY;

    forEach(listings, (listing) => {
      const currentListingMinBed = listing.listingMinMaxPriceBeds.minBeds;
      const currentListingMaxBed = listing.listingMinMaxPriceBeds.maxBeds;
      if (currentListingMinBed < min) {
        min = currentListingMinBed;
      }

      if (currentListingMaxBed > max) {
        max = currentListingMaxBed;
      }
    });

    const rangeArray = genericUtils.createRangeArray(1, min, max);
    return reduce(
      rangeArray,
      (obj, el) => {
        const currentMap = BEDROOM_MAP[el] || BEDROOM_MAP[4];

        if (el > 4) {
          obj[4] = currentMap;
        } else {
          obj[el] = currentMap;
        }

        return obj;
      },
      {},
    );
  },
  /**
   * Gathers rent pricing for all models based on number of
   * bedrooms.
   */
  getModelPriceByNumBeds(listing, minMaxBeds) {
    /**
     * current listing (model: UnitV2) has `modelsAndPricing` key
     * similar listings (model: SummaryV2) has `models` key
     */
    const models = listing.modelsAndPricing || listing.models;
    const minMaxBedsValues = Object.values(minMaxBeds);
    const tempModelMap = reduce(
      minMaxBedsValues,
      (obj, el) => {
        const defaultValue = null;
        obj[el] = defaultValue;
        return obj;
      },
      {},
    );
    const modelMap = reduce(
      models,
      (obj, el) => {
        const lowPrice = el.lowPrice;
        const highPrice = el.highPrice;
        const currentModelNumBeds = BEDROOM_MAP[el.numBeds] || BEDROOM_MAP[4];

        if (!lowPrice && !highPrice) {
          const noPriceGiven = 'Available';
          obj[currentModelNumBeds] = noPriceGiven;
          return obj;
        }

        const currentModelLowPrice = numberUtils.asCurrency(lowPrice);
        // const currentModelHighPrice = numberUtils.fullNumber(highPrice); // Commented out for now

        obj[currentModelNumBeds] =
          isEqual(lowPrice, highPrice) || isNil(highPrice)
            ? currentModelLowPrice
            : `${currentModelLowPrice}+`; /*`${currentModelLowPrice}-${currentModelHighPrice}`;*/

        return obj;
      },
      tempModelMap,
    );
    return modelMap;
  },
  handleArrowNavClick(direction, xlWidth, refElement) {
    const scrollLeftPosition = refElement.scrollLeft;
    /**
     * The following is based on .ComparisonTable-colgroup-xxx widths
     * If those change in the CSS, these must change as well for
     * consistent button activated scrolling!
     */
    const firstStep = xlWidth ? 563 : 330;
    const subsequentSteps = xlWidth ? 580 : 348;

    if (direction === 'left') {
      controller.sideScroll({
        element: refElement,
        direction: 'left',
        speed: 10,
        distance: scrollLeftPosition === 100 ? firstStep : subsequentSteps,
        step: 10,
      });
    }

    if (direction === 'right') {
      controller.sideScroll({
        element: refElement,
        direction: 'right',
        speed: 10,
        distance: scrollLeftPosition === 0 ? firstStep : subsequentSteps,
        step: 10,
      });
    }
  },
  // sideScroll function from https://stackoverflow.com/a/24636116/7234944
  sideScroll({ element, direction, speed, distance, step }) {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      if (direction === 'left') {
        element.scrollLeft -= step;
      } else {
        element.scrollLeft += step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
    }, speed);
  },
};

export default controller;
