// App
import React from 'react';

// Components
import Text from 'app/shared/core/Text';
import type { TextSizeKeys } from 'app/shared/core/Text/styles';
// Misc / utils
import IconZillowHorizontal from 'images/icons/zillow-horizontal-blue.svg';
import './style.scss';

const POWERED_BY_ZILLOW = 'Powered by';

interface Props {
  size?: TextSizeKeys;
}

const PoweredByZillow = ({ size = 'sm' }: Props = {} as Props) => {
  const IconZillow = (
    <img
      className="PoweredByZillowBadge-icon"
      src={IconZillowHorizontal}
      height="19px"
      width="67px"
      alt="Zillow Logo"
    />
  );

  return (
    <>
      <Text size={size}>{POWERED_BY_ZILLOW}</Text>
      {IconZillow}
    </>
  );
};

export default PoweredByZillow;
