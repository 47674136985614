// @ts-nocheck
/* eslint-enable */
import styled from 'styled-components';
import { colors } from 'app/shared/styles/_colors';
import { variables } from 'app/shared/styles/_variables';
import { viewports } from 'app/shared/styles/_breakpoints';

export const Carousel = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 16px 0 48px;
`;

export const Container = styled.div`
  display: -webkit-box;
  transition: ${({ useTransition }) => (useTransition ? 'all 300ms ease-in-out' : 'none')};
`;

export const CarouselItem = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0 auto;

  @media ${viewports['xl-and-up']} {
    padding: 0 24px;
  }
`;

const ArrowContainer = styled.button`
  position: absolute;
  bottom: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 1px solid ${colors['$hpx-grey-400']};
  cursor: pointer;
  transform: translateY(50%);

  @media (min-width: ${variables['sidebar-width-xl']}) {
    bottom: 50%;
  }

  @media ${viewports['md-and-up']} {
    bottom: 24px;
  }

  @media ${viewports['xl-and-up']} {
    bottom: 50%;
  }

  &:hover {
    background: ${colors['$hpx-grey-100']};
  }
`;

export const Prev = styled(ArrowContainer)`
  left: 16px;

  @media ${viewports['xl-and-up']} {
    left: 32px;
  }
`;

export const Next = styled(ArrowContainer)`
  right: 16px;

  @media ${viewports['xl-and-up']} {
    right: 32px;
  }
`;

export const DotsContainer = styled.div`
  position: absolute;
  bottom: 16px;
  left: 50%;
  transform: translate(-50%, -50%);
`;
