// @ts-nocheck
/* eslint-enable */
import styled from 'styled-components';
import { variables } from 'app/shared/styles/_variables';
import { viewports } from 'app/shared/styles/_breakpoints';
import { stacked } from 'app/shared/styles/_spacing';

// Components
import Button from 'app/shared/core/Button';
import Linker from 'app/shared/modules/Linker';
import Text from 'app/shared/core/Text';

export const Container = styled.div`
  width: 100%;
  max-height: 650px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 16px;

  @media (min-width: ${variables['sidebar-width-xl']}) {
    max-width: 435px;
    padding: 0;
  }

  @media ${viewports['md-and-up']} {
    max-width: 100%;
    padding: 16px;
  }

  @media ${viewports['xl-and-up']} {
    max-width: 435px;
    padding: 0;
  }
`;

export const Slide = styled.div`
  width: 100%;
  max-height: 650px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: lightgray;
`;

export const Image = styled.img`
  ${({ imgFull }) =>
    imgFull &&
    `
        width: 100%;
        margin-bottom: 24px;
    `};

  ${({ imgFull }) =>
    !imgFull &&
    `
        max-width: 248px;
    `};
`;

export const TextContainer = styled.div`
  width: 100%;
  ${stacked._3x};
`;

export const HeaderText = styled(Text)`
  font-weight: bold;
  ${stacked._2x};
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;

export const PrimaryButton = styled(Button)`
  text-decoration: none !important;
  line-height: 36px;
`;

export const SecondaryButton = styled(Linker)`
  margin-left: 24px;
`;

export const ZRMLogoImg = styled.img`
  margin-bottom: 8px;
`;
