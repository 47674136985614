// @ts-nocheck
/* eslint-enable */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Actions
import AppActions from 'app/shared/flux/actions/AppActions';
import ListingEngineActions from 'app/shared/flux/actions/ListingEngineActions';
import UserItemActions from 'app/shared/flux/actions/UserItemActions';

// Components
import BuildingHdp from 'app/shared/modules/hdp/BuildingHdp';
import Hdp from 'app/shared/modules/hdp/Hdp';
import HdpWrapper from 'app/shared/wrappers/HdpWrapper';
import ListingHead from 'app/shared/modules/hdp/ListingHead';
import ListingStructuredData from 'app/shared/modules/hdp/ListingStructuredData';

// Misc / Utils
import BreadcrumbController from 'app/shared/modules/navigation/AreaBreadcrumbs/controller';
import constants from 'app/shared/constants/ConstantsBundle';
import controller from './controller';
import { listingPageView } from 'app/client/universal-analytics';
import './style.scss';
import { TrackViewHdp } from 'app/shared/models/Clickstream/HdpClickstreamEvents';

const { string, object } = PropTypes;

class ListingPage extends React.Component {
  static propTypes = {
    currentListing: object,
    sidebarId: string,
  };

  static defaultProps = {
    currentListing: {},
    sidebarId: '',
  };

  constructor(props) {
    super(props);
    const { dispatch } = this.props;
    dispatch(listingPageView({ newLaneEvent: TrackViewHdp() }));
  }

  componentDidMount = () => {
    const { dispatch, currentListing, isMobile } = this.props;
    const expired = currentListing ? !currentListing.active : '';
    const aliasEncoded = currentListing ? currentListing.aliasEncoded : '';

    if (isMobile) {
      this.scrollToTop();
    }
    this.viewStartTime = Date.now();

    dispatch(UserItemActions.addUserItem('viewed', currentListing));
    dispatch(
      AppActions.sendEventToApi(
        expired ? constants.EXPIRED_LISTING_REALLY_VIEWED : constants.LISTING_REALLY_VIEWED,
        aliasEncoded,
      ),
    );
  };

  componentDidUpdate(prevProps = {}) {
    const { dispatch } = this.props;

    /**
     * Check and see if we're jumping back from one listing to another by
     * checking if Malone IDs are NOT equal
     */
    const currentMaloneLotIdEncoded = this.props.currentListing.maloneLotIdEncoded;
    const prevMaloneLotId = prevProps.currentListing.maloneLotIdEncoded;
    const hasDifferentMaloneLotId = prevMaloneLotId !== currentMaloneLotIdEncoded;

    /**
     * Check and see if we're jumping back from a unit listing to a building
     * by checking if Malone IDs are equal but their building status is
     * NOT equal
     */
    const currentBldgStatus = this.props.currentListing.building;
    const prevBldgStatus = prevProps.currentListing.building;
    const hasDifferentBldgStatus = !hasDifferentMaloneLotId && prevBldgStatus !== currentBldgStatus;

    const trackPageviewOnBack = hasDifferentMaloneLotId || hasDifferentBldgStatus;

    if (trackPageviewOnBack) {
      dispatch(listingPageView());
    }
  }

  componentWillUnmount = () => {
    const { dispatch, currentListing } = this.props;
    dispatch(ListingEngineActions.sendHdpStats(currentListing.aliasEncoded, this.viewStartTime));
  };

  scrollToTop() {
    const sidebar = document.getElementById('SplitMapTemplate-right-sidebar');
    sidebar.scrollTop = 0;
  }

  render() {
    const { area, currentListing, filter, pathname, sidebarId } = this.props;
    const showMultiModels = controller.shouldShowMultiModels(currentListing);
    const breadcrumbs = BreadcrumbController.getBreadcrumbs({
      area,
      filter,
      currentListing,
      pathname,
    });

    return (
      <div className="ListingPage" id="ListingPage">
        <ListingStructuredData breadcrumbs={breadcrumbs} />
        <ListingHead />
        {currentListing?.isBuilding ? (
          <BuildingHdp />
        ) : (
          <HdpWrapper>
            <Hdp sidebarId={sidebarId} showMultiModels={showMultiModels} />
          </HdpWrapper>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    area: state.area.area,
    byCoordsListings: state.listings.listingGroups.byCoords,
    currentListing: state.currentListingDetails.currentListing,
    filter: state.filter,
    isMobile: state.app.device.screenWidth === 'sm',
    pathname: state.location.current.pathname,
  };
};

export default connect(mapStateToProps)(ListingPage);
