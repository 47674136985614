// @ts-nocheck
/* eslint-enable */
// Misc / utils
import StaticMap from 'app/shared/modules/map/StaticMap';
import getGmapTheme from 'app/client/utils/map/gmapTheme';
import DISPATCH_CONSTANTS from 'app/shared/constants/DispatchConstants';
import routeUtils from 'app/shared/utils/routeUtils';

const PLACEHOLDER_MAP = {
  3: '',
  4: '',
  5: '',
  6: '',
  7: '',
  8: '',
  9: '',
  10: '',
  11: '',
  12: '',
  13: '',
  14: '',
  15: '',
  16: '',
  17: '',
  18: '',
};

const _getLatLon = ({ area, query }) => {
  const hasLatAndLonInQuery = routeUtils.hasLatAndLon(query);
  if (hasLatAndLonInQuery) {
    return {
      lat: Number(query.lat),
      lon: Number(query.lon),
    };
  } else {
    return {
      lat: Number(area.coordinates.lat),
      lon: Number(area.coordinates.lon),
    };
  }
};

export const MapActions_fetchStaticMap = ({ area, query }) => {
  return async (dispatch) => {
    const latLonDataSansZoom = _getLatLon({ area, query });
    const zoomLevels = Object.keys(PLACEHOLDER_MAP);
    const staticMapPromises = zoomLevels.map((zoom) => {
      const staticMap = new StaticMap({});
      const defaultHeight = 720;
      const defaultWidth = 962;

      staticMap
        .centerByLatAndLon(latLonDataSansZoom.lat, latLonDataSansZoom.lon)
        .size(defaultWidth, defaultHeight)
        .zoom(zoom)
        .styleFromConfig(getGmapTheme())
        .style({
          feature: 'all',
          element: 'labels',
          visibility: 'off',
        });

      return dispatch(staticMap.generate()).then((res) => {
        const { data } = res;
        const { signedUrl } = data;

        return (PLACEHOLDER_MAP[zoom] = signedUrl);
      });
    });

    await Promise.all(staticMapPromises).then(() => {
      // Promises have resolved
      dispatch(MapActions_saveStaticMap(PLACEHOLDER_MAP));
    });
  };
};

export const MapActions_saveStaticMap = (placeholders) => {
  return (dispatch) => {
    dispatch({
      type: DISPATCH_CONSTANTS.SAVE_STATIC_MAP_PLACEHOLDERS,
      payload: placeholders,
    });
  };
};
