// @ts-nocheck
/* eslint-enable */
import React from 'react';
import { connect } from 'react-redux';
import map from 'lodash/map';
import PropTypes from 'prop-types';

import { analyticsEvent } from 'app/client/universal-analytics';
import { gaEvents } from 'app/shared/constants/AnalyticsConstants';
import Autocomplete from 'app/shared/modules/Autocomplete';
import constants from 'app/shared/constants/ValueConstants';
import googleAttribution from 'images/googleAttribution.png';
import UserPointActions from 'app/shared/flux/actions/UserPointActions';
import gmapInit from 'app/client/utils/map/gmapInit';
import './style.scss';

const { ESC_KEYCODE } = constants;

class CommuteAutocomplete extends React.Component {
  static propTypes = {
    focusInput: PropTypes.bool,
    handleSelectUserPoint: PropTypes.func,
    inputSize: PropTypes.string,
    userPoint: PropTypes.object,
  };

  static defaultProps = {
    focusInput: false,
    handleSelectUserPoint: () => {},
    inputSize: 'lg',
  };

  constructor(props) {
    super(props);

    this.state = {
      address: '',
      placeholder: props.userPoint && props.userPoint.address ? props.userPoint.address : 'Enter an address',
      predictions: [],
      showDropdown: false,
    };

    this.dropdownRef = React.createRef();
  }

  componentDidMount() {
    const { googleMapsKey } = this.props;

    if (this.props.gmapLoaded) {
      this.autocompleteService = new window.google.maps.places.AutocompleteService();
      this.geoCoderService = new window.google.maps.Geocoder();
    } else {
      gmapInit({ key: googleMapsKey }).then(() => {
        this.autocompleteService = new window.google.maps.places.AutocompleteService();
        this.geoCoderService = new window.google.maps.Geocoder();
      });
    }

    document.addEventListener('keydown', this.handleKeyDown);
    document.addEventListener('mousedown', this.handleMousedown, false);
    document.addEventListener('touchend', this.handleMousedown, false);
  }

  componentDidUpdate(prevProps) {
    const mapLoaded = prevProps.gmapLoaded !== this.props.gmapLoaded;

    if (mapLoaded) {
      this.autocompleteService = new window.google.maps.places.AutocompleteService();
      this.geoCoderService = new window.google.maps.Geocoder();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
    document.removeEventListener('mousedown', this.handleMousedown, false);
    document.removeEventListener('touchend', this.handleMousedown, false);
  }

  autocompleteCallback = (predictions) => {
    const updatePredictions = map(predictions, (place) => {
      return {
        description: place.description,
        primaryText: place.structured_formatting.main_text,
        secondaryText: place.structured_formatting.secondary_text,
        placeId: place.place_id,
      };
    });

    this.updatePredictions(updatePredictions);
  };

  handleFocus = () => {
    if (this.props.focusInput) {
      window.scrollTo(0, 0);
      document.body.scrollTop = 0;
    }

    this.setState({
      showDropdown: true,
    });
  };

  autocompleteCallback = (predictions) => {
    const updatePredictions = map(predictions, (place) => {
      return {
        description: place.description,
        primaryText: place.structured_formatting.main_text,
        secondaryText: place.structured_formatting.secondary_text,
        placeId: place.place_id,
      };
    });

    this.setState({
      predictions: updatePredictions,
    });
  };

  handleInputChange = (e) => {
    this.setState({
      address: e.target.value,
      showDropdown: true,
    });

    if (!this.autocompleteService) {
      return;
    }

    this.autocompleteService.getPlacePredictions(
      {
        input: e.target.value,
        componentRestrictions: { country: 'us' },
      },
      this.autocompleteCallback,
    );
  };

  handleKeyDown = (e) => {
    const keyCode = e.which;

    if (keyCode === ESC_KEYCODE) {
      e.target.blur();
      this.setState({ showDropdown: false });
      return;
    }
  };

  handleMousedown = (e) => {
    if (this.state.showDropdown && this.dropdownRef.current && !this.dropdownRef.current.contains(e.target)) {
      this.setState({
        address: '',
        showDropdown: false,
      });
    }
  };

  handlePredictionClick = (e, place) => {
    e.stopPropagation();

    this.geoCoderService.geocode({ placeId: place.placeId }, (results) => {
      const location = results[0].geometry.location;
      const newUserPointObj = {
        lat: location.lat(),
        lon: location.lng(),
        name: place.description,
      };

      this.props.dispatch(UserPointActions.create(newUserPointObj, { active: true }));
      this.props.handleSelectUserPoint();
    });

    this.setState({
      address: '',
      placeholder: place.description,
      predictions: [],
      showDropdown: false,
    });

    this.props.dispatch(analyticsEvent(gaEvents.COMMUTEV2_SEARCH_LOCATION));
  };

  handlePreviousClick = (e, point) => {
    e.stopPropagation();

    this.props.dispatch(UserPointActions.select(point.address));
    this.props.handleSelectUserPoint();
    this.setState({
      address: '',
      placeholder: point.address,
      predictions: [],
      showDropdown: false,
    });
  };

  renderDropdown = () => {
    const { dropDownStyle, userPoints } = this.props;
    const { predictions } = this.state;
    let adjustedTop = '40px';

    if (dropDownStyle === 'CommuteSelectorModal') {
      adjustedTop = '54px';
    }

    return (
      <div className="CommuteAutocomplete-dropdown-wrapper" style={{ top: adjustedTop }} ref={this.dropdownRef}>
        <div className="CommuteAutocomplete-dropdown">
          {predictions.length > 0 && <div className="CommuteAutocomplete-item-header">Suggestions</div>}
          <div>
            {map(predictions, (place) => {
              return (
                <div
                  className="CommuteAutocomplete-place"
                  key={place.description}
                  onClick={(e) => this.handlePredictionClick(e, place)}
                >
                  <span className="CommuteAutocomplete-place-primary">{place.primaryText}</span>
                  <span className="CommuteAutocomplete-place-secondary">{place.secondaryText}</span>
                </div>
              );
            })}
          </div>
          {predictions.length > 0 && (
            <div className="CommuteAutocomplete-attribution">
              <img src={googleAttribution} />
            </div>
          )}
          {userPoints.length > 0 && (
            <div>
              <div className="CommuteAutocomplete-item-header">Previous locations</div>
              {map(userPoints, (point, i) => {
                const pointDetails = point.name.split(',');
                const pointSecondary = pointDetails.slice(1, pointDetails.length).join(',');

                return (
                  <div
                    className="CommuteAutocomplete-place"
                    key={point.name + '_' + i}
                    onClick={(e) => this.handlePreviousClick(e, point)}
                  >
                    <span className="CommuteAutocomplete-place-primary">{pointDetails[0]}</span>
                    <span className="CommuteAutocomplete-place-secondary">{pointSecondary}</span>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    );
  };

  render() {
    const { focusInput, inputSize, userPoints, inputId } = this.props;
    const { address, placeholder, predictions, showDropdown } = this.state;

    return (
      <div className="CommuteAutocomplete">
        <Autocomplete
          inputId={inputId}
          focusInput={focusInput}
          onChange={this.handleInputChange}
          onFocus={this.handleFocus}
          onKeyDown={this.handleKeyDown}
          placeholder={placeholder}
          selectAllText={focusInput}
          size={inputSize}
          value={address}
        />
        {showDropdown && (userPoints.length > 0 || predictions.length > 0) && this.renderDropdown()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    gmapLoaded: state.app.gmapLoaded,
    googleMapsKey: state.app.googleMaps.key,
    userPoints: state.user.userPoints.destinations,
  };
};

export default connect(mapStateToProps)(CommuteAutocomplete);
