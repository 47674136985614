// @ts-nocheck
/* eslint-enable */
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { analyticsEvent } from 'app/client/universal-analytics';
import { gaEvents } from 'app/shared/constants/AnalyticsConstants';
import Contact from 'app/shared/modules/hdp/form/Contact/Contact';
import ContactFormLite from 'app/shared/modules/hdp/form/Contact/ContactFormLite';
import Interstitial from 'app/shared/modules/hdp/ZillowApplicationsFlow/Interstitial';
import { useToolTip } from './hooks';
import { shouldShowApplyButton } from 'app/shared/utils/cometUtils';
import { TrackRentalAppPreStart } from 'app/shared/models/Clickstream/HdpClickstreamEvents';
import { getListingType, getTrackingFunction } from 'app/shared/utils/clickstreamUtils';

const ZillowApplicationPopup = ({ onActionPopup, onHidePopup }) => {
  const dispatch = useDispatch();

  const listing = useSelector((state) => state.currentListingDetails.currentListing);
  const similarListings = useSelector((state) => state.fetchListing.similarListings);
  const isMobile = useSelector((state) => state.app.device.isMobile);

  const [showToolTip, toggleToolTip] = useToolTip();

  const handleTracking = useCallback(
    (event, eventOptions) => {
      dispatch(analyticsEvent(event, eventOptions));
    },
    [dispatch],
  );

  const handleInterstitialApplyClick = useCallback(() => {
    handleTracking(gaEvents.HDP_APPLICATION_INTERSTITIAL_CTA_APPLY_NOW_BY_ZILLOW, {
      newLaneEvent: TrackRentalAppPreStart(),
    });
  }, [handleTracking]);

  const handleToolTipClick = useCallback(() => {
    toggleToolTip(!showToolTip);
  }, [showToolTip, toggleToolTip]);

  const handleContactFormTracking = useCallback(() => {
    const listingType = getListingType(listing);

    handleTracking(gaEvents.HDP_APPLICATION_CTA_APPLICATION_REQUEST_SUCCESS, {
      newLaneEvent: getTrackingFunction({ type: listingType })({
        triggerObject: 'home_details_component|modal_contact_form',
        triggerSource: 'button_to_complete_request_to_apply_form',
      }),
    });
  }, [handleTracking, listing]);

  const { rentalApplicationStatus } = listing;
  const acceptsApplications = shouldShowApplyButton(rentalApplicationStatus);

  return (
    <>
      {acceptsApplications ? (
        <Interstitial
          handleToolTipClick={handleToolTipClick}
          showToolTip={showToolTip}
          handleInterstitialApplyClick={handleInterstitialApplyClick}
          propertyId={listing.aliasEncoded}
          isMobile={isMobile}
        />
      ) : (
        <Contact
          isPopup
          onShowSuccessModal={onActionPopup}
          onHidePopup={onHidePopup}
          listing={listing}
          similarListings={similarListings}
          customForm={ContactFormLite}
          customTracking={handleContactFormTracking}
        />
      )}
    </>
  );
};

export default ZillowApplicationPopup;
