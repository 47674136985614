// @ts-nocheck
/* eslint-enable */
// App
import React, { useEffect, useRef, useState } from 'react';
import { useYieldCallback } from '@zillow/yield-callback';

// Icons
import IconArrowDown from 'images/icons/arrow-down.min.svg';
import IconArrowUp from 'images/icons/arrow-up.min.svg';
import { kebabCase } from 'lodash';

// Misc / utils
import * as S from './styles';

const DesktopLinkGroup = ({ header, links }) => {
  // React state
  const [isExpanded, setIsExpanded] = useState(true);

  const isMounted = useRef(false);
  const firstHiddenElementRef = useRef();
  const lastVisibleElementRef = useRef();

  useEffect(() => {
    if (isMounted.current) {
      if (isExpanded) {
        firstHiddenElementRef.current.focus();
      }
      if (!isExpanded) {
        lastVisibleElementRef.current.focus();
      }
    }
    isMounted.current = true;
  }, [isExpanded]);

  const shouldShowMoreButton = links.length > 5;

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  const headerId = kebabCase(header.toLowerCase());

  const yieldTransitionTo = useYieldCallback((path) => {
    window.router.transitionTo(path);
  });

  const handleLinkClick = (e, path) => {
    // Prevent the browser from going through with its default <a> behavior
    e.preventDefault();
    yieldTransitionTo(path);
  };

  return (
    <S.SectionItem key={`seo-footer-${header}-group`}>
      <S.header>
        <S.h2 id={headerId}>{header}</S.h2>
      </S.header>
      <ul>
        {links.map((link, idx) => {
          const uriV2 = link.uriV2;
          if (idx <= 4) {
            return (
              <S.li key={`seo-footer-${link.name}-link-${link.listingCount}-listings-isExpanded--${isExpanded}`}>
                <S.Link href={uriV2} ref={idx === 4 ? lastVisibleElementRef : null} tabIndex={0}>
                  {link.name}
                </S.Link>
              </S.li>
            );
          }

          return (
            <S.li
              key={`seo-footer-${link.name}-link-${link.listingCount}-listings-isExpanded--${isExpanded}`}
              $isVisible={isExpanded}
            >
              <S.Link
                href={uriV2}
                onClick={(e) => handleLinkClick(e, uriV2)}
                tabIndex={0}
                ref={idx === 5 ? firstHiddenElementRef : null}
              >
                {link.name}
              </S.Link>
            </S.li>
          );
        })}
      </ul>
      {shouldShowMoreButton && (
        <S.ShowMoreButton onClick={toggleExpansion} aria-describedby={headerId} aria-expanded={isExpanded}>
          {isExpanded ? 'Less' : 'More'}
          <S.ShowMoreCarat
            loading="lazy"
            src={isExpanded ? IconArrowUp : IconArrowDown}
            height="10px"
            width="11px"
            alt=""
            aria-hidden="true"
          />
        </S.ShowMoreButton>
      )}
    </S.SectionItem>
  );
};

export default DesktopLinkGroup;
