// @ts-nocheck
/* eslint-enable */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cx from 'classnames';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';
import { textOverflow } from 'app/shared/styles/_mixins';
import { colors } from 'app/shared/styles/_colors';
import { yieldCallback } from '@zillow/yield-callback';

// Components
import ActionPopupWrapper from 'app/shared/modules/popups/ActionPopupWrapper';
import Button from 'app/shared/core/Button';
import CallLinkV2 from 'app/shared/modules/hdp/CallLinkV2';
import FloorplanImage from 'app/shared/modules/hdp/FloorplanImage';
import Iframe3dTours from 'app/shared/modules/Iframe3dTours';
import LinkToggle from 'app/shared/modules/LinkToggle';
import PhotoGalleryFull from 'app/shared/modules/photo-gallery/PhotoGalleryFull';
import PopupModal from 'app/shared/modules/popups/PopupModal';
import Tour3dButton from 'app/shared/modules/hdp/Tour3dButton';

// Utils / Misc
import { analyticsEvent } from 'app/client/universal-analytics';
import { gaEvents } from 'app/shared/constants/AnalyticsConstants';
import { listingUtils_modelBaths, listingUtils_modelBeds, listingUtils_modelSqft } from 'app/shared/utils/listingUtils';
import appUtils from 'app/shared/utils/appUtils';
import controller from './controller';
import IconContactV2 from 'images/icons/mail-accent.svg';
import IconXWhite from 'images/icons/x-white.svg';
import './style.scss';

const { func, object } = PropTypes;

const ModelFloorPlanContactButton = styled(Button)`
  display: flex;
  align-items: center;
`;
const TextOverflowDiv = styled.div`
  ${textOverflow};
  ${(props) => props.hasUnitName === false && `color: ${colors['$hpx-grey-600']};`};
`;
const StyledBoldSpan = styled.span`
  font-weight: 700;
`;
const ModelFloorplanUnitAvailability = TextOverflowDiv;
class ModelFloorplanItem extends React.Component {
  static propTypes = {
    floorplan: object,
    listing: object,
    onActionPopup: func,
    onClickOpenFdp: func,
  };

  static defaultProps = {
    floorplan: null,
    listing: null,
    onActionPopup() {},
    onClickOpenFdp() {},
  };

  constructor(props) {
    super(props);
    this.LAST_SCROLL_TOP = 0;
    this.state = {
      isContactModalOpen: false,
      isPopupModalOpen: false,
      isIframe3dTourOpen: false,
      isTruncated: true,
    };
  }

  handleTogglePhotoModal = () => {
    if (this.state.isPopupModalOpen) {
      if (__BROWSER__) {
        this.setState({ isPopupModalOpen: false }, () => {
          window.scrollTo(0, this.LAST_SCROLL_TOP);
        });
      }
    } else {
      this.LAST_SCROLL_TOP = document.body.scrollTop || document.scrollingElement.scrollTop;
      this.setState({ isPopupModalOpen: true });
    }
  };

  yieldOpenContactModal = yieldCallback(() => {
    const { isMobile } = this.props;
    if (isMobile) {
      appUtils.saveScrollPosition();
    }

    this.setState({
      isContactModalOpen: true,
    });
  });

  handleContactPopupModal = () => {
    const { listing } = this.props;

    if (listing.active) {
      this.yieldOpenContactModal();
    }
  };

  handleViewMoreToggle = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({ isTruncated: !this.state.isTruncated });
  };

  handleClose3dTours = () => {
    this.setState({
      isIframe3dTourOpen: false,
    });
  };

  handleToursToPhotoGallery = () => {
    this.setState({
      isIframe3dTourOpen: false,
      isPopupModalOpen: true,
    });
  };

  handleToursToContact = () => {
    this.setState({
      isContactModalOpen: true,
      isIframe3dTourOpen: false,
    });
  };

  handleOpen3dTour = (e) => {
    const { dispatch } = this.props;
    e.preventDefault();
    e.stopPropagation();

    dispatch(analyticsEvent(gaEvents.HDP_FLOORPLAN_3DTOUR_CLICKED));

    this.setState({
      isIframe3dTourOpen: true,
    });
  };

  handlePhotoGalleryTo3dTours = () => {
    this.setState({
      isIframe3dTourOpen: true,
      isPopupModalOpen: false,
    });
  };

  handleCloseContactModal = (e) => {
    if (e) {
      e.stopPropagation(); // prevents this.handleContactPopupModal from being called
    }
    const { isMobile } = this.props;

    this.setState(
      {
        isContactModalOpen: false,
      },
      () => {
        if (isMobile) {
          appUtils.restoreScrollPosition();
        }
      },
    );
  };

  renderContactModal = (modelId) => {
    const { onActionPopup, floorplan } = this.props;
    const { beds, baths } = floorplan;

    return (
      <ActionPopupWrapper
        componentName="contact"
        onHidePopup={this.handleCloseContactModal}
        onActionPopup={onActionPopup}
        unitDetails={{ beds, baths, modelId }}
      />
    );
  };

  renderPhotoModal = () => {
    const { onActionPopup, floorplan, onClickOpenFdp } = this.props;
    const { thumbPhotos, modelId, photos, zillow3dTourUrl } = floorplan;

    return (
      <PopupModal
        customCloseIcon={<img src={IconXWhite} height={20} width={20} alt="" />}
        isFullScreen
        onHidePopup={this.handleTogglePhotoModal}
        paddingTop="0"
        paddingBottom="0"
      >
        <PhotoGalleryFull
          photos={controller.checkPhotos(photos)}
          thumbPhotos={controller.checkPhotos(thumbPhotos)}
          floorplanModelId={modelId}
          onClickContact={() => {
            onActionPopup('contact');
          }}
          onClickOpenFdp={onClickOpenFdp}
          has3dTour={Boolean(zillow3dTourUrl)}
          onPhotoGalleryTo3dTours={this.handlePhotoGalleryTo3dTours}
        />
      </PopupModal>
    );
  };

  renderIframe3dTourModal = () => {
    const { floorplan } = this.props;

    return (
      <PopupModal
        customCloseIcon={<img src={IconXWhite} height={20} width={20} alt="" />}
        isFullScreen
        onHidePopup={this.handleClose3dTours}
        paddingTop="0"
        paddingBottom="0"
      >
        <Iframe3dTours
          url={floorplan.zillow3dTourUrl}
          onClickContact={this.handleToursToContact}
          onGalleryOpen={this.handleToursToPhotoGallery}
        />
      </PopupModal>
    );
  };

  renderModelFloorplanUnit = (unit, i) => {
    const { listing, screenWidth } = this.props;
    let priceText = controller.getPriceSummaryDisplay({ unit });
    if (isEmpty(priceText)) {
      priceText = <CallLinkV2 listing={listing} useLongText={screenWidth === 'xl'} />;
    }

    return (
      <div className="ModelFloorplanItem-unit" key={`floor-plan-unit-${i}`}>
        <div className="ModelFloorplanItem-unit-price">{priceText}</div>
        <ModelFloorplanUnitAvailability className="ModelFloorplanItem-unit-availability">
          {controller.getAvailabilityDisplay({ listing, unit, screenWidth })}
        </ModelFloorplanUnitAvailability>
        <TextOverflowDiv className={cx('ModelFloorplanItem-unit-name')} hasUnitName={Boolean(unit.name)}>
          {unit.name || ''}
        </TextOverflowDiv>
      </div>
    );
  };

  renderModelFloorplanEmptyUnit = () => {
    const { listing, floorplan, screenWidth } = this.props;
    let priceText = controller.getPriceSummaryDisplay({ floorplan });
    if (isEmpty(priceText)) {
      priceText = <CallLinkV2 listing={listing} useLongText={screenWidth === 'xl'} />;
    }

    return (
      <div className="ModelFloorplanItem-empty-unit">
        <div className="ModelFloorplanItem-empty-unit-price">{priceText}</div>
        {listing.waitlisted && (
          <TextOverflowDiv className="ModelFloorplanItem-empty-unit-contact">Currently unavailable</TextOverflowDiv>
        )}
      </div>
    );
  };

  render() {
    const { floorplan } = this.props;
    const { name, baths, beds, modelId, sqft, units, thumbPhotos, zillow3dTourUrl } = floorplan;
    const { isContactModalOpen, isPopupModalOpen, isTruncated, isIframe3dTourOpen } = this.state;
    const unitsToShow = isTruncated ? units.slice(0, 5) : units;
    const { active } = this.props.listing;

    return (
      <div className="ModelFloorplanItem" onClick={this.handleContactPopupModal}>
        {isContactModalOpen && this.renderContactModal(modelId)}
        {isPopupModalOpen && this.renderPhotoModal()}

        <div className="ModelFloorplanItem-card">
          <div className="ModelFloorplanItem-left">
            <div className="ModelFloorplanItem-photo">
              <FloorplanImage
                modelId={modelId}
                name={name}
                photos={thumbPhotos}
                onClickPopupModal={this.handleTogglePhotoModal}
                shouldShowZoom
              />
            </div>
            {zillow3dTourUrl && (
              <div className="ModelFloorplanItem-3dtour-wrapper" onClick={this.handleOpen3dTour}>
                <Tour3dButton zillow3dTourUrl={zillow3dTourUrl} />
                {isIframe3dTourOpen && this.renderIframe3dTourModal()}
              </div>
            )}
          </div>
          <div className="ModelFloorplanItem-right">
            <div className="ModelFloorplanItem-header">
              <div className="ModelFloorplanItem-details">
                <StyledBoldSpan className="ModelFloorplanItem-detail">{listingUtils_modelBeds(beds)}</StyledBoldSpan>
                <div className="ModelFloorplanItem-detail">
                  <span className="ModelFloorplanItem-bthsqft">{listingUtils_modelBaths(baths)}</span>
                  <span className="ModelFloorplanItem-bthsqft">{listingUtils_modelSqft(sqft)}</span>
                </div>
              </div>
              {active && (
                <ModelFloorPlanContactButton btnType="primary-outline" size="sm">
                  <img src={IconContactV2} className="ModelFloorplanItem-contact-icon" alt="" />
                  Contact
                </ModelFloorPlanContactButton>
              )}
            </div>
            {!isEmpty(units) && (
              <div className="ModelFloorplanItem-label-header">
                <div className="ModelFloorplanItem-label-price">Rent / mo</div>
                <div className="ModelFloorplanItem-label-availability">Available</div>
                <div className="ModelFloorplanItem-label-name">Unit</div>
              </div>
            )}
            {unitsToShow.map((unit, i) => this.renderModelFloorplanUnit(unit, i))}
            {isEmpty(units) && this.renderModelFloorplanEmptyUnit()}
          </div>
        </div>
        {units.length > 5 && (
          <div className="ModelFloorplanItem-expand" onClick={this.handleViewMoreToggle}>
            <LinkToggle onClick={() => {}} linkType="accent">
              {isTruncated ? `See all ${units.length} units` : 'See only 5 units'}
            </LinkToggle>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isMobile: state.app.device.screenWidth === 'sm',
  screenWidth: state.app.device.screenWidth,
});

export default connect(mapStateToProps)(ModelFloorplanItem);
