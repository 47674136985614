// @ts-nocheck
/* eslint-enable */
import styled from 'styled-components';
import { colors } from 'app/shared/styles/_colors';
import { zindex } from 'app/shared/styles/_zindex';
import { viewports } from 'app/shared/styles/_breakpoints';
import { navbar, variables } from 'app/shared/styles/_variables';

// Components
import TextBase from 'app/shared/core/Text';

export const ListingHiddenNotice = styled.div`
  position: fixed;
  margin-top: -${navbar['hdp-nav-bar-height-md']};
  height: ${navbar['hdp-nav-bar-height-md']};
  width: 100%;
  z-index: ${zindex['$z-index-grand-parent']};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 8px;
  background-color: ${colors['$hpx-blue-600']};
  color: ${colors['$hpx-white']};

  // X button does not fit for width < 380
  @media (min-width: ${variables['contact-button-width-md'] + 20}px) {
    justify-content: flex-start;
  }

  @media ${viewports['xl-and-up']} {
    justify-content: center;
    margin-top: -${navbar['hdp-nav-bar-height-xl']};
    height: ${navbar['hdp-nav-bar-height-xl']};
    width: ${variables['sidebar-width-xl']};
  }
`;

export const ListingHiddenText = styled(TextBase)`
  padding-left: 16px;
  padding-right: 8px;

  @media ${viewports['xl-and-up']} {
    padding-left: 0;
    padding-right: 16px;
  }
`;

export const ListingHiddenClose = styled.div`
  display: none;
  position: absolute;
  right: 16px;
  padding: 8px 8px 4px 8px;
  cursor: pointer;

  // X button does not fit for width < 380
  @media (min-width: ${variables['contact-button-width-md'] + 20}px) {
    display: block;
  }
`;
