// @ts-nocheck
/* eslint-enable */
import React from 'react';
import { connect } from 'react-redux';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import HdpContentWrapper from 'app/shared/modules/hdp/HdpContentWrapper';
import ListingWrapper from 'app/shared/modules/listing-cards/ListingWrapper';
import 'app/shared/modules/hdp/FeaturedListingsGroup.scss';

class FeaturedListingsGroup extends React.Component {
  static propTypes = {
    header: PropTypes.string,
    listings: PropTypes.array.isRequired,
    onListingClick: PropTypes.func,
    trackImpression: PropTypes.func,
    triggerLocation: PropTypes.string,
    triggerObject: PropTypes.string,
  };

  constructor(props) {
    super(props);
  }

  handleListingClick = () => {
    if (this.props.onListingClick) {
      this.props.onListingClick();
    }
  };

  render() {
    const { limit, listings, header, trackImpression, triggerLocation, triggerObject } = this.props;
    let listingsArray = [].concat(listings);

    if (listings.length === 0) {
      return null;
    }

    if (limit) {
      listingsArray = listings.slice(0, limit);
    }

    return (
      <div className="FeaturedListingsGroup">
        <HdpContentWrapper collapsable active header={header} headerHtmlTag="h2" slimPadding>
          <div className="FeaturedListings-container">
            {map(listingsArray, (listing) => {
              if (listing) {
                return (
                  <ListingWrapper
                    key={listing.aliasEncoded}
                    listing={listing}
                    onClick={this.handleListingClick}
                    trackImpression={trackImpression}
                    triggerLocation={triggerLocation}
                    triggerObject={triggerObject}
                  />
                );
              }
            })}
          </div>
        </HdpContentWrapper>
      </div>
    );
  }
}
export default connect()(FeaturedListingsGroup);
