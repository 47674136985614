import type {
  SaveHomeTriggerLocation,
  SaveHomeTriggerObject,
} from 'app/shared/models/Clickstream/HdpClickstreamEvents';
import type { ReactNode } from 'react';
import React, { createContext, useContext } from 'react';

interface ListingContextType {
  listing: any;
  triggerLocation: SaveHomeTriggerLocation;
  triggerObject: SaveHomeTriggerObject;
}

// eslint-disable-next-line no-undefined
const ListingContext = createContext<ListingContextType | undefined>(undefined);

interface ListingProviderProps {
  children: ReactNode;
  listing: any;
  triggerLocation: SaveHomeTriggerLocation;
  triggerObject: SaveHomeTriggerObject;
}

export const ListingProvider: React.FC<ListingProviderProps> = ({
  children,
  listing,
  triggerLocation = 'search_results',
  triggerObject = 'property_card',
}) => {
  const value = { listing, triggerLocation, triggerObject };

  return <ListingContext.Provider value={value}>{children}</ListingContext.Provider>;
};

export const useListing = (): ListingContextType => {
  const context = useContext(ListingContext);
  if (typeof context === 'undefined') {
    throw new Error('useListing must be used within a ListingProvider');
  }
  return context;
};
