// @ts-nocheck
/* eslint-enable */
import dateUtils from 'app/shared/utils/dateUtils';
import isEmpty from 'lodash/isEmpty';
import imageUtils from 'app/shared/utils/imageUtils';
import numberUtils from 'app/shared/utils/numberUtils';
import { listingUtils_modelBeds } from 'app/shared/utils/listingUtils';

const controller = {
  getFloorplanDetails(floorplan) {
    const { name, beds, baths, sqft, largePhotos, units = [], priceRangeDisplay } = floorplan;
    const { url, height, width } = largePhotos[0];
    const topDisplay = `${name}, ${listingUtils_modelBeds(beds)} ${baths} bath`;
    let priceDisplay = priceRangeDisplay[0];
    if (priceRangeDisplay[0] !== priceRangeDisplay[1]) {
      priceDisplay += '–' + priceRangeDisplay[1];
    }
    const sqftDisplay = sqft.toLocaleString() + ' sqft';
    const bottomDisplay = priceDisplay + ' • ' + sqftDisplay;
    const imgUrl = url || imageUtils.noImageFound().url;

    // set min height to prevent flash of table appearing out of position until the img loads
    const imgMinHeight = Math.floor((width / height) * 100) + 'vw';
    const imgStyle = { minHeight: imgMinHeight };

    return { topDisplay, bottomDisplay, imgUrl, imgStyle, units };
  },
  getAvailabilityDisplay({ active, unit, waitlisted }) {
    if (!active) {
      return 'N/A';
    } else if (waitlisted) {
      return 'Waitlist';
    } else if (!unit.availabilityDate) {
      return 'Now';
    } else if (unit.availabilityDate) {
      return `${dateUtils.formatDateToString(unit.availabilityDate)}`;
    }
  },
  getPriceSummaryDisplay({ unit = {}, floorplan = {} }) {
    let low;
    let high;
    if (!isEmpty(unit)) {
      low = unit.low;
      high = unit.high;
    } else {
      low = floorplan.low;
      high = floorplan.high;
    }
    const lowDisplay = numberUtils.compact(low);
    const highDisplay = numberUtils.compact(high);
    let display = '';

    if (high > 0 && high < Infinity) {
      if (low === high) {
        display = `\$${lowDisplay}`;
      } else if (low > 0) {
        display = `\$${lowDisplay}–\$${highDisplay}`;
      } else {
        display = `Up to \$${highDisplay}`;
      }
    } else if (low > 0) {
      display = `\$${lowDisplay}+`;
    }

    return display;
  },
};

export default controller;
