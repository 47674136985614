import React from 'react';
import './style.scss';

const SpinnerDotsV2: React.FC = () => {
  return (
    <div className="LargeSpinnerDots">
      <div className="LargeSpinnerDots-child dots-bounce1" />
      <div className="LargeSpinnerDots-child dots-bounce2" />
      <div className="LargeSpinnerDots-child dots-bounce3" />
    </div>
  );
};

export default SpinnerDotsV2;
