// @ts-nocheck
/* eslint-enable */
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useYieldCallback } from '@zillow/yield-callback';

import { analyticsEvent } from 'app/client/universal-analytics';
import { gaEvents } from 'app/shared/constants/AnalyticsConstants';
import VisuallyHidden from 'app/shared/modules/VisuallyHidden';
import { Swipeable } from 'app/shared/modules/navigation/Swipeable';
import IconLeftArrow from 'images/icons/arrow-left-dark-grey.svg';
import IconRightArrow from 'images/icons/arrow-right-dark-grey.svg';
import {
  carouselContentCX,
  carouselWrapperCX,
  dotWrapperCX,
  getArrowImageCX,
  getNextImageButtonCX,
  getPrevImageButtonCX,
  heroImageCX,
  nextTouchTargetCX,
  prevTouchTargetCX,
} from './styles';

import Dots from './dots';

const ListingCardCarousel = ({
  displayName = '',
  handleListingClick = {},
  photoUrls = [],
  FavoriteButtonComponent = null,
} = {}) => {
  const dispatch = useDispatch();
  const [showSlideArrows, setShowSlideArrows] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);
  const [isRenderingAllPhotos, setIsRenderingAllPhotos] = useState(false);

  const yieldTrackAndGoToIdx = useYieldCallback(({ idx, shouldFireTracking = false, event = null }) => {
    setImageIndex(idx);
    if (shouldFireTracking) {
      dispatch(analyticsEvent(event));
    }
    setIsRenderingAllPhotos(true);
  });

  const handlePrevClick = useCallback(() => {
    if (imageIndex - 1 < 0) {
      yieldTrackAndGoToIdx({
        idx: 0,
      });
    } else {
      yieldTrackAndGoToIdx({
        idx: imageIndex - 1,
        shouldFireTracking: true,
        event: gaEvents.LISTING_CARD_IMAGE_PREV,
      });
    }
  }, [yieldTrackAndGoToIdx, imageIndex]);

  const handleNextClick = useCallback(() => {
    if (imageIndex + 1 > photoUrls.length - 1) {
      return;
    } else {
      yieldTrackAndGoToIdx({
        idx: imageIndex + 1,
        shouldFireTracking: true,
        event: gaEvents.LISTING_CARD_IMAGE_NEXT,
      });
    }
  }, [yieldTrackAndGoToIdx, imageIndex, photoUrls.length]);

  /**
   * TODOs:
   *
   * - Handle swipes (mobile devices)
   * - Handle clicks on side of image (desktop)
   * - Loop through images
   * - capture clicks so that we don't transition to listing (need to modify ListingWrapper, which is tightly coupled to old listing cards)
   *
   * QUESTIONS:
   * - Walk through clicking, swiping behavior. Where are click targets on listing card for going to HDP?
   */

  return (
    <Swipeable onSwipedLeft={handleNextClick} onSwipedRight={handlePrevClick} trackMouse preventScrollOnSwipe>
      <div
        className={carouselWrapperCX}
        data-name="CarouselWrapper"
        onBlur={() => setShowSlideArrows(false)}
        onFocus={() => setShowSlideArrows(true)}
        onMouseEnter={() => setShowSlideArrows(true)}
        onMouseLeave={() => setShowSlideArrows(false)}
      >
        {FavoriteButtonComponent}
        <button className={prevTouchTargetCX} data-name="PrevTouchTarget" onClick={handlePrevClick} tabIndex={-1} />
        <button
          className={getPrevImageButtonCX(showSlideArrows && imageIndex > 0)}
          data-name="PrevImageButton"
          onClick={handlePrevClick}
          tabIndex={imageIndex > 0 ? 0 : -1}
          aria-hidden={imageIndex === 0}
        >
          <img
            className={getArrowImageCX(showSlideArrows && imageIndex > 0)}
            data-name="LeftArrow"
            src={IconLeftArrow}
            height="16px"
            width="16px"
            aria-hidden="true"
            loading="lazy"
            fetchpriority="low"
            decoding="async"
          />
          <VisuallyHidden>Previous image</VisuallyHidden>
        </button>
        <button className={nextTouchTargetCX} data-name="NextTouchTarget" onClick={handleNextClick} tabIndex={-1} />
        <button
          className={getNextImageButtonCX(showSlideArrows && imageIndex !== photoUrls.length - 1)}
          data-name="NextImageButton"
          onClick={handleNextClick}
          tabIndex={imageIndex !== photoUrls.length - 1 ? 0 : -1}
          aria-hidden={imageIndex === photoUrls.length - 1}
        >
          <img
            className={getArrowImageCX(showSlideArrows && imageIndex !== photoUrls.length - 1)}
            data-name="RightArrow"
            src={IconRightArrow}
            height="16px"
            width="16px"
            aria-hidden="true"
            loading="lazy"
            fetchpriority="low"
            decoding="async"
          />
          <VisuallyHidden>Next image</VisuallyHidden>
        </button>
        <div
          className={carouselContentCX}
          data-name="CarouselContent"
          style={{ transform: `translateX(-${imageIndex * 100}%)` }}
          onClick={handleListingClick}
        >
          {!isRenderingAllPhotos && photoUrls.length > 0 && (
            <img
              className={heroImageCX}
              data-name="HeroImage"
              alt={`${displayName} Photo 1`}
              key={photoUrls[0]}
              src={photoUrls[0]}
              title={`${displayName} Photo 1`}
              fetchpriority="high"
            />
          )}
          {isRenderingAllPhotos &&
            photoUrls.map((photoUrl, idx) => {
              const photoTxt = `${displayName} Photo ${idx + 1}`;
              return <img className={heroImageCX} alt={photoTxt} key={photoUrl} src={photoUrl} title={photoTxt} />;
            })}
        </div>
        {photoUrls.length > 1 && (
          <div className={dotWrapperCX} data-name="DotWrapper">
            <Dots length={photoUrls.length} active={imageIndex} />
          </div>
        )}
      </div>
    </Swipeable>
  );
};

export default ListingCardCarousel;
