// @ts-nocheck
/* eslint-enable */
import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import './style.scss';

class Spinner extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    message: PropTypes.string,
  };

  constructor(props) {
    super(props);
  }

  render() {
    const classNameFromProps = this.props.className;
    let message;

    if (this.props.message) {
      message = <div className="Spinner-message">{this.props.message}</div>;
    }

    return (
      <div className={cx('Spinner', classNameFromProps)}>
        <div
          className={cx({
            'Spinner-default-load-wrapper': !classNameFromProps,
            'Spinner-absolute-overlay-load-wrapper': classNameFromProps === 'Spinner-absolute-overlay',
          })}
        >
          <svg className="Spinner-load-svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M51.387 29.824a1.927 1.927 0 0 1-1.921 1.921 1.927 1.927 0 0 1-1.922-1.921V2.922c0-1.057.865-1.922 1.922-1.922 1.056 0 1.921.865 1.921 1.922v26.902zm0 66.186a1.927 1.927 0 0 1-1.921 1.921 1.927 1.927 0 0 1-1.922-1.922V69.108c0-1.057.865-1.922 1.922-1.922 1.056 0 1.921.865 1.921 1.922v26.901zm17.721-44.623a1.927 1.927 0 0 1-1.922-1.922c0-1.056.865-1.921 1.922-1.921h26.901c1.057 0 1.922.865 1.922 1.921a1.927 1.927 0 0 1-1.922 1.922H69.108zm-66.186 0A1.927 1.927 0 0 1 1 49.465c0-1.056.865-1.921 1.922-1.921h26.902c1.056 0 1.921.865 1.921 1.921a1.927 1.927 0 0 1-1.921 1.922H2.922zm59.074 13.326a1.926 1.926 0 0 1 0-2.717 1.926 1.926 0 0 1 2.717 0l19.023 19.022a1.926 1.926 0 0 1 0 2.717 1.929 1.929 0 0 1-2.719 0L61.996 64.713zm-46.801-46.8a1.926 1.926 0 0 1 0-2.717 1.926 1.926 0 0 1 2.717 0l19.024 19.021a1.926 1.926 0 0 1 0 2.717 1.926 1.926 0 0 1-2.717 0L15.195 17.913zm19.023 44.083a1.926 1.926 0 0 1 2.717 0c.747.747.747 1.97 0 2.717L17.913 83.736a1.929 1.929 0 0 1-2.719 0 1.932 1.932 0 0 1 0-2.719l19.024-19.021zm46.8-46.801a1.926 1.926 0 0 1 2.717 0c.748.748.748 1.97 0 2.717L64.714 36.936a1.926 1.926 0 0 1-2.717 0 1.926 1.926 0 0 1 0-2.717l19.021-19.024z"
              fillRule="evenodd"
            />
          </svg>
          {message}
        </div>
      </div>
    );
  }
}

export default Spinner;
