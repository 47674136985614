// @ts-nocheck
/* eslint-enable */
import React from 'react';
import { connect } from 'react-redux';

import './style.scss';

const OFFSET_X = 20;
const OFFSET_Y = -2;

class MapBorderTooltip extends React.Component {
  constructor() {
    super();

    this.handleMouseMove = this.handleMouseMove.bind(this);

    this.state = {
      left: null,
      top: null,
    };
  }

  componentDidMount() {
    document.body.addEventListener('mousemove', this.handleMouseMove, false);
  }

  componentWillUnmount() {
    document.body.removeEventListener('mousemove', this.handleMouseMove, false);
  }

  handleMouseMove(e) {
    this.setState({
      left: e.pageX + OFFSET_X + 'px',
      top: e.pageY + OFFSET_Y + 'px',
    });
  }

  render() {
    const { left, top } = this.state;

    if (!left || !top) {
      return null;
    }

    return (
      <div
        className="MapBorderTooltip"
        style={{
          left,
          top,
        }}
      >
        Click to see more
      </div>
    );
  }
}

export default connect()(MapBorderTooltip);
