// @ts-nocheck
/* eslint-enable */
// App
import React, { useState } from 'react';
import { useYieldCallback } from '@zillow/yield-callback';

// Icons
import IconArrowDown from 'images/icons/arrow-down.min.svg';
import IconArrowUp from 'images/icons/arrow-up.min.svg';

// Misc / utils
import * as S from './styles';

const MobileLinkGroup = ({ header, links }) => {
  // React state
  const [isExpanded, setIsExpanded] = useState(true);
  const toggleExpansion = useYieldCallback(() => {
    setIsExpanded(!isExpanded);
  });
  const yieldTransitionTo = useYieldCallback((path) => {
    window.router.transitionTo(path);
  });
  const handleLinkClick = (e, path) => {
    // Prevent the browser from going through with its default <a> behavior
    e.preventDefault();
    yieldTransitionTo(path);
  };
  return (
    <S.SectionItem key={`seo-footer-${header}-group`}>
      <S.header>
        <S.ScreenReaderOnly as="h2">{header}</S.ScreenReaderOnly>
        <S.HeadingButton tabIndex="0" aria-expanded={isExpanded} onClick={toggleExpansion}>
          <S.HeadingButtonCarat
            alt=""
            loading="lazy"
            src={isExpanded ? IconArrowUp : IconArrowDown}
            height="16px"
            width="16px"
            aria-hidden="true"
          />
          {header}
        </S.HeadingButton>
      </S.header>
      <S.ul $isVisible={isExpanded}>
        {links.map((link) => {
          const uriV2 = link.uriV2;
          return (
            <S.li key={`seo-footer-${link.name}-link-${link.listingCount}-listings-isExpanded--${isExpanded}`}>
              <S.Link href={uriV2} onClick={(e) => handleLinkClick(e, uriV2)} linkType="text-color" tabIndex={0}>
                {link.name}
              </S.Link>
            </S.li>
          );
        })}
      </S.ul>
    </S.SectionItem>
  );
};

export default MobileLinkGroup;
