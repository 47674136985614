import routeUtils from 'app/shared/utils/routeUtils';
import seoUtils from 'app/shared/utils/seoUtils';

const controller = {
  getListingSeoInfo({ pathname, searchSlug }: { pathname: string; searchSlug: string }) {
    const maloneLotIdEncoded = routeUtils.getMaloneLotIdEncodedFromUrl(pathname);
    const keyword = seoUtils.translateSlugToApiKeyword(searchSlug);
    return { keyword, maloneLotIdEncoded };
  },
};

export default controller;
