// @ts-nocheck
/* eslint-enable */
import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import './style.scss';
import Input from 'app/shared/core/Input';
import Button from 'app/shared/core/Button';

const StyledDivLeft = styled.div`
  display: inline-block;
  width: 50%;
  vertical-align: top;
  ${(props) =>
    props.isLoading &&
    `
        display:none
    `};
`;

const StyledDivRight = styled.div`
  ${(props) =>
    !props.isLoading &&
    `
        display:inline-block;
        width:50%;
        vertical-align:top;
    `};
`;
class ReviewResponseForm extends React.Component {
  static displayName = 'ReviewResponseForm';

  static propTypes = {
    onResponseSubmit: PropTypes.func.isRequired,
    onResponseCancel: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      commentValue: '',
      error: false,
      errorMsg: '',
    };
  }

  handleInputChange = (e) => {
    const comment = e.target.value;
    this.setState(() => {
      return {
        commentValue: comment,
        error: false,
      };
    });
  };

  handleFormSubmit = (e) => {
    e.preventDefault();
    if (!this.state.commentValue) {
      this.setState(() => {
        return {
          error: true,
          errorMsg: 'This is a required field.',
        };
      });

      return;
    }

    this.setState(() => {
      return {
        error: false,
        errorMsg: '',
      };
    });

    this.props.onResponseSubmit(this.state.commentValue);
  };

  render() {
    const { onResponseCancel, isLoading } = this.props;

    return (
      <div className="ReviewResponseForm">
        <Input
          className="ReviewResponseForm-input"
          type="textarea"
          label="Response to the above review:"
          placeholder="Provide us your response"
          onChange={this.handleInputChange}
          help={this.state.errorMsg}
          hpxStyle={this.state.error ? 'error' : ''}
          required
          fixedSize
        />
        <StyledDivLeft isLoading={isLoading} className="ReviewResponseForm-col-left">
          <Button full btnType="default" size="sm" onClick={onResponseCancel}>
            Nevermind
          </Button>
        </StyledDivLeft>
        <StyledDivRight
          isLoading={isLoading}
          className={cx({
            'ReviewResponseForm-col-right': !isLoading,
          })}
        >
          <Button full size="sm" disabled={isLoading} onClick={this.handleFormSubmit}>
            {isLoading ? 'Submitting...' : 'Submit'}
          </Button>
        </StyledDivRight>
      </div>
    );
  }
}

export default ReviewResponseForm;
