// @ts-nocheck
/* eslint-enable */
/* eslint-disable react/jsx-no-bind */
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { createRef } from 'react';
import throttle from 'lodash/throttle';
import { InView } from 'react-intersection-observer';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { yieldCallback } from '@zillow/yield-callback';
import { anchorOffset } from 'app/shared/styles/_mixins';
import { DeferredRender, RenderMode } from '@zillow/react-deferred-hydration';
import './style.scss';

import appUtils from 'app/shared/utils/appUtils';
import perfUtils from 'app/shared/utils/perfUtils';
import queryUtils from 'app/shared/utils/queryUtils';
import trackingUtils from 'app/shared/utils/trackingUtils';
import { getScheduledTour } from 'app/shared/utils/listingUtils';
import UserItemActions from 'app/shared/flux/actions/UserItemActions';
import ListingEngineActions from '../../../flux/actions/ListingEngineActions';

import { analyticsEvent } from 'app/client/universal-analytics';
import { gaEvents } from 'app/shared/constants/AnalyticsConstants';
import { shouldShowRequestToApplyButton } from 'app/shared/utils/cometUtils';
import { trackListingView } from 'app/client/tracking';
import constants from 'app/shared/constants/ConstantsBundle';
import type { RootReduxState } from 'app/types/redux';

// Required Components
import BreadcrumbNav from 'app/shared/modules/BreadcrumbNav';
import BrokerInfoTop from 'app/shared/modules/hdp/BrokerInfo/BrokerInfoTop';
import ChatButton from 'app/shared/modules/hdp/ChatButton';
import ContactButtonV2 from 'app/shared/modules/hdp/ContactButtonV2';

import Container from 'app/shared/core/Container';
import ExpiredListingHeader from 'app/shared/modules/hdp/ExpiredListingHeader';
import ExpiredRecommendations from 'app/shared/modules/hdp/ExpiredRecommendations';
import FaqSection from 'app/shared/modules/FaqSection/component';
import FooterNav from 'app/shared/modules/navigation/footer/FooterNav';
import HdpAmenitySection from 'app/shared/modules/hdp/HdpAmenitySection';
import HdpDescription from 'app/shared/modules/hdp/HdpDescription';
import HdpDescriptionRoomForRent from 'app/shared/modules/hdp/HdpDescriptionRoomForRent';
import HdpHighlights from 'app/shared/modules/hdp/HdpHighlights';
import HdpLegalInfo from 'app/shared/modules/hdp/HdpLegalInfo';
import HdpOpenHouseText from 'app/shared/modules/hdp/HdpOpenHouseText';
import HdpPhotoGalleryV2 from 'app/shared/modules/hdp/galleryV2/HdpPhotoGalleryV2';
import HdpPpcLink from 'app/shared/modules/hdp/HdpPpcLink';
import HdpSatelliteWrapper from 'app/shared/modules/satellite/HdpSatelliteWrapper';
import HdpSchools from 'app/shared/modules/hdp/HdpSchools';
import HdpTextSection from 'app/shared/modules/hdp/HdpTextSection';
import LinkToBuilding from 'app/shared/modules/hdp/LinkToBuilding';
import ListingHiddenNotification from 'app/shared/modules/hdp/ListingHiddenNotification';
import ListingNavBar from 'app/shared/modules/hdp/ListingNavBar';
import ListingNavBarMenu from 'app/shared/modules/hdp/ListingNavBarMenu';
import Local from 'app/shared/modules/hdp/Local';
import Popularity from 'app/shared/modules/hdp/Popularity';
import RecentlyViewed from 'app/shared/modules/hdp/RecentlyViewed';
import RepHdpLink from 'app/shared/modules/hdp/RepHdpLink';
import Row from 'app/shared/core/Row';
import BookTourNowButtonV2 from 'app/shared/modules/hdp/BookTourNowButtonV2';
import TourStatusModule from 'app/shared/modules/hdp/InstantTour/components/TourStatusModule';
import SeoFooter from 'app/shared/modules/navigation/footer/SeoFooter';
import SpecialOffers from 'app/shared/modules/hdp/SpecialOffers';

// Dependant Components
import ActionPopupWrapper from 'app/shared/modules/popups/ActionPopupWrapper';
import Ad from 'app/shared/modules/Ad';
import HdpContentWrapper from 'app/shared/modules/hdp/HdpContentWrapper';
import HdpForm from 'app/shared/modules/hdp/HdpForm';
import HdpFullDetailModalWrapper from 'app/shared/modules/hdp/HdpFdp/HdpFullDetailModalWrapper';
import HdpReviewsList from 'app/shared/modules/hdp/HdpReviewsList';
import MultiModelsGroup from 'app/shared/modules/hdp/MultiModelsGroup';
import PetPolicy from 'app/shared/modules/hdp/PetPolicy';
import PortfolioProperties from 'app/shared/modules/hdp/PortfolioProperties';
import SimilarListingsContainer from 'app/shared/modules/hdp/SimilarListingsContainer';
import SurroundingStats from 'app/shared/modules/hdp/SurroundingStats';
import WaitlistNotice from 'app/shared/modules/hdp/WaitlistNotice';
import ZillowApplicationsButton from 'app/shared/modules/hdp/ZillowApplicationsButton';
import ZrmUpsellCarousel from 'app/shared/modules/ZrmUpsellCarousel';
import { getFaqSchemaTemplate } from 'app/shared/modules/FaqSection/controller';
import HdpSummary from 'app/shared/modules/hdp/HdpSummary';
import BrokerListedBy from '../BrokerInfo/BrokerListedBy';
import dateUtils from 'app/shared/utils/dateUtils';
import * as S from './styles';
import {
  TrackHdpSimilarListingClick,
  TrackHideHomeFromHdp,
  TrackRequestContactStart,
  TrackUnhideHomeFromHdp,
} from 'app/shared/models/Clickstream/HdpClickstreamEvents';

const AnchorOffset = styled.a`
  ${anchorOffset}
`;
class Hdp extends React.Component {
  static propTypes = {
    device: PropTypes.object,
    clientLoaded: PropTypes.bool,
    isClientLoadedPage: PropTypes.bool,
    listing: PropTypes.object,
    scheduledToursForUser: PropTypes.array,
    sidebarId: PropTypes.string, // sidebarId passed from SplitMapTemplate
    showContactModalOnLoad: PropTypes.bool,
  };

  static defaultProps = {
    clientLoaded: false,
    isClientLoadedPage: false,
    listing: {},
    scheduledToursForUser: [],
    sidebarId: '',
    showContactModalOnLoad: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      contactButtonClass: '',
      isNavBarMenuOpen: false,
      activeFullDetailPage: null,
      shouldShowHideNotification: false,
      visibleActionPopup: null,
    };

    this.calculateHeight = throttle(this.calculateHeight.bind(this), 50);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.BookTourNowButtonRef = createRef();
    this.TourStatusModuleRef = createRef();
  }

  componentDidMount() {
    const { listing, showContactModalOnLoad, showInstantTourOnLoad, sidebarId, isRep } = this.props;
    if (showInstantTourOnLoad) {
      return;
    }

    this.getScrollPositions();
    this.calculateHeight();

    if (showContactModalOnLoad) {
      this.handleActionPopup('contact');
    }

    if (listing && listing.active) {
      window.addEventListener('scroll', this.calculateHeight);

      if (sidebarId) {
        this.SIDEBAR = document.getElementById(sidebarId);
        if (this.SIDEBAR) {
          this.SIDEBAR.addEventListener('scroll', this.calculateHeight);
        }
      }
    }

    perfUtils.endMarker('HdpNavBackClick');
    perfUtils.endMarker('HdpNavBackClick_OutsideList');
    perfUtils.endMarker('HdpNavBackClick');
    perfUtils.endMarker('HdpNavForwardClick');
    perfUtils.endMarker('ListingWrapperClick');
    perfUtils.endMarker('ListingCardV3Click');
    perfUtils.endMarker('MapMarkerClick');
    perfUtils.endMarker('SimilarListingsClick');
    trackListingView(listing);
    trackingUtils.goog.appendScript();

    if (isRep) {
      window.addEventListener('keydown', this.handleKeyDown);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.showInstantTourOnLoad) {
      return;
    }

    if (prevProps.device && this.props.device) {
      if (prevProps.device.screenWidth !== this.props.device.screenWidth) {
        // these HTML elements will shift with screen size
        this.getScrollPositions();
      }
    }
  }

  componentWillUnmount() {
    const { dispatch, sidebarId } = this.props;

    if (window.googletag) {
      window.googletag.cmd.push(() => {
        if (window.googletag.pubads && window.googletag.pubads() && window.googletag.pubads().clear) {
          window.googletag.pubads().clear();
        }
      });
    }

    if (sidebarId) {
      document.getElementById(this.props.sidebarId).removeEventListener('scroll', this.calculateHeight);
    }

    trackingUtils.removeElementById('google-ad-services');
    window.removeEventListener('scroll', this.calculateHeight);
    window.removeEventListener('keydown', this.handleKeyDown);

    // Need to clear instant tour form data when HDP listing is unmounted.
    dispatch(ListingEngineActions.hpTours.clearInstantTourForm());
  }

  getScrollPositions = () => {
    const { sidebarId } = this.props;
    if (!this.SIDEBAR && sidebarId) {
      this.SIDEBAR = document.getElementById(sidebarId) || {};
    }
    const contactButtonId = 'ContactButtonV2Sticky';
    this.CONTACT_BUTTON_OFFSET_TOP = document.getElementById(contactButtonId)?.offsetTop ?? 0;
    this.CONTACT_FORM_OFFSET_TOP = document.getElementById('HdpForm')?.offsetTop ?? 0;
  };

  calculateHeight = () => {
    const { contactButtonClass } = this.state;
    const scrollingElement = document.scrollingElement || {};
    const scrollTop = Math.max(this.SIDEBAR.scrollTop, scrollingElement.scrollTop);
    const additionalOffset = 21;

    let newContactButtonClass = contactButtonClass;

    if (scrollTop <= this.CONTACT_BUTTON_OFFSET_TOP + additionalOffset) {
      if (contactButtonClass !== '') {
        newContactButtonClass = '';
      }
    } else if (
      scrollTop > this.CONTACT_BUTTON_OFFSET_TOP + additionalOffset &&
      scrollTop <= this.CONTACT_FORM_OFFSET_TOP
    ) {
      if (contactButtonClass !== 'compact') {
        newContactButtonClass = 'compact';
      }
    } else if (scrollTop > this.CONTACT_FORM_OFFSET_TOP) {
      if (contactButtonClass !== 'flat') {
        newContactButtonClass = 'flat';
      }
    }

    if (newContactButtonClass !== contactButtonClass) {
      this.setState({ contactButtonClass: newContactButtonClass });
    }
  };

  handlePositionChange = () => {
    /**
     * If a user expands any section on the hdp, recalculate
     * this.CONTACT_FORM_OFFSET_TOP for the contact button animation
     */
    this.getScrollPositions();
  };

  handleActionPopup = yieldCallback((popupComponentName) => {
    this.setState({ visibleActionPopup: popupComponentName });
  });

  handleKeyDown(event) {
    const { listing } = this.props;
    if (event.ctrlKey && event.key === 'L') {
      window.open(constants.REP_HDP_URI + listing.aliasEncoded, '_blank');
    }
  }

  handleSetFullDetailPage = ({ fdpName, source, params = {} }) => {
    const { dispatch, isMobile } = this.props;

    if (isMobile) {
      appUtils.saveScrollPosition();
    }

    this.setState({ activeFullDetailPage: { name: fdpName, params } });

    dispatch(
      analyticsEvent(gaEvents.FDP_CLICK, {
        category: source,
        action: fdpName + 'FDPClick',
      }),
    );
  };

  handleHideFullDetailModal = yieldCallback(() => {
    const { isMobile } = this.props;
    this.setState({ activeFullDetailPage: null }, () => {
      if (isMobile) {
        appUtils.restoreScrollPosition();
      }
    });
  });

  handleContactCTAClick = yieldCallback(({ customGaEvent = null, customGaLabel = null } = {}) => {
    const { dispatch, isMobile } = this.props;
    const { contactButtonClass } = this.state;
    const ctaPosition = contactButtonClass ? 'fixed' : 'inline';

    if (isMobile) {
      appUtils.saveScrollPosition();
    }

    this.setState({ visibleActionPopup: 'contact' });

    if (customGaEvent) {
      // custom gaEvents are passed if called from FDPs
      dispatch(analyticsEvent(customGaEvent, { newLaneEvent: TrackRequestContactStart() }));
    } else {
      const gaLabel = customGaLabel ? `${ctaPosition} - ${customGaLabel}` : ctaPosition;
      dispatch(
        analyticsEvent(gaEvents.HDP_CONTACT_CTA_CLICK, {
          label: gaLabel,
          newLaneEvent: TrackRequestContactStart(),
        }),
      );
    }
  });

  refocusElementBeforePopup = (popupComponentName) => {
    if (popupComponentName === 'BookTourNowButton') {
      this.BookTourNowButtonRef.current?.focus();
    }
    if (popupComponentName === 'contactTourStatusModule') {
      this.TourStatusModuleRef.current?.focusContactPropertyButton();
    }
    if (popupComponentName === 'ModifyTourCTA') {
      this.TourStatusModuleRef.current?.focusRescheduleOrBookAnotherButton();
    }
  };

  handleHideActionPopup = yieldCallback(() => {
    const { dispatch, instantTourForm, isMobile } = this.props;
    const popupComponentName = this.state.visibleActionPopup;

    if (popupComponentName === 'BookTourNowButton' || popupComponentName === 'ModifyTourCTA') {
      dispatch(analyticsEvent(gaEvents.HPTOUR_INSTANT_TOUR_CLOSE, { label: instantTourForm.step }));
      this.getScrollPositions();
    }

    this.setState({ visibleActionPopup: null }, () => {
      if (isMobile) {
        appUtils.restoreScrollPosition();
      }
      this.refocusElementBeforePopup(popupComponentName);
    });
  });

  yieldToggleNavBarMenu = yieldCallback(() => {
    const { isNavBarMenuOpen } = this.state;

    if (!isNavBarMenuOpen) {
      this.props.dispatch(analyticsEvent(gaEvents.HDP_NAVBAR_MENU_OPEN));
    }
    this.setState({
      isNavBarMenuOpen: !isNavBarMenuOpen,
    });
  });

  handleNavBarMenuToggle = () => {
    this.yieldToggleNavBarMenu();
  };

  handleNavBarMenuActionClick = yieldCallback((componentName) => {
    this.handleActionPopup(componentName);
    this.handleNavBarMenuToggle();

    this.props.dispatch(
      analyticsEvent(gaEvents.HDP_NAVBAR_MENU_ACTION_CLICK, {
        action: componentName,
      }),
    );
  });

  handleToggleHideListing = yieldCallback(() => {
    const { dispatch, listing, isLoggedIn } = this.props;
    const isListingHidden = listing.userItemTypes && includes(listing.userItemTypes, 'hidden');
    this.handleNavBarMenuToggle();
    dispatch(UserItemActions.toggleUserItem('hidden', listing));
    if (!isLoggedIn) {
      return null;
    }
    if (!isListingHidden) {
      this.setState({ shouldShowHideNotification: true });
      this.hideNotificationTimeout = setTimeout(this.handleHideHideNotification, 3000);
      dispatch(analyticsEvent({ newLaneEvent: TrackHideHomeFromHdp() }));
    } else {
      dispatch(analyticsEvent({ newLaneEvent: TrackUnhideHomeFromHdp() }));
    }
  });

  handleUndoHideListing = () => {
    const { dispatch, listing } = this.props;
    this.handleHideHideNotification();
    dispatch(UserItemActions.toggleUserItem('hidden', listing));
  };

  handleHideHideNotification = () => {
    clearTimeout(this.hideNotificationTimeout);
    this.setState({ shouldShowHideNotification: false });
  };

  handleHdpToHdpOutsideOfList = () => {
    const { dispatch, currentPathname } = this.props;

    dispatch(ListingEngineActions.setCurrentListingOutsideOfList(true, currentPathname));
  };

  handleRecommendedListingClick = () => {
    const { dispatch } = this.props;

    dispatch(analyticsEvent(gaEvents.HDP_RECOMMENDED_CLICK));
    this.handleHdpToHdpOutsideOfList();
  };

  handleExpiredRecommendationClick = () => {
    const { dispatch } = this.props;

    dispatch(analyticsEvent(gaEvents.HDP_EXPIRED_RECOMMENDATION_CLICK));
    this.handleHdpToHdpOutsideOfList();
  };

  handlePropertyPortfolioListingClick = () => {
    const { dispatch } = this.props;
    dispatch(analyticsEvent(gaEvents.HDP_RECOMMENDED_CLICK));
    this.handleHdpToHdpOutsideOfList();
  };

  handleRecentlyViewedClick = () => {
    const { dispatch } = this.props;

    dispatch(analyticsEvent(gaEvents.HDP_RECENTLY_VIEWED_RECOMMENDED_CLICK));
    this.handleHdpToHdpOutsideOfList();
  };

  handleSimilarListingsClick = () => {
    const { dispatch } = this.props;

    perfUtils.setMarker('SimilarListingsClick');
    dispatch(analyticsEvent(gaEvents.HDP_SIMILAR_LISTINGS_CLICK, { newLaneEvent: TrackHdpSimilarListingClick() }));
    this.handleHdpToHdpOutsideOfList();
  };

  getSchemaData = (listing) => {
    return getFaqSchemaTemplate({ data: { listing } });
  };

  render() {
    if (!this.props.listing) {
      return null;
    }

    const {
      dispatch,
      clientLoaded,
      conversations,
      device,
      filter,
      gamAttributes,
      indexInList,
      isAdmin,
      isHdpExp,
      listing,
      listings,
      portfolioListings,
      scheduledToursForUser,
      showInstantTourOnLoad,
      shouldShowAd,
      showMultiModels,
      similarListings,
    } = this.props;
    const {
      contactButtonClass,
      visibleActionPopup,
      isNavBarMenuOpen,
      shouldShowHideNotification,
      activeFullDetailPage,
    } = this.state;
    const {
      active: isListingActive,
      isAllowedToRetain,
      aliasEncoded,
      broker,
      details,
      history,
      ratingsAndReviews,
      rentalApplicationStatus,
      trusted,
      waitlisted,
    } = listing;
    const isListingHidden = listing.userItemTypes && includes(listing.userItemTypes, 'hidden');
    const listOfHdpUrls = listings.map((summary) => summary.uriV2);
    const faqSectionData = trusted && this.getSchemaData(listing);
    const hasConversation = conversations[aliasEncoded];
    const satelliteEnabled = hasConversation ? conversations[aliasEncoded].satelliteEnabled : false;
    const shouldDisplayBrokerInfo = broker?.isBrokerExclusiveListing || broker?.isBrokerListing;
    const { leaseTermsAndUtilitiesShareDescription } = details ?? {};
    const { inPersonProvider, liveVideoProvider, selfGuidedProvider } = details.instantTourProviders ?? {};
    const isInstantTourAvailable = inPersonProvider || liveVideoProvider || selfGuidedProvider;
    const scheduledTour = getScheduledTour(aliasEncoded, scheduledToursForUser);
    const userHasTourForListing = Object.keys(scheduledTour).length > 0;

    if (showInstantTourOnLoad) {
      let isRescheduling = false;

      if (userHasTourForListing) {
        const scheduledDateTime = dateUtils.appendTimeISOSubstringIfMissing(scheduledTour.startTime);
        isRescheduling = !dateUtils.isDateInThePast(scheduledDateTime);
      }

      return (
        <ActionPopupWrapper
          componentName={isRescheduling ? 'ModifyTourCTA' : 'BookTourNowButton'}
          onActionPopup={() => {}}
          onHidePopup={() => {}}
        />
      );
    }

    const showTourStatusModule = userHasTourForListing;
    const showScheduleTourButton = isListingActive && isInstantTourAvailable && !showTourStatusModule;
    const showContactButton = (!hasConversation || !satelliteEnabled) && isListingActive;
    const showApplicationButton =
      isListingActive &&
      (!trusted || shouldShowRequestToApplyButton(rentalApplicationStatus)) &&
      ((!showTourStatusModule && !showScheduleTourButton) || !showScheduleTourButton);

    /**
     * Data should be hidden per MLS if the listing is:
     * -- active: false
     * -- isAllowedToRetain: false
     */
    const shouldBeHiddenIfDataCannotBeRetained = isListingActive === false && isAllowedToRetain === false;

    // TODO HP-6197: delete later after AA test is complete.
    if (isAdmin) {
      console.log(`isHdpExp: ${isHdpExp}`);
    }

    return (
      <div id="Hdp-container" className="Hdp">
        <ListingNavBar
          contactButtonClass={contactButtonClass}
          currentListingIndex={indexInList}
          listOfHdpUrls={listOfHdpUrls}
          listing={listing}
          isMenuOpen={isNavBarMenuOpen}
          onActionClick={this.handleActionPopup}
          onMenuToggle={this.handleNavBarMenuToggle}
          onContactFabClick={this.handleContactCTAClick}
        />
        {isNavBarMenuOpen && (
          <ListingNavBarMenu
            listing={listing}
            isListingHidden={isListingHidden}
            onToggleOpen={this.handleNavBarMenuToggle}
            onActionClick={this.handleNavBarMenuActionClick}
            onToggleHide={this.handleToggleHideListing}
          />
        )}
        {shouldShowHideNotification && (
          <ListingHiddenNotification
            isOpen={this.shouldShowHideNotification}
            onClose={this.handleHideHideNotification}
            onUndo={this.handleUndoHideListing}
          />
        )}
        <HdpPhotoGalleryV2
          listing={listing}
          key={listing.aliasEncoded}
          onClickContact={() => {
            this.handleActionPopup('contact');
          }}
          onClickOpenFdp={this.handleSetFullDetailPage}
        />
        {activeFullDetailPage && (
          <HdpFullDetailModalWrapper
            componentName={activeFullDetailPage.name}
            componentParams={activeFullDetailPage.params}
            onClickContact={this.handleContactCTAClick}
            onHideModal={this.handleHideFullDetailModal}
          />
        )}
        <div className="Hdp-listing-container" id="Hdp-listing-container">
          {!isListingActive && <ExpiredListingHeader history={history} />}
          {isListingActive && waitlisted && <WaitlistNotice />}
          <ChatButton listing={listing} />
          <LinkToBuilding listing={listing} />
          <HdpSummary listing={listing} />
          {hasConversation && <HdpSatelliteWrapper />}
          {!isListingActive && <ExpiredRecommendations onListingClick={this.handleSimilarListingsClick} />}
          {!isListingActive && <ZrmUpsellCarousel />}
          <section>
            {showTourStatusModule && (
              <S.TourStatusModuleContainer>
                <TourStatusModule
                  ref={this.TourStatusModuleRef}
                  listing={listing}
                  isRenderedOnHdp
                  onBookAnotherTourClick={() => {
                    this.handleActionPopup('BookTourNowButton');
                    const yieldTracking = yieldCallback(() =>
                      dispatch(analyticsEvent(gaEvents.HPTOUR_BOOK_ANOTHER_TOUR)),
                    );
                    yieldTracking();
                  }}
                  onRescheduleTourClick={() => {
                    this.handleActionPopup('ModifyTourCTA');
                    const yieldTracking = yieldCallback(() =>
                      dispatch(analyticsEvent(gaEvents.HPTOUR_RESCHEDULE_TOUR_HDP)),
                    );
                    yieldTracking();
                  }}
                  onContactPropertyClick={() => {
                    this.handleActionPopup('contactTourStatusModule');
                    const yieldTracking = yieldCallback(() => dispatch(analyticsEvent(gaEvents.HPTOUR_CONTACT_HDP)));
                    yieldTracking();
                  }}
                />
              </S.TourStatusModuleContainer>
            )}
            {showContactButton && (
              <ContactButtonV2
                listing={listing}
                onClick={this.handleContactCTAClick}
                contactButtonOffsetTop={this.CONTACT_BUTTON_OFFSET_TOP}
              />
            )}
            {showApplicationButton && (
              <ZillowApplicationsButton showContactButton={showContactButton} onActionPopup={this.handleActionPopup} />
            )}
            {showScheduleTourButton && (
              <BookTourNowButtonV2
                showContactButton={showContactButton}
                ref={this.BookTourNowButtonRef}
                onClick={() => {
                  this.handleActionPopup('BookTourNowButton');
                  const yieldTracking = yieldCallback(() => dispatch(analyticsEvent(gaEvents.HPTOUR_BOOK_TOUR_NOW)));
                  yieldTracking();
                }}
              />
            )}
          </section>
          <SpecialOffers offers={listing.specialOffers} onActionPopup={this.handleActionPopup} />
          {shouldBeHiddenIfDataCannotBeRetained ? null : <HdpHighlights listing={listing} />}
          {showMultiModels && (
            <DeferredRender mode={RenderMode.RENDER_CLIENT_SIDE_ON_VISIBLE} visibilityOptions={{ rootMargin: '200px' }}>
              <InView onChange={this.handlePositionChange}>
                <MultiModelsGroup
                  filter={filter}
                  listing={listing}
                  onActionPopup={this.handleActionPopup}
                  onClickOpenFdp={this.handleSetFullDetailPage}
                />
              </InView>
            </DeferredRender>
          )}
          {shouldBeHiddenIfDataCannotBeRetained ? null : (
            <DeferredRender mode={RenderMode.RENDER_CLIENT_SIDE_ON_VISIBLE} visibilityOptions={{ rootMargin: '200px' }}>
              <>
                {shouldDisplayBrokerInfo && <BrokerInfoTop broker={broker} isListingActive={isListingActive} />}
                <Local listing={listing} />
              </>
            </DeferredRender>
          )}
          <DeferredRender mode={RenderMode.RENDER_CLIENT_SIDE_ON_VISIBLE} visibilityOptions={{ rootMargin: '200px' }}>
            <HdpPpcLink listing={listing} linkText="View more details on property website" ppcLocation="hdp" />
          </DeferredRender>
          {visibleActionPopup && (
            <ActionPopupWrapper
              componentName={visibleActionPopup}
              onActionPopup={this.handleActionPopup}
              onHidePopup={this.handleHideActionPopup}
            />
          )}
          <DeferredRender mode={RenderMode.RENDER_CLIENT_SIDE_ON_VISIBLE} visibilityOptions={{ rootMargin: '200px' }}>
            <AnchorOffset id="PropertyDetails" />
          </DeferredRender>
          {shouldBeHiddenIfDataCannotBeRetained ? null : (
            <DeferredRender mode={RenderMode.RENDER_CLIENT_SIDE_ON_VISIBLE} visibilityOptions={{ rootMargin: '200px' }}>
              <HdpDescription />
              <HdpDescriptionRoomForRent />
              <HdpAmenitySection listing={listing} screenWidth={device.screenWidth} />
            </DeferredRender>
          )}
          {shouldBeHiddenIfDataCannotBeRetained ? null : (
            <DeferredRender mode={RenderMode.RENDER_CLIENT_SIDE_ON_VISIBLE} visibilityOptions={{ rootMargin: '200px' }}>
              <PetPolicy onPetCtaClick={this.handleContactCTAClick} />
              <Popularity
                clientLoaded={clientLoaded}
                handleContactCta={this.handleActionPopup.bind(this, 'contact')}
                listing={listing}
              />
              <HdpOpenHouseText listing={listing} />
            </DeferredRender>
          )}
          {shouldBeHiddenIfDataCannotBeRetained ? null : (
            <DeferredRender mode={RenderMode.RENDER_CLIENT_SIDE_ON_VISIBLE} visibilityOptions={{ rootMargin: '200px' }}>
              <>
                <HdpTextSection
                  content={leaseTermsAndUtilitiesShareDescription}
                  header={`Terms at ${
                    listing.address && listing.address.hideStreet ? 'this listing' : listing.displayName
                  }`}
                />
                <HdpTextSection content={details.contactTimes} header={`Office hours at ${listing.displayName}`} />
                {!listing.isApartmentBldg && <SurroundingStats listing={listing} />}
              </>
            </DeferredRender>
          )}
          <DeferredRender mode={RenderMode.RENDER_CLIENT_SIDE_ON_VISIBLE} visibilityOptions={{ rootMargin: '200px' }}>
            <>
              <HdpSchools listing={listing} hideStreet={listing.address && listing.address.hideStreet} />
              {listing.legalDisclaimer && !isEmpty(listing.legalDisclaimer) && <HdpLegalInfo listing={listing} />}
              {listing.isApartmentBldg && (
                <HdpReviewsList
                  aliasEncoded={aliasEncoded}
                  ratingsAndReviews={ratingsAndReviews}
                  onClickReviews={() => this.handleActionPopup('reviews')}
                  listingName={listing.displayName}
                />
              )}
            </>
          </DeferredRender>
          {isListingActive && (
            <InView onChange={this.handlePositionChange}>
              <div>
                <div id="HdpFormWrapper">
                  <HdpForm
                    onShowSuccessModal={this.handleActionPopup}
                    listing={listing}
                    similarListings={similarListings}
                  />
                </div>
              </div>
            </InView>
          )}
          <DeferredRender mode={RenderMode.RENDER_CLIENT_SIDE_ON_VISIBLE}>
            <>
              {isListingActive && (
                <SimilarListingsContainer
                  currentListing={listing}
                  similarListings={similarListings}
                  onClick={this.handleSimilarListingsClick}
                />
              )}
              {isListingActive && (
                <PortfolioProperties
                  currentListing={listing}
                  portfolioListings={portfolioListings}
                  onListingClick={this.handleRecentlyViewedClick}
                />
              )}
            </>
          </DeferredRender>
          <DeferredRender mode={RenderMode.RENDER_CLIENT_SIDE_ON_VISIBLE}>
            <>
              <RecentlyViewed onListingClick={this.handleRecentlyViewedClick} />
              {trusted && faqSectionData && (
                <FaqSection data={faqSectionData.data} wrapper={HdpContentWrapper} {...faqSectionData.props} />
              )}
            </>
          </DeferredRender>
          {shouldShowAd && <Ad gamAttributes={gamAttributes} squareAd />}
          <DeferredRender mode={RenderMode.RENDER_CLIENT_SIDE_ON_VISIBLE}>
            <RepHdpLink listing={listing} />
            <BrokerListedBy />
          </DeferredRender>
          <DeferredRender mode={RenderMode.RENDER_CLIENT_SIDE_ON_VISIBLE}>
            <Container>
              <Row size="md">
                <BreadcrumbNav />
              </Row>
            </Container>
            <SeoFooter />
            <FooterNav noMarginTop />
          </DeferredRender>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootReduxState, ownProps) => {
  const isAdmin = state.user.info.roles.indexOf('admin') > -1;
  const isRep = state.user.info.roles.indexOf('rep') > -1;

  return {
    areaType: state.area.area.type,
    clientLoaded: state.app.clientLoaded,
    isClientLoadedPage: state.app.isClientSideLoadedPage,
    conversations: state.user.conversations,
    currentPathname: state.location.current.pathname,
    device: state.app.device,
    filter: state.filter,
    indexInList: state.currentListingDetails.indexInList,
    isAdmin,
    isLoggedIn: state.user.loggedIn,
    isMobile: state.app.device.screenWidth === 'sm' || state.app.device.screenWidth === 'xs',
    isRep,
    listing: state.currentListingDetails.currentListing,
    listings: state.listings.listingGroups.byCoords,
    instantTourForm: state.currentListingDetails.instantTourForm,
    scheduledToursForUser: state.user.scheduledTours,
    portfolioListings: state.listings.listingGroups.portfolio,
    showContactModalOnLoad: queryUtils.parse(ownProps.location.search).contactModal,
    showInstantTourOnLoad: state.location.ssrEntry.query.instantTour,
    similarListings: state.fetchListing.similarListings,
    isHdpExp: state.analytics.runningExperiments[constants.HOTPADS_WEB_HDP_AA_TEST]?.isControl === false,
  };
};

export default withRouter(connect(mapStateToProps)(Hdp));
