// @ts-nocheck
/* eslint-enable */
// App
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Lodash
import isEqual from 'lodash/isEqual';

// Components
import BuildingUnitGroup from 'app/shared/modules/hdp/BuildingHdp/BuildingUnitGroup';
import FooterNav from 'app/shared/modules/navigation/footer/FooterNav';
import ListingNavBar from 'app/shared/modules/hdp/ListingNavBar';
import ListingWrapper from 'app/shared/modules/listing-cards/ListingWrapper';

// Misc / utils
import { impression } from 'app/client/zg-analytics';
import controller from './controller';
import stringHelper from 'app/shared/utils/stringHelper';
import * as S from 'app/shared/modules/hdp/BuildingHdp/styles';
import { analyticsEvent } from 'app/client/universal-analytics';
import { TrackImpressionForPropertyCard } from 'app/shared/models/Clickstream/ListingCardClickstreamEvents';

const BuildingHdp = () => {
  // Redux
  const dispatch = useDispatch();
  const building = useSelector((state) => state.currentListingDetails.currentListing);
  const filter = useSelector((state) => state.filter);
  const listings = useSelector((state) => state.listings.listingGroups.byCoords);
  const currentListing = useSelector((state) => state.currentListingDetails.currentListing);
  const indexInList = useSelector((state) => state.currentListingDetails.indexInList);

  // React
  const [showRent, updateShowRent] = useState(false);

  const {
    rentUnits,
    matchingUnits,
    matchingUnitsRoom,
    matchingUnits0bed,
    matchingUnits1bed,
    matchingUnits2bed,
    matchingUnits3bed,
    matchingUnits4bedPlus,
  } = controller.getUnitsByType({ building, filter });
  const { displayName, address } = building;

  const getMatchingUnitsString = (numOfUnits) => {
    return stringHelper.pluralize(numOfUnits, 'unit', 'units');
  };

  const listOfHdpUrls = listings.map((summary) => summary.uriV2);

  const handleAllAvailableRentalsClick = useCallback(() => {
    updateShowRent(!showRent);
  }, [showRent]);

  const trackImpression = useCallback(
    (listing) => {
      const { aliasEncoded } = listing;

      impression({
        category: 'RentalBDP',
        label: 'buildingUnitCard',
        dimension5: currentListing.aliasEncoded,
        dimension60: aliasEncoded,
      });

      dispatch(
        analyticsEvent({
          newLaneEvent: TrackImpressionForPropertyCard({ listing, triggerLocation: 'building_unit_card' }),
        }),
      );
    },
    [currentListing.aliasEncoded, dispatch],
  );

  const SharedContent = (
    <>
      <S.BuildingSubText as="p">
        This building has {getMatchingUnitsString(matchingUnits.length)} that match your search. Each unit may be
        separately owned and have a different property manager.
      </S.BuildingSubText>

      {Boolean(matchingUnitsRoom.length) && <BuildingUnitGroup bedCount="r4r" unitType={matchingUnitsRoom} />}
      {Boolean(matchingUnits0bed.length) && <BuildingUnitGroup bedCount={0} unitType={matchingUnits0bed} />}
      {Boolean(matchingUnits1bed.length) && <BuildingUnitGroup bedCount={1} unitType={matchingUnits1bed} />}
      {Boolean(matchingUnits2bed.length) && <BuildingUnitGroup bedCount={2} unitType={matchingUnits2bed} />}
      {Boolean(matchingUnits3bed.length) && <BuildingUnitGroup bedCount={3} unitType={matchingUnits3bed} />}
      {Boolean(matchingUnits4bedPlus.length) && <BuildingUnitGroup bedCount={4} unitType={matchingUnits4bedPlus} />}
      {Boolean(rentUnits.length) && !isEqual(rentUnits, matchingUnits) && (
        <>
          <S.LinkToggle onClick={handleAllAvailableRentalsClick}>
            Show all available rentals ({rentUnits.length})
          </S.LinkToggle>

          {showRent && (
            <>
              {rentUnits.map((unit) => {
                return (
                  <ListingWrapper
                    listing={unit}
                    key={'rent' + unit.aliasEncoded}
                    trackImpression={trackImpression}
                    triggerLocation="home_details"
                    triggerObject="home_details_component|more_from_provider"
                  />
                );
              })}
            </>
          )}
        </>
      )}
    </>
  );

  return (
    <S.BuildingHdp>
      <ListingNavBar currentListingIndex={indexInList} listOfHdpUrls={listOfHdpUrls} listing={building} />
      <S.BuildingHdpContent>
        <S.BuildingTitle size="md">
          {displayName}
          <br />
          {address.city}, {address.state}
        </S.BuildingTitle>
        {SharedContent}
      </S.BuildingHdpContent>
      <S.AreaBreadcrumbs />
      <FooterNav noMarginTop />
    </S.BuildingHdp>
  );
};

export default React.memo(BuildingHdp);
