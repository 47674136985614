// @ts-nocheck
/* eslint-enable */
import assign from 'lodash/assign';
import forEach from 'lodash/forEach';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import reduce from 'lodash/reduce';
import { adapt_reduxToJava } from 'app/shared/flux/actions/FilterActions/adapters';

const controller = {
  getTabs({ floorplans, filter, isDefaultFilter }) {
    const tabs = [];
    if (isDefaultFilter) {
      tabs.push({
        title: 'All',
        floorplans: this.matchFloorplansToFilter(floorplans, filter),
      });
    } else {
      tabs.push({
        title: 'Matches',
        floorplans: this.matchFloorplansToFilter(floorplans, filter),
      });
    }

    const floorplansByBedNum = this.mergeBedrooms(this.floorplansByBedType(floorplans));

    forEach(floorplansByBedNum, (n, bedNumber) => {
      tabs.push({
        title: this.bedDict(bedNumber),
        floorplans: floorplansByBedNum[bedNumber],
      });
    });

    return tabs;
  },
  floorplansByBedType(floorplans) {
    const sortedFloorplans = {};

    floorplans.forEach((floorplan) => {
      sortedFloorplans[floorplan.beds] = sortedFloorplans[floorplan.beds] || [];
      sortedFloorplans[floorplan.beds].push(floorplan);
    });

    return sortedFloorplans;
  },
  matchFloorplansToFilter(floorplans, filter = {}) {
    const adaptedFilter = adapt_reduxToJava({ filter });
    const lowPrice = Number(adaptedFilter.lowPrice);
    const highPrice = Number(adaptedFilter.highPrice);
    const minSqft = Number(adaptedFilter.minSqft);
    const maxSqft = Number(adaptedFilter.maxSqft);

    const utcTimezone = 'T00:00:00.000';
    const endDate = filter.availability.end && new Date(filter.availability.end.concat(utcTimezone));
    const startDate = filter.availability.start && new Date(filter.availability.start.concat(utcTimezone));

    const arrayAdaptedBeds = adaptedFilter.bedrooms.split(',');
    const arrayAdaptedBaths = adaptedFilter.bathrooms.split(',');

    // filter floor plans by bed / bath / sqft first
    floorplans = floorplans.filter((floorplan) => {
      // Beds
      const bedsFromFilter = arrayAdaptedBeds.map((bed) => Number(bed));
      const currentFloorplanBeds = Number(floorplan.beds);
      const shouldFloorplanBeFilteredBasedOnBeds = !includes(bedsFromFilter, currentFloorplanBeds);
      const doesNotInclude8PlusBeds = !includes(arrayAdaptedBeds, '8plus');
      const firstBedFilterSelection = Number(arrayAdaptedBeds[0]);
      // Baths
      const bathsFromFilter = arrayAdaptedBaths.map((baths) => Number(baths));
      const currentFloorplanBaths = Number(floorplan.baths);
      const shouldFloorplanBeFilteredBasedOnBaths = !includes(bathsFromFilter, currentFloorplanBaths);
      const doesNotInclude8PlusBaths = !includes(arrayAdaptedBaths, '8plus');
      const firstBathFilterSelection = Number(arrayAdaptedBaths[0]);
      // Sqft
      const currentFloorplanSqft = Number(floorplan.sqft);

      if (
        shouldFloorplanBeFilteredBasedOnBeds &&
        (doesNotInclude8PlusBeds || currentFloorplanBeds < firstBedFilterSelection)
      ) {
        return false;
      } else if (
        shouldFloorplanBeFilteredBasedOnBaths &&
        (doesNotInclude8PlusBaths || currentFloorplanBaths < firstBathFilterSelection)
      ) {
        return false;
      }

      if ((minSqft && currentFloorplanSqft < minSqft) || (maxSqft && currentFloorplanSqft > maxSqft)) {
        return false;
      }

      return true;
    });

    // filter floor plan & units by price
    return reduce(
      floorplans,
      (acc, floorplan = {}) => {
        const currFloorplan = assign({}, floorplan);
        let shouldAdd = true;

        // depending on how the listing was posted, some floor plans have no units
        if (isEmpty(currFloorplan.units)) {
          const floorplanLow = Number(currFloorplan.low);
          const floorplanHigh = Number(currFloorplan.high);

          if (lowPrice && highPrice) {
            if (floorplanLow < lowPrice && floorplanHigh < lowPrice) {
              shouldAdd = false;
            } else if (floorplanLow > highPrice) {
              shouldAdd = false;
            }
          } else if (highPrice && floorplanLow > highPrice) {
            shouldAdd = false;
          } else if (lowPrice && floorplanLow < lowPrice) {
            shouldAdd = false;
          }
        } else {
          currFloorplan.units = currFloorplan.units.filter((unit = {}) => {
            const unitLow = Number(unit.low);
            const unitHigh = Number(unit.high);
            const modelAvailabilityDate = unit.availabilityDate && new Date(unit.availabilityDate.concat(utcTimezone));
            const noModelAvailabilityDate = isEmpty(unit.availabilityDate);

            // Hide floorplans if they fall outside of Rachel's desired move in date window
            const shouldHideUnavailableModels =
              (startDate && !noModelAvailabilityDate && modelAvailabilityDate < startDate) ||
              (endDate && !noModelAvailabilityDate && modelAvailabilityDate > endDate);

            if (lowPrice && highPrice) {
              if (unitLow < lowPrice && unitHigh < lowPrice) {
                return false;
              } else if (unitLow > highPrice) {
                return false;
              }
            } else if (highPrice && unitLow > highPrice) {
              return false;
            } else if (lowPrice && unitLow < lowPrice) {
              return false;
            }

            if (shouldHideUnavailableModels) {
              return false;
            }

            return true;
          });

          if (isEmpty(currFloorplan.units)) {
            shouldAdd = false;
          }
        }

        if (shouldAdd) {
          acc.push(currFloorplan);
        }

        return acc;
      },
      [],
    );
  },
  bedDict(number) {
    const dict = ['Studio', '1 Bed', '2 Beds', '3+ Beds'];

    if (number > 3) {
      return dict[3];
    } else {
      return dict[number];
    }
  },
  mergeBedrooms(floorplans) {
    // merge all 3+ bedrooms into one category
    const newModels = {};

    forEach(floorplans, (modelsArray, bedNumber) => {
      if (bedNumber < 3) {
        newModels[bedNumber] = modelsArray;
      } else if (!newModels[3]) {
        newModels[3] = modelsArray;
      } else {
        newModels[3] = (newModels[3] || []).concat(modelsArray);
      }
    });

    return newModels;
  },
};

export default controller;
