// @ts-nocheck
/* eslint-enable */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { yieldCallback } from '@zillow/yield-callback';

import FetchListingActions from 'app/shared/flux/actions/FetchListingActions';
import ListingEngineActions from 'app/shared/flux/actions/ListingEngineActions';
import ListingPreview from 'app/shared/modules/listing-cards/ListingPreview';
import RouteActions from 'app/shared/flux/actions/RouteActions';
import UserItemActions from 'app/shared/flux/actions/UserItemActions';
import * as S from './SlidingPreviewContainerStyles';
import { Swipeable } from 'app/shared/modules/navigation/Swipeable';
import { analyticsEvent } from 'app/client/universal-analytics';
import { TrackImpressionForPropertyCard } from 'app/shared/models/Clickstream/ListingCardClickstreamEvents';

const preventDefaultSwipeAction = (e) => {
  e = e || window.event;
  e.preventDefault();
};

class SlidingPreviewContainer extends React.Component {
  static propTypes = {
    isListingLoading: PropTypes.bool,
    listing: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    const listingIsLoading = false;

    this.props.dispatch(FetchListingActions.resetFetchListingState(listingIsLoading));
  }

  componentDidMount() {
    // Prevents mobile user swipes inside this element from causing the web app to move.
    const domElement = document.getElementsByClassName('listing-preview')[0];

    if (domElement) {
      domElement.addEventListener('touchmove', preventDefaultSwipeAction);
    }

    if (this.props.previewListing) {
      this.props.dispatch(UserItemActions.addUserItem('viewed', this.props.previewListing));
    }

    this.trackImpression();
  }

  componentDidUpdate(prevProps) {
    if (prevProps?.listing.aliasEncoded !== this.props?.listing.aliasEncoded) {
      this.trackImpression();
    }
  }

  componentWillUnmount() {
    const domElement = document.getElementsByClassName('listing-preview')[0];

    if (domElement) {
      domElement.removeEventListener('touchmove', preventDefaultSwipeAction);
    }
  }

  handleSwipeUp() {
    const { dispatch, listing } = this.props;
    dispatch(RouteActions.transitionToListing({ listingUri: listing.uriV2, keepMapLocation: true }));
  }

  trackImpression() {
    const { dispatch, listing } = this.props;
    dispatch(
      analyticsEvent({
        newLaneEvent: TrackImpressionForPropertyCard({ listing, triggerLocation: 'search_results_map' }),
      }),
    );
  }

  yieldTransitionToListing = yieldCallback(() => {
    const { dispatch, listing } = this.props;

    dispatch(RouteActions.transitionToListing({ listingUri: listing.uriV2, keepMapLocation: true }));
  });

  handleTap(e) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
    }

    this.yieldTransitionToListing();
  }

  handleSwipeDown() {
    this.props.dispatch(ListingEngineActions.clearPreviewAndCurrent());
  }

  render() {
    const { listing, isListingLoading } = this.props;
    if (isListingLoading) {
      return null;
    }

    return (
      <S.SlidingPreviewContainer onClick={(e) => this.handleTap(e)}>
        <Swipeable onSwipedDown={() => this.handleSwipeDown()} onSwipedUp={() => this.handleSwipeUp()}>
          <ListingPreview key={listing.aliasEncoded} listing={listing} listingPath={listing.uriV2} />
        </Swipeable>
      </S.SlidingPreviewContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isListingLoading: state.fetchListing.isListingLoading,
    previewListing: state.listings.listingGroups.previewListing,
  };
};

export default connect(mapStateToProps)(SlidingPreviewContainer);
