// @ts-nocheck
/* eslint-enable */
import styled from 'styled-components';
import { viewports } from 'app/shared/styles/_breakpoints';
import { colors } from 'app/shared/styles/_colors';
import { fontSize } from 'app/shared/styles/_fontsize';
import Button from 'app/shared/core/Button';

export const HdpPhotoGalleryV2Wrapper = styled.div`
  position: relative;
  height: 248px;
  padding: 16px 16px 0px 16px;

  @media ${viewports['xl-and-up-bigger-listing-cards']} {
    height: 348px;
    max-width: 830px;
    margin: 0 auto;
    padding: 16px 6px 0px 0px;
  }
`;

export const HdpPhotoGalleryV2ContentHeight = 230;
export const HdpPhotoGalleryV2ContentHeightXl = 332;

export const HdpPhotoGalleryV2Content = styled.div`
  position: relative;
  height: ${HdpPhotoGalleryV2ContentHeight + 'px'};

  @media ${viewports['xl-and-up-bigger-listing-cards']} {
    height: ${HdpPhotoGalleryV2ContentHeightXl + 'px'};
  }
`;

export const HdpPhotoGalleryV2ListingAgent = styled.div`
  position: absolute;
  bottom: 24px;
  left: 16px;
  color: ${colors['$hpx-white']};
  font-weight: normal;
  font-size: ${fontSize['$font-size-sm']};
`;

export const HdpPhotoGalleryV2TagWrapper = styled.div`
  position: absolute;
  top: 16px;
  left: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const HdpPhotoGalleryV2Tag = styled(Button)`
  font-size: ${fontSize['$font-size-tiny']};
  height: 24px;
  margin-bottom: 8px;
  & .Tooltip-children {
    border-bottom: 0px;
  }
`;

export const HdpPhotoGalleryV2TagIcon = styled.img`
  display: inline-block;
  height: 18px;
  width: 24px;
  vertical-align: top;
  padding-right: 8px;
`;
