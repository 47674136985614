// @ts-nocheck
/* eslint-enable */
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import styled from 'styled-components';

import Text from 'app/shared/core/Text';
import HdpContentWrapper from 'app/shared/modules/hdp/HdpContentWrapper';
import PpcLink from 'app/shared/modules/hdp/PpcLink';
import Container from 'app/shared/core/Container';

import { colors } from 'app/shared/styles/_colors';
import { viewports } from 'app/shared/styles/_breakpoints';

export const HdpAddressDisplayName = styled(Text)`
  color: ${colors['$hpx-blue-600']};
  font-weight: bold;
  margin-top: 16px;
  margin-bottom: 8px;
  font-size: 22px;
  line-height: 24px;
  @media ${viewports['md-and-up']} {
    font-size: 28px;
    line-height: 24px;
  }
`;

export const HdpAddressDisplayWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const HdpAddressDisplayAddress = styled(Text)`
  font-color: ${colors[`$hpx-grey-500`]};
`;

const HdpAddressV2 = ({ listing }) => {
  const { displayName, address } = listing;
  const { street, city, state, zip, hideStreet } = address;
  const displayNameHasStreet = displayName.includes(street);
  const displayAddress =
    hideStreet || displayNameHasStreet ? `${city}, ${state} ${zip}` : `${street}, ${city}, ${state} ${zip}`;

  return (
    <div className="HdpAddressV2">
      <HdpContentWrapper fullContent active noBorder noPadding>
        <Container>
          <HdpAddressDisplayWrapper>
            <HdpAddressDisplayName htmlTag="h1">{displayName}</HdpAddressDisplayName>
          </HdpAddressDisplayWrapper>
          <HdpAddressDisplayAddress htmlTag="h2">{displayAddress}</HdpAddressDisplayAddress>
          <PpcLink listing={listing} ppcLocation="address" />
        </Container>
      </HdpContentWrapper>
    </div>
  );
};

export default HdpAddressV2;
