// @ts-nocheck
/* eslint-enable */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ButtonV2 from 'app/shared/core/ButtonV2';
import { hdpSharedCSSButtonContainers } from 'app/shared/modules/hdp/Hdp/styles';
import IconInstantTour from 'images/icons/instant-tour-teal.svg';

export const StyledContainer = styled.div`
  ${hdpSharedCSSButtonContainers}
`;

const BookTourNowButtonV2 = React.forwardRef(({ showContactButton = false, onClick = () => {} }, ref) => {
  return (
    <StyledContainer showContactButton={showContactButton}>
      <ButtonV2
        ref={ref}
        onClick={onClick}
        btnType={'primary-outline'}
        rounded={false}
        full
        bold
        size={'lg'}
        iconGap="xs"
        icon={<img src={IconInstantTour} height="20px" width="22px" alt="" />}
      >
        Book tour now
      </ButtonV2>
    </StyledContainer>
  );
});

BookTourNowButtonV2.propTypes = {
  onClick: PropTypes.func,
};

export default BookTourNowButtonV2;
