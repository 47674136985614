/* eslint-disable no-nested-ternary */
import { css } from 'app/styled-system/css';

export const GridWallCSS = css({
  position: 'relative',
  display: 'grid',
  gap: '8px',
  gridAutoColumns: '1fr',
  width: '100%',
  margin: '0px',
  padding: '0px',
  maxHeight: 'none !important',
});

export const LargeTileCSS = css({
  gridColumn: { base: '', md: 'span 2' },
  height: { base: '286px', sm: '300px', md: '606px', lg: '606px', xl: '606px', xxl: '606px' },
});

export const SmallTileCSS = css({
  height: { base: '286px', sm: '300px', md: '300px', lg: '300px', xl: '300px', xxl: '300px' },
});
