// @ts-nocheck
/* eslint-enable */
import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class UserRoleRequired extends Component {
  static propTypes = {
    admin: PropTypes.bool,
    rep: PropTypes.bool,
    user: PropTypes.bool,
  };

  render() {
    const adminRequired = this.props.admin;
    const repRequired = this.props.rep;
    const userRequired = this.props.user;

    const isAdmin = this.props.userRoles.indexOf('admin') > -1;
    const isRep = this.props.userRoles.indexOf('rep') > -1;
    const isUser = this.props.userRoles.indexOf('user') > -1;

    if (adminRequired && isAdmin) {
      return this.props.children;
    } else if (repRequired && isRep) {
      return this.props.children;
    } else if (userRequired && isUser) {
      return this.props.children;
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    userRoles: state.user.info.roles,
  };
};

export default connect(mapStateToProps)(UserRoleRequired);
