// @ts-nocheck
/* eslint-enable */
import React, { Component } from 'react';
import styled from 'styled-components';
import { colors } from 'app/shared/styles/_colors';
import PropTypes from 'prop-types';
import cx from 'classnames';
import omit from 'lodash/omit';

import './style.scss';
import IconArrowGrey from 'images/icons/arrow-dark-grey-down.svg';
import Label from 'app/shared/core/Label';
import Text from 'app/shared/core/Text';

const { bool, string, func, any } = PropTypes;
const StyledAlertText = styled(Text)`
  color: ${colors['$hpx-red-400']};
`;
class Select extends Component {
  static propTypes = {
    children: any,
    disabled: bool,
    help: string,
    hpxStyle: string,
    label: string,
    onChange: func,
    required: bool,
  };

  static defaultProps = {
    children: null,
    disabled: false,
    help: '',
    hpxStyle: '',
    label: '',
    onChange: () => {},
    required: true,
  };

  handleChange = (e) => {
    const { disabled, onChange } = this.props;
    if (disabled) {
      return null;
    }

    onChange(e);
    e.target.blur();
  };

  render() {
    const { label, disabled, children, help, hpxStyle, required } = this.props;
    const rest = omit(this.props, ['help', 'hpxStyle']);

    return (
      <>
        {label && (
          <Label>
            {label}
            {!required && ' (optional)'}
          </Label>
        )}
        <div className="Select-input-container">
          <select
            {...rest}
            className={cx('Select-input', {
              disabled,
              error: hpxStyle === 'error',
            })}
            onChange={this.handleChange}
          >
            {children}
          </select>
          <span className="Select-dropdown-arrow">
            <img src={IconArrowGrey} height="12px" width="12px" alt="" />
          </span>
        </div>
        {help && <StyledAlertText size="sm">{help}</StyledAlertText>}
      </>
    );
  }
}

export default Select;
