// @ts-nocheck
/* eslint-enable */
import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';
import last from 'lodash/last';
import styled from 'styled-components';

// Actions
import AppActions from 'app/shared/flux/actions/AppActions';
import RouteActions from 'app/shared/flux/actions/RouteActions';
import UserItemActions from 'app/shared/flux/actions/UserItemActions';

// Components
import Animation from 'app/shared/modules/Animation';
import FloatingActionContactButton from 'app/shared/modules/hdp/ContactButtonV2/FloatingActionContactButton';
import HdpToHdpNav from 'app/shared/modules/hdp/HdpToHdpNav';
import HeaderNavMobileLogo from 'app/shared/modules/navigation/header/HeaderNavMobileLogo';
import Linker from 'app/shared/modules/Linker';
import PopupModal from 'app/shared/modules/popups/PopupModal';
import ShareModal from 'app/shared/modules/hdp/ShareModal';
import Text from 'app/shared/core/Text';
import VisuallyHidden from 'app/shared/modules/VisuallyHidden';

// Misc / Utils
import * as S from './styles';
import './style.scss';
import { analyticsEvent } from 'app/client/universal-analytics';
import { gaEvents } from 'app/shared/constants/AnalyticsConstants';
import animationData from 'images/animations/heart-bluetoyellowAnimation.json';
import IconBuilding from 'images/icons/building.svg';
import IconMenu from 'images/icons/dots.svg';
import IconShare from 'images/icons/share-v2.svg';
import IconX from 'images/icons/x.svg';
import { listingUtils_checkFavorite } from 'app/shared/utils/listingUtils';
import { yieldCallback } from '@zillow/yield-callback';
import {
  TrackHdpNativeShare,
  TrackSaveHomeClick,
  TrackShareHomeFromHdp,
  TrackUnSaveHomeClick,
} from 'app/shared/models/Clickstream/HdpClickstreamEvents';
import { getGlobalLogger } from '@zg-rentals/logger-base';
import { logError } from '@zg-rentals/log-error';

const logger = getGlobalLogger('modules/hdp');
const StyledVertCenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const StyledVertCenterLinker = styled(Linker)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
class ListingNavBar extends Component {
  static propTypes = {
    isMobile: PropTypes.bool,
    onMenuToggle: PropTypes.func,
    listing: PropTypes.object,
    currentListingIndex: PropTypes.number,
    listOfHdpUrls: PropTypes.arrayOf(PropTypes.string),
  };

  static defaultProps = {
    isMobile: false,
    onMenuToggle: () => {},
  };

  constructor(props) {
    super(props);

    this.savedActiveElement = null;
    this.state = { showShareModal: false, backUrl: this.getBackUrl() };
  }

  componentDidUpdate(prevProps) {
    const { filter } = this.props;
    if (filter !== prevProps.filter) {
      // this ensures back url links to the correct SRP
      this.setBackUrl();
    }
  }

  getBackUrl = () => {
    const { listing, dispatch } = this.props;
    const belongsToMultipleUnitBuilding = listing && listing.belongsToMultipleUnitBuilding;

    // byCoords and byMaloneLotId agree that the unit comes from a building AND we aren't on a building page
    if (belongsToMultipleUnitBuilding && !listing.building && this.belongsToBuildingBasedOnFilter()) {
      return dispatch(RouteActions.buildListingPathWithMap({ listingUri: listing.uriBuilding, keepMapLocation: true }));
    } else {
      return dispatch(RouteActions.getAreaPathWithMapData());
    }
  };

  setBackUrl = () => {
    this.setState({ backUrl: this.getBackUrl() });
  };

  // byCoords call determined the listing was a building (based on filter)
  belongsToBuildingBasedOnFilter = () => {
    const { listing, listings } = this.props;

    if (!listings.length) {
      // short circuit logic (and warning) until listings.listingGroups.byCoords is set
      return false;
    }

    const matchedListingInList = listings.filter((summary) => {
      return summary.maloneLotIdEncoded === listing.maloneLotIdEncoded;
    });

    if (matchedListingInList.length === 0) {
      logger?.info('ListingNavBar - could not find listing in byCoords list');

      return false;
    } else {
      return matchedListingInList[0].building;
    }
  };

  handleOpenHamburgerNav = yieldCallback(() => {
    const { dispatch } = this.props;

    this.savedActiveElement = document.activeElement;
    dispatch(AppActions.activateHamburger());
    dispatch(analyticsEvent(gaEvents.HAMBURGER_NAV_OPEN));
  });

  handleCloseHamburgerNav = () => {
    const { dispatch } = this.props;

    if (this.savedActiveElement) {
      this.savedActiveElement.focus();
    }

    dispatch(AppActions.deactivateHamburger());
    this.savedActiveElement = null;
  };

  yieldFavorite = yieldCallback((listing) => {
    const { dispatch } = this.props;
    dispatch(UserItemActions.toggleUserItem('favorite', listing));
  });

  handleFavorite = yieldCallback(() => {
    const { listing, dispatch } = this.props;
    const isFavorited = listingUtils_checkFavorite(listing);

    if (!isFavorited) {
      dispatch(
        analyticsEvent(gaEvents.HDP_FAVORITE_ATTEMPT, {
          newLaneEvent: TrackSaveHomeClick({
            listing,
            triggerLocation: 'home_details',
            triggerObject: 'home_details_component|action_bar',
          }),
        }),
      );
    } else {
      dispatch(
        analyticsEvent(gaEvents.HDP_UNFAVORITED_ATTEMPT, {
          newLaneEvent: TrackUnSaveHomeClick({
            listing,
            triggerLocation: 'home_details',
            triggerObject: 'home_details_component|action_bar',
          }),
        }),
      );
    }

    this.yieldFavorite(listing);
  });

  yieldShareClick = yieldCallback(() => {
    const { dispatch } = this.props;
    dispatch(analyticsEvent(gaEvents.HDP_SHARE, { newLaneEvent: TrackShareHomeFromHdp() }));

    if (navigator.share) {
      // Web Share API is supported
      navigator
        .share({
          title: 'HotPads listing',
          text: 'Check out this listing I found on HotPads!',
          url: window.location.href,
        })
        .then(() => {
          dispatch(analyticsEvent(gaEvents.HDP_NATIVE_SHARE, { newLaneEvent: TrackHdpNativeShare() }));
          logger?.info('Successfully shared via Web Share API');
        })
        .catch((err) => {
          logError({ error: err, context: 'Error sharing via Web Share API' });
        });
    } else {
      // Web Share API not supported - use ShareModal
      this.setState({ showShareModal: true });
    }
  });

  handleShareClick = () => {
    this.yieldShareClick();
  };

  handleCloseShareModal = yieldCallback(() => {
    this.setState({ showShareModal: false });
  });

  renderBack = () => {
    const { isSSRLandingPage, isMobile, isBuildingPage, listing, unreadMsgCount = 0 } = this.props;
    const { backUrl } = this.state;
    const isBuilding = (listing && listing.isBuilding) || isBuildingPage;
    const belongsToMultipleUnitBuilding = listing && this.belongsToBuildingBasedOnFilter();
    const isNotBuildingOrBelongsToBuilding = !isBuilding && !belongsToMultipleUnitBuilding;

    const yieldRouterTransition = yieldCallback(() => {
      window.router.transitionTo(backUrl);
    });

    const handleLinkerClick = (e) => {
      // Prevent the browser from going through with its default <a> behavior
      e.preventDefault();

      // Use react-router to navigate user back to the last screen
      yieldRouterTransition();
    };

    if (isSSRLandingPage) {
      return (
        <div className="ListingNavBar-landing">
          <StyledVertCenterLinker className="ListingNavBar-nav-hard-left-ssr" to={backUrl} onClick={handleLinkerClick}>
            <div className="ListingNavBar-more-listings">More Listings</div>
          </StyledVertCenterLinker>
          {isMobile && (
            <div className="ListingNavBar-nav-next-left">
              <HeaderNavMobileLogo
                onClick={this.handleOpenHamburgerNav}
                onCloseHamburgerNav={this.handleCloseHamburgerNav}
                unreadMsgCount={unreadMsgCount > 0}
              />
            </div>
          )}
        </div>
      );
    } else {
      return (
        <Linker
          className={cx('ListingNavBar-nav-hard-left', {
            'ListingNavBar-nav-hard-mobile': isMobile,
          })}
          to={backUrl}
          onClick={handleLinkerClick}
        >
          {isBuilding &&
            (isMobile ? ( // eslint-disable-line no-nested-ternary
              <img src={IconX} className="ListingNavBar-x-icon" alt="close" />
            ) : (
              <Text className="ListingNavBar-back" size="sm">
                Close
              </Text>
            ))}
          {!isBuilding && belongsToMultipleUnitBuilding && (
            <Text className="ListingNavBar-back" size="sm">
              Back to building
            </Text>
          )}
          {isNotBuildingOrBelongsToBuilding &&
            (isMobile ? ( // eslint-disable-line no-nested-ternary
              <img src={IconX} className="ListingNavBar-x-icon" alt="close" />
            ) : (
              <Text className="ListingNavBar-back" size="sm">
                Close
              </Text>
            ))}
        </Linker>
      );
    }
  };

  shouldShowHdpToHdp = () => {
    const { isSSRLandingPage, listing } = this.props;

    const hasMultipleUnitsAfterFilter = listing && this.belongsToBuildingBasedOnFilter();

    if (isSSRLandingPage) {
      return false;
    }

    if (listing.building) {
      return true;
    }

    if (listing.belongsToMultipleUnitBuilding && hasMultipleUnitsAfterFilter) {
      return false;
    }

    return true;
  };

  render() {
    const {
      currentListingIndex,
      existsOutsideOfList,
      isBuildingPage,
      isHamburgerOpen,
      isLoggedIn,
      isMenuOpen,
      lastVisitedListingFromListUri,
      listing,
      listOfHdpUrls,
      onMenuToggle,
      contactButtonClass,
      onContactFabClick,
    } = this.props;
    const { showShareModal } = this.state;

    if (!listing) {
      return null;
    }

    const isBuilding = (listing && listing.isBuilding) || isBuildingPage;
    const isFavorited = listingUtils_checkFavorite(listing);

    const showHdpToHdpnav = this.shouldShowHdpToHdp();

    return (
      <S.StyledListingNavBar
        className={cx({
          'ListingNavBar-hamburger-open': isHamburgerOpen,
          'ListingNavBar-hamburger-close': !isHamburgerOpen,
        })}
      >
        <div className="ListingNavBar-left">
          {!existsOutsideOfList && !lastVisitedListingFromListUri && this.renderBack()}

          {showHdpToHdpnav && (
            <HdpToHdpNav
              currentListingIndex={currentListingIndex}
              existsOutsideOfList={existsOutsideOfList}
              listOfHdpUrls={listOfHdpUrls}
              lastVisitedListingFromListUri={lastVisitedListingFromListUri}
            />
          )}
        </div>
        <StyledVertCenterContainer className="ListingNavBar-right">
          {isBuilding ? (
            <img src={IconBuilding} width="20px" height="20px" style={{ marginRight: '16px' }} alt="" />
          ) : (
            <div className="ListingNavBar-fav-menu">
              <button
                className="ListingNavBar-fav"
                onClick={this.handleFavorite}
                aria-pressed={isFavorited ? 'true' : 'false'}
              >
                <Animation
                  className="ListingNavBar-animation-icon"
                  isInteractive={isLoggedIn}
                  shouldUseLastFrame={isFavorited}
                  animationData={animationData}
                />
                <VisuallyHidden>Favorite</VisuallyHidden>
              </button>
              <button
                id="ListingNavBar-share"
                className={cx({
                  'ListingNavBar-share': true,
                  expanded: showShareModal,
                })}
                onClick={this.handleShareClick}
              >
                <img
                  src={IconShare}
                  width="20px"
                  height="20px"
                  alt="share listing"
                  fetchpriority="low"
                  decoding="async"
                />
              </button>
              <button
                id="ListingNavBar-menu"
                className={cx({
                  'ListingNavBar-menu': true,
                  expanded: isMenuOpen,
                })}
                onClick={onMenuToggle}
                aria-expanded={isMenuOpen ? 'true' : 'false'}
              >
                <img
                  src={IconMenu}
                  width="20px"
                  height="20px"
                  style={{ marginTop: '13px' }}
                  alt="listing menu"
                  fetchpriority="low"
                  decoding="async"
                />
              </button>
            </div>
          )}
        </StyledVertCenterContainer>
        {showShareModal && (
          <PopupModal
            aria-labelledby="dialog-title"
            onHidePopup={this.handleCloseShareModal}
            paddingInline="4x"
            position="top"
            slideUp
          >
            <ShareModal />
          </PopupModal>
        )}
        <FloatingActionContactButton contactButtonClass={contactButtonClass} onContactFabClick={onContactFabClick} />
      </S.StyledListingNavBar>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const isSSRLandingPage =
    state.app.isInitialSsrPage && ownProps.location.pathname === state.location.ssrEntry.pathname;

  // listing.isBuilding is not available early enough for the skeleton nav bar
  const isBuildingPage = last(ownProps.location.pathname.split('/')) === 'building';

  return {
    existsOutsideOfList: state.currentListingDetails.existsOutsideOfList,
    filter: state.filter,
    isBuildingPage,
    isHamburgerOpen: state.app.hamburger,
    isLoggedIn: state.user.loggedIn,
    isMobile: state.app.device.screenWidth === 'sm',
    isSSRLandingPage,
    lastVisitedListingFromListUri: state.currentListingDetails.lastVisitedListingFromListUri,
    listings: state.listings.listingGroups.byCoords,
    unreadMsgCount: state.user.unreadMsgCount,
  };
};

export default withRouter(connect(mapStateToProps)(ListingNavBar));
