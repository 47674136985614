import { css, cx } from 'app/styled-system/css';

export const carouselWrapperCX = css({
  bgColor: 'hpxGrey400',
  height: '224px',
  overflow: 'hidden',
  position: 'relative',
  width: { base: '100%', listingCardWidth: '402px' },
  borderRadius: '8px 8px 0px 0px',
});

export const carouselContentCX = css({
  transition: 'transform 0.3s',
  whiteSpace: 'nowrap',
});

export const touchTargetCX = css({
  alignItems: 'center',
  backgroundColor: 'transparent',
  cursor: 'pointer',
  display: 'flex',
  height: '48px',
  justifyContent: 'center',
  position: 'absolute',
  top: '110px',
  width: '48px',
  zIndex: '1',
});

export const prevTouchTargetCX = cx(
  touchTargetCX,
  css({
    left: '4px',
  }),
);

export const nextTouchTargetCX = cx(
  touchTargetCX,
  css({
    right: '4px',
  }),
);

export const getSlideButtonCX = (isVisible: boolean) =>
  css({
    alignItems: 'center',
    animation: 'fadingIn',
    borderRadius: '50%',
    cursor: 'pointer',
    display: 'flex',
    height: '32px',
    justifyContent: 'center',
    position: 'absolute',
    top: '118px',
    width: '32px',
    zIndex: '1',
    bgColor: isVisible ? 'white' : '',
    boxShadow: isVisible ? '0px 2px 4px rgba(0, 0, 0, 0.3)' : '',
  });

export const getPrevImageButtonCX = (isVisible: boolean) =>
  cx(
    getSlideButtonCX(isVisible),
    css({
      left: '8px',
    }),
  );

export const getNextImageButtonCX = (isVisible: boolean) =>
  cx(
    getSlideButtonCX(isVisible),
    css({
      right: '8px',
    }),
  );

export const getArrowImageCX = (isVisible: boolean) =>
  css({
    display: isVisible ? 'inline' : 'none',
  });

export const heroImageCX = css({
  bgColor: 'hpxGrey400',
  height: '268px',
  objectFit: 'cover',
  width: '402px',
});

export const dotWrapperCX = css({
  position: 'absolute',
  bottom: '8px',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: '1',
});
