// @ts-nocheck
/* eslint-enable */
// App
import React, { useCallback } from 'react';
import styled from 'styled-components';
import { colors } from 'app/shared/styles/_colors';

// Components
import ListingWrapper from 'app/shared/modules/listing-cards/ListingWrapper';

// Misc / utils
import { impression } from 'app/client/zg-analytics';
import controller from './controller';
import stringHelper from 'app/shared/utils/stringHelper';
import { BuildingArticleGroup, BuildingHdpGroupBeds, BuildingHdpGroupPrice, UnitList } from './styles';
import { useSelector } from 'react-redux';

const BEDROOM_COUNT_MAP = {
  0: 'Studio',
  1: '1 bedroom',
  2: '2 bedrooms',
  3: '3 bedrooms',
  4: '4+ bedrooms',
  r4r: 'Room for rent',
};
const StyledDarkTextSpan = styled.span`
  color: ${colors['$hpx-grey-600']};
`;
const BuildingUnitGroup = ({ bedCount, unitType }) => {
  const currentListing = useSelector((state) => state.currentListingDetails.currentListing);
  const unitTypeLength = unitType.length;

  const getMatchingUnitsString = (numOfUnits) => {
    return stringHelper.pluralize(numOfUnits, 'unit', 'units');
  };

  const trackImpression = useCallback(
    (listing) => {
      const { aliasEncoded } = listing;

      impression({
        category: 'RentalBDP',
        label: 'buildingUnitCard',
        dimension5: currentListing.aliasEncoded,
        dimension60: aliasEncoded,
      });
    },
    [currentListing.aliasEncoded],
  );

  return (
    <BuildingArticleGroup>
      <BuildingHdpGroupBeds>
        {BEDROOM_COUNT_MAP[bedCount]}
        <StyledDarkTextSpan> — {getMatchingUnitsString(unitTypeLength)}</StyledDarkTextSpan>
      </BuildingHdpGroupBeds>
      <BuildingHdpGroupPrice>{controller.getMinMaxPriceOfUnits(unitType)}</BuildingHdpGroupPrice>

      <UnitList>
        {unitType.map((unit) => {
          return (
            <ListingWrapper
              listing={unit}
              key={unit.aliasEncoded}
              trackImpression={trackImpression}
              triggerLocation="home_details"
              triggerObject="home_details_component|more_from_provider"
            />
          );
        })}
      </UnitList>
    </BuildingArticleGroup>
  );
};

export default BuildingUnitGroup;
