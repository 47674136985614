import styled from 'styled-components';
import Input from 'app/shared/core/Input';
import { fontSize } from 'app/shared/styles/_fontsize';
import InputPhoneNumber from 'app/shared/modules/InputPhoneNumber';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  padding: 0 20px;
`;

export const Header = styled.h2`
  width: 100%;
  font-weight: bold;
  font-size: ${fontSize['$font-size-xl']};
`;

export const TextInput = styled(Input)`
  width: 100%;
`;

export const PhoneTextInput = styled(InputPhoneNumber)`
  width: 100%;
`;
