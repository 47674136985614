// @ts-nocheck
/* eslint-enable */
import React from 'react';
import { useSelector } from 'react-redux';
import HdpContentWrapper from 'app/shared/modules/hdp/HdpContentWrapper';
import styled from 'styled-components';
import { font } from 'app/shared/styles/_fontsize';
import { colors } from 'app/shared/styles/_colors';

const HeadingTwo = styled.h2`
  margin: 16px 0px 16px 0px;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
`;

const HeadingThree = styled.h3`
  margin-top: 8px;
  font-weight: bold;
  color: ${colors['$hpx-grey-600']};
`;

const DescriptionText = styled.p`
  ${font.md};
`;

const HdpDescriptionRoomForRent = React.memo(() => {
  const listing = useSelector((state) => state.currentListingDetails.currentListing);
  const { active } = listing;
  const isExpired = !active;
  const preferredNewRoommate = listing.details.roomForRent.preferredNewRoommate;
  const { description, roommatesInfo, petsInfo } = listing.details.roomForRent.roommateDetails;

  if (!description && !roommatesInfo && !petsInfo && !preferredNewRoommate) {
    return null;
  }

  return (
    <HdpContentWrapper
      active={isExpired}
      collapsable
      header={description ? `About housemate(s)` : 'Housemate details'}
      headerHtmlTag="h2"
      style={{ whitespace: 'pre-wrap' }}
    >
      {description && (
        <>
          <DescriptionText>{description}</DescriptionText>
          <HeadingTwo>Housemate details</HeadingTwo>
        </>
      )}
      {roommatesInfo && (
        <>
          <HeadingThree>Current housemates</HeadingThree>
          <DescriptionText>{roommatesInfo}</DescriptionText>
        </>
      )}
      {petsInfo && (
        <>
          <HeadingThree>Current pets</HeadingThree>
          <DescriptionText>{petsInfo}</DescriptionText>
        </>
      )}
      {preferredNewRoommate && (
        <>
          <HeadingThree>Preferred new housemate</HeadingThree>
          <DescriptionText>{preferredNewRoommate}</DescriptionText>
        </>
      )}
    </HdpContentWrapper>
  );
});

export default HdpDescriptionRoomForRent;
