/* eslint-disable no-undefined */
import type { ReactElement, ReactNode } from 'react';
import React, { forwardRef, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useYieldCallback } from '@zillow/yield-callback';

import { analyticsEvent } from 'app/client/universal-analytics';
import { gaEvents } from 'app/shared/constants/AnalyticsConstants';
import Container from 'app/shared/core/Container';
import IconArrowGrey from 'images/icons/arrow-grey.svg';
import * as S from './styles';

interface HdpContentWrapperProps {
  active?: boolean;
  children?: ReactNode;
  className?: string;
  collapsable?: boolean;
  fullContent?: boolean;
  headerAside?: ReactElement;
  header?: string;
  headerHtmlTag?: keyof JSX.IntrinsicElements;
  noBorder?: boolean;
  noPadding?: boolean;
  slimPadding?: boolean;
  parentRef?: React.Ref<HTMLDivElement>;
}

const HdpContentWrapper = forwardRef<HTMLDivElement, HdpContentWrapperProps>(
  (
    {
      active = false,
      children,
      className,
      collapsable = false,
      fullContent = false,
      headerAside,
      header,
      headerHtmlTag = 'h2',
      noBorder = false,
      noPadding = false,
      slimPadding = false,
      parentRef = null,
    },
    ref,
  ) => {
    const dispatch = useDispatch();
    const isMobile = useSelector(
      (state: any) => state.app.device.screenWidth === 'sm' || state.app.device.screenWidth === 'xs',
    );

    const [isActive, setIsActive] = useState(active);
    const headerId = header && header.split(' ').join('-') + '-header';

    const yieldTrackAndToggle = useYieldCallback(() => {
      dispatch(
        analyticsEvent(gaEvents.HDP_TOGGLE_SECTION, {
          label: header,
        }),
      );
      setIsActive(!isActive);
    });

    const toggleActive = useCallback(() => {
      yieldTrackAndToggle();
    }, [yieldTrackAndToggle]);

    return (
      <S.HdpContentWrapper noBorder={noBorder} noPadding={noPadding} ref={parentRef || ref}>
        {header && (
          <S.Header
            as="header"
            id={headerId}
            onClick={collapsable ? toggleActive : undefined}
            aria-expanded={isMobile ? isActive : undefined}
          >
            <S.HeaderLeftColumn>
              {collapsable && (
                <S.DropdownWrapper>
                  <S.DropdownIcon isActive={isActive} src={IconArrowGrey} alt="" />
                </S.DropdownWrapper>
              )}
              <S.HeaderTitle
                as={headerHtmlTag}
                // TODO: Figure out wtf this works?
                size={'lg' as unknown as number}
              >
                {header}
              </S.HeaderTitle>
            </S.HeaderLeftColumn>
            {headerAside && <S.HeaderRightColumn>{headerAside}</S.HeaderRightColumn>}
          </S.Header>
        )}
        <S.ContentContainer
          isActive={isActive}
          fullContent={fullContent}
          noHeader={!header}
          role="region"
          aria-labelledby={headerId}
        >
          {fullContent ? (
            children
          ) : (
            <Container className={className} slimPadding={slimPadding}>
              {children}
            </Container>
          )}
        </S.ContentContainer>
      </S.HdpContentWrapper>
    );
  },
);

export default HdpContentWrapper;
