// @ts-nocheck
/* eslint-enable */
import React from 'react';
import { connect } from 'react-redux';
import { yieldCallback } from '@zillow/yield-callback';
import { analyticsEvent } from 'app/client/universal-analytics';
import { gaEvents } from 'app/shared/constants/AnalyticsConstants';
import IconZoom from 'images/icons/zoom.svg';
import noFloorplan from 'images/noFloorplan.png';
import './style.scss';

class FloorplanImage extends React.Component {
  constructor(props) {
    super(props);
  }

  yieldFloorplanImageClick = yieldCallback(() => {
    const { dispatch, modelId, name, onClickPopupModal } = this.props;
    onClickPopupModal();
    dispatch(
      analyticsEvent(gaEvents.MF_FLOORPLAN_CLICK, {
        label: modelId,
        value: name,
      }),
    );
  });

  handleClick = (e) => {
    e.stopPropagation();
    this.yieldFloorplanImageClick();
  };

  render() {
    const { photos, shouldShowZoom } = this.props;

    if (photos && photos[0]) {
      return shouldShowZoom ? (
        <div className="FloorplanImage-container" onClick={this.handleClick}>
          <div className="FloorplanImage-zoom-container">
            <img src={IconZoom} className="FloorplanImage-zoom-icon" alt="" />
          </div>
          <img className="FloorplanImage" src={photos[0].url} alt="" />
        </div>
      ) : (
        <img className="FloorplanImage" onClick={this.handleClick} src={photos[0].url} alt="" />
      );
    } else {
      return <img src={noFloorplan} alt="" />;
    }
  }
}

export default connect()(FloorplanImage);
