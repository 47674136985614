// @ts-nocheck
/* eslint-enable */
import React from 'react';
import { useSelector } from 'react-redux';

import HdpContentWrapper from 'app/shared/modules/hdp/HdpContentWrapper';
import { listingUtils_shouldShowPpcLink } from 'app/shared/utils/listingUtils';
import PpcLink from 'app/shared/modules/hdp/PpcLink';
import * as S from './styles';

const HdpPpcLink = (props = {}) => {
  const device = useSelector((state) => state.app.device);
  const { listing, ppcLocation } = props;

  if (!listingUtils_shouldShowPpcLink(listing.details, device)) {
    return null;
  }

  return (
    <HdpContentWrapper active fullContent>
      <S.HdpPpcLink>
        <PpcLink listing={listing} ppcLocation={ppcLocation} />
      </S.HdpPpcLink>
    </HdpContentWrapper>
  );
};

export { HdpPpcLink };
export default HdpPpcLink;
