// @ts-nocheck
/* eslint-enable */
import styled from 'styled-components';
import ButtonV2 from 'app/shared/core/ButtonV2';
import { hdpSharedCSSWidth } from 'app/shared/modules/hdp/Hdp/styles';
import { colors } from 'app/shared/styles/_colors';
import { viewports } from 'app/shared/styles/_breakpoints';
import { zindex } from 'app/shared/styles/_zindex';

const FAB_SIZE = '66px';
const FAB_HALF_SIZE = `calc(${FAB_SIZE} / 2)`;

const HDP_WIDTH_MOBILE = '100vw';
const HDP_SINGLE_COL_WIDTH = '452px';
const HDP_DOUBLE_COL_WIDTH = '880px';

const MOBILE_COORDS_HIDDEN = `calc((${HDP_WIDTH_MOBILE} / 2) - ${FAB_HALF_SIZE}), -60px, 0px`;
const MOBILE_COORDS_DISPLAYED = `calc((${HDP_WIDTH_MOBILE} / 2) - ${FAB_HALF_SIZE}), 22px, 0px`;
const SINGLE_COL_COORDS_HIDDEN = `calc((${HDP_SINGLE_COL_WIDTH} / 2) - ${FAB_HALF_SIZE}), -60px, 0px`;
const SINGLE_COL_COORDS_DISPLAYED = `calc((${HDP_SINGLE_COL_WIDTH} / 2) - ${FAB_HALF_SIZE}), 22px, 0px`;
const DOUBLE_COL_COORDS_HIDDEN = `calc((880px / 2) - ${FAB_HALF_SIZE}), -60px, 0px`;
const DOUBLE_COL_COORDS_DISPLAYED = `calc((${HDP_DOUBLE_COL_WIDTH} / 2) - ${FAB_HALF_SIZE}), 23px, 0px`;

export const HdpContactButtonV2Layout = styled.div`
  position: relative;
  ${hdpSharedCSSWidth}
  height: 48px;
  margin: 0 auto 16px;

  @media ${viewports['xl-and-up-bigger-listing-cards']} {
    display: inline-block;
    vertical-align: top;
    margin: 0 16px;
  }
`;

export const HdpContactButtonV2Container = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledContactButtonV2 = styled(ButtonV2)`
  ${hdpSharedCSSWidth}
  // translateZ forces GPU acceleration
    transform: translateZ(0);
  transition: all 0.25s ease-in-out;
`;

export const StyledContactIcon = styled.img`
  max-width: none;
`;

export const ContactFAB = styled(ButtonV2)`
  position: fixed;
  z-index: ${zindex['$z-index-over-parent']};
  width: ${FAB_SIZE};
  height: ${FAB_SIZE};
  border-radius: 60px;
  box-shadow: 1px 1px 2px ${colors['$hpx-grey-600']};

  /** When contact FAB is hidden from view */
  // Mobile
  transform: translate3d(${MOBILE_COORDS_HIDDEN});

  // Desktop single COL
  @media ${viewports['md-and-up']} {
    transform: translate3d(${SINGLE_COL_COORDS_HIDDEN});
  }

  // Desktop double COL
  @media ${viewports['xl-and-up-bigger-listing-cards']} {
    transform: translate3d(${DOUBLE_COL_COORDS_HIDDEN});
  }

  ${(props) =>
    props.contactButtonClass === 'compact' &&
    `
        /** When contact FAB is displayed */
        // Mobile
        transform: translate3d(${MOBILE_COORDS_DISPLAYED});

        // Desktop single COL
        @media ${viewports['md-and-up']} {
            transform: translate3d(${SINGLE_COL_COORDS_DISPLAYED});
        }

        // Desktop double COL
        @media ${viewports['xl-and-up-bigger-listing-cards']} {
            transform: translate3d(${DOUBLE_COL_COORDS_DISPLAYED});
        }
    `}
`;
