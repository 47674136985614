// @ts-nocheck
/* eslint-enable */
import styled from 'styled-components';
import { colors } from 'app/shared/styles/_colors';
import ListingWrapperBase from 'app/shared/modules/listing-cards/ListingWrapper';
import { font } from 'app/shared/styles/_fontsize';
import { inset } from 'app/shared/styles/_spacing';
import { viewports } from 'app/shared/styles/_breakpoints';

export const RecommendedText = styled.p`
  ${font.md};
  ${inset._1x};
`;

export const Divider = styled.div`
  border-bottom: 1px solid ${colors['$hpx-grey-100']};
  margin: auto;
  width: 60%;
  padding-top: 8px;
`;

export const ListingWrapper = styled(ListingWrapperBase)`
  width: 100%;
`;

export const ListContainer = styled.ul`
  padding: 24px 16px 0 16px;

  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  row-gap: 28px;
  max-height: calc(100vh - 250px);

  @media ${viewports['md-and-up']} {
    max-height: 450px;
  }
`;
