// @ts-nocheck
/* eslint-enable */
import cx from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { yieldCallback } from '@zillow/yield-callback';
import PropTypes from 'prop-types';
import './style.scss';

class Tooltip extends React.Component {
  static propTypes = {
    children: PropTypes.any,
    tip: PropTypes.any,
    device: PropTypes.object,
    tooltipPosition: PropTypes.string,
  };

  static defaultProps = {
    children: null,
    tip: null,
    device: {
      deviceType: 'desktop',
    },
    tooltipPosition: 'above',
  };

  constructor(props) {
    super(props);
    this.state = {
      showTooltip: false,
    };
  }

  componentDidMount() {
    window.addEventListener('click', this.handleClickCloseTooltip);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleClickCloseTooltip);
  }

  handleClickCloseTooltip = () => {
    const { showTooltip } = this.state;

    if (showTooltip) {
      this.setState({
        showTooltip: false,
      });
    }
  };

  yieldHandleClick = yieldCallback(() => {
    const { showTooltip } = this.state;
    this.setState({
      showTooltip: !showTooltip,
    });
  });

  handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.yieldHandleClick();
  };

  handleMouseOver = () => {
    const { device } = this.props;
    if (device.deviceType === 'desktop') {
      this.setState({ showTooltip: true });
    }
  };

  handleMouseLeave = () => {
    const { device } = this.props;
    if (device.deviceType === 'desktop') {
      this.setState({ showTooltip: false });
    }
  };

  render() {
    const { tip, children, tooltipPosition, width, className } = this.props;
    const { showTooltip } = this.state;
    return (
      <span
        className={cx('Tooltip', className)}
        onClick={this.handleClick}
        onMouseLeave={this.handleMouseLeave}
        onMouseOver={this.handleMouseOver}
      >
        {showTooltip && (
          <span>
            <span
              className={cx('Tooltip-arrow', {
                'Tooltip-arrow-above': tooltipPosition === 'above',
                'Tooltip-arrow-below': tooltipPosition === 'below',
              })}
            />
            <span
              style={{ width: width || '200px' }}
              className={cx('Tooltip-tip', {
                'Tooltip-tip-above': tooltipPosition === 'above',
                'Tooltip-tip-below': tooltipPosition === 'below',
              })}
            >
              {tip}
            </span>
          </span>
        )}
        <span className="Tooltip-children">{children}</span>
      </span>
    );
  }
}

const mapStateToProps = (state) => ({
  device: state.app.device,
});

export default connect(mapStateToProps)(Tooltip);
