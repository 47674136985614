import styled from 'styled-components';
import Linker from 'app/shared/modules/Linker';
import pathsManifest from 'app/shared/utils/pathsManifest';
import AlertIcon from 'images/icons/alert-hexagon.svg';
import { useSelector } from 'react-redux';
import type { RootReduxState } from 'app/types/redux';

const EmergencyBanner = styled.div`
  border-width: 0.5px;
  border-style: solid;
  border-color: #33a592;
  border-radius: 4px;
  background-color: #daf2f0;
  margin-bottom: 16px;
  padding: 8px;
  width: 100%;
`;

const BannerContent = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
`;

const AlertImage = styled.img`
  flex-shrink: 0;
`;

const BannerText = styled.div`
  flex: 1;
  line-height: 24px;
`;

export default function HdpEmergencyBanner() {
  const areaCounty = useSelector((state: RootReduxState) => state.area.area.county);
  const validCounties = ['Los Angeles County', 'Ventura County'];

  if (!areaCounty || !validCounties.includes(areaCounty)) {
    return null;
  }

  return (
    <EmergencyBanner>
      <BannerContent>
        <AlertImage src={AlertIcon} alt="Emergency Alert" />
        <BannerText>
          Important information for renters during a state of emergency.{' '}
          <Linker to={pathsManifest.emergencyHelpPage}>Learn more.</Linker>
        </BannerText>
      </BannerContent>
    </EmergencyBanner>
  );
}
