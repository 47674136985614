// @ts-nocheck
/* eslint-enable */
import cx from 'classnames';
import React from 'react';
import { connect } from 'react-redux';

import Linker from 'app/shared/modules/Linker';

import { analyticsEvent } from 'app/client/universal-analytics';
import { gaEvents } from 'app/shared/constants/AnalyticsConstants';
import AppActions from 'app/shared/flux/actions/AppActions';
import ListingEngineActions from 'app/shared/flux/actions/ListingEngineActions';
import RouteActions from 'app/shared/flux/actions/RouteActions';
import routeUtils from 'app/shared/utils/routeUtils';

import ArrowLeft from 'images/icons/ic-caret-left.svg';
import ArrowRight from 'images/icons/ic-caret-right.svg';
import ArrowLeftDisabled from 'images/icons/arrow-left-grey.svg';
import ArrowRightDisabled from 'images/icons/arrow-right-grey.svg';

import perfUtils from 'app/shared/utils/perfUtils';
import { canGoBackward, canGoForward, isProbablyLoading } from './controller';

import { yieldCallback } from '@zillow/yield-callback';

import './style.scss';
import { TrackHdpNextClick, TrackHdpPrevClick } from 'app/shared/models/Clickstream/HdpClickstreamEvents';
import { getGlobalLogger } from '@zg-rentals/logger-base';
import { logError } from '@zg-rentals/log-error';

const logger = getGlobalLogger('modules/hdp');

class HdpToHdpNav extends React.Component {
  static defaultProps = {
    currentListingIndex: null,
    listOfHdpUrls: [],
  };

  constructor(props) {
    super(props);

    this.changeHdp = this.changeHdp.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
    this.handleForwardClick = this.handleForwardClick.bind(this);
  }

  getListingUri = (index) => {
    const { listOfHdpUrls } = this.props;
    return listOfHdpUrls[index];
  };

  // find hdp in list and redirect to it
  changeHdp = yieldCallback((index) => {
    const { listOfHdpUrls, dispatch } = this.props;

    const listingUri = this.getListingUri(index);

    if (listingUri) {
      const maloneLotIdEncoded = routeUtils.getMaloneLotIdEncodedFromUrl(listingUri);
      dispatch(AppActions.setAreaPageScrollPosition(maloneLotIdEncoded));
      dispatch(ListingEngineActions.clearPreviewAndCurrent());
      dispatch(RouteActions.transitionToListing({ listingUri }));
    } else {
      logError({
        error: '​HdpToHdpNav -> changeHdp',
        errorType: 'HdpToHdpNavError',
        context: { index, listOfHdpUrlsLength: listOfHdpUrls?.length ?? 0 },
      });
    }
  });

  handleBackClick(e) {
    e.preventDefault();
    const { dispatch, currentListingIndex, existsOutsideOfList, lastVisitedListingFromListUri } = this.props;

    dispatch(analyticsEvent(gaEvents.HDP_PREV_CLICK, { newLaneEvent: TrackHdpPrevClick() }));

    if (existsOutsideOfList) {
      perfUtils.setMarker('HdpNavBackClick_OutsideList');
      dispatch(ListingEngineActions.clearPreviewAndCurrent());
      dispatch(ListingEngineActions.setCurrentListingOutsideOfList(false));
      dispatch(RouteActions.transitionToListing({ listingUri: lastVisitedListingFromListUri }));
    } else if (canGoBackward(currentListingIndex)) {
      perfUtils.setMarker('HdpNavBackClick');
      this.changeHdp(currentListingIndex - 1);
    }
  }

  handleForwardClick(e) {
    e.preventDefault();
    const { dispatch, currentListingIndex, listOfHdpUrls } = this.props;

    dispatch(analyticsEvent(gaEvents.HDP_NEXT_CLICK, { newLaneEvent: TrackHdpNextClick() }));

    if (canGoForward(currentListingIndex, listOfHdpUrls.length)) {
      perfUtils.setMarker('HdpNavForwardClick');
      this.changeHdp(currentListingIndex + 1);
    }
  }

  render() {
    const { currentListingIndex, listOfHdpUrls, existsOutsideOfList } = this.props;
    const hasIncompleteData = isProbablyLoading(currentListingIndex, listOfHdpUrls.length);
    const backDisabled = !canGoBackward(currentListingIndex) && !hasIncompleteData && !existsOutsideOfList;
    const forwardDisabled = !canGoForward(currentListingIndex, listOfHdpUrls.length) && !hasIncompleteData;
    const backUri = canGoBackward(currentListingIndex) ? this.getListingUri(currentListingIndex - 1) : '#';
    const forwardUri = canGoForward(currentListingIndex) ? this.getListingUri(currentListingIndex + 1) : '#';

    return (
      <div className="HdpToHdpNav">
        <Linker
          className={cx('HdpToHdpNav-left', {
            'HdpToHdpNav-direction': true,
            'HdpToHdpNav-direction-disabled': backDisabled,
          })}
          disabled={backDisabled}
          to={backUri}
          onClick={this.handleBackClick}
          tabIndex={0}
        >
          <img
            className="HdpToHdpNav-arrow"
            src={backDisabled ? ArrowLeftDisabled : ArrowLeft}
            alt="previous listing"
            aria-disabled={backDisabled ? 'true' : 'false'}
          />
        </Linker>
        {!existsOutsideOfList && (
          <Linker
            className={cx('HdpToHdpNav-right', {
              'HdpToHdpNav-direction': true,
              'HdpToHdpNav-direction-disabled': forwardDisabled,
            })}
            disabled={forwardDisabled}
            to={forwardUri}
            onClick={this.handleForwardClick}
            tabIndex={0}
          >
            <img
              className="HdpToHdpNav-arrow"
              src={forwardDisabled ? ArrowRightDisabled : ArrowRight}
              alt="next listing"
              aria-disabled={forwardDisabled ? 'true' : 'false'}
            />
          </Linker>
        )}
      </div>
    );
  }
}

export default connect()(HdpToHdpNav);
