// @ts-nocheck
/* eslint-enable */
import forEach from 'lodash/forEach';
import includes from 'lodash/includes';
import keys from 'lodash/keys';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import React from 'react';
import sortBy from 'lodash/sortBy';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { colors } from 'app/shared/styles/_colors';
import { yieldCallback } from '@zillow/yield-callback';

import { analyticsEvent } from 'app/client/universal-analytics';
import { gaEvents } from 'app/shared/constants/AnalyticsConstants';
import { areaUtils_forTypeOf } from 'app/shared/utils/areaUtils';
import HdpContentWrapper from 'app/shared/modules/hdp/HdpContentWrapper';
import Linker from 'app/shared/modules/Linker';
import LinkToggle from 'app/shared/modules/LinkToggle';
import { listingUtils_getDistanceToUserPoint } from 'app/shared/utils/listingUtils';
import PopupModal from 'app/shared/modules/popups/PopupModal';
import Row from 'app/shared/core/Row';
import SchoolItem from 'app/shared/modules/hdp/SchoolItem';
import SchoolRatingIcon from 'app/shared/modules/hdp/SchoolRatingIcon';
import 'app/shared/modules/hdp/HdpSchools.scss';
import TabGroup from 'app/shared/modules/TabGroup';
import TabItem from 'app/shared/modules/TabItem';
import Text from 'app/shared/core/Text';

function closestNSchools(listing, n) {
  const { schools } = listing;

  const processedSchools = schools.map((school) => {
    const locationParams = {
      originLat: listing.geo.lat,
      originLon: listing.geo.lon,
      lat0: school.geo.lat,
      lon0: school.geo.lon,
    };
    school.distance = listingUtils_getDistanceToUserPoint(locationParams).toFixed(1);
    return school;
  });
  const sortedSchools = sortBy(processedSchools, (school) => {
    return school.distance;
  });
  return sortedSchools.slice(0, n);
}

function groupSchoolsByType(schools) {
  const typeToSchoolsMap = {
    primaryschool: [],
    middleschool: [],
    highschool: [],
    mixedschool: [],
    university: [],
  };
  schools.forEach((school) => {
    typeToSchoolsMap[school.type].push(school);
  });
  return typeToSchoolsMap;
}

const INITIAL_VISIBLE_COUNT = 4;
let ALL_VISIBLE_COUNT = 0;
const SchoolTypes = {
  ALL: 'all',
  PRIMARY: 'primaryschool',
  MIDDLE: 'middleschool',
  HIGH: 'highschool',
  MIXED: 'mixedschool',
  UNIVERSITY: 'university',
};
const StyledDarkText = styled(Text)`
  color: ${colors['$hpx-grey-600']};
`;
class HdpSchools extends React.Component {
  static propTypes = {
    listing: PropTypes.object,
  };

  constructor(props) {
    super(props);
    const { listing } = this.props;

    const nearbySchools = closestNSchools(listing, 10);
    const typeToSchoolsMap = groupSchoolsByType(nearbySchools);

    this.state = {
      nearbySchools,
      typeToSchoolsMap,
      isExpanded: {
        all: false,
        primaryschool: false,
        middleschool: false,
        highschool: false,
        mixedschool: false,
        university: false,
      },
      showDisclaimer: false,
    };

    this.schoolRefs = {
      all: React.createRef(),
      primaryschool: React.createRef(),
      middleschool: React.createRef(),
      highschool: React.createRef(),
      mixedschool: React.createRef(),
      university: React.createRef(),
    };
  }

  componentDidUpdate(_, prevState) {
    const { isExpanded } = this.state;
    if (isExpanded !== prevState.isExpanded) {
      keys(isExpanded).forEach((schoolType) => {
        const showMoreButtonPressed = isExpanded[schoolType] !== prevState.isExpanded[schoolType];

        if (showMoreButtonPressed && isExpanded[schoolType]) {
          // set focus to start of the expanded content
          this.schoolRefs[schoolType].current.focus();
        }
      });
    }
  }
  yieldToggleShowMore = yieldCallback((schoolType) => {
    const { dispatch } = this.props;
    const { isExpanded } = this.state;
    this.setState({ isExpanded: { ...isExpanded, [schoolType]: !isExpanded[schoolType] } });

    dispatch(
      analyticsEvent(gaEvents.SHOW_MORE_LINK_TOGGLE, {
        category: 'HDP',
        label: 'HdpSchools',
      }),
    );
  });
  toggleShowMore = (schoolType) => {
    this.yieldToggleShowMore(schoolType);
  };

  handleToggleDisclaimer = yieldCallback(() => {
    const { showDisclaimer } = this.state;
    this.setState({ showDisclaimer: !showDisclaimer });
  });

  yieldTransitionToSchoolDistrictAreaClick = yieldCallback((uri) => {
    window.router.transitionTo(uri);
  });

  handleSchoolDistrictLinkClick = (e, uri) => {
    // Prevent the browser from going through with its default <a> behavior
    e.preventDefault();
    this.yieldTransitionToSchoolDistrictAreaClick(uri);
  };

  renderMessage = (districts) => {
    const { listing, hideStreet } = this.props;
    const { nearbySchools } = this.state;
    let schoolDistrictMessage = <span>This property at {listing.displayName} is within the boundaries of </span>;
    let schoolDistrictEndMessage = <span>.</span>;
    const schoolDistrictsArray = [];

    if (districts.length > 0) {
      if (nearbySchools.length > 0) {
        schoolDistrictMessage = (
          <span>
            Students who live {hideStreet ? 'near this listing' : `in ${listing.displayName}`} attend the following{' '}
          </span>
        );
        schoolDistrictEndMessage = <span> public schools:</span>;
      }

      districts.forEach((district, i, array) => {
        const districtFormattedName = areaUtils_forTypeOf[district.type].display.specific;
        let joinWord = null;

        // In some cases, a listing is part of multiple school districts. This handles how we separate
        // multiple schools districts. Commas + 'and' for more 2, just 'and' for only two types.
        if ((array.length === 2 && i !== array.length - 1) || (array.length > 2 && i === array.length - 2)) {
          joinWord = ' and ';
        } else if (array.length > 2 && i < array.length - 2) {
          joinWord = ', ';
        }

        schoolDistrictsArray.push(
          <span key={i}>
            <Linker
              to={district.uriV2}
              onClick={(e) => this.handleSchoolDistrictLinkClick(e, district.uriV2)}
              linkType="accent"
            >
              {district.name}
            </Linker>
            {!includes(district.name, districtFormattedName) && ' (' + districtFormattedName + ')'}
            {joinWord}
          </span>,
        );
      });

      return (
        <Row size="sm">
          <Text size="md">
            {schoolDistrictMessage}
            {schoolDistrictsArray}
            {schoolDistrictEndMessage}
          </Text>
        </Row>
      );
    } else {
      return null;
    }
  };

  getSchools = (type) => {
    const {
      listing: { areas },
    } = this.props;
    const { typeToSchoolsMap } = this.state;

    let schools = {};

    if (type === SchoolTypes.ALL) {
      ALL_VISIBLE_COUNT = 0;

      schools.all = [];

      // typeToSchoolsMap are already sorted by distance!
      forEach(typeToSchoolsMap, (v) => {
        if (v.length) {
          ALL_VISIBLE_COUNT += 1;

          schools.all.push(v[0]);
        }
      });

      // after the initial ones have been added, add the rest
      forEach(typeToSchoolsMap, (v) => {
        if (v.length > 1) {
          v.forEach((school, i) => {
            if (i > 0) {
              schools.all.push(school);
            }
          });
        }
      });
    } else if (type === SchoolTypes.UNIVERSITY) {
      schools = areas.schools;
    } else {
      schools = typeToSchoolsMap;
    }

    return schools[type];
  };

  renderSchools = (type) => {
    const { listing } = this.props;
    const { isExpanded } = this.state;
    const schools = this.getSchools(type);

    return (
      <ul className="Schools">
        {map(schools, (school, i) => {
          if (!isExpanded[type] && i >= INITIAL_VISIBLE_COUNT) {
            return false;
          }
          const isStartOfExpandedContent =
            (type === SchoolTypes.ALL && i === ALL_VISIBLE_COUNT) ||
            (type !== SchoolTypes.ALL && i === INITIAL_VISIBLE_COUNT);

          return (
            <SchoolItem
              ref={isStartOfExpandedContent ? this.schoolRefs[type] : null}
              hidden={!isExpanded[type] && type === SchoolTypes.ALL && i >= ALL_VISIBLE_COUNT}
              key={school + i}
              listing={listing}
              school={school}
            />
          );
        })}
      </ul>
    );
  };

  getNumSchoolsMessage = (schoolObj) => {
    let initialCount = schoolObj.totalSchools < 4 ? schoolObj.totalSchools : INITIAL_VISIBLE_COUNT;
    const isPlural = initialCount > 1;
    let schoolTitle;

    switch (schoolObj.type) {
      case SchoolTypes.PRIMARY:
        schoolTitle = `primary school${isPlural ? 's' : ''}`;
        break;
      case SchoolTypes.MIDDLE:
        schoolTitle = `middle school${isPlural ? 's' : ''}`;
        break;
      case SchoolTypes.HIGH:
        schoolTitle = `high school${isPlural ? 's' : ''}`;
        break;
      case SchoolTypes.MIXED:
        schoolTitle = `mixed school${isPlural ? 's' : ''}`;
        break;
      case SchoolTypes.UNIVERSITY:
        schoolTitle = isPlural ? 'universities' : 'university';
        break;
      default:
        // this is the ALL type, and this message only shows when not expanded
        initialCount = ALL_VISIBLE_COUNT;

        schoolTitle = `school${isPlural ? 's' : ''}`;
    }

    return (
      <span>
        {`Showing ${initialCount} of
                ${schoolObj.totalSchools} ${schoolTitle}.`}
      </span>
    );
  };

  getTabs = () => {
    const tabs = [];
    const {
      listing: { areas },
    } = this.props;
    const { nearbySchools, typeToSchoolsMap } = this.state;
    const { primaryschool, middleschool, highschool, mixedschool } = typeToSchoolsMap;
    if (nearbySchools.length > 0) {
      tabs.push({
        title: 'All',
        type: SchoolTypes.ALL,
        totalSchools: nearbySchools.length,
        schools: this.renderSchools(SchoolTypes.ALL),
      });
    }

    if (primaryschool.length > 0) {
      tabs.push({
        title: 'Primary',
        type: SchoolTypes.PRIMARY,
        totalSchools: primaryschool.length,
        schools: this.renderSchools(SchoolTypes.PRIMARY),
      });
    }

    if (middleschool.length > 0) {
      tabs.push({
        title: 'Middle',
        type: SchoolTypes.MIDDLE,
        totalSchools: middleschool.length,
        schools: this.renderSchools(SchoolTypes.MIDDLE),
      });
    }

    if (highschool.length > 0) {
      tabs.push({
        title: 'High',
        type: SchoolTypes.HIGH,
        totalSchools: highschool.length,
        schools: this.renderSchools(SchoolTypes.HIGH),
      });
    }

    if (mixedschool.length > 0) {
      tabs.push({
        title: 'Mixed',
        type: SchoolTypes.MIXED,
        totalSchools: mixedschool.length,
        schools: this.renderSchools(SchoolTypes.MIXED),
      });
    }

    // SchoolRestController doesn't return information about universities.
    // So, we get this information from the AreaRestController (nearby areas).
    if (areas.schools.university.length > 0) {
      tabs.push({
        title: 'University',
        type: SchoolTypes.UNIVERSITY,
        totalSchools: areas.schools.university.length,
        schools: this.renderSchools(SchoolTypes.UNIVERSITY),
      });
    }

    return tabs;
  };

  renderPreview = () => {
    const { nearbySchools } = this.state;
    const schoolPreview = [];

    // Prevent showing schools with no ratings in the header preview.
    nearbySchools.forEach((school) => {
      if (schoolPreview.length < 3) {
        if (school.rating !== 0) {
          schoolPreview.push(school);
        }
      }
    });

    return (
      <div className="HdpSchools-preview">
        {schoolPreview.map((school, i) => {
          return (
            <span className="HdpSchools-preview-rating" key={`school-${i}`}>
              <SchoolRatingIcon rating={school.rating} size="sm" />
            </span>
          );
        })}
      </div>
    );
  };

  render() {
    const { listing } = this.props;
    const { isExpanded, showDisclaimer } = this.state;
    const { areas } = listing;
    const isExpired = !listing.active;
    const hasTabs = this.getTabs().length >= 1;

    return (
      <div className="HdpSchools">
        <HdpContentWrapper
          active={isExpired}
          collapsable
          header="Nearby schools"
          headerAside={this.renderPreview()}
          headerHtmlTag="h2"
        >
          {this.renderMessage(areas.districts)}
          <Row>
            {hasTabs && (
              <TabGroup>
                {this.getTabs().map((tab, i) => {
                  const toggleMoreLess = `Show ${isExpanded[tab.type] ? 'less ' : 'more '}`;

                  return (
                    <TabItem tabTitle={tab.title} key={tab.title + i}>
                      {this.renderSchools(tab.type)}
                      <div className="HdpSchools-nearby">
                        {!isExpanded[tab.type] && (
                          <Row size="sm">
                            <Text size="tiny">{this.getNumSchoolsMessage(tab)}</Text>
                          </Row>
                        )}
                        {tab.totalSchools > INITIAL_VISIBLE_COUNT && (
                          <Row>
                            <LinkToggle onClick={() => this.toggleShowMore(tab.type)}>{toggleMoreLess}</LinkToggle>
                          </Row>
                        )}
                      </div>
                    </TabItem>
                  );
                })}
              </TabGroup>
            )}
            <Row size="sm">
              <StyledDarkText size="tiny">
                GreatSchools ratings are based on test scores and additional metrics when available.{' '}
                <Linker linkType="default" useButton onClick={this.handleToggleDisclaimer}>
                  <Text size="tiny">Learn more.</Text>
                </Linker>
                {showDisclaimer && (
                  <PopupModal
                    onHidePopup={this.handleToggleDisclaimer}
                    paddingInline="4x"
                    paddingTop="6x"
                    paddingBottom="6x"
                  >
                    <strong>About the ratings:</strong>
                    <p className="HdpSchools-disclaimer-section">
                      Historically,{' '}
                      <Linker target="_blank" to="https://www.greatschools.org/gk/ratings/" linkType="default">
                        GreatSchools
                      </Linker>{' '}
                      ratings have been based solely on a comparison of standardized test results for all schools in a
                      given state. As of September 2017, the GreatSchools ratings also incorporate additional
                      information, when available, such as college readiness, academic progress, advanced courses,
                      equity, discipline and attendance data. GreatSchools ratings are designed to be a starting point
                      to help parents compare schools, and they should not be the only factor used in selecting the
                      right school for your family.{' '}
                    </p>
                    <p className="HdpSchools-disclaimer-section">
                      <strong>Disclaimer:</strong>
                    </p>
                    <p className="HdpSchools-disclaimer-section">
                      School attendance zone boundaries are provided by a third party and subject to change. Check with
                      applicable school district prior to making a decision based on these boundaries. In addition,
                      school data is obtained from a third party vendor and not guranteed to be accurate, up to date or
                      complete.
                    </p>
                  </PopupModal>
                )}
              </StyledDarkText>
            </Row>
          </Row>
        </HdpContentWrapper>
      </div>
    );
  }
}

export default connect()(HdpSchools);
