// @ts-nocheck
/* eslint-enable */
import styled from 'styled-components';
import Container from 'app/shared/core/Container';
import { colors } from 'app/shared/styles/_colors';

export const OffersColumn = styled.div`
  display: inline-block;
  margin: 16px 0;
`;

export const IconColumn = styled(OffersColumn)`
  vertical-align: top;
  width: 36px;
`;

export const SpecialOffers = styled(Container)`
  padding-top: 24px;
  margin-bottom: 8px;
`;

export const PromoIcon = styled.img`
  display: inline-block;
  margin: 0 8px 0 12px;
`;

export const SpecialOfferContainer = styled.div`
  background-color: rgba(218, 242, 240, 0.25);
  border: 0.5px solid ${colors['$hpx-teal-100']};
  border-radius: 4px;
`;

export const OfferTextColumn = styled(OffersColumn)`
  padding-right: 36px;
  width: calc(100% - 36px);
`;

export const OfferDetails = styled.div`
  padding-bottom: 16px;

  &:last-child {
    padding-bottom: 0;
  }
`;

export const OfferText = styled.span`
  font-size: 14px;
  line-height: 16px;
  color: ${colors['$hpx-grey-600']};

  ${(props = {}) =>
    props.bold &&
    `
        	font-weight: bold;
    `}
`;
