// @ts-nocheck
/* eslint-enable */
import React from 'react';

import Row from 'app/shared/core/Row';
import SkeletonLine from 'app/shared/modules/skeleton/SkeletonLine';

const SkeletonContactListedBy: React.FC = () => {
  return (
    <div className="SkeletonContactListedBy">
      <Row size="sm">
        <SkeletonLine width="50%" />
      </Row>
      <SkeletonLine width="40%" />
      <Row size="sm">
        <SkeletonLine width="42%" />
      </Row>
    </div>
  );
};

export default SkeletonContactListedBy;
