// @ts-nocheck
/* eslint-enable */
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { padding } from 'app/shared/styles/_spacing';
import { colors } from 'app/shared/styles/_colors';
import { iconForListing } from 'app/client/utils/map/gmapIcons';
import CommuteAutocomplete from 'app/shared/modules/CommuteAutocomplete';
import CommuteTimesV2 from 'app/shared/modules/hdp/CommuteTimesV2';
import constants from 'app/shared/constants/ValueConstants';
import DisableOverlay from 'app/shared/modules/DisableOverlay';
import GoogleMap from 'app/shared/modules/map/GoogleMap';
import LocationMarker from 'images/location-marker.svg';
import Marker from 'app/client/google/Marker';
import Polyline from 'app/client/google/Polyline';
import Radio from 'app/shared/core/Radio';
import Toast from 'app/shared/modules/Toast';
import userPointMarker from 'images/user-point-marker.png';
import VisuallyHidden from 'app/shared/modules/VisuallyHidden';
import './style.scss';

const { MAX_COMMUTE_DISTANCE } = constants;
const MAP_PADDING_PX = 64;
const StyledCommuteOptionsContainer = styled.div`
  ${padding.top._2x};
`;
const StyledMileageText = styled.div`
  color: ${colors['$hpx-grey-600']};
`;
class CommuteSelectionModal extends React.Component {
  static defaultProps = {
    focusInput: false,
    userPoint: {},
  };

  constructor(props) {
    super(props);

    this.state = {
      googleMap: null,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { listing, minMaxLatLong = {}, userPoint = {} } = this.props;

    // Weird: If userPoint === null above, it's not set to default empty object...
    const userPointFromProps = userPoint || {};
    const prevUserPointFromProps = prevProps.userPoint || {};

    const mapLoaded = prevState.googleMap === null && this.state.googleMap !== null;
    const userPointChanged =
      prevUserPointFromProps.lat !== userPointFromProps.lat && prevUserPointFromProps.lon !== userPointFromProps.lon;

    if (mapLoaded || userPointChanged) {
      if (this.state.googleMap && minMaxLatLong) {
        if (minMaxLatLong.maxLat === minMaxLatLong.minLat || minMaxLatLong.maxLon === minMaxLatLong.minLon) {
          // mapBounds will be the same if no userPoint is found.
          // In this instance, programatically set map center point / zoom to listing.
          const latLng = new window.google.maps.LatLng(listing.geo.lat, listing.geo.lon);
          this.state.googleMap.setCenter(latLng);
          this.state.googleMap.setZoom(14);
        } else {
          const bounds = new window.google.maps.LatLngBounds(
            new window.google.maps.LatLng(minMaxLatLong.maxLat, minMaxLatLong.minLon),
            new window.google.maps.LatLng(minMaxLatLong.minLat, minMaxLatLong.maxLon),
          );

          this.state.googleMap.fitBounds(bounds, {
            top: MAP_PADDING_PX,
            bottom: MAP_PADDING_PX,
            right: MAP_PADDING_PX,
            left: MAP_PADDING_PX,
          });
        }
      }
    }
  }

  setMap = (googleMap) => {
    this.setState({ googleMap });
  };

  render() {
    const {
      departureTime,
      distance,
      focusInput,
      isMobile,
      listing,
      loading,
      onSetDepartureTime,
      showDefaultLocation,
      userPoint,
      userPointName,
    } = this.props;
    let infoWindowContent = null;
    let userPointPosition = {};

    const icon = iconForListing(listing, false, true);
    const position = {
      lat: listing.geo.lat,
      lng: listing.geo.lon,
    };

    const userIconDetails = {
      size: { width: 32, height: 32 },
      scaledSize: { width: 32, height: 32 },
      anchor: { x: 31 / 2, y: 25 / 2 },
      url: userPointMarker,
    };

    if (userPoint) {
      userPointPosition = {
        lat: userPoint.lat,
        lng: userPoint.lon,
      };

      const mode = this.props.activeRouteMode;
      const travelTime = this.props.travelTime[mode] + ' min';
      infoWindowContent = `
                <strong>${travelTime}</strong> commute by<br/>
                ${this.props.activeRouteMode + ' '}
                <span style="color:#888;">(${this.props.distance} miles)</span><br /><br />
                <a href="${this.props.gmapLinks[mode]}" target="_blank" style="color: #007a70">View in Google Maps</a>
            `;
    }

    return (
      <div className="CommuteSelection-body">
        <div className="CommuteSelection-title">Commute information</div>
        {isMobile && <CommuteAutocomplete focusInput={focusInput} userPoint={userPoint} />}
        <div className="CommuteSelection-map-container">
          <GoogleMap attachWindow={false} setMap={this.setMap}>
            {distance > MAX_COMMUTE_DISTANCE && (
              <Toast>Please enter a closer destination for more accurate commute information.</Toast>
            )}
            <Marker name={listing.displayName} position={position} icon={icon} />
            <Polyline path={this.props.path} />
            {userPoint && (
              <Marker
                icon={userIconDetails}
                infoWindowContent={infoWindowContent}
                name={listing.displayName}
                position={userPointPosition}
              />
            )}
          </GoogleMap>
        </div>
        <div className="CommuteSelection-right-sidebar">
          {!isMobile && (
            <div className="CommuteSelectorModal-sidebar-autocomplete">
              <div className="CommuteSelection-sidebar-title">Commuting to:</div>{' '}
              <CommuteAutocomplete dropDownStyle="CommuteSelectorModal" focusInput={focusInput} userPoint={userPoint} />
            </div>
          )}
          {!isMobile && (
            <StyledCommuteOptionsContainer className="HdpLocal-commute-details HdpLocal-commute-details-sidebar">
              <div className="HdpLocal-location-marker-container">
                <img className="HdpLocal-location-marker" src={LocationMarker} alt="" />
              </div>
              <div className="HdpLocal-commute-details-distance">
                {!loading && !showDefaultLocation && !userPoint && (
                  <Fragment>
                    <strong>Enter a commute destination</strong> to see commute options from this listing.
                  </Fragment>
                )}

                {!loading && !showDefaultLocation && userPoint && (
                  <Fragment>
                    Commute to <strong>{userPointName}</strong>.
                  </Fragment>
                )}

                {!loading && showDefaultLocation && (
                  <Fragment>
                    Commute to <strong>{userPoint.name}</strong> (city default)
                  </Fragment>
                )}
              </div>
              {distance && <StyledMileageText className="HdpLocal-mileage"> {distance} miles away.</StyledMileageText>}
            </StyledCommuteOptionsContainer>
          )}
          {isMobile ? (
            <VisuallyHidden>
              <div className="CommuteSelection-section-title">Select mode of travel to see route:</div>
            </VisuallyHidden>
          ) : (
            <div className="CommuteSelection-section-title">Select mode of travel to see route:</div>
          )}
          <div className="CommuteSelection-commute-options-container">
            <CommuteTimesV2
              activeRouteMode={this.props.activeRouteMode}
              onClick={this.props.onHandleSetActiveMode}
              gmapLinks={this.props.gmapLinks}
              travelTime={this.props.travelTime}
              userPoint={userPoint}
            />
          </div>
          {isMobile ? (
            <VisuallyHidden>
              <div className="CommuteSelection-section-title" id="departure-time-group">
                Departure time:
              </div>
            </VisuallyHidden>
          ) : (
            <div className="CommuteSelection-section-title" id="departure-time-group">
              Departure time:
            </div>
          )}
          <div className="CommuteSelection-departure-wrapper" role="radiogroup" aria-labelledby="departure-time-group">
            {(!userPoint || distance > MAX_COMMUTE_DISTANCE) && <DisableOverlay />}
            <div className="CommuteSelection-departure-option">
              <Radio
                ariaLabel="Depart now"
                isChecked={departureTime === 'now'}
                name="DepartureOption"
                id="departNow"
                onChange={onSetDepartureTime}
                value="now"
              >
                Now
              </Radio>
            </div>
            <div className="CommuteSelection-departure-option">
              <Radio
                ariaLabel="Depart during rush hour"
                isChecked={departureTime === 'rushHour'}
                name="DepartureOption"
                id="departRushHour"
                onChange={onSetDepartureTime}
                value="rushHour"
              >
                Rush hour
              </Radio>
            </div>
            <div className="CommuteSelection-departure-option">
              <Radio
                ariaLabel="Depart during off peak"
                isChecked={departureTime === 'offPeak'}
                name="DepartureOption"
                id="departOffPeak"
                onChange={onSetDepartureTime}
                value="offPeak"
              >
                Off peak
              </Radio>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.app.device.screenWidth === 'sm',
  };
};

export default connect(mapStateToProps)(CommuteSelectionModal);
