import styled from 'styled-components';
import { viewports } from 'app/shared/styles/_breakpoints';
import { inline, stacked } from 'app/shared/styles/_spacing';
import { colors } from 'app/shared/styles/_colors';
import { font } from 'app/shared/styles/_fontsize';

// Components to be styled
import AreaBreadcrumbsBase from 'app/shared/modules/navigation/AreaBreadcrumbs';
import LinkToggleBase from 'app/shared/modules/LinkToggle';
import TitleBase from 'app/shared/modules/Title';
import { HdpAddressDisplayName as HdpAddressDisplayNameBase } from 'app/shared/modules/hdp/HdpSummary/HdpSummaryAddress';

const hdpNavBarHeightMd = '42px';
const hdpNavBarHeightXl = '48px';

export const BuildingHdp = styled.div`
  position: relative;
  top: ${hdpNavBarHeightMd};
  @media ${viewports['xl-and-up']} {
    top: ${hdpNavBarHeightXl};
  }
`;

export const BuildingHdpContent = styled.div`
  min-height: calc(100vh - 421px);
  position: relative;
  padding: 16px;
  max-width: 920px;
`;

export const BuildingHdpGroupBeds = styled.div`
  ${stacked._2x};
  display: inline-block;
  width: 50%;
`;

export const BuildingHdpGroupPrice = styled.div`
  ${stacked._2x};
  display: inline-block;
  width: 50%;
  text-align: right;
  ${font.sm};
  color: ${colors['$hpx-grey-600']};
`;

export const AreaBreadcrumbs = styled(AreaBreadcrumbsBase)`
  padding: 0 16px 16px 16px;
`;

export const BuildingTitle = styled(TitleBase)`
  ${stacked._1x};
`;

export const BuildingSubText = styled.p`
  ${stacked._3x};
  ${font.sm};
  color: ${colors['$hpx-grey-600']};
`;

export const BuildingArticleGroup = styled.article`
  ${stacked._6x};
`;

export const LinkToggle = styled(LinkToggleBase)`
  ${stacked._2x};
`;

// HPWEB-6082: Support styles for Big Listing Cards
export const UnitList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 12px;

  @media (min-width: 768px) {
    display: flex;
    padding-bottom: 0;
    row-gap: 20px;
    column-gap: 20px;
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

export const BuildingHdpContentV2 = styled.div`
  position: relative;
  max-width: 635px;
`;

export const BuildingHdpV2AddressWrapper = styled.section`
  ${stacked._3x};
  display: flex;
  flex-direction: column;
`;

export const HdpAddressDisplayName = styled(HdpAddressDisplayNameBase)`
  display: flex;
  align-items: center;
`;

export const DisplayName = styled.span`
  ${inline._2x};
`;
