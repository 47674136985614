// @ts-nocheck
/* eslint-enable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import PropTypes from 'prop-types';
import clone from 'lodash/clone';
import map from 'lodash/map';
import styled from 'styled-components';
import { colors } from 'app/shared/styles/_colors';
import { yieldCallback } from '@zillow/yield-callback';

// Components
import Button from 'app/shared/core/Button';
import ContactCallToAction from 'app/shared/modules/photo-gallery/ContactCallToAction';
import ImageLoader from 'app/shared/modules/photo-gallery/ImageLoader';
import PhotoCarousel from 'app/shared/modules/PhotoCarousel';

// Misc / Utils
import './style.scss';
import { analyticsEvent } from 'app/client/universal-analytics';
import { gaEvents } from 'app/shared/constants/AnalyticsConstants';
import { listingUtils_getListingImgAltText, listingUtils_getListingImgTitleText } from 'app/shared/utils/listingUtils';
import controller from './controller';
import Icon3DGlasses from 'images/icons/3d-glasses-white.svg';
import IconArrowRight from 'images/icons/arrow-right-white.svg';
import IconMail from 'images/icons/mail-white.svg';
import noPhoto from 'images/noPhoto.png';

const PHOTO_THUMB_WIDTH = 80;
const { bool, func, number, array, arrayOf, shape, string } = PropTypes;

const StyledPhotoCountText = styled.span`
  color: ${colors['$hpx-white']};
  fill: ${colors['$hpx-white']};
  font-weight: bold;
`;

/**
 * This component displays listing photos (+ floorplans and 3D tour if available) in a scrollable carousel.
 * It is used for
 *   - the expanded photo view (via photo gallery) for DESKTOP ONLY
 *   - the expanded floorplan view (via HDP) for MWEB + DESKTOP
 */

class PhotoGalleryFull extends Component {
  static defaultProps = {
    defaultCurrentIndex: 0,
    onChange() {},
    onClickContact() {},
    onClickOpenFdp() {},
    photoCount: 0,
    photos: [{ url: noPhoto, caption: '' }],
    screenWidth: '',
    showContactUpsell: false,
    thumbPhotos: [{ url: noPhoto, caption: '' }],
    has3dTour: false,
  };

  static propTypes = {
    defaultCurrentIndex: number,
    onChange: func,
    onClickContact: func,
    onClickOpenFdp: func,
    photoCount: number,
    photos: arrayOf(shape({ url: string, caption: string })),
    screenWidth: string,
    showContactUpsell: bool,
    thumbPhotos: array,
    has3dTour: bool,
  };

  constructor(props) {
    super(props);
    const { thumbPhotos, defaultCurrentIndex } = props;

    this.state = {
      currentIndex: defaultCurrentIndex,
      cachedPhotos: [],
      thumbPhotos: controller.checkPhotos(thumbPhotos),
      bottomNavUsableWidth: 0,
      thumbsTranslateX: 0,
    };

    this.photoNav = React.createRef();
  }

  componentDidMount() {
    const { photos } = this.props;

    const cachedPhotos = map(photos, () => {
      return 0;
    });

    this.setState({
      cachedPhotos,
    });
  }

  componentDidUpdate(prevProp, prevState) {
    const { currentIndex } = this.state;
    if (currentIndex !== prevState.currentIndex) {
      this.scrollToActiveThumbnail();
    }
  }

  activeThumbnailIsHidden = () => {
    const { photos } = this.props;
    const { currentIndex, thumbsTranslateX, bottomNavUsableWidth } = this.state;
    if (currentIndex === photos.length - 1) {
      return false;
    }

    const isHiddenToLeft = currentIndex * PHOTO_THUMB_WIDTH < -thumbsTranslateX;
    const isHiddenToRight = (currentIndex + 1) * PHOTO_THUMB_WIDTH > bottomNavUsableWidth - thumbsTranslateX;

    return isHiddenToRight || isHiddenToLeft;
  };

  scrollToActiveThumbnail = () => {
    const { photos } = this.props;
    const { currentIndex } = this.state;
    const photoNavWidth = this.photoNav.current.offsetWidth;
    const maxVisiblePhotos = Math.floor(photoNavWidth / PHOTO_THUMB_WIDTH);
    const photosBeforeMiddle = Math.floor((photoNavWidth + PHOTO_THUMB_WIDTH) / 2 / PHOTO_THUMB_WIDTH);
    const maxTranslateX = -(photos.length * PHOTO_THUMB_WIDTH) + photoNavWidth;

    if (photos.length <= maxVisiblePhotos) {
      // don't scroll at all if all photos can fit on screen
      return;
    }

    if (currentIndex <= photosBeforeMiddle - 1) {
      this.setState({ thumbsTranslateX: 0 });
    } else {
      const translateXToCenterPhoto = photoNavWidth / 2 - currentIndex * PHOTO_THUMB_WIDTH - PHOTO_THUMB_WIDTH / 2;
      this.setState({ thumbsTranslateX: Math.max(maxTranslateX, translateXToCenterPhoto) });
    }
  };

  fetchNextImages = (start, end) => {
    const { photos } = this.props;
    const { currentIndex, cachedPhotos } = this.state;
    let newCachedPhotos;

    start = start || currentIndex;
    end = end || photos.length;

    // check to see if we're already maximum cache
    if (start >= end || start >= photos.length) {
      return;
    } else if (cachedPhotos[start]) {
      return;
    } else {
      newCachedPhotos = clone(cachedPhotos);
      newCachedPhotos[start] = photos[start];
      start += 1;
    }

    this.setState(
      {
        cachedPhotos: newCachedPhotos,
      },
      () => {
        this.fetchNextImages(start, end);
      },
    );
  };

  fetchPrevImages = (start, end) => {
    const { photos } = this.props;
    const { cachedPhotos } = this.state;
    let newCachedPhotos;

    start = start || photos.length - 1;
    end = end || 0;

    // check to see if we're already maximum cache
    if (start <= end || start <= 0) {
      return;
    } else if (cachedPhotos[start]) {
      return;
    } else {
      newCachedPhotos = clone(cachedPhotos);
      newCachedPhotos[start] = photos[start];
      start -= 1;
    }

    this.setState(
      {
        cachedPhotos: newCachedPhotos,
      },
      () => {
        this.fetchPrevImages(start, end);
      },
    );
  };

  handleImageChange = (index) => {
    const { onChange } = this.props;
    this.setState({ currentIndex: index });

    onChange(index);
  };
  // TODO: How to bind this to an index?
  handleClickThumbnail = (index, e) => {
    e.stopPropagation();

    this.props.dispatch(analyticsEvent(gaEvents.HDP_ADDITIONAL_PHOTO_VIEW));

    this.setState({ currentIndex: index }, function () {
      this.fetchNextImages(index, index + 1);
      this.fetchPrevImages(index, index - 1);
    });
  };

  handleOpenFdpClick = yieldCallback(() => {
    const { photoCount, onClickOpenFdp, photos, floorplanModelId } = this.props;
    const { currentIndex } = this.state;
    const currentPhoto = currentIndex < photos.length ? photos[currentIndex] : false;
    const isFloorplanPhoto = currentIndex >= photoCount;

    // floorplanModelId is only passed in from ModelFloorplanItem
    let modelId = floorplanModelId;
    if (!modelId) {
      modelId = isFloorplanPhoto && currentPhoto ? currentPhoto.modelId : '';
    }
    if (!modelId) {
      return;
    }

    onClickOpenFdp({
      fdpName: 'FloorPlans', // PascalCase because it is used for analytics
      source: 'PhotoGallery',
      params: { modelId },
    });
  });

  renderPhotoNav = () => {
    const { currentIndex, thumbPhotos, thumbsTranslateX } = this.state;
    const { listingAddress } = this.props;

    return (
      <ul className="PhotoGalleryFull-photo-nav-list" style={controller.getThumbnailStyle(thumbsTranslateX)}>
        {thumbPhotos.map((photo, index) => {
          const { displayName } = this.props;
          const photoCaption = photo.caption;
          const imgAltText = listingUtils_getListingImgAltText({
            photoCaption,
            nameOrAddress: displayName,
            isPrimaryPhoto: index === 0,
          });
          const imgTitleText = listingUtils_getListingImgTitleText({
            currentIndex: index,
            maxIndex: thumbPhotos.length,
            nameOrAddress: displayName,
            addressDetails: listingAddress,
          });

          return (
            <li
              className={cx({
                'PhotoGalleryFull-photo-thumb-wrapper': true,
                active: currentIndex === index,
              })}
              key={photo.url}
            >
              <button className="PhotoGalleryFull-overlay" onClick={this.handleClickThumbnail.bind(null, index)}>
                <div className="PhotoGalleryFull-photo-thumb">
                  <ImageLoader
                    className="PhotoGalleryFull-Img"
                    image={photo.url}
                    key={`${photo.url}`}
                    altText={imgAltText}
                    titleText={imgTitleText}
                  />
                </div>
              </button>
            </li>
          );
        })}
      </ul>
    );
  };

  render() {
    const {
      displayName,
      listingAddress,
      onClickContact,
      onPhotoGalleryTo3dTours,
      photoCount,
      photos,
      screenWidth,
      showContactUpsell,
      has3dTour,
    } = this.props;
    const { currentIndex, cachedPhotos } = this.state;

    const currentPhoto = currentIndex < photos.length ? photos[currentIndex] : false;
    const caption = currentPhoto ? currentPhoto.caption : '';
    const isFloorplanPhoto = currentIndex >= photoCount && currentIndex < photos.length;

    return (
      <div className="PhotoGalleryFull">
        <div className="PhotoGalleryFull-action-btns">
          {onClickContact && (
            <Button btnType="dark" className="PhotoGalleryFull-contact-btn" onClick={onClickContact}>
              <img src={IconMail} className="PhotoGalleryFull-contact-icon" />
              <span className="PhotoGalleryFull-info-link">Contact now</span>
            </Button>
          )}
          {has3dTour && (
            <button className="PhotoGalleryFull-3d-tours-btn-top" onClick={onPhotoGalleryTo3dTours}>
              <img src={Icon3DGlasses} className="PhotoGalleryFull-3d-tours-icon" />
              Switch to 3D tour
            </button>
          )}
        </div>
        <div className="PhotoGalleryFull-main-body">
          <div className="PhotoGalleryFull-carousel-container">
            <PhotoCarousel
              animateChange={!(screenWidth === 'lg' || screenWidth === 'xl')}
              defaultIndex={currentIndex}
              loopable
              onChange={this.handleImageChange}
              showItemCount={false}
            >
              {photos.map((photo, idx) => {
                const photoCaption = photo.caption;
                const imgAltText = listingUtils_getListingImgAltText({
                  photoCaption,
                  nameOrAddress: displayName,
                  isPrimaryPhoto: idx === 0,
                });
                const imgTitleText = listingUtils_getListingImgTitleText({
                  currentIndex: idx,
                  maxIndex: photos.length,
                  nameOrAddress: displayName,
                  addressDetails: listingAddress,
                });
                return (
                  <ImageLoader image={photo.url} key={`${photo.url}`} altText={imgAltText} titleText={imgTitleText} />
                );
              })}
              {onClickContact && showContactUpsell && (
                <div>
                  <ContactCallToAction onCtaClick={onClickContact} />
                </div>
              )}
            </PhotoCarousel>
          </div>

          {isFloorplanPhoto && (
            <section className="PhotoGalleryFull-floorplan-btn-container">
              <Button btnType="dark" className="PhotoGalleryFull-floorplan-btn" onClick={this.handleOpenFdpClick}>
                View details about this floor plan
                <img className="PhotoGalleryFull-floorplan-btn-caret" src={IconArrowRight} alt="" />
              </Button>
            </section>
          )}

          <div className="PhotoGalleryFull-bottom-info">
            {currentIndex < photos.length && (
              <div>
                <div className="PhotoGalleryFull-image-caption">{caption}</div>
                <StyledPhotoCountText>
                  {currentIndex + 1} of {photos.length}
                </StyledPhotoCountText>
              </div>
            )}
            <div className="PhotoGalleryFull-photo-nav" ref={this.photoNav}>
              {this.renderPhotoNav()}
            </div>
          </div>
          {has3dTour && (
            <div className="PhotoGalleryFull-3d-tours-btn-bottom" onClick={onPhotoGalleryTo3dTours}>
              <img src={Icon3DGlasses} className="PhotoGalleryFull-3d-tours-icon" alt="" />
              Switch to 3D tour
            </div>
          )}
        </div>
        <div className="PhotoGalleryFull-image-cache">
          {map(cachedPhotos, (cachedPhoto, i) => {
            if (cachedPhoto) {
              return <img key={cachedPhoto.url + i} src={cachedPhoto.url} />;
            }
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    displayName: state.currentListingDetails.currentListing.displayName,
    listingAddress: state.currentListingDetails.currentListing.address,
    screenWidth: state.app.device.screenWidth,
  };
};

export default connect(mapStateToProps)(PhotoGalleryFull);
