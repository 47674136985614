import type { ReactNode } from 'react';
import React from 'react';
import cx from 'classnames';

import './style.scss';

interface ToastProps {
  className?: string;
  children: ReactNode;
}

const Toast: React.FC<ToastProps> = ({ className, children }) => {
  return (
    <div className={cx('Toast', className)}>
      <div className="Toast-align">{children}</div>
    </div>
  );
};

export default Toast;
