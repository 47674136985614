// @ts-nocheck
/* eslint-enable */
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { padding } from 'app/shared/styles/_spacing';
import { colors } from 'app/shared/styles/_colors';
import './ContactUserInfo.scss';
import { listingUtils_getContactEmail } from 'app/shared/utils/listingUtils';
import Text from 'app/shared/core/Text';

const propTypes = {
  user: PropTypes.object.isRequired,
  userInquiryDataCache: PropTypes.object.isRequired,
  onEditUserInfo: PropTypes.func.isRequired,
};
const StyledContactUserInfoContainer = styled.div`
  ${padding.top._2x};
`;
const StyledSpan = styled.span`
  color: ${colors['$hpx-grey-500']};
  font-weight: 700;
`;
const StyledAlertText = styled(Text)`
  color: ${colors['$hpx-red-400']};
`;
const StyledBoldSpan = styled.span`
  font-weight: 700;
`;
const ContactUserInfo = ({ emailErrorMessage, phoneErrorMessage, user, userInquiryDataCache, onEditUserInfo }) => {
  const userInfoFirstLastName = `${user.info.firstName} ${user.info.lastName}`;
  const userName = userInquiryDataCache.name || userInfoFirstLastName;
  const userEmail = listingUtils_getContactEmail(user, userInquiryDataCache);
  const userPhone = userInquiryDataCache.phone;
  return (
    <StyledContactUserInfoContainer className="ContactUserInfo">
      <div className="ContactUserInfo-details">
        <Text responsive={{ smAndUp: 'sm', xlAndUp: 'md' }} htmlTag="div">
          Your name: <StyledBoldSpan>{userName}</StyledBoldSpan>
        </Text>
        <Text responsive={{ smAndUp: 'sm', xlAndUp: 'md' }} htmlTag="div">
          Your email: <StyledBoldSpan>{userEmail}</StyledBoldSpan>
        </Text>
        {emailErrorMessage && (
          <StyledAlertText size="sm" aria-live="assertive" role="alert">
            {emailErrorMessage}
          </StyledAlertText>
        )}
        <Text responsive={{ smAndUp: 'sm', xlAndUp: 'md' }} htmlTag="div">
          Your phone:{' '}
          {userPhone ? <StyledBoldSpan>{userPhone}</StyledBoldSpan> : <StyledSpan>none provided</StyledSpan>}
        </Text>
        {phoneErrorMessage && (
          <StyledAlertText size="sm" aria-live="assertive" role="alert">
            {phoneErrorMessage}
          </StyledAlertText>
        )}
      </div>
      <button className="ContactUserInfo-edit" onClick={onEditUserInfo}>
        <Text responsive={{ smAndUp: 'sm', xlAndUp: 'md' }}>Edit</Text>
      </button>
    </StyledContactUserInfoContainer>
  );
};

ContactUserInfo.propTypes = propTypes;

const mapStateToProps = (state) => {
  return {
    user: state.user,
    userInquiryDataCache: state.user.inquiryDataCache,
  };
};

export default connect(mapStateToProps)(ContactUserInfo);
