// @ts-nocheck
/* eslint-enable */
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { textOverflow } from 'app/shared/styles/_mixins';

import PropTypes from 'prop-types';
import cx from 'classnames';
import constants from 'app/shared/constants/ConstantsBundle';
import IconAlert from 'images/icons/alert-dark-grey.svg';
import IconHistory from 'images/icons/history.svg';
import IconLocation from 'images/icons/location.svg';
import IconSearch from 'images/icons/search.svg';
import Input from 'app/shared/core/Input';
import Row from 'app/shared/core/Row';
import './style.scss';
import Text from 'app/shared/core/Text';
import { getGlobalLogger } from '@zg-rentals/logger-base';

const logger = getGlobalLogger('modules/autocomplete');
const { bool, string, func, array } = PropTypes;

const StyledOverflowDiv = styled.div`
  ${textOverflow};
`;
const StyledOverflowText = styled(Text)`
  ${textOverflow};
`;
class Autocomplete extends React.Component {
  static defaultProps = {
    onBlur: () => {},
    onChange: () => {},
    onSelect: () => {},
    onFocus: () => {},
    allowFreeform: false,
    border: true,
    className: '',
    disabled: false,
    focusInput: false,
    help: '',
    hpxStyle: '',
    inputButtonGroupPosition: '',
    items: [],
    label: '',
    placeholder: '',
    required: false,
    size: 'md',
    type: 'text',
    userPointModal: false,
    useSmallScreenWidthDropdown: false,
    value: '',
  };
  static propTypes = {
    onFocus: func,
    onBlur: func,
    onChange: func,
    onSelect: func,
    allowFreeform: bool,
    border: bool,
    className: string,
    disabled: bool,
    focusInput: bool,
    help: string,
    hpxStyle: string,
    inputButtonGroupPosition: string,
    items: array,
    label: string,
    placeholder: string,
    required: bool,
    size: string,
    type: string,
    userPointModal: bool,
    useSmallScreenWidthDropdown: bool,
    value: string,
  };
  constructor(props) {
    super(props);
    this.ignoreBlur = false;
    this.state = {
      index: -1,
      showDropdown: false,
      dimInputTextColor: true,
    };
  }
  handleChange = (e) => {
    const { onChange } = this.props;
    onChange(e);
    this.setState({ showDropdown: true, index: -1 });
  };
  handleBlur = (e) => {
    const { onBlur } = this.props;
    if (!this.ignoreBlur) {
      this.setState({ showDropdown: false, dimInputTextColor: true });
      onBlur(e);
    }
  };
  handleKeyDown = (e) => {
    const { items, allowFreeform, value } = this.props;
    const { index } = this.state;
    const keyCode = e.which;
    const overMaxIndex = items.length;

    if (keyCode === constants.UP_ARROW_KEYCODE) {
      const prevIndex = index === -1 ? -1 : index - 1;
      this.setState({ index: prevIndex });
      e.preventDefault();
    } else if (keyCode === constants.DOWN_ARROW_KEYCODE) {
      const nextIndex = index === overMaxIndex ? overMaxIndex : index + 1;
      this.setState({ index: nextIndex });
      e.preventDefault();
    } else if (keyCode === constants.ESC_KEYCODE) {
      this.setState({ showDropdown: false });
      e.preventDefault();
    } else if (keyCode === constants.ENTER_KEYCODE) {
      e.target.blur();
      this.setState({ showDropdown: false });
      const selectedItem = items.length > 0 && index === -1 ? items[0] : items[index];
      if (selectedItem) {
        this.handleSelect(selectedItem);
      } else if (allowFreeform) {
        logger?.warn('User did not select an option. Returning text as value.');
        this.handleSelect(value);
      } else {
        logger?.warn('User did not select an option. Freeform not allowed.');
      }
    }
  };
  handleSelect = (item) => {
    const { onSelect } = this.props;
    this.setState({ showDropdown: false });
    onSelect(item);
  };
  renderItemIcon = (type) => {
    switch (type) {
      case 'recentSearch':
        return <img src={IconHistory} width="16px" height="16px" />;
      case 'savedSearch':
        return <img src={IconAlert} width="16px" height="16px" />;
      case 'geoip':
        return <img src={IconLocation} width="16px" height="16px" />;
      default:
        return null;
    }
  };
  render() {
    const { index, dimInputTextColor, showDropdown } = this.state;
    const {
      border,
      className,
      disabled,
      focusInput,
      help,
      hpxStyle,
      inputButtonGroupPosition,
      items,
      label,
      onFocus,
      placeholder,
      required,
      size,
      type = 'text',
      userPointModal,
      useSmallScreenWidthDropdown,
      value,
      inputId,
    } = this.props;

    return (
      <div className={cx('Autocomplete', className)}>
        <img
          src={IconSearch}
          className={cx('Autocomplete-search-icon', {
            'Autocomplete-search-icon-small': useSmallScreenWidthDropdown,
            'Autocomplete-search-icon-md': size === 'md',
          })}
        />
        <Input
          id={inputId}
          className={cx('Autocomplete-input', {
            'Autocomplete-input-small': useSmallScreenWidthDropdown,
            'Autocomplete-input-md': size === 'md',
          })}
          customTagClassName={cx({
            'Autocomplete-dimmed-text': dimInputTextColor,
          })}
          size={size}
          inputButtonGroupPosition={inputButtonGroupPosition}
          type={type}
          label={label}
          value={value}
          placeholder={placeholder}
          help={help}
          disabled={disabled}
          border={border}
          hpxStyle={hpxStyle}
          required={required}
          focusInput={focusInput}
          onChange={(e) => this.handleChange(e)}
          onKeyDown={(e) => this.handleKeyDown(e)}
          onBlur={(e) => this.handleBlur(e)}
          onFocus={(e) => {
            this.setState({
              showDropdown: true,
              dimInputTextColor: false,
            });
            onFocus(e);
          }}
        />
        {showDropdown && Boolean(items) && Boolean(items.length) && Boolean(items.length > 0) && (
          <div
            className={cx('Autocomplete-dropdown', {
              'Autocomplete-dropdown-lg': size === 'lg',
              'Autocomplete-dropdown-userpoint': userPointModal,
            })}
          >
            {items.map((item, i) => {
              return (
                <StyledOverflowDiv
                  className={cx('Autocomplete-item', {
                    active: i === index,
                    'Autocomplete-item-small': useSmallScreenWidthDropdown,
                  })}
                  key={item.name + item.description + i}
                  onMouseOver={() => {
                    this.setState({ index: i });
                  }}
                  onMouseDown={() => {
                    this.ignoreBlur = true;
                  }}
                  onTouchStart={() => {
                    this.ignoreBlur = true;
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    this.handleSelect(item);
                    this.ignoreBlur = false;
                  }}
                >
                  <Row className="Autocomplete-item-content" size="md">
                    <span className="Autocomplete-item-icon">{this.renderItemIcon(item.type)}</span>
                    <StyledOverflowText size="sm">
                      {item.name}
                      {item.description && <span className="Autocomplete-item-subtext"> {item.description}</span>}
                    </StyledOverflowText>
                  </Row>
                </StyledOverflowDiv>
              );
            })}
          </div>
        )}
      </div>
    );
  }
}

export default connect()(Autocomplete);
