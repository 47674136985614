// @ts-nocheck
/* eslint-enable */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { textOverflow } from 'app/shared/styles/_mixins';

import './style.scss';
import Linker from 'app/shared/modules/Linker';
import RouteActions from 'app/shared/flux/actions/RouteActions';
import Text from 'app/shared/core/Text';

/**
 * Array of breadcrumbs segment objects, like this:
 *   [{
 *       name: 'Home'
 *       uri: 'https://hotpads/'
 *   }, {
 *       name: 'Unavailable'
 *       unavailable: true
 *   }, {
 *       name: 'Current'
 *       uri: 'https://hotpads/unavailable'
 *   }]
 *
 * Each segment will be rendered in order, like this
 *
 *   <ul>
 *       <li><a href="https://hotpads/">Home</a></li>
 *       <li><a>Unavailable</a></li>
 *       <li><a href="https://hotpads/unavailable">Current</a></li>
 *   </ul>
 *
 * @param {string} name - Link name renders as text content.
 * @param {string} uri - Link target.
 * @param {boolean} unavailable - Render link as unavailable.
 */
const StyledList = styled.ul`
  ${textOverflow};
`;
class HpBreadcrumb extends React.Component {
  static propTypes = {
    breadcrumbs: PropTypes.array,
  };

  static defaultProps = {
    breadcrumbs: [],
  };

  handleClick = (e, url) => {
    const { dispatch } = this.props;
    if (e) {
      e.preventDefault();
    }

    dispatch(RouteActions.transitionToUrlWithFilterQuery(url));
  };

  renderBreadcrumb = (segment) => {
    if (segment && segment.uri) {
      return (
        <Linker linkType="accent" to={segment.uri} onClick={(e) => this.handleClick(e, segment.uri)}>
          <span>{segment.name}</span>
        </Linker>
      );
    } else if (segment) {
      return <span>{segment.name}</span>;
    } else {
      return null;
    }
  };

  render() {
    const { breadcrumbs } = this.props;

    return (
      <StyledList className="HpBreadcrumb">
        {breadcrumbs.map((segment, index, list) => {
          const position = index + 1;
          const lastSegment = position === list.length;

          return (
            <li className="HpBreadcrumb-item" key={`breadcrumb-${position}`}>
              <Text size="sm">
                {this.renderBreadcrumb(segment)}
                {!lastSegment && ' / '}
              </Text>
            </li>
          );
        })}
      </StyledList>
    );
  }
}

export default connect()(HpBreadcrumb);
