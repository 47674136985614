// @ts-nocheck
/* eslint-enable */
import React, { useEffect, useRef } from 'react';

// Components
import Dots from './Dots';

// Hooks
import { useCarousel } from './hooks';

// Styles
import * as S from './styles';
import IconArrowRight from 'images/icons/arrow-right-teal.svg';
import IconLeftArrow from 'images/icons/arrow-left-teal.svg';

// must match carousel container transition css (milliseconds).
const TRANSITION_DURATION = 300;

const Carousel = ({ width, items = [], autoScrollSpeed, autoScrollPauseDuration }) => {
  const count = items.length;
  // add items at start and end of carousel to simulate infinite scroll (see ./hooks)
  const extendedItems = [items[items.length - 1]].concat(items).concat(items[0]);

  const {
    canAutoScroll,
    handleAutoScroll,
    handleGoToItem,
    handleNext,
    handlePrev,
    handleTouchEnd,
    handleTouchMove,
    handleTouchStart,
    index,
    translateX,
    useTransition,
  } = useCarousel({
    autoScrollPauseDuration,
    autoScrollSpeed,
    count: items.length,
    transitionDuration: TRANSITION_DURATION,
    width,
  });

  // setInterval will reference state & props at time of declaration
  // useRef will use the current value of state/props
  const indexRef = useRef(index);
  const widthRef = useRef(width);
  const canAutoScrollRef = useRef(canAutoScroll);
  indexRef.current = index;
  widthRef.current = width;
  canAutoScrollRef.current = canAutoScroll;

  useEffect(() => {
    let autoScrollInterval = setInterval(() => {});

    if (autoScrollSpeed) {
      autoScrollInterval = setInterval(() => {
        if (canAutoScrollRef.current) {
          handleAutoScroll({
            index: indexRef.current,
            width: widthRef.current,
          });
        }
      }, autoScrollSpeed);
    }
    return () => clearInterval(autoScrollInterval);
  }, [autoScrollSpeed, handleAutoScroll]);

  return (
    <S.Carousel>
      <S.Container
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        style={{ transform: `translateX(-${translateX}px)` }}
        useTransition={useTransition}
      >
        {width ? (
          extendedItems.map((item, idx) => <S.CarouselItem key={idx}>{item}</S.CarouselItem>)
        ) : (
          <S.CarouselItem>{items[0]}</S.CarouselItem>
        )}
      </S.Container>
      <S.Prev onClick={handlePrev}>
        <img src={IconLeftArrow} height="16px" width="16px" />
      </S.Prev>
      <Dots index={index} count={count} onGoToItem={handleGoToItem} />
      <S.Next onClick={handleNext}>
        <img src={IconArrowRight} height="16px" width="16px" />
      </S.Next>
    </S.Carousel>
  );
};

export default Carousel;
