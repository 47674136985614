// @ts-nocheck
/* eslint-enable */
import styled from 'styled-components';
import { colors } from 'app/shared/styles/_colors';
import { smoothTransition } from 'app/shared/styles/_mixins';

export const Button = styled.button`
  ${smoothTransition};
  margin: 0px;
  font-size: 14px;
  line-height: 16px;
  border: none;
  border-radius: 4px;
  box-shadow: none;
  cursor: pointer;
  box-shadow: none;
  letter-spacing: 0.5px;
  font-weight: normal;
  user-select: none;
  display: table-cell;
  vertical-align: middle;
  &:hover,
  &:active {
    outline: none;
  }

  ${(props) =>
    props.size === 'sm' &&
    `
        font-size: 14px;
        height: 32px;
        padding: 0 16px;
    `}

  ${(props) =>
    props.size === 'md' &&
    `
        font-size: 16px;
        height: 40px;
        padding: 0 24px;
    `}

    ${(props) =>
    props.size === 'lg' &&
    `
        font-size: 16px;
        padding: 0 32px;
        height: 48px;
    `}

    ${(props) =>
    props.full &&
    `
        width: 100%;
    `}

   ${(props) =>
    props.inputButtonGroupPosition === 'before' &&
    `
        border-bottom-right-radius: 0px;
        border-top-right-radius: 0px;

        ${
          props.hideInputBorder
            ? `
            border-right: 1px solid ${colors['$hpx-white']};
        `
            : ''
        }
   `}

    ${(props) =>
    props.inputButtonGroupPosition === 'after' &&
    `
        border-bottom-left-radius: 0px;
        border-top-left-radius: 0px;
        margin-left: -1px;

        ${
          props.hideInputBorder
            ? `
            border-left: 1px solid ${colors['$hpx-white']};
        `
            : ''
        }
    `}

    ${(props) =>
    props.btnType === 'primary' &&
    `
        color: ${colors['$hpx-white']};
        font-weight: bold;
        background-color: ${colors['$hpx-teal-500']};
        border: 1px solid ${colors['$hpx-teal-500']};
        &:focus {
            border: 1px solid ${colors['$hpx-teal-600']};
            background-color: ${colors['$hpx-teal-600']};
        }
        @media (hover: hover) {
            &:hover {
                border: 1px solid ${colors['$hpx-teal-600']};
                background-color: ${colors['$hpx-teal-600']};
            }
        }
        &.active,
        &:active {
            border: 1px solid ${colors['$hpx-teal-700']};
            background-color: ${colors['$hpx-teal-700']};
        }
    `}

    ${(props) =>
    props.btnType === 'primary-outline' &&
    `
        color: ${colors['$hpx-teal-500']};
        border: 1px solid ${colors['$hpx-teal-500']};
        background-color: ${colors['$hpx-white']};
        &:focus {
            background-color: ${colors['$hpx-grey-100']};
        }
        @media (hover: hover) {
            &:hover {
                background-color: ${colors['$hpx-grey-100']};
            }
        }
        &.active,
        &:active {
            color: ${colors['$hpx-white']};
            background-color: ${colors['$hpx-teal-500']};
        }
    `}

    ${(props) =>
    props.btnType === 'secondary' &&
    `
        color: ${colors['$hpx-grey-600']};
        border: 1px solid ${colors['$hpx-grey-500']};
        background-color: ${colors['$hpx-white']};
        &:focus {
            border: 1px solid ${colors['$hpx-grey-500']};
            background-color: ${colors['$hpx-grey-100']};
        }
        @media (hover: hover) {
            &:hover {
                border: 1px solid ${colors['$hpx-grey-500']};
                background-color: ${colors['$hpx-grey-100']};
            }
        }
        &.active,
        &:active {
            color: ${colors['$hpx-white']};
            border: 1px solid ${colors['$hpx-blue-500']};
            background-color: ${colors['$hpx-blue-500']};
        }
    `}

    ${(props) =>
    props.btnType === 'tertiary' &&
    `
        color: ${colors['$hpx-white']};
        font-weight: bold;
        background-color: ${colors['$hp-purple-500']};
        border: 1px solid ${colors['$hp-purple-500']};
        &:focus {
            border: 1px solid ${colors['$hp-purple-600']};
            background-color: ${colors['$hp-purple-600']};
        }
        @media (hover: hover) {
            &:hover {
                border: 1px solid ${colors['$hp-purple-600']};
                background-color: ${colors['$hp-purple-600']};
            }
        }
        &.active,
        &:active {
            border: 1px solid ${colors['$hp-purple-700']};
            background-color: ${colors['$hp-purple-700']};
        }
    `}

    ${(props) =>
    props.btnType === 'text-color' &&
    `
        color: ${colors['$hpx-white']};
        background-color: ${colors['$hpx-blue-500']};
        border: 1px solid ${colors['$hpx-blue-500']};
        font-weight: bold;
        &:focus {
            border: 1px solid ${colors['$hpx-blue-600']};
            background-color: ${colors['$hpx-blue-600']};
        }
        @media (hover: hover) {
            &:hover {
                border: 1px solid ${colors['$hpx-blue-600']};
                background-color: ${colors['$hpx-blue-600']};
            }
        }
        &.active,
        &:active {
            border: 1px solid ${colors['$hpx-blue-700']};
            background-color: ${colors['$hpx-blue-700']};
        }
    `}

    ${(props) =>
    props.btnType === 'text-color-outline' &&
    `
        border: 1px solid ${colors['$hpx-blue-500']};
        background-color: ${colors['$hpx-white']};
        color: ${colors['$hpx-blue-500']};
        font-weight: bold;
        &:focus {
            background-color: ${colors['$hpx-grey-100']};
        }
        @media (hover: hover) {
            &:hover {
                background-color: ${colors['$hpx-grey-100']};
            }
        }
        &.active,
        &:active {
            color: ${colors['$hpx-white']};
            background-color: ${colors['$hpx-blue-500']};
        }
    `}

    ${(props) =>
    props.btnType === 'text-color-light' &&
    `
        color: ${colors['$hpx-white']};
        background-color: ${colors['$hpx-blue-600']};
        border: 1px solid ${colors['$hpx-white']};
        font-weight: normal;
        &:focus {
            background-color: ${colors['$hpx-blue-500']};
        }
        @media (hover: hover) {
            &:hover {
                background-color: ${colors['$hpx-blue-500']};
            }
        }
        &.active,
        &:active {
            background-color: ${colors['$hpx-blue-700']};
        }
    `}

    ${(props) =>
    props.btnType === 'default' &&
    `
        color: ${colors['$hpx-blue-600']};
        border: 1px solid ${colors['$hpx-grey-500']};
        background-color: ${colors['$hpx-white']};
        &:focus {
            background-color: ${colors['$hpx-teal-100']};
        }
        @media (hover: hover) {
            &:hover {
                background-color: ${colors['$hpx-teal-100']};
            }
        }
        &.active,
        &:active {
            color: ${colors['$hpx-white']};
            border: 1px solid ${colors['$hpx-teal-700']};
            background-color: ${colors['$hpx-teal-700']};
        }
    `}

    ${(props) =>
    props.btnType === 'warning' &&
    `
        color: ${colors['$hpx-red-500']};
        background-color: ${colors['$hpx-white']};
        border: 1px solid ${colors['$hpx-grey-500']};
        &:focus {
            color: ${colors['$hpx-white']};
            border: 1px solid ${colors['$hpx-red-500']};
            background-color: ${colors['$hpx-red-500']};
        }
        @media (hover: hover) {
            &:hover {
                color: ${colors['$hpx-white']};
                border: 1px solid ${colors['$hpx-red-500']};
                background-color: ${colors['$hpx-red-500']};
            }
        }
        &.active,
        &:active {
            color: ${colors['$hpx-white']};
            border: 1px solid ${colors['$hpx-red-600']};
            background-color: ${colors['$hpx-red-600']};
        }
    `}

    ${(props) =>
    props.btnType === 'alert-secondary' &&
    `
        border: 1px solid ${colors['$hpx-red-500']};
        color: ${colors['$hpx-grey-600']};
        @media (hover: hover) {
            &:hover {
                outline: none;
                border: 1px solid ${colors['$hpx-red-600']};
            }
        }
    `}

    ${(props) =>
    props.btnType === 'primary-inactive' &&
    `
        cursor: not-allowed;
        pointer-events: none;
        color: ${colors['$hpx-white']};
        font-weight: bold;
        background-color: ${colors['$hpx-teal-600']};
        border: 1px solid ${colors['$hpx-teal-600']};
    `}

    ${(props) =>
    props.btnType === 'dark' &&
    `
        color: ${colors['$hpx-white']};
        border: 1px solid ${colors['$hpx-white']};
        background-color: ${colors['$hpx-black']};
        &:focus {
            color: ${colors['$hpx-grey-100']};
            border: 1px solid ${colors['$hpx-grey-100']};
        }
        @media (hover: hover) {
            &:hover {
                color: ${colors['$hpx-grey-100']};
                border: 1px solid ${colors['$hpx-grey-100']};
            }
        }
    `}

    ${(props) =>
    props.bold &&
    `
        font-weight: bold;
    `}

    ${(props) =>
    props.disabled &&
    `
        font-weight: normal;
        border: 1px solid ${colors['$hpx-grey-500']};
        background-color: ${colors['$hpx-grey-100']};
        color: ${colors['$hpx-grey-600']};
        pointer-events: none;
        cursor: not-allowed;
    `}

    ${(props) =>
    props.rounded &&
    props.size === 'sm' &&
    `
        border-radius: 16px;
    `}

    ${(props) =>
    props.rounded &&
    props.size === 'md' &&
    `
        border-radius: 20px;
    `}

    ${(props) =>
    props.rounded &&
    props.size === 'lg' &&
    `
        border-radius: 24px;
    `}
`;

export const ButtonContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${(props) =>
    (props.iconGap === 'sm' || props.iconGap === '') &&
    `
        gap: 8px;
    `}

  ${(props) =>
    props.iconGap === 'xs' &&
    `
        gap: 4px;
    `}

    ${(props) =>
    props.iconGap === 'none' &&
    `
        gap: 0px;
    `}
`;
