// @ts-nocheck
/* eslint-enable */
import React from 'react';
import styled from 'styled-components';
import { anchorOffset } from 'app/shared/styles/_mixins';

import 'app/shared/modules/hdp/ExpiredRecommendations.scss';
import NeighborhoodLink from 'app/shared/modules/hdp/NeighborhoodLink';
import SimilarListingsContainer from 'app/shared/modules/hdp/SimilarListingsContainer';

const AnchorOffset = styled.a`
  ${anchorOffset}
`;

const ExpiredRecommendations = ({ onListingClick }) => (
  <div className="ExpiredRecommendationsHeader">
    <AnchorOffset id="SimilarListings" />
    <SimilarListingsContainer onClick={onListingClick} hideTable />
    <NeighborhoodLink />
  </div>
);

export default ExpiredRecommendations;
