// @ts-nocheck
/* eslint-enable */
import React, { Fragment } from 'react';
import styled from 'styled-components';
import './style.scss';
import Container from 'app/shared/core/Container';
import Row from 'app/shared/core/Row';
import Text from 'app/shared/core/Text';

const StyledBoldText = styled(Text)`
  font-weight: 700;
`;
const ReviewResponseMessage = ({ responseMessage }) => {
  return (
    <div className="ReviewResponseMessage">
      <Container>
        <Row size="md">
          <Fragment>
            <StyledBoldText size="sm">Property manager's reply</StyledBoldText>
          </Fragment>
          <Text size="sm">{responseMessage}</Text>
        </Row>
      </Container>
    </div>
  );
};

export default ReviewResponseMessage;
