// @ts-nocheck
/* eslint-enable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from 'app/shared/core/Button';
import Input from 'app/shared/core/Input';
import './style.scss';

const propTypes = {
  onReportSubmit: PropTypes.func.isRequired,
};

class ReportReview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      commentValue: '',
      error: false,
      errorMsg: '',
    };
  }
  handleInputChange = (e) => {
    const comment = e.target.value;
    this.setState(() => {
      return {
        commentValue: comment,
        error: false,
      };
    });
  };
  handleFormSubmit = (e) => {
    e.preventDefault();
    if (!this.state.commentValue) {
      this.setState(() => {
        return {
          error: true,
          errorMsg: 'This is a required field.',
        };
      });

      return;
    }

    this.props.onReportSubmit(this.state.commentValue);
  };
  render() {
    return (
      <div className="ReportReview">
        <Input
          className="ReportReview-input"
          type="textarea"
          label="Why are you reporting this review?"
          onChange={this.handleInputChange}
          help={this.state.errorMsg}
          hpxStyle={this.state.error ? 'error' : ''}
          required
          fixedSize
        />
        <Button size="sm" onClick={this.handleFormSubmit}>
          Submit
        </Button>
      </div>
    );
  }
}

ReportReview.propTypes = propTypes;
export { ReportReview };
export default ReportReview;
