import React from 'react';
import './style.scss';

interface SpinnerDotsProps {}

const SpinnerDots: React.FC<SpinnerDotsProps> = () => {
  return (
    <div className="SpinnerDots">
      <div className="SpinnerDots-child dots-bounce1" />
      <div className="SpinnerDots-child dots-bounce2" />
      <div className="SpinnerDots-child dots-bounce3" />
    </div>
  );
};

export default SpinnerDots;
