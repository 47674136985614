import styled from 'styled-components';
import { colors } from 'app/shared/styles/_colors';
import { font } from 'app/shared/styles/_fontsize';
import { padding } from 'app/shared/styles/_spacing';
import Linker from 'app/shared/modules/Linker';

export const NeighborhoodLinkContainer = styled.div`
  ${padding.left._2x};
  ${padding.right._2x};
  ${padding.top._3x};
  ${padding.bottom._3x};
  border-top: 1px solid ${colors['$hpx-grey-100']};
  border-bottom: 1px solid ${colors['$hpx-grey-100']};
  background-color: ${colors['$hpx-white']};
  text-align: right;
  vertical-align: middle;
  width: 100%;
`;

export const StyledLinker = styled(Linker)`
  ${font.md};
`;

export const ArrowIcon = styled.img`
  ${padding.left._1x};
  height: 16px;
  width: 16px;
  display: inline-block;
  position: relative;
  top: -1px;
`;
