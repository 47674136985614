import React from 'react';
import cx from 'classnames';
import './style.scss';

interface Props {
  className: string;
  disableClick?: boolean;
}

const handleClick = (e: React.MouseEvent<HTMLDivElement>, props: Props) => {
  if (props.disableClick) {
    e.preventDefault();
  }
};

const DisableOverlay = (props: Props) => {
  return (
    <div className={cx('DisableOverlay', props.className)} onClick={(e) => handleClick(e, props)}>
      <div className="DisableOverlay-opacity" />
    </div>
  );
};

export default DisableOverlay;
