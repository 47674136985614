// @ts-nocheck
/* eslint-enable */
import React, { useEffect, useState } from 'react';

import ArrowLeft from 'images/icons/ic-caret-left.svg';
import ArrowLeftDisabled from 'images/icons/arrow-left-grey.svg';
import ArrowRight from 'images/icons/ic-caret-right.svg';
import ArrowRightDisabled from 'images/icons/arrow-right-grey.svg';

import * as S from './styles';

const PhotoGallery3DTour = (props = {}) => {
  const { onGalleryBackClick, urls, isMobile } = props;
  const [urlIndex, setUrlIndex] = useState(0);
  const [prevDisabled, setPrevDisabled] = useState(true);
  const [nextDisabled, setNextDisabled] = useState(false);

  const handleNextTour = () => {
    if (urlIndex < urls.length - 1) {
      setUrlIndex(urlIndex + 1);
    }
  };

  const handlePrevTour = () => {
    if (urlIndex > 0) {
      setUrlIndex(urlIndex - 1);
    }
  };

  useEffect(() => {
    setNextDisabled(urlIndex === urls.length - 1);
    setPrevDisabled(urlIndex === 0);
  }, [urls, urlIndex]);

  // This should not happen, but double check for safety.
  if (urls.isEmpty) {
    return null;
  }

  return (
    <S.Container>
      <S.Header>
        <S.HeaderEdge>
          <S.BackButton onClick={onGalleryBackClick}>
            <S.NavIcon src={ArrowLeft} />
            <span>{isMobile ? 'Back' : 'Back to gallery'}</span>
          </S.BackButton>
        </S.HeaderEdge>
        <S.HeaderCenter>
          {urls.length > 1 && (
            <>
              <S.TourNavButton onClick={handlePrevTour}>
                <S.Padding isMobile={isMobile}>
                  <S.NavIcon src={prevDisabled ? ArrowLeftDisabled : ArrowLeft} alt="" />
                </S.Padding>
              </S.TourNavButton>
              <S.Padding as="span" isMobile={isMobile}>
                {isMobile ? `${urlIndex + 1} / ${urls.length}` : `${urlIndex + 1} of ${urls.length} 3D tours`}
              </S.Padding>
              <S.TourNavButton onClick={handleNextTour}>
                <S.Padding isMobile={isMobile}>
                  <S.NavIcon src={nextDisabled ? ArrowRightDisabled : ArrowRight} alt="" />
                </S.Padding>
              </S.TourNavButton>
            </>
          )}
        </S.HeaderCenter>
        <S.HeaderEdge />
      </S.Header>
      <S.IFrameContainer>
        <iframe
          src={urls[urlIndex]}
          title="3D Tour"
          width="100%"
          height="100%"
          referrerPolicy="no-referrer-when-downgrade"
        />
      </S.IFrameContainer>
    </S.Container>
  );
};

export default PhotoGallery3DTour;
