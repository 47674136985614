export const STEPS = {
  SELECT_TOUR_TYPE: 'SELECT_TOUR_TYPE',
  SELECT_DATE_TIME: 'SELECT_DATE_TIME',
  CONTACT_INFO_AND_CONFIRM: 'CONTACT_INFO_AND_CONFIRM',
  MODIFY_TOUR: 'MODIFY_TOUR',
  CONFIRMATION: 'CONFIRMATION',
  CANCEL_SUCCESS: 'CANCEL_SUCCESS',
  CANCEL_ERROR: 'CANCEL_ERROR',
  RESCHEDULE_SUCCESS: 'RESCHEDULE_SUCCESS',
  RESCHEDULE_ERROR: 'RESCHEDULE_ERROR',
  SYSTEM_ERROR: 'SYSTEM_ERROR',
  SCHEDULE_TOUR_ERROR: 'SCHEDULE_TOUR_ERROR',
  PROMPT_LOGIN: 'PROMPT_LOGIN',
};

export const TOUR_TYPES = {
  IN_PERSON: 'IN_PERSON',
  SELF_GUIDED: 'SELF_GUIDED',
  LIVE_VIDEO: 'LIVE_VIDEO',
};
