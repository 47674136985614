// @ts-nocheck
/* eslint-enable */
/* eslint-disable @zg-rentals/workspace-imports */
import React from 'react';
import Text from 'app/shared/core/Text';
import dateUtils from 'app/shared/utils/dateUtils';

type Props = {
  dateTimeToDisplay: string;
  dateOnly: boolean;
  zoneId;
};

const DayAndTimeDisplay = ({ dateTimeToDisplay = '', dateOnly = false, zoneId = '' }: Props = {}) => {
  const pattern = dateOnly ? 'EEEE, MMM dd' : "EEEE, MMM dd 'at' h:mm a";

  return dateTimeToDisplay ? (
    <Text htmlTag="p">{dateUtils.parseISODateString(dateTimeToDisplay, zoneId, pattern)}</Text>
  ) : null;
};

export default DayAndTimeDisplay;
