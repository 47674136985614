// @ts-nocheck
/* eslint-enable */
import React from 'react';
import { useSelector } from 'react-redux';

// Constellation
import IconCheckmark from 'images/icons/checkmark-green.svg';

// Components
import Slide from './Slide';

// Utils
import { gaEvents } from 'app/shared/constants/AnalyticsConstants';
import pathsManifest from 'app/shared/utils/pathsManifest';
import TileOneImage from 'images/zrmUpsell/tile-1-image.jpg';
import TileOneHeading from 'images/zrmUpsell/tile-1-heading-new.png';
import TileTwoImage from 'images/zrmUpsell/tile-2-image.png';
import TileThreeImage from 'images/zrmUpsell/tile-3-image.png';
import TileFourImage from 'images/zrmUpsell/tile-4-image.png';
import TileFiveImage from 'images/zrmUpsell/tile-5-image.png';
import TileSixImage from 'images/zrmUpsell/tile-6-image.jpg';

// Style
import { ZRMLogoImg } from 'app/shared/modules/ZrmUpsellCarousel/Slide/styles';

const iconStyles = {
  marginRight: 8,
  width: 20,
  height: 20,
};

const Tiles = () => {
  // Redux
  const isMobile = useSelector((state) => state.app.device.isMobile);
  const isMultiFamilyListing = useSelector((state) => state.currentListingDetails.currentListing.trusted);
  const UTM_PARAMS = `utm_source=hotpads&utm_medium=${isMobile ? 'mweb' : 'web'}&utm_campaign=offlineUpsell`;
  const SLIDES_DATA = [
    isMultiFamilyListing
      ? {
          heading: (
            <>
              <ZRMLogoImg src={TileOneHeading} width="255px" alt="Zillow Rental Manager Logo" />
              <p>Do you manage this property?</p>
            </>
          ),
          body: 'Claiming gives you access to insights and data about this property.',
          btnPrimaryEvent: gaEvents.ZRM_UPSELL_ZRM,
          btnPrimaryLabel: 'Get started',
          btnPrimaryHref: pathsManifest.zrmBase + `?${UTM_PARAMS}`,
          imgSrc: TileOneImage,
          imgFull: true,
        }
      : {
          heading: <img src={TileOneHeading} width="255px" alt="Zillow Rental Manager Logo" />,
          body: 'Is this your beautiful rental property? List your home for rent with our parent company, Zillow Rental Manager, and take advantage of the property management tools for tenant screening, digital lease signing and rent management, for free!',
          btnPrimaryEvent: gaEvents.ZRM_UPSELL_ZRM,
          btnPrimaryLabel: 'Get started',
          btnPrimaryHref: pathsManifest.zrmBase + `?${UTM_PARAMS}`,
          imgSrc: TileOneImage,
          imgFull: true,
        },
    {
      heading: 'Post a rental listing',
      body: 'Easy-to-use tools let you list your property where millions of renters search each month. Plus, messaging and tenant screening are integrated into our listing platform to help you find and screen applicants faster.',
      btnPrimaryEvent: gaEvents.ZRM_UPSELL_POST_LISTING,
      btnPrimaryLabel: 'Get started',
      btnPrimaryHref: pathsManifest.zrmPostAListingPage + `&${UTM_PARAMS}`,
      btnSecondaryEvent: gaEvents.ZRM_UPSELL_POST_LISTING_LEARN_MORE,
      btnSecondaryLabel: 'Learn more',
      btnSecondaryHref: pathsManifest.zrmPostAListingLandingPage + `?${UTM_PARAMS}`,
      imgSrc: TileTwoImage,
    },
    {
      heading: 'Screen tenants with online rental applications',
      body: 'Sign your next tenant with confidence using our online rental applications. Save time with credit reports, background checks and eviction history for each applicant – all in one place',
      btnPrimaryEvent: gaEvents.ZRM_UPSELL_SCREEN_TENANTS,
      btnPrimaryLabel: 'Get started',
      btnPrimaryHref: pathsManifest.zrmApplicationsPage + `&${UTM_PARAMS}`,
      btnSecondaryEvent: gaEvents.ZRM_UPSELL_SCREEN_TENANTS_LEARN_MORE,
      btnSecondaryLabel: 'Learn more',
      btnSecondaryHref: pathsManifest.zrmTenantScreeningPage + `?${UTM_PARAMS}`,
      imgSrc: TileThreeImage,
    },
    {
      heading: 'Sign a rental lease',
      body: 'Create your rental lease using an online lease builder and templates drafted with local law firms, and e-sign it with your renters.',
      btnPrimaryEvent: gaEvents.ZRM_UPSELL_SIGN_LEASE,
      btnPrimaryLabel: 'Create a lease',
      btnPrimaryHref: pathsManifest.zrmCreateALeasePage + `&${UTM_PARAMS}`,
      btnSecondaryEvent: gaEvents.ZRM_UPSELL_SIGN_LEASE_LEARN_MORE,
      btnSecondaryLabel: 'Learn more',
      btnSecondaryHref: pathsManifest.zrmLeaseAgreementPage + `?${UTM_PARAMS}`,
      imgSrc: TileFourImage,
    },
    {
      heading: 'Collect rent payments',
      body: 'The convenient way for landlords to get paid. Rent, utilities, move-in fees and more are deposited straight into your bank account — at no cost to you.',
      btnPrimaryLabel: 'Get paid',
      btnPrimaryEvent: gaEvents.ZRM_UPSELL_COLLECT_RENT,
      btnPrimaryHref: pathsManifest.zrmPaymentsPage + `&${UTM_PARAMS}`,
      btnSecondaryEvent: gaEvents.ZRM_UPSELL_COLLECT_RENT_LEARN_MORE,
      btnSecondaryLabel: 'Learn more',
      btnSecondaryHref: pathsManifest.zrmCollectRentPage + `?${UTM_PARAMS}`,
      imgSrc: TileFiveImage,
    },
    {
      heading: 'Your first listing is free',
      body: (
        <>
          <strong style={{ marginBottom: 8, display: 'inline-block' }}>Every listing includes:</strong>
          <br />
          <div style={{ display: 'flex', marginBottom: 8 }}>
            <img src={IconCheckmark} style={iconStyles} />
            Posting to Zillow, Trulia and Hotpads
          </div>
          <div style={{ display: 'flex', marginBottom: 8 }}>
            <img src={IconCheckmark} style={iconStyles} />
            Tenant screening with background and other credit checks
          </div>
          <div style={{ display: 'flex', marginBottom: 8 }}>
            <img src={IconCheckmark} style={iconStyles} />
            Online leases
          </div>
          <div style={{ display: 'flex' }}>
            <img src={IconCheckmark} style={iconStyles} />
            Online rent payments
          </div>
        </>
      ),
      btnPrimaryEvent: gaEvents.ZRM_UPSELL_FREE_LISTING,
      btnPrimaryLabel: 'Get started',
      btnPrimaryHref: pathsManifest.zrmPostAListingPage + `&${UTM_PARAMS}`,
      imgSrc: TileSixImage,
      imgFull: true,
    },
  ];

  return SLIDES_DATA.map((slide, idx) => {
    return (
      <Slide
        body={slide.body}
        btnPrimaryEvent={slide.btnPrimaryEvent}
        btnPrimaryHref={slide.btnPrimaryHref}
        btnPrimaryLabel={slide.btnPrimaryLabel}
        btnSecondaryHref={slide.btnSecondaryHref}
        btnSecondaryLabel={slide.btnSecondaryLabel}
        btnSecondaryEvent={slide.btnSecondaryEvent}
        heading={slide.heading}
        imgFull={slide.imgFull}
        imgSrc={slide.imgSrc}
        key={idx}
      />
    );
  });
};

export default Tiles;
