// @ts-nocheck
/* eslint-enable */
import styled from 'styled-components';
import { colors } from 'app/shared/styles/_colors';
import { viewports } from 'app/shared/styles/_breakpoints';

export const Container = styled.div`
  padding: 0 16px;
  position: relative;
  height: 100vh;
  width: 100vw;
  @media ${viewports['md-and-up']} {
    height: 80vh;
    width: 80vw;
  }
`;

export const Header = styled.div`
  display: flex;
  min-height: 50px;
`;

export const HeaderEdge = styled.div`
  display: flex;
  align-items: center;
  width: 25%;
`;

export const HeaderCenter = styled.div`
  display: inline-block;
  text-align: center;
  width: 50%;
`;

export const BackButton = styled.button`
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
`;

export const IFrameContainer = styled.div`
  height: 90%;
  width: 100%;
  padding-bottom: 16px;
`;

export const Padding = styled.div`
  ${({ isMobile }) => (isMobile ? 'padding: 8px;' : 'padding: 16px;')}
`;

export const TourNavButton = styled.button`
  border-left: 1px solid ${colors['$hpx-grey-100']};
  border-right: 1px solid ${colors['$hpx-grey-100']};
`;

export const NavIcon = styled.img`
  height: 16px;
  width: 16px;
`;
