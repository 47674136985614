const controller = {
  showAfterHoursPhone() {
    const time = new Date().getTime();
    // hide contact number for a listing if it's before 6am local time
    const morningTime = new Date().setHours(6, 0, 0);
    // hide contact number for a listing if it's after 8pm local time
    const nightTime = new Date().setHours(20, 0, 0);
    return time > nightTime && time < morningTime;
  },
};

export default controller;
