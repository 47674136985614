// @ts-nocheck
/* eslint-enable */
import forEach from 'lodash/forEach';
import forOwn from 'lodash/forOwn';
import isString from 'lodash/isString';
import { getGlobalLogger } from '@zg-rentals/logger-base';

import api from 'app/shared/utils/api';
import { logError } from '@zg-rentals/log-error';

const logger = getGlobalLogger('StaticMap');

/* eslint-disable func-style */
/* eslint-disable no-use-before-define */

/**
 * Generate URL for requesting image from Google Static Maps API V2
 * https://developers.google.com/maps/documentation/staticmaps/
 *
 * @construtor
 * @param {object} options - constructor options
 * @returns {Function} StaticMap
 */
function StaticMap(options) {
  this.makePath = options.encodePolylines ? encodePolyline : makeLocations;
  this.url = 'https://maps.googleapis.com/maps/api/staticmap?';

  return this;
}

StaticMap.prototype.center = function (lat, lon) {
  this.url += 'center=';
  if (isString(lat)) {
    this.url += lat;
  } else {
    this.url += lat + ',' + lon;
  }
  this.url += '&';
  return this;
};

StaticMap.prototype.centerByLatAndLon = function (lat, lon) {
  this.url += 'center=';
  this.url += `${lat},${lon}&`;

  return this;
};

StaticMap.prototype.size = function (width, height) {
  this.url += 'size=';
  this.url += width + 'x' + height;
  this.url += '&';
  return this;
};

StaticMap.prototype.zoom = function (zoom) {
  this.url += 'zoom=';
  this.url += zoom;
  this.url += '&';
  return this;
};

StaticMap.prototype.type = function (type) {
  this.url += 'maptype=';
  this.url += type;
  this.url += '&';
  return this;
};

StaticMap.prototype.markers = function (markers) {
  if (!markers) {
    return this;
  }

  forEach(markers, (marker) => {
    this.url += 'markers=';
    this.url += 'icon:' + marker.url;
    this.url += '%7C' + marker.geo.lat;
    this.url += ',' + marker.geo.lon;
    this.url += '&';
  });

  return this;
};

StaticMap.prototype.path = function (locations) {
  if (!locations) {
    return this;
  }

  const locationQuery = [];

  // Transpile [ lon / lat ] from
  // API to [ lat / lon ].
  forEach(locations, (arr) => {
    const [lon, lat] = arr;
    locationQuery.push(`${lat},${lon}`);
  });

  this.url += 'path=';
  this.url += 'color:0x189c88ff%7Cweight:5%7C';
  this.url += locationQuery.join('%7C');
  this.url += '&';

  return this;
};

StaticMap.prototype.polyline = function (encoded) {
  const defaultWeight = 5;
  const defaultHexColor = '0x189c88ff';

  if (!encoded) {
    return this;
  }

  this.url += 'path=';
  this.url += `color:${defaultHexColor}%7Cweight:${defaultWeight}%7C`;
  this.url += 'enc:' + encoded;
  this.url += '&';

  return this;
};

StaticMap.prototype.scale = function (scale) {
  this.url += 'scale=';
  this.url += scale;
  this.url += '&';
  return this;
};

StaticMap.prototype.style = function (style) {
  this.url += 'style=';
  this.url += makeStyle(style);
  this.url += '&';
  return this;
};

StaticMap.prototype.styleFromConfig = function (styleConfig) {
  const styleArr = styleConfig.map((styleArg) => {
    const styles = extractStyles(styleArg.stylers);
    return `style=feature:${styleArg.featureType}|element:${styleArg.elementType}|${styles}&`;
  });

  const styleString = styleArr.join('');

  const finalStyleString = styleString.replace(/#/gi, '0x');

  this.url += finalStyleString;

  return this;
};

StaticMap.prototype.generate = function () {
  const urlFinal = cleanUrl(this.url);
  return (dispatch) => {
    return dispatch(api.area.signUrl(urlFinal)).catch((err) =>
      logError({ error: err, errorType: 'MapError', context: 'StaticMap.prototype.generate' }),
    );
  };
};

function cleanUrl(url) {
  if (url[url.length - 1] === '&') {
    return url.slice(0, -1);
  }

  return url;
}

function makeStyle(style) {
  const list = [];
  let url = '';

  if (isString(style.feature)) {
    list.push('feature:' + style.feature);
  }

  if (isString(style.element)) {
    list.push('element:' + style.element);
  }

  forOwn(style, (value, key) => {
    if (key !== 'feature' && key !== 'element' && isString(value)) {
      list.push(key + ':' + value);
    }
  });

  if (list.length > 0) {
    url += list.join('%7C') + '%7C';
  }
  return url;
}

function makeLocations(paths) {
  const list = [];
  let url = '';
  let path;
  let i;

  for (i = 0; i < paths.length; i++) {
    path = paths[i];
    list.push(path.lat + ',' + path.lon);
  }
  if (list.length > 0) {
    url += list.join('%7C');
  }
  return url;
}

/**
 * Implement Google Maps' Polyline encoding format
 * https://developers.google.com/maps/documentation/utilities/polylinealgorithm
 *
 * @param {array} locations - lat/lon of locations
 * @param {number} precision - default of 5
 * @returns {string} encoded polyline
 *
 * NOTE: No encoding of zoom levels
 */
function encodePolyline(locations, precision) {
  let deltaLat, deltaLon, latScaled, lonScaled;
  let previousLat = 0;
  let previousLon = 0;
  let encoding = '';
  let i;

  precision = Math.pow(10, precision || 5);

  for (i = 0; i < locations.length; i++) {
    latScaled = Math.floor(locations[i].lat * precision);
    lonScaled = Math.floor(locations[i].lon * precision);
    deltaLat = latScaled - previousLat;
    deltaLon = lonScaled - previousLon;
    previousLat = latScaled;
    previousLon = lonScaled;
    encoding += encodeSignedNumber(deltaLat) + encodeSignedNumber(deltaLon);
  }
  return 'enc:' + encoding;
}

function encodeSignedNumber(number) {
  let signedNumber = number << 1;

  if (number < 0) {
    signedNumber = ~signedNumber;
  }

  return encodeNumber(signedNumber);
}

function encodeNumber(number) {
  let encoding = '';
  let nextValue;

  while (number >= 0x20) {
    nextValue = (0x20 | (number & 0x1f)) + 63;
    encoding += String.fromCharCode(nextValue);
    number >>= 5;
  }
  const finalValue = number + 63;
  encoding += String.fromCharCode(finalValue);
  return encoding;
}

function extractStyle(styleObj) {
  const [[styleKey, styleVal]] = Object.entries(styleObj);
  return `${styleKey}:${styleVal}`;
}

function extractStyles(styleArr) {
  const styles = styleArr.map(extractStyle);

  return styles.join('|');
}

export default StaticMap;
