// @ts-nocheck
/* eslint-enable */
import React from 'react';
import { connect } from 'react-redux';
import Linker from 'app/shared/modules/Linker';
import './style.scss';
import NearbyListings from 'app/shared/modules/NearbyListings';
import Container from 'app/shared/core/Container';
import Section from 'app/shared/modules/Section';
import Title from 'app/shared/modules/Title';
import { pageView } from 'app/client/universal-analytics';

class ServerError extends React.Component {
  constructor(props) {
    super(props);
    const { dispatch } = this.props;
    dispatch(pageView());
  }
  handleClick = () => {
    history.go(-1);
  };
  render() {
    return (
      <div className="ServerError">
        <Container>
          <div className="ServerError-wrapper">
            <Section>
              <Title size="lg">Something went wrong! Please try again later.</Title>
            </Section>
            <Section>
              <ul>
                <li>
                  Return to
                  <Linker to="/"> HotPads</Linker>
                </li>
                <li>
                  Go back to the
                  <Linker onClick={this.handleClick}> previous page</Linker>
                </li>
                <li>
                  Report a problem to
                  <Linker to="mailto:info@hotpads.com"> info@hotpads.com</Linker>
                </li>
              </ul>
            </Section>
          </div>
        </Container>
        <NearbyListings listings={this.props.listings} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  activeMarkerMaloneLotId: state.currentListingDetails.activeMarkerMaloneLotId,
});

export default connect(mapStateToProps)(ServerError);
