// @ts-nocheck
/* eslint-enable */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-multi-comp */
import React, { Fragment } from 'react';
import 'app/shared/modules/hdp/HdpReviewsList.scss';
import styled from 'styled-components';
import { colors } from 'app/shared/styles/_colors';

import ReviewItem from 'app/shared/modules/reviews/ReviewItem';
import Button from 'app/shared/core/Button';
import Row from 'app/shared/core/Row';
import Text from 'app/shared/core/Text';
import Divider from 'app/shared/modules/Divider';
import HdpContentWrapper from 'app/shared/modules/hdp/HdpContentWrapper';
import RatingsAndReviewStats from 'app/shared/modules/RatingsAndReviewStats';
import IconArrowRight from 'images/icons/arrow-right.svg';

const StyledSeeAllReviewsText = styled.span`
  color: ${colors['$hpx-teal-500']};
`;
const StyledDarkText = styled(Text)`
  color: ${colors['$hpx-grey-600']};
`;
const StyledSeeAllReviewsRow = styled(Row)`
  text-align: right;
`;
const HdpReviewsList = ({ listingName: displayName = '', ratingsAndReviews, onClickReviews, aliasEncoded = '' }) => {
  const { reviews, reviewsSummary } = ratingsAndReviews;
  const isValidReviewsSummary = reviewsSummary && reviewsSummary.totalNumberOfReviews > 0;

  function getReviewLink() {
    return `https://hotpads.com/renter-hub/property-review/tenant?providerListingId=${aliasEncoded}`;
  }

  function getReviewButton() {
    return (
      <Button
        size="sm"
        onClick={() => {
          window.open(getReviewLink(), '_blank');
        }}
        bold
        btnType="default"
      >
        Leave a review
      </Button>
    );
  }

  const displayedReviews = reviews.slice(0, 3);

  return (
    <div className="HdpReviewsList">
      <HdpContentWrapper
        collapsable
        header={`Apartment reviews of ${displayName}`}
        headerAside={getReviewButton()}
        headerHtmlTag="h2"
      >
        {isValidReviewsSummary ? (
          <Fragment>
            <div>
              <StyledDarkText size="sm">Property rating</StyledDarkText>
            </div>
            <RatingsAndReviewStats onClickReviews={onClickReviews} ratingsAndReviews={ratingsAndReviews} />
            <Divider />
            {displayedReviews.map((review, i) => {
              return (
                <Row key={`row-${i}`}>
                  <ReviewItem review={review} />
                </Row>
              );
            })}
            {reviews.length > 3 && (
              <StyledSeeAllReviewsRow>
                <StyledSeeAllReviewsText className="HdpReviewsList-show-all" onClick={onClickReviews}>
                  See all reviews{' '}
                  <img
                    src={IconArrowRight}
                    height="16px"
                    width="16px"
                    className="HdpReviewsList-arrow-right-svg"
                    alt=""
                  />
                </StyledSeeAllReviewsText>
              </StyledSeeAllReviewsRow>
            )}
          </Fragment>
        ) : (
          <div>Be the first to leave a review</div>
        )}
      </HdpContentWrapper>
    </div>
  );
};

HdpReviewsList.defaultProps = {
  onClickReviews: () => {},
  ratingsAndReviews: {},
};

export default HdpReviewsList;
