// @ts-nocheck
/* eslint-enable */
import React from 'react';
import isEmpty from 'lodash/isEmpty';
import dateUtils from 'app/shared/utils/dateUtils';
import HdpContentWrapper from 'app/shared/modules/hdp/HdpContentWrapper';
import ListItem from 'app/shared/modules/ListItem';
import Row from 'app/shared/core/Row';

class HdpOpenHouseText extends React.Component {
  static displayName = 'HdpOpenHouseText';
  static defaultProps = {
    listing: {},
  };

  render() {
    const { listing } = this.props;
    const { openHouses } = listing;

    if (isEmpty(openHouses)) {
      return null;
    }

    return (
      <div className="HdpOpenHouseText">
        <HdpContentWrapper collapsable header="Open Houses" headerHtmlTag="h2">
          <Row size="sm">
            <ul>
              {openHouses.map((openHouse, i) => (
                <ListItem key={`openhouse-${i}`}>
                  {`
                                        ${dateUtils.getDayDisplayFromDate(openHouse.date)}
                                        ${dateUtils.formatDateToString(openHouse.date, { skipYear: true })}
                                        from ${dateUtils.formatHourMinuteString(openHouse.starts)} to
                                        ${dateUtils.formatHourMinuteString(openHouse.ends)}
                                    `}
                </ListItem>
              ))}
            </ul>
          </Row>
        </HdpContentWrapper>
      </div>
    );
  }
}

export default HdpOpenHouseText;
