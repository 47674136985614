// @ts-nocheck
/* eslint-enable */
import React from 'react';
import FocusLock from 'react-focus-lock';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { zindex } from 'app/shared/styles/_zindex';

import IconX from 'images/icons/x.svg';

const StyledOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: ${zindex['$z-index-under-header']};
  overflow: hidden;
  background: rgba(242, 242, 242, 0.8);
  backdrop-filter: blur(5px);

  ${(props) =>
    props &&
    props.overlayOffset &&
    css`
      top: ${props.overlayOffset};
      height: calc(100% - ${props.overlayOffset});
    `}
`;

const StyledCloseButton = styled.button`
  padding: 16px;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  z-index: ${zindex['$z-index-step']};
`;

const Overlay = ({ children, onHidePopup, overlayOffset = null, shouldLockFocus = true }) => {
  return (
    <FocusLock autoFocus={false} disabled={!shouldLockFocus}>
      <StyledOverlay overlayOffset={overlayOffset}>
        {children}
        <StyledCloseButton onClick={onHidePopup} aria-label="Close overlay">
          <img src={IconX} height="16px" width="16px" alt="" />
        </StyledCloseButton>
      </StyledOverlay>
    </FocusLock>
  );
};

Overlay.propTypes = {
  children: PropTypes.node.isRequired,
  onHidePopup: PropTypes.func.isRequired,
  overlayOffset: PropTypes.string,
  shouldLockFocus: PropTypes.bool,
};

Overlay.defaultProps = {
  shouldLockFocus: false,
};

export default Overlay;
