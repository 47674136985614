import styled from 'styled-components';
import { colors } from 'app/shared/styles/_colors';
import { navbar, variables } from 'app/shared/styles/_variables';
import { viewports } from 'app/shared/styles/_breakpoints';
import { zindex } from 'app/shared/styles/_zindex';

export const StyledListingNavBar = styled.div`
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: ${navbar['hdp-nav-bar-height-md']};
  margin-top: -${navbar['hdp-nav-bar-height-md']};
  z-index: ${zindex['$z-index-over-parent']};
  background: ${colors['$hpx-white']};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

  @media ${viewports['md-and-up']} {
    width: ${variables['sidebar-width-md-bigger-listing-cards']};
  }

  @media ${viewports['xl-and-up-bigger-listing-cards']} {
    width: ${variables['sidebar-width-xl-bigger-listing-cards']};
    height: ${navbar['hdp-nav-bar-height-xl']};
    margin-top: -${navbar['hdp-nav-bar-height-xl']};
  }
`;
