import styled from 'styled-components';
import { viewports } from 'app/shared/styles/_breakpoints';
import { font } from 'app/shared/styles/_fontsize';
import Container from 'app/shared/core/Container';

export const HdpPpcLink = styled(Container)`
  ${font.sm};
  @media ${viewports['md-and-up']} {
    ${font.md};
  }
`;
