import React from 'react';
import loadable from '@loadable/component';

import SkeletonHdp from 'app/shared/modules/skeleton/SkeletonHdp';

const Hdp = loadable(
  () => import(/* webpackChunkName: "HdpComponent.loadable" */ 'app/shared/modules/hdp/Hdp/component'),
  {
    fallback: <SkeletonHdp />,
  },
);

class LoadableHdp extends React.Component {
  render() {
    return <Hdp {...this.props} />;
  }
}

export default LoadableHdp;
