// @ts-nocheck
/* eslint-enable */
import React from 'react';
import { connect } from 'react-redux';
import { InView } from 'react-intersection-observer';
import PropTypes from 'prop-types';

import 'app/shared/modules/hdp/HdpForm.scss';
import { analyticsEvent } from 'app/client/universal-analytics';
import { gaEvents } from 'app/shared/constants/AnalyticsConstants';
import Contact from 'app/shared/modules/hdp/form/Contact/Contact';
import { TrackInlineContactFormView } from 'app/shared/models/Clickstream/HdpClickstreamEvents';

const { object, func, array } = PropTypes;

class HdpForm extends React.Component {
  static propTypes = {
    listing: object.isRequired,
    similarListings: array,
    onShowSuccessModal: func,
  };

  static defaultProps = {
    similarListings: [],
    onShowSuccessModal() {},
  };

  constructor(props) {
    super(props);
  }

  handleScrollToBottom = () => {
    const { dispatch } = this.props;
    dispatch(analyticsEvent(gaEvents.HDP_SCROLL_REVEALS_LEAD_FORM, { newLaneEvent: TrackInlineContactFormView() }));
  };

  render() {
    const { listing, similarListings, onShowSuccessModal } = this.props;

    return (
      <div className="HdpForm" id="HdpForm">
        <Contact listing={listing} similarListings={similarListings} onShowSuccessModal={onShowSuccessModal} />
        {/* Handles GA tracking when user scrolls and HDP form comes into view */}
        <InView
          as="span"
          triggerOnce
          onChange={(inView) => {
            if (inView) {
              this.handleScrollToBottom();
            }
          }}
        />
      </div>
    );
  }
}

export default connect()(HdpForm);
